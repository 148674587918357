import {APIService, ICDCodeGroupSerializer, ICDCodeSerializer} from '../@core/api.service';
import {ICDCodeGroupService} from '../@core/icd-groups.service';
import {assignOrCreate, ModelBase} from './model-base';
import {toIcdFormat} from '../utils/string.utils';
import {GetterCache, MethodCache} from '../utils/accessor.utils';
import {map, shareReplay} from 'rxjs/operators';
import {ICD_VALUESET_OIDS} from '../@core/icd_valueset_oids';

export class ICDCodeGroup extends ModelBase implements ICDCodeGroupSerializer {
    id: number;
    name: string;
    category: number;
    color?: string;

    assign(codeGroup: ICDCodeGroupSerializer) {
        if (!ICDCodeGroup.get(codeGroup.id)) super.assign(codeGroup);
    }
}

export class ICDCode extends ModelBase implements ICDCodeSerializer {
    id: number;
    name: string;
    infection: boolean;
    description: string;
    icd_groups: ICDCodeGroup[];
    specialties: number[];
    is_chronic: boolean;
    display_name: any;
    is_effective: boolean;
    name_with_decimals: any;
    code: string;

    static viewSet = APIService.ICDCodeViewSet;

    assign(code: ICDCodeSerializer) {
        super.assign(code);

        if (code.icd_groups) {
            this.icd_groups = code.icd_groups.map(x => assignOrCreate(ICDCodeGroup, x));
        } else if (!this.icd_groups) {
            this.icd_groups = [];
        }

        this.code = toIcdFormat(this.name);
    }

    static getGroup(x: ICDCodeSerializer) {
        return x.icd_groups?.find(group => group?.category === ICDCodeGroupService.defGroupSetKey);
    }

    get group(): ICDCodeGroup {
        return ICDCode.getGroup(this) as ICDCodeGroup;
    }

    get ICDGroup(): ICDCodeGroup {
        return this.icd_groups.length ? this.icd_groups[0] : undefined;
    }

    get color(): string {
        return (this.group || this.ICDGroup)?.color;
    }

    static fullText(x: ICDCodeSerializer) {
        return `${toIcdFormat(x.name)} - ${x.description}`;
    }

    @GetterCache()
    get fullText() {
        return ICDCode.fullText(this);
    }

    @MethodCache()
    static getValueSet(category: keyof typeof ICD_VALUESET_OIDS) {
        return APIService.ICDValueSetViewSet.list({category}).pipe(
            map(res => res.map(x => assignOrCreate(ICDCode, x))),
            shareReplay(1),
        );
    }
}
