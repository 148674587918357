/** generated by `python manage.py export_api`, do not change */
// @ts-ignore
import {HttpClient, HttpParams} from "@angular/common/http";
// @ts-ignore
import {Injectable} from "@angular/core";
// @ts-ignore
import {Observable} from "rxjs";
// @ts-ignore
import {environment} from "../../environments/environment";

export interface QueryParams {
    [param: string]: string | number | boolean | Date | ReadonlyArray<string | number | boolean | Date>;
}

export interface Headers {
    [header: string]: string | string[];
}

interface Data {
    [prop: string]: any;
}

type PK = number;

type EndpointExtraArgs = [QueryParams?, Headers?];

export type ListEndpointArgs = EndpointExtraArgs;
export type CreateEndpointArgs = [Data, ...EndpointExtraArgs];
export type RetrieveEndpointArgs = [PK, ...EndpointExtraArgs];
export type UpdateEndpointArgs = [PK, Data, ...EndpointExtraArgs];
export type DestroyEndpointArgs = [PK, ...EndpointExtraArgs];

export type ListEndpoint<T> = (...args: ListEndpointArgs) => Observable<T>;
export type CreateEndpoint<T> = (...args: CreateEndpointArgs) => Observable<T>;
export type RetrieveEndpoint<T> = (...args: RetrieveEndpointArgs) => Observable<T>;
export type UpdateEndpoint<T> = (...args: UpdateEndpointArgs) => Observable<T>;
export type DestroyEndpoint<T> = (...args: DestroyEndpointArgs) => Observable<T>;

export type EndpointArgs = ListEndpointArgs | CreateEndpointArgs | RetrieveEndpointArgs | UpdateEndpointArgs | DestroyEndpointArgs;
export type Endpoint<T> = ListEndpoint<T> | CreateEndpoint<T> | RetrieveEndpoint<T> | UpdateEndpoint<T> | DestroyEndpoint<T>;

function createReqSettings<T>(queryParams?: T, headers?: Headers) {
    let r: {params?: HttpParams, headers?: Headers} = {};
    if (queryParams) {
        let p = new HttpParams();
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value || !['organization', 'facility'].some(x => key.includes(x)) && (value === 0 || value === false)) {
                let val: string;
                if (value as any instanceof Date) {
                    val = new Date(value as any).toISOString();
                } else if (typeof value === 'boolean') {
                    val = value ? '1' : '0';
                } else if (Array.isArray(value)) {
                    val = value.join(',');
                } else {
                    val = '' + value;
                }
                p = p.set(key, val);
            }
        });
        r.params = p;
    }
    if (headers) {
        r.headers = headers;
    }
    return r;
}

export function getQueryString(queryParams: QueryParams) {
    return createReqSettings(queryParams).params.toString();
}

export function addQueryString(path, queryParams) {
    if (!queryParams) return path;

    return path + "?" + getQueryString(queryParams);
}

export interface Paginated<T> {
    count: number;
    next: string;
    previous: string;
    results: T;
}

const AP = environment.apiPrefix;

export type ActionViewSetOrderingChoices = 'lab_order__lab_test_synonym__name' | '-lab_order__lab_test_synonym__name' | 'lab_order__urgency' | '-lab_order__urgency' | 'other_order__comments' | '-other_order__comments' | 'other_order__type' | '-other_order__type' | 'resolved_at' | '-resolved_at' | 'rx_order__instructions' | '-rx_order__instructions' | 'rx_order__medication' | '-rx_order__medication' | 'task__date' | '-task__date' | 'task__patient__last_name' | '-task__patient__last_name' | 'task__user__last_name' | '-task__user__last_name'

export type AntibioticsPatientViewSetOrderingChoices = 'id' | '-id' | 'last_name' | '-last_name' | 'treatment_count' | '-treatment_count'

export type AntibioticsTreatmentHistoryViewSetOrderingChoices = 'average_treatment_length' | '-average_treatment_length' | 'class_name' | '-class_name' | 'drug_administration_route' | '-drug_administration_route' | 'drug_name__name' | '-drug_name__name' | 'end_date' | '-end_date' | 'id' | '-id' | 'patient__last_name' | '-patient__last_name' | 'start_date' | '-start_date' | 'treatment_count' | '-treatment_count' | 'treatment_length' | '-treatment_length'

export type ClaimViewSetOrderingChoices = 'bill_name' | '-bill_name' | 'charge_sum' | '-charge_sum' | 'created_at' | '-created_at' | 'facility__name' | '-facility__name' | 'items__allowed_amount' | '-items__allowed_amount' | 'items__charge' | '-items__charge' | 'items__paid' | '-items__paid' | 'items__pos' | '-items__pos' | 'items__proc_code' | '-items__proc_code' | 'items__service_from' | '-items__service_from' | 'items__status' | '-items__status' | 'modified_at' | '-modified_at' | 'pat_rel' | '-pat_rel' | 'patient__first_name' | '-patient__first_name' | 'patient__last_name' | '-patient__last_name' | 'primary_payer_alt_name__name' | '-primary_payer_alt_name__name' | 'remits__id' | '-remits__id' | 'ren_first_name' | '-ren_first_name' | 'ren_last_name' | '-ren_last_name' | 'ren_prov_npi' | '-ren_prov_npi' | 'secondary_payer_alt_name__name' | '-secondary_payer_alt_name__name' | 'status' | '-status' | 'tasks__level_of_care' | '-tasks__level_of_care' | 'type' | '-type'

export type ClinicalDashboardViewOrderingChoices = 'drug' | '-drug' | 'end_date' | '-end_date' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'start_date' | '-start_date'

export type DailyVisitReportViewSetOrderingChoices = 'date' | '-date' | 'description' | '-description' | 'duration' | '-duration' | 'history_of_present_illness__goals_of_care' | '-history_of_present_illness__goals_of_care' | 'is_valid_for_billing' | '-is_valid_for_billing' | 'last_billing_status__status' | '-last_billing_status__status' | 'level_of_care' | '-level_of_care' | 'modified_at' | '-modified_at' | 'patient__date_ob' | '-patient__date_ob' | 'patient__facility__name' | '-patient__facility__name' | 'patient__last_name' | '-patient__last_name' | 'signed_at' | '-signed_at' | 'status' | '-status' | 'type' | '-type' | 'user__last_name' | '-user__last_name' | 'visit__date' | '-visit__date' | 'visit_details__addon_code' | '-visit_details__addon_code' | 'visit_details__billing_code' | '-visit_details__billing_code' | 'visit_details__billing_code' | '-visit_details__billing_code' | 'visit_details__comments' | '-visit_details__comments' | 'visit_details__next_appointment_in' | '-visit_details__next_appointment_in' | 'visit_details__next_appointment_with' | '-visit_details__next_appointment_with' | 'visit_details__reason_for_next_visit' | '-visit_details__reason_for_next_visit'

export type EligibilityRequestViewSetOrderingChoices = 'timestamp' | '-timestamp'

export type HL7UnmatchedViewSetOrderingChoices = 'date_ob' | '-date_ob' | 'last_name' | '-last_name' | 'received_at' | '-received_at'

export type ImmunizationViewSetOrderingChoices = 'date' | '-date' | 'name' | '-name' | 'procedure_type' | '-procedure_type' | 'type' | '-type'

export type ImportAccountStatusHistoryViewSetOrderingChoices = 'timestamp' | '-timestamp'

export type LabReportViewSetOrderingChoices = 'lab_tests__lab_type' | '-lab_tests__lab_type' | 'lab_tests__name' | '-lab_tests__name' | 'patient__census_location' | '-patient__census_location' | 'patient__facility__name' | '-patient__facility__name' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'resulted_date' | '-resulted_date'

export type LabTestViewSetOrderingChoices = 'lab_report__patient__census_location' | '-lab_report__patient__census_location' | 'lab_report__patient__facility__name' | '-lab_report__patient__facility__name' | 'lab_report__patient__last_name' | '-lab_report__patient__last_name' | 'lab_report__physician__last_name' | '-lab_report__physician__last_name' | 'lab_report__resulted_date' | '-lab_report__resulted_date' | 'lab_type' | '-lab_type' | 'name' | '-name'

export type MedicalProgramBillingPatientViewSetOrderingChoices = 'billing_npi_number' | '-billing_npi_number' | 'patient__last_name' | '-patient__last_name' | 'winning_procedure_code__duration' | '-winning_procedure_code__duration' | 'winning_procedure_code__procedure_code' | '-winning_procedure_code__procedure_code'

export type MedicationsViewSetOrderingChoices = 'drug' | '-drug' | 'end_date' | '-end_date' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'start_date' | '-start_date'

export type OrderViewSetOrderingChoices = 'end_date' | '-end_date' | 'order_type' | '-order_type' | 'patient__last_name' | '-patient__last_name' | 'start_date' | '-start_date'

export type PatientAdvancedMonitoringListViewSetOrderingChoices = 'date_ob' | '-date_ob' | 'first_name' | '-first_name' | 'id' | '-id' | 'last_name' | '-last_name'

export type PatientCensusViewSetOrderingChoices = 'census_location__name' | '-census_location__name' | 'census_payer' | '-census_payer' | 'date_ob' | '-date_ob' | 'facility' | '-facility' | 'gender' | '-gender' | 'last_name' | '-last_name' | 'last_seen' | '-last_seen' | 'last_seen_by_physician' | '-last_seen_by_physician' | 'physician__last_name' | '-physician__last_name' | 'scheduled' | '-scheduled'

export type PatientDocumentViewSetOrderingChoices = 'details__completed_date' | '-details__completed_date' | 'details__effective_date' | '-details__effective_date' | 'details__patient' | '-details__patient' | 'details__revised_by' | '-details__revised_by' | 'details__status' | '-details__status' | 'details__target_completion_date' | '-details__target_completion_date' | 'details__upload_date' | '-details__upload_date' | 'details__upload_target' | '-details__upload_target' | 'details__upload_trigger' | '-details__upload_trigger' | 'details__uploaded_by' | '-details__uploaded_by' | 'name' | '-name' | 'size' | '-size' | 'sub_type' | '-sub_type'

export type PatientPayerCategoryChangeViewSetOrderingChoices = 'last_name' | '-last_name' | 'start_date' | '-start_date'

export type PatientPhysicianTeamAccessPermissionViewSetOrderingChoices = 'created_at' | '-created_at' | 'modified_at' | '-modified_at' | 'patient__age' | '-patient__age' | 'patient__date_ob' | '-patient__date_ob' | 'patient__facility__name' | '-patient__facility__name' | 'patient__last_name' | '-patient__last_name' | 'physician_team__last_name' | '-physician_team__last_name' | 'status' | '-status'

export type PatientsAvailableToRequestAccessPermissionViewSetOrderingChoices = 'age' | '-age' | 'census_admission_date' | '-census_admission_date' | 'date_ob' | '-date_ob' | 'facility__name' | '-facility__name' | 'last_name' | '-last_name'

export type PsychotropicsViewSetOrderingChoices = 'date_ob' | '-date_ob' | 'groups' | '-groups' | 'missing_patient_count' | '-missing_patient_count' | 'name' | '-name' | 'off_label_patient_count' | '-off_label_patient_count' | 'patient_count' | '-patient_count' | 'primary_patient_count' | '-primary_patient_count' | 'secondary_patient_count' | '-secondary_patient_count'

export type RemitsViewSetOrderingChoices = 'first_name' | '-first_name' | 'last_name' | '-last_name' | 'origin_claim_id' | '-origin_claim_id' | 'paid' | '-paid' | 'payment__date' | '-payment__date' | 'payment__payer_name' | '-payment__payer_name'

export type ReportViewSetOrderingChoices = 'created_at' | '-created_at' | 'download_count' | '-download_count' | 'report' | '-report' | 'status' | '-status'

export type StatusObservationViewSetOrderingChoices = '-observation_date' | '--observation_date'

export type TaskSchedulerViewSetOrderingChoices = 'date' | '-date' | 'description' | '-description' | 'history_of_present_illness__goals_of_care' | '-history_of_present_illness__goals_of_care' | 'level_of_care' | '-level_of_care' | 'patient__date_ob' | '-patient__date_ob' | 'patient__facility__name' | '-patient__facility__name' | 'patient__last_name' | '-patient__last_name' | 'status' | '-status' | 'type' | '-type' | 'user__last_name' | '-user__last_name' | 'visit__date' | '-visit__date' | 'visit_details__billing_code' | '-visit_details__billing_code'

export type TaskViewSetOrderingChoices = 'date' | '-date' | 'description' | '-description' | 'history_of_present_illness__goals_of_care' | '-history_of_present_illness__goals_of_care' | 'level_of_care' | '-level_of_care' | 'patient__date_ob' | '-patient__date_ob' | 'patient__facility__name' | '-patient__facility__name' | 'patient__last_name' | '-patient__last_name' | 'status' | '-status' | 'type' | '-type' | 'user__last_name' | '-user__last_name' | 'visit__date' | '-visit__date' | 'visit_details__billing_code' | '-visit_details__billing_code'

export type TreatmentHistoryAntibioticsViewSetOrderingChoices = 'drug' | '-drug' | 'end_date' | '-end_date' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'start_date' | '-start_date'

export type TreatmentHistoryAntidepressantsViewSetOrderingChoices = 'drug' | '-drug' | 'end_date' | '-end_date' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'start_date' | '-start_date'

export type TreatmentHistoryAntipsychoticsViewSetOrderingChoices = 'drug' | '-drug' | 'end_date' | '-end_date' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'start_date' | '-start_date'

export type TreatmentHistoryNarcoticsViewSetOrderingChoices = 'drug' | '-drug' | 'end_date' | '-end_date' | 'patient__last_name' | '-patient__last_name' | 'physician__last_name' | '-physician__last_name' | 'start_date' | '-start_date'

export type UserAccessViewSetOrderingChoices = 'timestamp' | '-timestamp' | 'user__id' | '-user__id'

export type UserLogViewSetOrderingChoices = 'facility' | '-facility' | 'first_signin' | '-first_signin' | 'last_name' | '-last_name' | 'last_signin' | '-last_signin' | 'total_signin_count' | '-total_signin_count'

export type UserViewSetOrderingChoices = 'created_at' | '-created_at' | 'email' | '-email' | 'facilities' | '-facilities' | 'groups' | '-groups' | 'is_active' | '-is_active' | 'is_suspended' | '-is_suspended' | 'last_name' | '-last_name' | 'last_signin' | '-last_signin'

export type VisitReportViewSetOrderingChoices = 'date' | '-date' | 'description' | '-description' | 'duration' | '-duration' | 'history_of_present_illness__goals_of_care' | '-history_of_present_illness__goals_of_care' | 'is_valid_for_billing' | '-is_valid_for_billing' | 'last_billing_status__status' | '-last_billing_status__status' | 'level_of_care' | '-level_of_care' | 'modified_at' | '-modified_at' | 'patient__date_ob' | '-patient__date_ob' | 'patient__facility__name' | '-patient__facility__name' | 'patient__last_name' | '-patient__last_name' | 'signed_at' | '-signed_at' | 'status' | '-status' | 'type' | '-type' | 'user__last_name' | '-user__last_name' | 'visit__date' | '-visit__date' | 'visit_details__addon_code' | '-visit_details__addon_code' | 'visit_details__billing_code' | '-visit_details__billing_code' | 'visit_details__billing_code' | '-visit_details__billing_code' | 'visit_details__comments' | '-visit_details__comments' | 'visit_details__next_appointment_in' | '-visit_details__next_appointment_in' | 'visit_details__next_appointment_with' | '-visit_details__next_appointment_with' | 'visit_details__reason_for_next_visit' | '-visit_details__reason_for_next_visit'

export type VitalViewSetOrderingChoices = 'recorded' | '-recorded' | 'time' | '-time'

export type WorkListViewSetOrderingChoices = 'date_ob' | '-date_ob' | 'effective_date' | '-effective_date' | 'first_name' | '-first_name' | 'instructions' | '-instructions' | 'last_name' | '-last_name' | 'medication_name' | '-medication_name' | 'pharmacy_name' | '-pharmacy_name' | 'physician' | '-physician' | 'physician_group' | '-physician_group' | 'received_date' | '-received_date' | 'request_type' | '-request_type' | 'request_uid' | '-request_uid' | 'status' | '-status'

export type PatientCensusViewSetLiteralChoices = 'FACILITY' | 'STATUS' | 'LOC' | 'ANY'

export interface AccessTokenViewSetQueryParams {
    application_id?: number;
    search?: string;
}

export interface ActionViewSetQueryParams {
    action?: string;
    assessment_plan__isnull?: boolean;
    bst?: number;
    diagnosis?: any;
    draft?: boolean;
    e_prescription?: boolean;
    end_date?: Date | string;
    event?: number;
    exclude_actions?: string[];
    facility?: number;
    hidden_in_plan?: boolean;
    icd?: any;
    lab_test?: number;
    lab_test__in?: string;
    order?: boolean;
    ordering?: ActionViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    payer_category?: string;
    physician?: number;
    resolved?: boolean;
    rx_order__controlled_substance?: any;
    search?: string;
    start_date?: Date | string;
    task__assigned_users__user?: any;
    task__patient?: any;
    task__patient__physician?: any;
    task__status?: any;
    task__user?: any;
    treatment_history?: number;
    type?: string;
}

export interface AddressViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patients?: any;
}

export interface AllPayersQueryParams {
    facility?: number;
    organization?: number;
    payer_category?: string;
}

export interface AllergyViewSetQueryParams {
    deduplicate?: boolean;
    page?: number;
    page_size?: number;
    patients?: any;
    search?: string;
}

export interface AntibioticsPatientViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    interval?: string;
    member?: boolean;
    on_call?: boolean;
    ordering?: AntibioticsPatientViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    payer_category?: string;
    physician?: number;
}

export interface AntibioticsTreatmentHistoryViewSetQueryParams {
    bst_view?: string;
    class_name?: string;
    days?: number;
    drug_name?: string;
    end_date?: Date | string;
    facility?: number;
    interval?: string;
    new_admissions?: number;
    ordering?: AntibioticsTreatmentHistoryViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    payer_category?: string;
    physician?: number;
    route?: string;
    start_date?: Date | string;
}

export interface ApplicationLaunchViewQueryParams {
    application_id?: number;
    encounter_id?: number;
    patient_id?: number;
}

export interface AvailableFacilityViewSetQueryParams {
    id?: number;
    name?: string;
    zip_code?: string;
}

export interface AvailableProvidersViewSetQueryParams {
    facility?: number;
}

export interface BedBedOriginViewSetQueryParams {
    bed__location__facility?: any;
    bed__location__facility__organization?: any;
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface BedOriginViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    is_active?: boolean;
    location__facility?: any;
    location__facility__organization?: any;
    organization?: number;
}

export interface BedViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    is_active?: boolean;
    location?: any;
    location__facility?: any;
    location__facility__organization?: any;
    organization?: number;
}

export interface BillingCodePriceViewSetQueryParams {
    codes?: string[];
    locality?: string;
}

export interface BillingGroupViewSetQueryParams {
    customer?: any;
}

export interface BillingInfoApprovalViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient__facility?: any;
}

export interface BillingInfoNotRequestedPatientsModelViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface BillingInfoRequestViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface CalendarEventViewSetQueryParams {
    end_date?: Date | string;
    month?: number;
    on_call?: boolean;
    physician__in?: number[];
    start_date?: Date | string;
    type?: string;
    user_facility__in?: number[];
    user_facility__user?: any;
    year?: number;
}

export interface CaptchaViewSetQueryParams {
    facility?: number;
    organization?: number;
}

export interface CensusHistoryRehospitalizationViewSetQueryParams {
    bst_view?: string;
    companies?: string[];
    day_of_admission?: string;
    day_of_leave?: string;
    end_date?: number;
    facility?: number;
    hospital_names?: string[];
    organization?: number;
    patient_id?: any;
    start_date?: number;
}

export interface CensusHistoryViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_id?: any;
}

export interface CensusOversightViewQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    payer_category?: string;
    physician?: number;
    start_date?: Date | string;
    timestamp__gt?: Date | string;
    timestamp__lt?: Date | string;
}

export interface CensusStatusCheckViewSetQueryParams {
    facility_id?: number;
}

export interface ChargeAmountSettingViewSetQueryParams {
    customer?: any;
    physician_team?: any;
    user?: any;
}

export interface ClaimViewSetQueryParams {
    billing_code?: string;
    bst_view?: string;
    comment?: string;
    facility?: number;
    facility__in?: number[];
    generated_end_date?: Date | string;
    generated_start_date?: Date | string;
    ordering?: ClaimViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    pat_first_name?: string;
    pat_last_name?: string;
    patient?: any;
    patient__first_name?: any;
    patient__last_name?: any;
    provider__in?: number[];
    sbr_first_name?: string;
    sbr_last_name?: string;
    search?: string;
    service_end_date?: Date | string;
    service_start_date?: Date | string;
    status?: string;
    status__in?: string[];
    timezone?: any;
    updated_end_date?: Date | string;
    updated_start_date?: Date | string;
}

export interface ClinicalDashboardViewQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    date__gt?: Date | string;
    date__lt?: Date | string;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    icd?: number[];
    member?: boolean;
    on_call?: boolean;
    ordering?: TreatmentHistoryAntipsychoticsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    prn?: string;
    product_type_category?: string[];
    search?: string;
    start_date?: Date | string;
    type?: string;
    views?: string[];
}

export interface ClinicalTestResultViewSetQueryParams {
    bst_view?: string;
    class_types?: any;
    code?: any;
    facility?: number;
    organization?: number;
    patient?: any;
    search?: any;
}

export interface CoachingViewSetQueryParams {
    comparison_period_count?: number;
    end_date?: Date | string;
    facility?: number;
    interval?: string;
    organization?: number;
    start_date?: Date | string;
    timezone?: string;
}

export interface CodedNoteViewSetQueryParams {
    bst_view?: string;
    code?: any;
    facility?: number;
    organization?: number;
    task?: any;
}

export interface ContactPointViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patients?: any;
}

export interface CptCodeDetailViewSetQueryParams {
    codes?: string[];
}

export interface CriticalValueViewQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    medical_result_type?: any;
    medical_result_type__in?: number[];
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    recorded__gt?: Date | string;
    recorded__lt?: Date | string;
    start_date?: Date | string;
}

export interface DailyVisitReportViewSetQueryParams {
    assignee__in?: number[];
    billing_code?: string;
    bst_view?: string;
    downloaded_for_billing?: boolean;
    draft?: boolean;
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    include_drafts?: boolean;
    ordering?: DailyVisitReportViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_name?: string;
    pending?: boolean;
    physician?: number;
    physician__in?: number[];
    reported_at_end_date?: Date | string;
    reported_at_start_date?: Date | string;
    reviewed_for_scheduling?: boolean;
    signed_at_end_date?: Date | string;
    signed_at_start_date?: Date | string;
    start_date?: Date | string;
    timezone?: any;
    to_review?: boolean;
    unverified?: boolean;
    user?: any;
    user__in?: number[];
    validated?: boolean;
    verified_at_end_date?: Date | string;
    verified_at_start_date?: Date | string;
    visit_day?: string;
    visit_physician?: number;
    with_claim?: boolean;
}

export interface DepartmentViewSetQueryParams {
    id?: number;
    name?: string;
    organization?: any;
    page?: number;
    page_size?: number;
}

export interface DiagnosisViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
    psychotropic_group__in?: string[];
    specialty_id?: number;
}

export interface DocumentViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    id?: number;
    organization?: number;
    page?: number;
    page_size?: number;
}

export interface DrugCategoryViewSetQueryParams {
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugNameViewSetQueryParams {
    drug?: any;
    has_treatment?: boolean;
    id?: number;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugViewSetQueryParams {
    categories?: any;
    drug_names__name?: any;
    drugbank_id?: string;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugbankProductConceptRxCuiViewSetQueryParams {
    id?: number;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugbankProductConceptViewSetQueryParams {
    id?: number;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugbankProductViewSetQueryParams {
    id?: number;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugbankValueSetViewSetQueryParams {
    category?: string;
    oids?: string;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface DrugsCategoryViewSetQueryParams {
    page?: number;
    page_size?: number;
}

export interface EMRImportViewSetQueryParams {
    sync_detail_id?: number;
    task?: number;
}

export interface EMRProfessionalViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface EMRUploadViewSetQueryParams {
    bst_view?: string;
    created_at__gt?: Date | string;
    created_at__lt?: Date | string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    id__in?: number[];
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    place?: string;
    start_date?: Date | string;
    status?: string;
}

export interface EligibilityRequestViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    ordering?: EligibilityRequestViewSetOrderingChoices;
    organization?: number;
}

export interface EncounterViewQueryParams {
    patient?: number;
}

export interface EntryProposalViewSetQueryParams {
    type?: string;
}

export interface EventViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    date__gt?: Date | string;
    date__lt?: Date | string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    start_date?: Date | string;
    type?: string;
}

export interface FHIRDeviceParserViewQueryParams {
    di?: string;
    udi?: string;
}

export interface FHIRDeviceViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patient?: number;
}

export interface FHIRQuestionnaireResponseViewSetQueryParams {
    questionnaire?: any;
    task?: any;
}

export interface FHIRQuestionnaireViewSetQueryParams {
    code?: string;
    id?: number;
}

export interface FacilityActivePatientStatisticsViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface FacilityLabImportAccountViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface FacilityOriginViewSetQueryParams {
    id?: number;
    import_account?: any;
    lab_facilities__facility_id?: any;
    name?: string;
}

export interface FacilityViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    id?: number;
    name?: string;
    organization?: number;
    search?: string;
    zip_code?: string;
}

export interface FaxStatusHistoryViewSetQueryParams {
    lab_report?: number;
    task?: number;
}

export interface FaxViewSetQueryParams {
    facility?: number;
    id?: number;
    lab_report?: number;
    organization?: number;
    task?: number;
}

export interface FloorPlanViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    facility__organization?: any;
    organization?: number;
}

export interface FollowedPatientViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    on_call?: boolean;
    organization?: number;
    page?: number;
    page_size?: number;
}

export interface GetLanguageFromCodeQueryParams {
    language_code?: string;
}

export interface HL7DashboardViewSetQueryParams {
    page?: number;
    page_size?: number;
    source?: string;
    type?: string;
}

export interface HL7MessageViewSetQueryParams {
    error_code?: string;
    facility?: any;
    id__in?: number[];
    lab_provider?: string;
    page?: number;
    page_size?: number;
    patient?: any;
    sftp_username?: string;
    status?: string;
    type?: string;
}

export interface HL7UnmatchedViewSetQueryParams {
    facility?: number;
    ordering?: HL7UnmatchedViewSetOrderingChoices;
    page?: number;
    page_size?: number;
    patient?: number;
}

export interface ICDCodeAllLatestViewSetQueryParams {
    name__in?: string[];
    search?: string;
}

export interface ICDCodeGroupViewSetQueryParams {
    category?: number;
    page?: number;
    page_size?: number;
}

export interface ICDCodeIndicationsViewSetQueryParams {
    icd?: string;
    medication?: string;
    page?: number;
    page_size?: number;
}

export interface ICDCodeMedicalSpecialtyViewSetQueryParams {
    icd_id?: number;
}

export interface ICDCodeSuggestionViewSetQueryParams {
    treatment_id?: number;
}

export interface ICDCodeViewSetQueryParams {
    infection?: boolean;
    name?: string;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface ICDValueSetViewSetQueryParams {
    category?: string;
    oids?: string;
    search?: string;
}

export interface ImagingResultViewSetQueryParams {
    bst_view?: string;
    code?: any;
    facility?: number;
    organization?: number;
    patient?: any;
    search?: any;
}

export interface ImmunizationViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    covid?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    ordering?: ImmunizationViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    procedure_type?: string;
    start_date?: Date | string;
}

export interface ImportAccountStatusHistoryViewSetQueryParams {
    import_account?: number;
    ordering?: ImportAccountStatusHistoryViewSetOrderingChoices;
    page?: number;
    page_size?: number;
}

export interface ImportAccountViewSetQueryParams {
    import_type?: string;
    is_renewable?: boolean;
}

export interface ImportFileUploadViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    id?: number;
    organization?: number;
    page?: number;
    page_size?: number;
}

export interface ImportLogViewSetQueryParams {
    auth_logs?: boolean;
    days?: number;
    end_date?: Date | string;
    level?: string;
    level__in?: string[];
    start_date?: Date | string;
    sync_detail?: any;
    sync_detail_id?: number;
    task?: string;
    timestamp?: Date | string;
    timestamp__gt?: Date | string;
    timestamp__lt?: Date | string;
    type?: string;
    type__in?: string[];
}

export interface ImportViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    id?: number;
    name?: string;
    organization?: number;
}

export interface IndicationViewSetQueryParams {
    drug_name?: string;
    icd_name?: string;
    indication_type?: string;
    page?: number;
    page_size?: number;
}

export interface InfectionEntryViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    by_start_date?: boolean;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    covid?: boolean;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    id?: number;
    member?: boolean;
    monthly_antibiotic?: boolean;
    on_call?: boolean;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    start_date?: Date | string;
}

export interface InfectionInformationVerificationViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    infection_information?: any;
    organization?: number;
}

export interface InfectionInformationViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
}

export interface InitialImportViewSetQueryParams {
    days?: number;
    facility_id?: number;
    last_data_import?: boolean;
    reports?: any;
    start?: any;
    type?: string;
}

export interface InsuranceCompaniesQueryParams {
    facility?: number;
    organization?: number;
    payer_category?: string;
}

export interface LabAntibiogramViewSetQueryParams {
    bst_view?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    lab_result__lab_report__collected_date__gt?: Date | string;
    lab_result__lab_report__collected_date__lt?: Date | string;
    organization?: number;
    start_date?: Date | string;
    timezone?: string;
}

export interface LabOrderViewSetQueryParams {
    barcode?: string;
    encounter_id?: string;
    facility?: number;
    id?: number;
    lab_order_group?: any;
    lab_shipment?: number;
    organization?: number;
    patient?: number;
    pdf_format?: string;
    status?: string;
}

export interface LabReportViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    covid?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    has_result?: boolean;
    has_treatment?: boolean;
    id?: number;
    is_abnormal?: boolean;
    is_critical?: boolean;
    is_monochrome?: boolean;
    lab_order__lab_shipment?: any;
    lab_tests__lab_type?: any;
    lab_type?: string;
    member?: boolean;
    on_call?: boolean;
    ordering?: LabReportViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    patient__facility?: any;
    patient__facility__organization?: any;
    patient_id?: any;
    physician?: number;
    resulted_date?: Date | string;
    resulted_date__gt?: Date | string;
    resulted_date__lt?: Date | string;
    search?: string;
    start_date?: Date | string;
    status?: string;
}

export interface LabResultBillingReportViewSetQueryParams {
    billing_details?: boolean;
    bst_view?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    lab_report__resulted_date__gt?: Date | string;
    lab_report__resulted_date__lt?: Date | string;
    month?: number;
    ordered_test?: number;
    organization?: number;
    physician?: number;
    start_date?: Date | string;
    year?: number;
}

export interface LabResultViewSetQueryParams {
    bst_view?: string;
    covid?: boolean;
    created_at__gt?: Date | string;
    created_at__lt?: Date | string;
    culture_type?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    infection_culture?: any;
    lab_test__source?: string;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    search?: string;
    start_date?: Date | string;
}

export interface LabShipmentBagViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface LabShipmentBoxViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface LabShipmentViewSetQueryParams {
    bst_view?: string;
    date?: Date | string;
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    organization?: number;
    start_date?: Date | string;
    status__in?: string[];
}

export interface LabSponsoredInfectionControlViewSetQueryParams {
    bst_view?: string;
    covid?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    infection_culture?: number;
    lab_report__collected_date__gt?: Date | string;
    lab_report__collected_date__lt?: Date | string;
    lab_test__source?: string;
    mdro?: boolean;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    search?: string;
    start_date?: Date | string;
}

export interface LabTestTypesViewSetQueryParams {
    patient?: number;
    search?: string;
}

export interface LabTestViewSetQueryParams {
    aco?: boolean;
    active?: number;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    attachment?: number;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    companies?: string[];
    covid?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    has_result?: boolean;
    has_treatment?: boolean;
    id?: number;
    in_house?: number;
    is_abnormal?: boolean;
    is_critical?: boolean;
    lab_report__patient__facility?: any;
    lab_report__patient__facility__organization?: any;
    lab_report__patient_id?: any;
    lab_report__resulted_date?: any;
    lab_report__resulted_date__gt?: Date | string;
    lab_report__resulted_date__lt?: Date | string;
    lab_type?: string;
    member?: boolean;
    on_call?: boolean;
    ordering?: LabTestViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    physician?: number;
    search?: string;
    specialty_id?: number;
    start_date?: Date | string;
    status?: string;
}

export interface LabelViewSetQueryParams {
    search?: string;
}

export interface LastVisitTaskViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    ids?: number[];
    organization?: number;
}

export interface LocationViewSetQueryParams {
    facility?: any;
    name?: string;
    page?: number;
    page_size?: number;
}

export interface LogViewSetQueryParams {
    auth_logs?: boolean;
    days?: number;
    end_date?: Date | string;
    level?: string;
    level__in?: string[];
    start_date?: Date | string;
    sync_detail?: any;
    timestamp?: Date | string;
    timestamp__gt?: Date | string;
    timestamp__lt?: Date | string;
    type?: string;
    type__in?: string[];
}

export interface MACLocalityBillingCodeViewSetQueryParams {
    billing_code?: string;
    search?: string;
}

export interface ManualImportViewQueryParams {
    document_id?: number;
    report_type?: string;
}

export interface MedicalProgramBillingFacilityViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    page?: number;
    page_size?: number;
}

export interface MedicalProgramBillingPatientViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    author__in?: number[];
    billing_npi_number?: string;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    medical_programs?: string[];
    member?: boolean;
    month?: number;
    on_call?: boolean;
    ordering?: MedicalProgramBillingPatientViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_id?: number;
    physician?: number;
    report_type?: string;
    search?: string;
    year?: number;
}

export interface MedicalProgramBillingTaskViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    author__in?: number[];
    billing_npi_number?: string;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    medical_programs?: string[];
    member?: boolean;
    month?: number;
    on_call?: boolean;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    patient_id?: number;
    physician?: number;
    search?: string;
    year?: number;
}

export interface MedicalProgramBillingUserViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    page?: number;
    page_size?: number;
}

export interface MedicalResultTypeViewSetQueryParams {
    critical?: number;
    page?: number;
    page_size?: number;
}

export interface MedicalSpecialtyViewSetQueryParams {
    drug_category_id?: number;
    drug_name_id?: number;
    icd_group?: number;
}

export interface MedicationsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    icd?: number[];
    member?: boolean;
    on_call?: boolean;
    ordering?: MedicationsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    prn?: string;
    product_type_category?: string[];
    search?: string;
    start_date?: Date | string;
}

export interface NotificationViewSetQueryParams {
    page?: number;
    page_size?: number;
    unread?: boolean;
}

export interface NurseStationViewSetQueryParams {
    facility?: number;
    id?: number;
    name?: string;
    organization?: number;
}

export interface OperationViewSetQueryParams {
    end_date?: Date | string;
    start_date?: Date | string;
}

export interface OrderDownloadViewSetQueryParams {
    consolidate?: boolean;
    end_date?: Date | string;
    facility?: number;
    initialize?: boolean;
    organization?: number;
    service_type?: string;
    start_date?: Date | string;
}

export interface OrderViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    order_type?: string;
    ordering?: OrderViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    search?: string;
    start_date?: Date | string;
    status?: string;
    type?: string;
}

export interface OrganizationGroupViewSetQueryParams {
    id?: number;
    name?: string;
    organization?: any;
}

export interface OrganizationViewSetQueryParams {
    facilities__name?: any;
    id?: number;
    name?: string;
}

export interface PIMeasuresViewSetQueryParams {
    end_date?: Date | string;
    facility_id?: number;
    physician_team_id?: number;
    report_type?: string;
    start_date?: Date | string;
}

export interface PatientAddressViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patient?: any;
}

export interface PatientAdvancedMonitoringListViewSetQueryParams {
    aco?: boolean;
    active?: number;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    companies?: string[];
    facility?: number;
    followed?: boolean;
    in_house?: number;
    member?: boolean;
    on_call?: boolean;
    ordering?: PatientAdvancedMonitoringListViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    physician?: number;
}

export interface PatientAllergyViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: number;
    search?: string;
}

export interface PatientCensusViewQueryParams {
    active?: boolean;
    assigned_patients?: boolean;
    ccm?: boolean;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    date?: any;
    facility?: number;
    followed?: boolean;
    hospice?: boolean;
    in_house?: boolean;
    long_term?: boolean;
    moqi?: boolean;
    order_by?: string[];
    organization?: number;
    patient?: number;
    patient_id?: number;
    payers?: number[];
    recently_active?: number;
    skilled?: boolean;
}

export interface PatientCensusViewSetQueryParams {
    aco?: boolean;
    active?: number;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_census_mismatch?: PatientCensusViewSetLiteralChoices;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    companies?: string[];
    customer?: number;
    exclude_related_patients?: number;
    facility?: number;
    facility__in?: number[];
    followed?: boolean;
    in_house?: number;
    last_visit_task_physician_team?: number;
    last_visit_task_type?: string;
    last_visit_task_user?: number;
    member?: boolean;
    on_call?: boolean;
    ordering?: PatientCensusViewSetOrderingChoices;
    organization?: number;
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    physician?: number;
    search?: string;
    specialties?: any;
}

export interface PatientComplexSearchViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    date_ob?: Date | string;
    facility?: number;
    first_name?: string;
    followed?: boolean;
    last_name?: string;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface PatientContactPointViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    patient?: any;
}

export interface PatientCreateViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    physician_team?: number;
}

export interface PatientDetailViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface PatientDocumentCDAViewSetQueryParams {
    bst_view?: string;
    document_id?: number;
    facility?: number;
    organization?: number;
    original_id?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    search?: string;
}

export interface PatientDocumentViewSetQueryParams {
    bst_view?: string;
    end_date?: Date | string;
    facility?: number;
    id?: number;
    ordering?: PatientDocumentViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    start_date?: Date | string;
    type?: string;
    types?: string[];
}

export interface PatientERXViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface PatientInformationAuditViewQueryParams {
    active?: number;
    bst_view?: string;
    companies?: string[];
    facility?: number;
    in_house?: number;
    organization?: number;
    patient_status?: string;
    payers?: string[];
}

export interface PatientInsuranceViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
}

export interface PatientMedicationServiceDetailsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface PatientMergeViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    from?: number;
    organization?: number;
    search?: string;
}

export interface PatientNoteViewSetQueryParams {
    bst_view?: string;
    date?: Date | string;
    facility?: number;
    organization?: number;
    patient?: any;
}

export interface PatientPayerCategoryChangeViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    ordering?: PatientPayerCategoryChangeViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    payer_category?: string;
    physician?: number;
    start_date?: Date | string;
    timestamp__gt?: Date | string;
    timestamp__lt?: Date | string;
}

export interface PatientPersonViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface PatientPhaseOfDeclineChangeViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    payer_category?: string;
    physician?: number;
    start_date?: Date | string;
}

export interface PatientPhysicianTeamAccessPermissionHistoryViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    medical_specialties?: number[];
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    physician_teams?: number[];
    statuses?: string[];
}

export interface PatientPhysicianTeamAccessPermissionViewSetQueryParams {
    active?: number;
    bst_view?: string;
    companies?: string[];
    days?: number;
    end_date?: Date | string;
    facility?: number;
    in_house?: number;
    medical_specialties?: number[];
    modified_at__gt?: Date | string;
    modified_at__lt?: Date | string;
    ordering?: PatientPhysicianTeamAccessPermissionViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_id?: number;
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    physician_teams?: number[];
    start_date?: Date | string;
    statuses?: string[];
}

export interface PatientPhysicianTeamAccessPermissionWithConnectionsViewSetQueryParams {
    bst_view?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    medical_specialties?: number[];
    modified_at__gt?: Date | string;
    modified_at__lt?: Date | string;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_id?: number;
    payer_category?: string;
    physician_teams?: number[];
    start_date?: Date | string;
    statuses?: string[];
}

export interface PatientPhysicianTeamViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface PatientQrdaExportViewSetQueryParams {
    bst_view?: string;
    cms_id?: string;
    facility?: number;
    organization?: number;
    year?: number;
}

export interface PatientReportViewSetQueryParams {
    aco?: boolean;
    active?: number;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    companies?: string[];
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    followed?: boolean;
    in_house?: number;
    member?: boolean;
    month?: number;
    on_call?: boolean;
    organization?: number;
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    physician?: number;
    search?: string;
    specialties?: number[];
    start_date?: Date | string;
    year?: number;
}

export interface PatientRepresentativeViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
    search?: string;
    user?: any;
}

export interface PatientSpecialtyMedicationViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    medical_specialties?: number[];
    organization?: number;
}

export interface PatientSyncViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface PatientViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    before_discharge?: boolean;
    bst_view?: string;
    ccda_type?: any;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    customer?: number;
    date?: Date | string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    id__in?: number[];
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    page?: number;
    page_size?: number;
    physician_team?: number;
    processed?: boolean;
    recipient_first_name?: string;
    recipient_last_name?: string;
    recipient_organization?: string;
    recipient_title?: string;
    search?: string;
    source_patient?: number;
    start_date?: Date | string;
    task_id?: number;
    type?: string;
}

export interface PatientsAtRiskViewQueryParams {
    all_time?: boolean;
    assigned_patients?: boolean;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    facility?: number;
    followed?: boolean;
    organization?: number;
    patient?: number;
    patient_id?: number;
}

export interface PatientsAvailableToRequestAccessPermissionViewSetQueryParams {
    active?: number;
    bst_view?: string;
    can_request_access?: boolean;
    census_admission_date__gt?: Date | string;
    census_admission_date__lt?: Date | string;
    companies?: string[];
    days?: number;
    end_date?: Date | string;
    exclude_origin?: string[];
    facility?: number;
    has_access_permission?: boolean;
    in_house?: number;
    medical_specialties?: number[];
    ordering?: PatientsAvailableToRequestAccessPermissionViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_id?: number;
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    start_date?: Date | string;
}

export interface PatientsAvailableToSearchViewSetQueryParams {
    bst_view?: string;
    exclude_origin?: string[];
    facility?: number;
    facility__in?: number[];
    organization?: number;
    search?: string;
}

export interface PayerAltNameViewSetQueryParams {
    class?: string;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface PayerViewSetQueryParams {
    search?: string;
}

export interface PersonIdViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    person?: any;
}

export interface PersonMergeEventViewSetQueryParams {
    page?: number;
    page_size?: number;
    search?: string;
    subject_id?: number;
    subject_type?: string;
}

export interface PersonMergeProposalViewSetQueryParams {
    id?: number;
    last_index?: number;
    page_size?: number;
    search?: string;
    subject_type?: string;
}

export interface PersonViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: number;
    search?: string;
}

export interface PhysicianTeamUserViewQueryParams {
    bst_view?: string;
    check_mail?: boolean;
    facility?: number;
    organization?: number;
}

export interface PhysicianViewSetQueryParams {
    active?: number;
    facility?: number;
    include_arsana_import_physicians?: boolean;
    organization?: number;
    search?: string;
    with_physicians?: boolean;
}

export interface ProcedureViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
}

export interface ProductivityReportQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    start_date?: Date | string;
    timezone?: string;
    type?: string;
    visit_details__billing_code?: any;
}

export interface ProgressNoteViewSetQueryParams {
    bst_view?: string;
    category?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    organization?: number;
    patient?: number;
    search?: string;
    start_date?: Date | string;
    timezone?: string;
}

export interface PsychotropicGroupsViewSetQueryParams {
    page?: number;
    page_size?: number;
    psychotropic_group_filter_parameter?: string;
    search?: string;
}

export interface PsychotropicsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    diagnosis_group_name?: string;
    drug_name?: string;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    id__in?: number[];
    include_off_labels?: boolean;
    include_secondary?: boolean;
    member?: boolean;
    on_call?: boolean;
    ordering?: PsychotropicsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    payer_category?: string;
    physician?: number;
    start_date?: Date | string;
    treatment_group_name?: string;
}

export interface QRDAViewSetQueryParams {
    age?: number;
    age_relation?: string;
    city?: string;
    cms?: number;
    country?: string;
    deduplication?: number;
    ethnicity?: string;
    gender?: string;
    line?: string;
    npi?: string;
    patients?: number[];
    payer?: string;
    physician_teams?: number[];
    physicians?: number[];
    postal_code?: string;
    problems?: string;
    race?: string;
    state?: string;
    tin?: string;
    year?: number;
}

export interface RefreshTokenViewSetQueryParams {
    application_id?: number;
    search?: string;
}

export interface RehospitalizationViewQueryParams {
    end_date?: Date | string;
    facility?: number;
    physician?: number;
    start_date?: Date | string;
}

export interface RelatedPersonModelViewSetQueryParams {
    patient?: number;
}

export interface RemitsViewSetQueryParams {
    claim_created_end_date?: Date | string;
    claim_created_start_date?: Date | string;
    claim_id?: any;
    claim_modified_end_date?: Date | string;
    claim_modified_start_date?: Date | string;
    claim_service_end_date?: Date | string;
    claim_service_start_date?: Date | string;
    facility__in?: number[];
    ordering?: RemitsViewSetOrderingChoices;
    page?: number;
    page_size?: number;
    provider__in?: number[];
    remit_created_end_date?: Date | string;
    remit_created_start_date?: Date | string;
    search?: string;
    timezone?: any;
}

export interface ReportViewSetQueryParams {
    ordering?: ReportViewSetOrderingChoices;
    page?: number;
    page_size?: number;
    report_type?: string;
}

export interface ResidentDaysViewQueryParams {
    end_date?: Date | string;
    facility?: number;
    physician?: number;
    start_date?: Date | string;
    transfer_outcome?: string;
}

export interface ResidentNoteViewSetQueryParams {
    bst_view?: string;
    category?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    organization?: number;
    patient?: any;
    search?: string;
    start_date?: Date | string;
    timezone?: string;
}

export interface RxNormViewSetQueryParams {
    page?: number;
    page_size?: number;
    search?: string;
}

export interface SMARTAuthorizationViewQueryParams {
    allow?: string;
    aud?: string;
    client_id?: string;
    code_challenge?: string;
    code_challenge_method?: string;
    redirect_uri?: string;
    response_type?: string;
    scope?: string;
}

export interface SSNModelViewSetQueryParams {
    patient?: number;
}

export interface SearchBillingCodeViewSetQueryParams {
    searchString?: string;
}

export interface SearchBillingModifierViewSetQueryParams {
    searchString?: string;
}

export interface SearchCptCodeViewSetQueryParams {
    searchString?: string;
}

export interface ServiceCodeViewSetQueryParams {
    code?: string;
    code__in?: any;
    description?: string;
    end?: Date | string;
    name?: string;
    name__in?: any;
    search?: string;
    start?: Date | string;
}

export interface SnomedConceptSearchViewSetQueryParams {
    search?: string;
    snomed_category?: string;
    vsac_category?: any;
}

export interface StatusObservationViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    ordering?: StatusObservationViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
}

export interface SyncDetailPaginatedViewSetQueryParams {
    facility?: number;
    organization?: number;
    page?: number;
    page_size?: number;
    status?: string;
}

export interface SyncDetailViewSetQueryParams {
    sync_process_id?: any;
}

export interface SyncProcessViewSetQueryParams {
    date?: string;
}

export interface SyncViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    origin_type?: string;
    page?: number;
    page_size?: number;
    status?: string;
    transfer_type?: string;
    user?: any;
}

export interface TaskBillingStatusViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst?: number;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
}

export interface TaskHistoryQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    month?: number;
    on_call?: boolean;
    organization?: number;
    patient?: number;
    physician?: number;
    year?: number;
}

export interface TaskLastValuesViewQueryParams {
    date_before?: Date | string;
    patient?: number;
    physician_team?: number;
    source_patient?: number;
    type?: string;
}

export interface TaskProposalViewSetQueryParams {
    aco?: boolean;
    active?: number;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    companies?: string[];
    facility?: number;
    followed?: boolean;
    in_house?: number;
    member?: boolean;
    on_call?: boolean;
    patient?: number;
    patient_ids?: number[];
    patient_status?: string;
    payer_category?: string;
    payers?: string[];
    physician?: number;
    physician_team?: number;
    start_date?: Date | string;
    type?: string;
    visit?: any;
}

export interface TaskSchedulerViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    date?: Date | string;
    facility?: number;
    followed?: boolean;
    member?: boolean;
    on_call?: boolean;
    ordering?: TaskSchedulerViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    patient__followed_by?: any;
    physician_team?: any;
    status?: string;
    type?: string;
    user?: any;
    visit?: number;
}

export interface TaskStatusHistoryViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    task?: number;
}

export interface TaskSummaryQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    billing_code_category?: string;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    followed?: boolean;
    medical_programs?: string[];
    member?: boolean;
    month?: number;
    on_call?: boolean;
    organization?: number;
    patient?: number;
    physician?: number;
    report_type?: string;
    start_date?: Date | string;
    year?: number;
}

export interface TaskViewSetQueryParams {
    aco?: boolean;
    action_foreign_key?: string;
    action_foreign_key_id?: number;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    assigned_to_me?: boolean;
    assigned_users__done?: boolean;
    assigned_users__user?: number;
    batch_downloaded_by_user?: boolean;
    billing_status?: string;
    bst?: number;
    by_own_physician_team?: boolean;
    by_physician_team?: boolean;
    ccda_type?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    created_by_me?: boolean;
    date?: Date | string;
    draft?: boolean;
    end_date?: Date | string;
    facility?: number;
    fax?: boolean;
    followed?: boolean;
    id__in?: number[];
    include_drafts?: boolean;
    includes_e_prescription?: boolean;
    member?: boolean;
    note_with_billing?: boolean;
    on_call?: boolean;
    order?: boolean;
    ordering?: TaskViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: number;
    patient__followed_by?: any;
    patient_note?: boolean;
    physician?: number;
    physician_team?: any;
    physician_team__specialty?: any;
    reviewed_for_scheduling?: boolean;
    skip_history?: boolean;
    start_date?: Date | string;
    status?: string;
    template?: string;
    type?: string;
    user?: any;
    visit?: boolean;
    visit_summary?: boolean;
    without_assigned_users?: boolean;
}

export interface TreatmentEntriesBySpecialtyViewSetQueryParams {
    patient_id?: number;
    specialty_id?: number;
}

export interface TreatmentHistoryAntibioticsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    icd?: number[];
    member?: boolean;
    on_call?: boolean;
    ordering?: TreatmentHistoryAntibioticsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    prn?: string;
    product_type_category?: string[];
    search?: string;
    start_date?: Date | string;
}

export interface TreatmentHistoryAntidepressantsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    icd?: number[];
    member?: boolean;
    on_call?: boolean;
    ordering?: TreatmentHistoryAntidepressantsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    prn?: string;
    product_type_category?: string[];
    search?: string;
    start_date?: Date | string;
}

export interface TreatmentHistoryAntipsychoticsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    icd?: number[];
    member?: boolean;
    on_call?: boolean;
    ordering?: TreatmentHistoryAntipsychoticsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    prn?: string;
    product_type_category?: string[];
    search?: string;
    start_date?: Date | string;
}

export interface TreatmentHistoryAuditViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
    treatment_history?: any;
}

export interface TreatmentHistoryICDUpdateViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
}

export interface TreatmentHistoryNarcoticsViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    icd?: number[];
    member?: boolean;
    on_call?: boolean;
    ordering?: TreatmentHistoryNarcoticsViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    payer_category?: string;
    physician?: number;
    prn?: string;
    product_type_category?: string[];
    search?: string;
    start_date?: Date | string;
}

export interface TreatmentHistoryOriginsViewSetQueryParams {
    bst_view?: string;
    facility?: number;
    organization?: number;
    patient?: any;
    treatment_history?: any;
}

export interface TreatmentHistoryViewSetQueryParams {
    bst_view?: string;
    days?: number;
    drug_name?: number[];
    drug_name__drug__categories?: number[];
    end_date?: Date | string;
    facility?: number;
    icd?: number[];
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    physician?: any;
    product_type_category?: string[];
    psychotropic_group__in?: string[];
    search?: string;
    specialty_id?: number;
    start_date?: Date | string;
}

export interface TrendViewSetQueryParams {
    comparison_period_count?: number;
    end_date?: Date | string;
    facility?: number;
    icd_code?: number;
    icd_group?: number;
    icd_id?: number;
    interval?: string;
    organization?: number;
    start_date?: Date | string;
    timezone?: string;
}

export interface UnitViewSetQueryParams {
    facility?: any;
    name?: string;
    page?: number;
    page_size?: number;
}

export interface UnmatchedPatientActionViewSetQueryParams {
    categories?: string;
    category?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    facility_id?: any;
    modified_at__gt?: Date | string;
    modified_at__lt?: Date | string;
    patient?: number;
    patient_id?: any;
    sources?: string;
    start_date?: Date | string;
}

export interface UsedICDCodeViewSetQueryParams {
    views?: string[];
}

export interface UserAccessViewSetQueryParams {
    ordering?: UserAccessViewSetOrderingChoices;
    page?: number;
    page_size?: number;
    patient?: number;
    search?: string;
    user?: any;
}

export interface UserFacilityViewSetQueryParams {
    bst?: boolean;
    bst_view?: string;
    customer?: number;
    facility?: number;
    facility__in?: number[];
    is_member?: boolean;
    organization?: number;
    search?: string;
    user?: number;
}

export interface UserLogViewSetQueryParams {
    comparison_period_count?: number;
    days?: number;
    end_date?: Date | string;
    exclude_facility?: number;
    facility?: number;
    facility_id?: number;
    interval?: string;
    logs__timestamp__gte?: Date | string;
    logs__timestamp__lte?: Date | string;
    new_users?: boolean;
    ordering?: UserLogViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    search?: string;
    start_date?: Date | string;
    timezone?: string;
    user_name?: string;
}

export interface UserStatusChangesViewSetQueryParams {
    bst_view?: string;
    change?: string;
    changed_by?: any;
    facility?: number;
    organization?: number;
    user?: any;
}

export interface UserTimeSpentOnLocationViewSetQueryParams {
    id?: number;
    page?: number;
    page_size?: number;
}

export interface UserViewSetQueryParams {
    customer?: number;
    exclude_facility?: number;
    facilities?: any;
    facility?: number;
    facility_id?: number;
    first_name?: string;
    group?: number;
    is_active?: boolean;
    is_suspended?: boolean;
    last_name?: string;
    logged_in?: boolean;
    ordering?: UserViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    role?: string;
    search?: string;
}

export interface ValueSetViewSetQueryParams {
    category?: string;
    oids?: string;
    preferred?: boolean;
    same_as?: string;
    search?: string;
}

export interface VisitReportAuditViewSetQueryParams {
    aco?: boolean;
    assigned_facilities?: boolean;
    assigned_patients?: boolean;
    bst_view?: string;
    by_physician_team?: boolean;
    ccm_consent?: boolean;
    ccm_eligible?: boolean;
    downloaded_for_billing?: boolean;
    draft?: boolean;
    end_date?: Date | string;
    facility?: number;
    followed?: boolean;
    include_drafts?: boolean;
    member?: boolean;
    on_call?: boolean;
    organization?: number;
    physician?: number;
    reviewed_for_scheduling?: boolean;
    start_date?: Date | string;
}

export interface VisitReportViewSetQueryParams {
    assignee__in?: number[];
    billing_code?: string;
    bst_view?: string;
    downloaded_for_billing?: boolean;
    draft?: boolean;
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    history?: boolean;
    include_drafts?: boolean;
    month?: number;
    ordering?: VisitReportViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient_name?: string;
    pending?: boolean;
    physician?: number;
    physician__in?: number[];
    reported_at_end_date?: Date | string;
    reported_at_start_date?: Date | string;
    reviewed_for_scheduling?: boolean;
    signed_at_end_date?: Date | string;
    signed_at_start_date?: Date | string;
    start_date?: Date | string;
    timezone?: any;
    to_review?: boolean;
    unverified?: boolean;
    user?: any;
    user__in?: number[];
    validated?: boolean;
    verified_at_end_date?: Date | string;
    verified_at_start_date?: Date | string;
    visit_day?: string;
    visit_physician?: number;
    year?: number;
}

export interface VisitViewSetQueryParams {
    bst_view?: string;
    end_date?: Date | string;
    facility?: number;
    facility__in?: number[];
    month?: number;
    on_call?: boolean;
    organization?: number;
    physician?: number;
    physician__in?: number[];
    secondary_assignees__in?: number[];
    start_date?: Date | string;
    user_facility?: any;
    user_facility__in?: number[];
    user_facility__user?: any;
    year?: number;
}

export interface VitalViewSetQueryParams {
    bst_view?: string;
    days?: number;
    end_date?: Date | string;
    facility?: number;
    medical_result_type?: any;
    medical_result_type__critical?: any;
    medical_result_type__in?: number[];
    ordering?: VitalViewSetOrderingChoices;
    organization?: number;
    page?: number;
    page_size?: number;
    patient?: any;
    processed?: boolean;
    recorded__gt?: Date | string;
    recorded__lt?: Date | string;
    start_date?: Date | string;
}

export interface WorkListViewSetQueryParams {
    ordering?: WorkListViewSetOrderingChoices;
    page?: number;
    page_size?: number;
    status?: string;
}

export interface AbdomenExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    bowel_sounds?: string;
    tenderness?: string;
    colostomy?: string;
    medical_equipment?: string;
    comment?: string;
}

export interface AccessTokenSerializer {
    id?: number;
    token?: string;
    client_id?: any;
    created?: Date | string;
    user?: UserBaseSerializer;
}

export interface AccountLoginSerializer {
    url?: string;
}

export interface ActionReadSerializer {
    id?: number;
    action?: string;
    comments?: string;
    icd?: ICDCodeSerializer;
    icd_update?: ICDCodeSerializer;
    diagnosis?: DiagnosisDetailedSerializer;
    event?: EventDetailSerializer;
    vital?: VitalSimpleSerializer;
    lab_test?: LabTestSerializer;
    treatment_history?: TreatmentHistoryRelatedTasksSerializer;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    sort?: number;
    hidden_in_plan?: boolean;
    resolved_by?: number;
    resolved_at?: Date | string;
    goal?: GoalReadSerializer;
    procedure?: ProcedureReadOnlySerializer;
    patient_allergy?: PatientAllergyReadSerializer;
    immunization?: ImmunizationReadSerializer;
    clinical_test_result?: ClinicalTestResultCreateUpdateSerializer;
    imaging_result?: ImagingResultReadSerializer;
    negation_reason?: NegationReasonReadSerializer;
    disease_status?: string;
}

export interface ActionReadSerializerWithTask {
    id?: number;
    action?: string;
    comments?: string;
    icd?: ICDCodeSerializer;
    icd_update?: ICDCodeSerializer;
    diagnosis?: DiagnosisDetailedSerializer;
    event?: EventDetailSerializer;
    vital?: VitalSimpleSerializer;
    lab_test?: LabTestSerializer;
    treatment_history?: TreatmentHistoryRelatedTasksSerializer;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    sort?: number;
    hidden_in_plan?: boolean;
    resolved_by?: number;
    resolved_at?: Date | string;
    task?: TaskActionSerializer;
    negation_reason?: NegationReasonReadSerializer;
    disease_status?: string;
}

export interface ActionSimpleReadSerializer {
    id?: number;
    action?: string;
    comments?: string;
    disease_status?: string;
    icd?: CachedICDCodeSerializer;
    icd_update?: CachedICDCodeSerializer;
    hash?: string;
    sort?: number;
    hidden_in_plan?: boolean;
}

export interface ActivateFacilityFaxSerializer {
    code?: string;
}

export interface ActiveCensusStatisticsSerializer {
    count?: number;
    date_from?: Date | string;
    date_to?: Date | string;
}

export interface ActiveTreatmentsSerializer {
    date_from?: Date | string;
    date_to?: Date | string;
    count?: number;
}

export interface AdvancedFilterSerializer {
    specialties?: Array<number>;
    assigned?: boolean;
    medical_program?: MedicalProgramAdvancedFilterSerializer;
}

export interface AllergiesPatientsSerializer {
    allergy?: number;
    start_date?: Date | string;
}

export interface AllergyPatientReadSerializer {
    id?: number;
    patient?: number;
    reaction_note?: string;
    severity?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean;
    verification_status?: string;
    allergy_type_snomed_concept?: SnomedConceptSerializer;
    allergy?: AllergySerializer;
    snomed_concept?: SnomedConceptSerializer;
    drugbank_product_concept?: number;
    drugbank_product_concept_rxcui?: DrugbankProductConceptRxCuiSerializer;
    reactions?: Array<PatientAllergyReactionReadSerializer>;
    patient_document?: number;
    copied_from?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
}

export interface AllergyPatientSaveSerializer {
    id?: number;
    patient?: number;
    reaction_note?: string;
    severity?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean;
    verification_status?: string;
    allergy_type_snomed_concept?: number;
    snomed_concept?: number;
    drugbank_product_concept?: number;
    drugbank_product_concept_rxcui?: number;
    reactions?: Array<PatientAllergyReactionSerializer>;
    patient_document?: number;
    copied_from?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
}

export interface AllergyReadSerializer {
    id?: number;
    name?: string;
    category?: string;
    drug_name?: number;
}

export interface AllergySerializer {
    id?: number;
    name?: string;
    drug_name?: DrugNameSerializer;
}

export interface AnonymizablePatientSerializer {
    id?: number;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    age?: number;
    patient_physician_teams?: Array<PatientPhysicianTeamIsDiscontinuableSerializer>;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    last_admission_date?: Date | string;
    location_name?: string;
    census_payer?: string;
    primary_physician_team?: PatientPhysicianTeamUserFacilitySerializer;
    level_of_care?: string;
    patient_emr_physician_teams?: Array<PatientEMRPhysicianTeamSerializer>;
    customer?: number;
    last_census_hospital?: HospitalSerializer;
    last_census_status_detail?: string;
}

export interface AnonymizablePatientSerializerWithConnections {
    access_permissions?: Array<PatientPhysicianTeamAccessPermissionSimpleSerializer>;
    id?: number;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    age?: number;
    patient_physician_teams?: Array<PatientPhysicianTeamIsDiscontinuableSerializer>;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    last_admission_date?: Date | string;
    location_name?: string;
    census_payer?: string;
    primary_physician_team?: PatientPhysicianTeamUserFacilitySerializer;
    level_of_care?: string;
    patient_emr_physician_teams?: Array<PatientEMRPhysicianTeamSerializer>;
    customer?: number;
    last_census_hospital?: HospitalSerializer;
    last_census_status_detail?: string;
}

export interface AntibioticsActiveTreatmentsSerializer {
    active_treatments?: Array<ActiveTreatmentsSerializer>;
    total_count?: number;
}

export interface AntibioticsByClassSerializer {
    treatment_count?: number;
    average_treatment_length?: any;
    class_name?: string;
}

export interface AntibioticsByClassWithMostCommonSerializer {
    results?: Array<AntibioticsByClassSerializer>;
    most_common?: AntibioticsByClassSerializer;
}

export interface AntibioticsByNameSerializer {
    treatment_count?: number;
    average_treatment_length?: any;
    drug_name?: DrugNameSerializer;
}

export interface AntibioticsByNameWithMostCommonSerializer {
    results?: Array<AntibioticsByNameSerializer>;
    most_common?: AntibioticsByNameSerializer;
}

export interface AntibioticsByPhysicianSerializer {
    treatment_count?: number;
    average_treatment_length?: any;
    physician?: UserWithoutRelatedSerializer;
}

export interface AntibioticsByPhysicianWithMostCommonSerializer {
    results?: Array<AntibioticsByPhysicianSerializer>;
    most_common?: AntibioticsByPhysicianSerializer;
}

export interface AntibioticsByRouteSerializer {
    treatment_count?: number;
    average_treatment_length?: any;
    drug_administration_route?: string;
}

export interface AntibioticsByRouteWithMostCommonSerializer {
    results?: Array<AntibioticsByRouteSerializer>;
    most_common?: AntibioticsByRouteSerializer;
}

export interface AntibioticsPatientSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    physician?: UserSimpleSerializer;
    physician_teams?: number[];
    facility?: number;
    payers?: Array<PayerHistorySerializer>;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    level_of_care?: string;
    origin?: string;
    customer?: number;
    treatment_count?: any;
    treatment_history?: Array<TreatmentHistoryWithLengthSerializer>;
    max_treatment_length?: any;
}

export interface AntibioticsPatientSummarySerializer {
    all_patient_count?: number;
    average_daily_census_count?: number;
    average_daily_antibiotics_count?: number;
    new_admission_patients_count?: number;
    antibiotics_patients_count?: number;
    new_admission_antibiotics_patients_count?: number;
}

export interface AntibioticsTreatmentHistorySerializer {
    id?: number;
    patient?: PatientSimpleSerializer;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: DrugNameWithAntibioticsClassAndRouteSerializer;
    physician?: UserSimpleSerializer;
    instructions?: string;
    treatment_length?: number;
    drug_administration_route?: string;
}

export interface ApplicationCreateResponseSerializer {
    client_id?: string;
    client_secret?: string;
}

export interface ApplicationScopesSerializer {
    scopes?: any;
}

export interface ApplicationSerializer {
    id?: number;
    client_id?: string;
    redirect_uris?: string;
    client_type?: string;
    authorization_grant_type?: string;
    name?: string;
    user?: number;
    skip_authorization?: boolean;
    created?: Date | string;
    updated?: Date | string;
    algorithm?: string;
    launch_uri?: string;
    scopes?: string;
    context?: any;
    description?: string;
    wellknown_url?: string;
    customer?: number;
}

export interface AssessmentPlanReadSerializer {
    id?: number;
    assessment?: string;
    plan?: string;
    systems?: any;
    comments?: string;
    related_icd_diagnoses?: Array<ICDCodeNameSerializer>;
    actions?: Array<ActionReadSerializer>;
}

export interface AssessmentPlanSerializer {
    id?: number;
    assessment?: string;
    plan?: string;
    systems?: any;
    comments?: string;
    related_icd_diagnoses?: number[];
    actions?: Array<PlanActionSerializer>;
}

export interface AssessmentPlanSimpleReadSerializer {
    id?: number;
    assessment?: string;
    plan?: string;
    systems?: any;
    comments?: string;
    related_icd_diagnoses?: Array<ICDCodeNameSerializer>;
    actions?: Array<ActionSimpleReadSerializer>;
}

export interface AuditSerializer {
    ssn?: boolean;
    immunizations?: boolean;
    diagnoses?: boolean;
    allergies?: boolean;
    orders?: boolean;
    vitals?: boolean;
}

export interface AuthorizationGetResponseSerializer {
    context?: string;
    user_type?: string;
    application?: string;
    patients?: Array<PatientSerializer>;
    scopes?: any;
}

export interface AuthorizationRequestSerializer {
    allow?: string;
    client_id?: string;
    code_challenge?: string;
    code_challenge_method?: string;
    redirect_uri?: string;
    response_type?: string;
    scope?: string;
    patient?: string;
    encounter?: string;
    launch?: string;
}

export interface BacteriaSummarySerializer {
    total?: number;
    mdro_total?: number;
    culture_id?: number;
    culture_name?: string;
    mdro?: SourcesSummarySerializer;
    organism_category?: string;
    sources?: SourcesSummarySerializer;
}

export interface BatchApproveBillingInfoRequestSerializer {
    requests?: Array<number>;
}

export interface BatchCreateBillingInfoRequestSerializer {
    patients?: Array<number>;
}

export interface BatchTaskIdSerializer {
    task_ids?: Array<number>;
}

export interface BatchUpdateCMRNErrorSerializer {
    cannot_be_assigned?: Array<number>;
    user_does_not_have_permission_to_patient?: Array<number>;
    patient_has_already_cmrn_set?: Array<number>;
    patient_does_not_exist?: Array<number>;
}

export interface BatchUpdateCMRNResponseSerializer {
    errors?: BatchUpdateCMRNErrorSerializer;
    success?: Array<PatientCMRNResponseSerializer>;
}

export interface BatchUpdateCMRNSerializer {
    patient_cmrns?: Array<PatientCMRNSerializer>;
}

export interface BedBedOriginDetailedSerializer {
    id?: number;
    bed?: BedSerializer;
    bed_origin?: BedOriginSerializer;
}

export interface BedBedOriginSerializer {
    id?: number;
    bed?: number;
    bed_origin?: number;
}

export interface BedOriginSerializer {
    id?: number;
    location?: number;
    name?: string;
    type?: string;
    cert?: string;
    is_active?: boolean;
}

export interface BedSerializer {
    id?: number;
    location?: number;
    name?: string;
    type?: string;
    cert?: string;
    is_active?: boolean;
}

export interface BedSimpleSerializer {
    id?: number;
    name?: string;
    type?: string;
    cert?: string;
    is_active?: boolean;
}

export interface BehavioralBaselineEvaluationSerializer {
    intelligence?: string;
    intelligence_note?: string;
    cognition?: string;
    cognition_note?: string;
    concentration?: string;
    concentration_note?: string;
    age?: string;
    age_note?: string;
    physique?: string;
    physique_note?: string;
    insight_and_judgement?: string;
    insight_and_judgement_note?: string;
    memory?: string;
    memory_note?: string;
}

export interface BehavioralDevelopmentalHistorySerializer {
    childhood_experience?: string;
    childhood_experience_note?: string;
    childhood_issues?: string;
    childhood_issues_note?: string;
    childhood_parents?: string;
    childhood_parents_note?: string;
    family_included?: string;
    family_included_note?: string;
    relationship?: string;
    relationship_note?: string;
    relationship_with_family?: string;
    relationship_with_family_note?: string;
    summary?: string;
}

export interface BehavioralFamilyHistorySerializer {
    stroke?: string;
    cad_mi?: string;
    dementia?: string;
    diabetes?: string;
    parkinson_disease?: string;
    alzheimer_disease?: string;
    cholesterol?: string;
    depression?: string;
    alcohol_dependence?: string;
    psychiatric_illness?: string;
    cancers?: string;
    abuse?: string;
    other?: string;
    dementia_note?: string;
    depression_note?: string;
    psychiatric_illness_note?: string;
    alcohol_dependence_note?: string;
    abuse_note?: string;
    other_note?: string;
}

export interface BehavioralMentalStatusExamAdditionalAssessmentSerializer {
    cognition?: string;
    concentration?: string;
    memory?: string;
    decisiveness?: string;
    worthiness?: string;
    psychomotor?: string;
    comment?: string;
    global_value?: string;
}

export interface BehavioralMentalStatusExamCognitionSerializer {
    cognition?: string;
    association?: string;
    mood?: string;
    affect?: string;
    comment?: string;
    global_value?: string;
}

export interface BehavioralMentalStatusExamConditionsAndSymptomsSerializer {
    sleep_disruptions?: string;
    fatigue?: string;
    suicidal?: string;
    homicidal?: string;
    psychosis?: string;
    appetite_and_weight?: string;
    insight_and_judgement?: string;
    comment?: string;
    global_value?: string;
}

export interface BehavioralMentalStatusExamCreateUpdateSerializer {
    additional_assessment?: BehavioralMentalStatusExamAdditionalAssessmentSerializer;
    cognition?: BehavioralMentalStatusExamCognitionSerializer;
    conditions_and_symptoms?: BehavioralMentalStatusExamConditionsAndSymptomsSerializer;
    general?: BehavioralMentalStatusExamGeneralSerializer;
    limited?: boolean;
    limited_due_to?: string;
    observation?: BehavioralMentalStatusExamObservationSerializer;
    status_observations?: Array<StatusObservationCreateUpdateSerializer>;
}

export interface BehavioralMentalStatusExamGeneralSerializer {
    awareness?: string;
    alertness?: string;
    weight_change?: string;
    malaise?: string;
    comment?: string;
    global_value?: string;
}

export interface BehavioralMentalStatusExamObservationSerializer {
    appearance?: string;
    behavior?: string;
    speech?: string;
    eye_contact?: string;
    motor_skills?: string;
    involuntary_movement?: string;
    comment?: string;
    global_value?: string;
}

export interface BehavioralMentalStatusExamReadSerializer {
    additional_assessment?: BehavioralMentalStatusExamAdditionalAssessmentSerializer;
    cognition?: BehavioralMentalStatusExamCognitionSerializer;
    conditions_and_symptoms?: BehavioralMentalStatusExamConditionsAndSymptomsSerializer;
    general?: BehavioralMentalStatusExamGeneralSerializer;
    limited?: boolean;
    limited_due_to?: string;
    observation?: BehavioralMentalStatusExamObservationSerializer;
    status_observations?: Array<StatusObservationReadSerializer>;
}

export interface BehavioralNeuroExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    hemiparesis?: string;
    focal_deficits?: string;
    comment?: string;
}

export interface BehavioralNeuroSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    weakness?: string;
    numbness?: string;
    loss_of_consciousness?: string;
    dizziness?: string;
    comment?: string;
}

export interface BehavioralPastPsychiatricHistorySerializer {
    dc_meds?: string;
    exposure_to_violence?: string;
    exposure_to_violence_note?: string;
    hx_of_suicide_attempts?: string;
    hx_of_suicide_attempts_note?: string;
    hx_of_self_harm?: string;
    hx_of_self_harm_note?: string;
    hx_of_violence?: string;
    hx_of_violence_note?: string;
    inpatient_treatment?: string;
    inpatient_treatment_note?: string;
    outpatient_treatment?: string;
    outpatient_treatment_note?: string;
    psychotherapy?: string;
    psychotherapy_note?: string;
}

export interface BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer {
    current_living_situation?: string;
    current_living_situation_note?: string;
    family?: string;
    family_note?: string;
    social?: string;
    social_note?: string;
    work_school_community?: string;
    work_school_community_note?: string;
}

export interface BehavioralPhysicalExaminationCreateUpdateSerializer {
    general_exam?: GeneralExamSerializer;
    skin_exam?: SkinExamSerializer;
    eyes_exam?: EyesExamSerializer;
    ent_exam?: ENTExamSerializer;
    neck_exam?: NeckExamSerializer;
    lungs_exam?: LungsExamSerializer;
    heart_exam?: HeartExamSerializer;
    abdomen_exam?: AbdomenExamSerializer;
    gu_exam?: GUExamSerializer;
    muscular_exam?: MuscularExamSerializer;
    neuro_exam?: BehavioralNeuroExamSerializer;
    limited?: boolean;
    limited_due_to?: string;
    status_observations?: Array<StatusObservationCreateUpdateSerializer>;
}

export interface BehavioralPhysicalExaminationReadSerializer {
    general_exam?: GeneralExamSerializer;
    skin_exam?: SkinExamSerializer;
    eyes_exam?: EyesExamSerializer;
    ent_exam?: ENTExamSerializer;
    neck_exam?: NeckExamSerializer;
    lungs_exam?: LungsExamSerializer;
    heart_exam?: HeartExamSerializer;
    abdomen_exam?: AbdomenExamSerializer;
    gu_exam?: GUExamSerializer;
    muscular_exam?: MuscularExamSerializer;
    neuro_exam?: BehavioralNeuroExamSerializer;
    limited?: boolean;
    limited_due_to?: string;
    status_observations?: Array<StatusObservationReadSerializer>;
}

export interface BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer {
    education?: string;
    education_note?: string;
    employment?: string;
    employment_note?: string;
    family?: string;
    family_note?: string;
    housing_and_economic?: string;
    housing_and_economic_note?: string;
    psychosocial?: string;
    psychosocial_note?: string;
    social?: string;
    social_note?: string;
    upbringing?: string;
    upbringing_note?: string;
}

export interface BehavioralReviewOfSystemsSerializer {
    ent_or_mouth?: ENTOrMouthSystemReviewSerializer;
    eyes?: EyesSystemReviewSerializer;
    cardiovascular?: CardiovascularSystemReviewSerializer;
    respiratory?: RespiratorySystemReviewSerializer;
    gastrointestinal?: GastrointestinalSystemReviewSerializer;
    genitourinary?: GenitourinarySystemReviewSerializer;
    musculoskeletal?: MusculoskeletalSystemReviewSerializer;
    skin?: SkinSystemReviewSerializer;
    neuro?: BehavioralNeuroSystemReviewSerializer;
    hem_lymph?: HemORLymphSystemReviewSerializer;
    endocrine?: EndocrineSystemReviewSerializer;
    limited?: boolean;
    limited_due_to?: string;
}

export interface BehavioralSocialHistoryReadSerializer {
    administrative_gender?: string;
    race?: string;
    marital_status?: string;
    current_living_situation?: string;
    primary_caregiver?: string;
    others_in_support_group?: string;
    educational_level_health_literacy?: string;
    language?: string;
    hobbies?: string;
    activity_exercise?: string;
    spirituality?: string;
    occupation_employment_history?: string;
    financial_status?: string;
    involved_services_community_agencies?: string;
    advanced_directives?: string;
    other?: string;
    alcohol_use?: string;
    substance_abuse?: string;
    tobacco_use?: string;
    pronouns?: string;
    identity?: string;
    marital_status_note?: string;
    current_living_situation_note?: string;
    primary_caregiver_note?: string;
    others_in_support_group_note?: string;
    educational_level_health_literacy_note?: string;
    language_note?: string;
    relationship_with_peers?: string;
    relationship_with_peers_note?: string;
    financial_status_note?: string;
    involved_services_community_agencies_note?: string;
    advanced_directives_note?: string;
    legal_issues?: string;
    legal_issues_note?: string;
    sexual_history?: string;
    sexual_history_note?: string;
    administrative_gender_note?: string;
    snomed_smoking_status?: SnomedConceptSerializer;
    snomed_smoking_status_date?: Date | string;
    snomed_sexual_orientation?: number;
    races?: string[];
    ethnicities?: string[];
    gender_identity?: string;
    birth_sex?: string;
}

export interface BehavioralSocialHistorySerializer {
    administrative_gender?: string;
    race?: string;
    marital_status?: string;
    current_living_situation?: string;
    primary_caregiver?: string;
    others_in_support_group?: string;
    educational_level_health_literacy?: string;
    language?: string;
    hobbies?: string;
    activity_exercise?: string;
    spirituality?: string;
    occupation_employment_history?: string;
    financial_status?: string;
    involved_services_community_agencies?: string;
    advanced_directives?: string;
    other?: string;
    alcohol_use?: string;
    substance_abuse?: string;
    tobacco_use?: string;
    pronouns?: string;
    identity?: string;
    marital_status_note?: string;
    current_living_situation_note?: string;
    primary_caregiver_note?: string;
    others_in_support_group_note?: string;
    educational_level_health_literacy_note?: string;
    language_note?: string;
    relationship_with_peers?: string;
    relationship_with_peers_note?: string;
    financial_status_note?: string;
    involved_services_community_agencies_note?: string;
    advanced_directives_note?: string;
    legal_issues?: string;
    legal_issues_note?: string;
    sexual_history?: string;
    sexual_history_note?: string;
    administrative_gender_note?: string;
    snomed_smoking_status?: number;
    snomed_smoking_status_date?: Date | string;
    snomed_sexual_orientation?: number;
    races?: string[];
    ethnicities?: string[];
    gender_identity?: string;
    birth_sex?: string;
}

export interface BehavioralSymptomsOfIllnessSerializer {
    sleep_disruptions?: string;
    sleep_disruptions_note?: string;
    fatigue?: string;
    fatigue_note?: string;
    impaired_concentration?: string;
    impaired_concentration_note?: string;
    guilt?: string;
    guilt_note?: string;
    lack_of_pleasure?: string;
    lack_of_pleasure_note?: string;
    appetite?: string;
    appetite_note?: string;
    psychomotor?: string;
    psychomotor_note?: string;
    suicidal_ideation?: string;
    suicidal_ideation_note?: string;
}

export interface BehavioralTraumaEvaluationSerializer {
    acknowledgements?: string;
    acknowledgements_note?: string;
    trauma_assessment?: string;
    trauma_assessment_note?: string;
    trauma_specified_as?: string;
    trauma_specified_as_note?: string;
    traumatic_stressful_events?: string;
    traumatic_stressful_events_note?: string;
}

export interface BillablePatientBillingDownloadSerializer {
    patient?: PatientTaskInfoWithPhysicianSerializer;
    winning_procedure_code?: ProcedureCodeSerializer;
    physician_team_procedure_codes?: Array<PhysicianTeamProcedureCodeSerializer>;
    procedure_code?: any;
    description?: any;
    category?: any;
    duration?: any;
    date?: any;
    patient_physician_team_medical_program?: any;
    physician_team?: any;
    medical_program_diagnoses?: string[];
    billing_physician_name?: string;
    billing_npi_number?: string;
}

export interface BillingCodeCategorySerializer {
    id?: number;
    name?: string;
}

export interface BillingCodeDetailedListSerializer {
    id?: number;
    code?: number;
    description?: string;
    specific_diagnoses?: any;
    min_time_tracked_on_tasks?: number;
    is_complex?: boolean;
    category?: BillingCodeCategorySerializer;
    within_discharged?: number;
    addon_of?: number;
    multiple_use?: boolean;
}

export interface BillingCodeSerializer {
    id?: number;
    code?: number;
    description?: string;
    specific_diagnoses?: any;
    min_time_tracked_on_tasks?: number;
    within_discharged?: number;
    category?: number;
    is_complex?: boolean;
    ccm_eligible?: boolean;
    addon_of?: number;
    multiple_use?: boolean;
}

export interface BillingCodesSerializer {
    billing_codes?: Array<string>;
}

export interface BillingGroupPhysicianSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    organization_group?: number;
    email?: string;
    is_active?: boolean;
    timezone?: string;
    facilities?: number[];
    roles?: string;
    phone?: any;
    origin?: string;
    customer?: number;
    owned_teams?: Array<BillingGroupPhysicianTeamSerializer>;
}

export interface BillingGroupPhysicianTeamSerializer {
    id?: number;
    members_count?: number;
}

export interface BillingGroupSerializer {
    id?: number;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    charge_amount_setting?: ChargeAmountSettingSerializer;
    name?: string;
    tin?: string;
    npi?: string;
    medicare_provider_number?: string;
    group_pac_id?: string;
    provider_transaction_access_number?: string;
    client_id?: string;
    taxonomy_code?: string;
    customer?: number;
    parent_group?: number;
}

export interface BillingGroupWithChildrenSerializer {
    id?: number;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    charge_amount_setting?: ChargeAmountSettingSerializer;
    child_groups?: any;
    users?: Array<BillingGroupPhysicianSerializer>;
    name?: string;
    tin?: string;
    npi?: string;
    medicare_provider_number?: string;
    group_pac_id?: string;
    provider_transaction_access_number?: string;
    client_id?: string;
    taxonomy_code?: string;
    customer?: number;
    parent_group?: number;
}

export interface BillingInfoRequestSerializer {
    id?: number;
    date?: Date | string;
    patient?: PatientSimpleSerializer;
    status?: string;
}

export interface BillingProductivityCultureSerializer {
    name?: string;
    total?: number;
    critical?: number;
    tnp?: number;
}

export interface BillingProductivitySerializer {
    name?: string;
    cultures?: Array<BillingProductivityCultureSerializer>;
}

export interface BillingValidationSerializer {
    billable?: boolean;
    invalid_task_reasons?: Array<string>;
    medical_program?: MedicalProgramSerializer;
    billing_code_category_name?: string;
}

export interface CCDAAddressImporterSerializer {
    use?: string;
    type?: string;
    line?: Array<string>;
    city?: string;
    district?: string;
    state?: string;
    postal_code?: string;
    country?: string;
}

export interface CCDAAllergyImporterReactionSerializer {
    severity?: string;
    date?: Date | string;
    snomed_concept?: CCDAConceptImporterSerializer;
}

export interface CCDAAllergyImporterSerializer {
    reactions?: Array<CCDAAllergyImporterReactionSerializer>;
    allergy?: CCDAFreeTextAllergySerializer;
    allergy_type_snomed_concept?: CCDAConceptImporterSerializer;
    snomed_concept?: CCDAConceptImporterSerializer;
    drugbank_product_concept_rxcui?: CCDAMedicationsImporterProductConceptRxCuiSerializer;
    severity?: string;
    is_active?: boolean;
    start_date?: Date | string;
    end_date?: Date | string;
    author?: Array<CCDAAuthorImportSerializer>;
}

export interface CCDAAuthorImportSerializer {
    time?: Date | string;
    name?: string;
    id?: string;
}

export interface CCDACCDImporterSerializer {
    medications?: Array<CCDAMedicationImporterSerializer>;
    allergies?: Array<CCDAAllergyImporterSerializer>;
    immunizations?: Array<CCDAImmunizationImporterSerializer>;
    diagnoses?: Array<CCDADiagnosisImporterSerializer>;
    patient?: CCDAPatientImporterSerializer;
    payers?: CCDAPatientInsuranceImporterSerializer;
}

export interface CCDAConceptImporterSerializer {
    id?: number;
    term?: string;
}

export interface CCDADemographicsImporterSerializer {
    administrative_gender?: string;
    language?: string;
    races?: Array<string>;
    ethnicities?: Array<string>;
}

export interface CCDADiagnosisImporterSerializer {
    category?: string;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
    snomed_concept?: CCDAConceptImporterSerializer;
    icd?: CCDAICDCodeSerializer;
    author?: Array<CCDAAuthorImportSerializer>;
}

export interface CCDAFreeTextAllergySerializer {
    name?: string;
    category?: string;
}

export interface CCDAICDCodeSerializer {
    id?: number;
    name?: string;
    description?: string;
}

export interface CCDAImmunizationImporterSerializer {
    date?: Date | string;
    status?: string;
    code_id?: number;
    name?: string;
}

export interface CCDAMedicationImporterSerializer {
    dose_quantity?: number;
    dose_unit?: string;
    frequency?: number;
    frequency_unit?: string;
    frequency_event?: string;
    route_code?: number;
    route_code_system?: string;
    rx_norm_code?: CCDARxNormCodeSerializer;
    start_date?: Date | string;
    end_date?: Date | string;
    author?: Array<CCDAAuthorImportSerializer>;
    instructions?: string;
    icd?: CCDAICDCodeSerializer;
    drug?: string;
    drug_name_id?: number;
}

export interface CCDAMedicationsImporterProductConceptRxCuiSerializer {
    id?: number;
    rxcui?: string;
    title?: string;
    product_concept?: CCDAMedicationsImporterProductConceptSerializer;
}

export interface CCDAMedicationsImporterProductConceptSerializer {
    title?: string;
}

export interface CCDANameImporterSerializer {
    use?: string;
    prefix?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    suffix?: string;
    start?: Date | string;
    end?: Date | string;
}

export interface CCDAPatientImporterSerializer {
    names?: Array<CCDANameImporterSerializer>;
    date_ob?: Date | string;
    gender?: string;
    demographics?: CCDADemographicsImporterSerializer;
    external_id?: string;
    addresses?: Array<CCDAAddressImporterSerializer>;
    contact_points?: Array<CCDATelecomImporterSerializer>;
}

export interface CCDAPatientInsuranceImporterSerializer {
    direct_bill?: boolean;
    mco_company?: string;
    mco_policy?: string;
    mco_group?: string;
    mco_effective_date?: Date | string;
    medicare_beneficiary_id?: string;
    medicare_coverage?: string;
    medicaid_company_name?: string;
    medicaid_policy?: string;
    biller_record_identifier?: string;
}

export interface CCDARxNormCodeSerializer {
    id?: number;
    term?: string;
}

export interface CCDATelecomImporterSerializer {
    use?: string;
    value?: string;
    system?: string;
}

export interface CDSInfobuttonSourceSerializer {
    id?: number;
    name?: string;
    url?: string;
    customer?: number;
    created_at?: Date | string;
    created_by?: number;
    modified_at?: Date | string;
}

export interface CDSInterventionConditionSerializer {
    id?: number;
    intervention_type?: string;
    snomed?: number;
    icd?: number;
    drugbank_product?: number;
    loinc_code?: string;
    numeric_value?: number;
    string_value?: string;
    relation?: string;
    cds_intervention?: number;
    created_at?: Date | string;
    created_by?: number;
    modified_at?: Date | string;
    drugbank_product_concept_rxcui?: number;
}

export interface CDSInterventionSerializer {
    id?: number;
    title?: string;
    body?: string;
    bibliographic_citation?: string;
    developer?: string;
    funding_source?: string;
    release_date?: Date | string;
    revision_dates?: string;
    created_at?: Date | string;
    created_by?: number;
    modified_at?: Date | string;
}

export interface CQMCalculateFilterSerializer {
    gender?: string;
    race?: string;
    ethnicity?: string;
    payer?: string;
    age?: number;
    age_relation?: string;
    npi?: string;
    tin?: string;
    problems?: string;
    line?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
    patients?: Array<number>;
    physicians?: Array<number>;
    provider?: string;
}

export interface CQMCalculateSerializer {
    cms?: number;
    year?: number;
    physicians?: Array<number>;
    performer_tin?: string;
    export_filters?: CQMCalculateFilterSerializer;
}

export interface CachedBedSimpleSerializer {
    id?: number;
    name?: string;
    type?: string;
    cert?: string;
    is_active?: boolean;
}

export interface CachedDrugNameItemSerializer {
    id?: number;
    name?: string;
}

export interface CachedFacilitySimpleSerializer {
    id?: number;
    name?: string;
}

export interface CachedICDCodeGroupSerializer {
    id?: number;
    name?: string;
    category?: number;
    is_infection?: boolean;
}

export interface CachedICDCodeNameSerializer {
    id?: number;
    name?: string;
    description?: string;
}

export interface CachedICDCodeSerializer {
    id?: number;
    name?: string;
    infection?: boolean;
    description?: string;
    icd_groups?: Array<CachedICDCodeGroupSerializer>;
    display_name?: any;
    is_chronic?: boolean;
    is_effective?: boolean;
}

export interface CachedLabDetailedResultSerializer {
    id?: number;
    susceptibility?: string;
    interpretation?: string;
    mic?: string;
}

export interface CachedLabTestSynonymSerializer {
    id?: number;
    name?: string;
    lab_code?: string;
    display_name?: any;
}

export interface CachedOrderSimpleSerializer {
    id?: number;
    order_date?: Date | string;
    start_date?: Date | string;
    end_date?: Date | string;
    flow_sheet_type?: string;
    order_type?: string;
    description?: string;
    related_tasks?: Array<TaskAuthorSerializer>;
}

export interface CachedPayerHistorySerializer {
    name?: string;
    start_date?: Date | string;
    end_date?: Date | string;
}

export interface CachedPhysicianNameSimpleSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
}

export interface CachedSchedulingReviewSerializer {
    task?: number;
    user?: number;
    task_proposal?: number;
    created_at?: Date | string;
}

export interface CachedTaskStatusHistorySerializer {
    id?: number;
    timestamp?: Date | string;
    status?: string;
    user?: CachedPhysicianNameSimpleSerializer;
    fax?: boolean;
    sms?: boolean;
    is_sms_sent?: boolean;
    comment?: string;
}

export interface CachedTreatmentHistoryFlagSerializer {
    id?: number;
    type?: string;
    description?: string;
    object_id?: number;
}

export interface CalendarEventEditSerializer {
    id?: number;
    type?: string;
    date?: Date | string;
    end_date?: Date | string;
    user?: number;
    user_facility?: number;
}

export interface CalendarEventSerializer {
    id?: number;
    type?: string;
    date?: Date | string;
    end_date?: Date | string;
    user?: PhysicianSerializer;
    user_facility?: number;
}

export interface CapabilityStatementDocumentSerializer {
    mode?: string;
    profile?: string;
}

export interface CapabilityStatementEndpointSerializer {
    address?: string;
}

export interface CapabilityStatementImplementationSerializer {
    description?: string;
}

export interface CapabilityStatementRestResourceSerializer {
    type?: string;
    supportedProfile?: Array<string>;
    interaction?: Array<InteractionSerializer>;
    searchParam?: Array<SearchParamSerializer>;
}

export interface CapabilityStatementRestSerializer {
    mode?: string;
    interaction?: Array<InteractionSerializer>;
    resource?: Array<CapabilityStatementRestResourceSerializer>;
}

export interface CapabilityStatementSerializer {
    resourceType?: string;
    status?: string;
    date?: Date | string;
    kind?: string;
    instantiates?: Array<string>;
    software?: CapabilityStatementSoftwareSerializer;
    implementation?: CapabilityStatementImplementationSerializer;
    fhirVersion?: string;
    format?: Array<string>;
    rest?: Array<CapabilityStatementRestSerializer>;
    messaging?: Array<CapilityStatementMessagingSerializer>;
    document?: Array<CapabilityStatementDocumentSerializer>;
}

export interface CapabilityStatementSoftwareSerializer {
    name?: string;
}

export interface CapilityStatementMessagingSerializer {
    endpoint?: Array<CapabilityStatementEndpointSerializer>;
}

export interface CardiovascularSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    chest_pain?: string;
    sob?: string;
    pnd?: string;
    claudication?: string;
    edema?: string;
    palpitations?: string;
    comment?: string;
}

export interface CategoryCountResponseSerializer {
    category?: string;
    count?: number;
}

export interface CensusHistoryCreateUpdateSerializer {
    id?: number;
    timestamp?: Date | string;
    patient?: number;
    status?: string;
    status_detail?: string;
    location?: number;
    bed?: number;
    source_of_payment?: number;
    payer?: string;
    days?: number;
    hospital?: number;
    created_by?: number;
    modified_by?: number;
    facility?: number;
    comment?: string;
}

export interface CensusHistoryEditHistorySerializer {
    id?: number;
    census_history?: number;
    history_date?: Date | string;
    created_by?: EditingUserSerializer;
    modified_by?: EditingUserSerializer;
}

export interface CensusHistorySerializer {
    id?: number;
    patient_id?: any;
    location?: LocationSerializer;
    status?: string;
    status_detail?: string;
    bed?: BedSerializer;
    timestamp?: Date | string;
    source_of_payment?: number;
    payer?: string;
    hospital?: HospitalSerializer;
    facility?: number;
    comment?: string;
}

export interface CensusHistorySimpleSerializer {
    status?: string;
    timestamp?: Date | string;
    payer?: string;
    hospital?: HospitalSerializer;
}

export interface CensusOversightSerializer {
    id?: number;
    status?: string;
    payer?: string;
    timestamp?: Date | string;
    patient?: PatientWithFacilitySerializer;
    location?: LocationListSerializer;
    bed?: BedSimpleSerializer;
}

export interface CensusStatusCheckReadOnlySerializer {
    timestamp?: Date | string;
    user?: UserSimpleSerializer;
    status?: string;
}

export interface CernerCommunityViewURLSerializer {
    cerner_community_view_url?: string;
}

export interface CharDiffSerializer {
    old?: string;
    new?: string;
}

export interface ChargeAmountPayerSettingCreateSerializer {
    payer_alt_name_id?: number;
    cpt_code?: string;
    charge_amount?: number;
}

export interface ChargeAmountPayerSettingSerializer {
    id?: number;
    payer_alt_name?: PayerAltNameDetailSerializer;
    cpt_code?: string;
    charge_amount?: number;
    charge_amount_setting?: number;
}

export interface ChargeAmountSettingCreateSerializer {
    id?: number;
    payer_settings?: Array<ChargeAmountPayerSettingCreateSerializer>;
    medicare_rate_charge_multiplier?: number;
    billing_group?: number;
    customer?: number;
    physician_team?: number;
    user?: number;
}

export interface ChargeAmountSettingSerializer {
    id?: number;
    customer?: number;
    physician_team?: number;
    user?: number;
    medicare_rate_charge_multiplier?: number;
    payer_settings?: Array<ChargeAmountPayerSettingSerializer>;
}

export interface ChartAuditSerializer {
    past_medical_hx?: PastMedicalHxSerializer;
    problem_list?: ProblemListSerializer;
    social_history?: SocialHistorySerializer;
    family_history?: FamilyHistorySerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    vital_examination?: VitalExaminationSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    patient_complexity?: PatientComplexitySerializer;
}

export interface CheckEligibilityFreeTextSerializer {
    patient_id?: number;
    payer_name?: string;
    subscriber_id?: string;
    provider_name?: string;
    provider_npi?: string;
    first_name?: string;
    last_name?: string;
    patient_dob?: Date | string;
}

export interface CheckEligibilitySerializer {
    payer_alt_name_id?: number;
    mco_policy?: string;
    mco_group?: string;
    mco_effective_date?: string;
    patient_id?: number;
    medicare_beneficiary_id?: string;
    name_prefix?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    name_suffix?: string;
    patient_dob?: Date | string;
    provider_npi?: string;
    provider_name?: string;
    insurance_type?: string;
    payer_name?: string;
    subscriber_id?: string;
}

export interface ChronicDiagnosisSerializer {
    icd_code?: number;
    description?: string;
    date_diagnosed?: Date | string;
}

export interface ClaimBatchSubmitSerializer {
    claim_ids?: Array<number>;
}

export interface ClaimDiffViewSerializer {
    id?: number;
    facility?: FacilityAddressSerializer;
    primary_payer_alt_name?: PayerAltNameDetailSerializer;
    secondary_payer_alt_name?: PayerAltNameDetailSerializer;
    invalidity_reasons?: any;
    charge_sum?: any;
    diag_codes?: any;
    patient?: ClaimPatientSerializer;
    tasks?: Array<ClaimTaskSerializer>;
    items?: Array<ClaimItemSerializer>;
    remits?: Array<RemitSimpleSerializer>;
    last_primary_eligibility_request?: EligibilityRequestDetailSerializer;
    last_claim_history_event?: ClaimHistoryEventSerializer;
    last_other_eligibility_request?: EligibilityRequestDetailSerializer;
    changes?: ClaimSerializer;
    is_deleted?: boolean;
    claim_ref?: string;
    status?: string;
    type?: string;
    created_at?: Date | string;
    modified_at?: Date | string;
    sbr_id?: string;
    secondary_sbr_id?: string;
    pat_last_name?: string;
    pat_middle_name?: string;
    pat_first_name?: string;
    pat_birthdate?: Date | string;
    pat_sex?: string;
    pat_suffix?: string;
    pat_phone?: string;
    admission_from?: Date | string;
    sbr_last_name?: string;
    sbr_middle_name?: string;
    sbr_first_name?: string;
    sbr_suffix?: string;
    sbr_phone?: string;
    pat_address?: string;
    pat_city?: string;
    pat_state?: string;
    pat_zip?: string;
    pat_rel?: string;
    sbr_address?: string;
    sbr_city?: string;
    sbr_state?: string;
    sbr_zip?: string;
    sbr_birthdate?: Date | string;
    sbr_sex?: string;
    bill_tin?: string;
    bill_tin_type?: string;
    bill_taxonomy_code?: string;
    bill_name?: string;
    bill_add1?: string;
    bill_city?: string;
    bill_state?: string;
    bill_zip?: string;
    bill_npi?: string;
    bill_phone?: string;
    sbr_policy_group?: string;
    ren_last_name?: string;
    ren_first_name?: string;
    ren_prov_npi?: string;
    ren_prov_taxonomy_code?: string;
    created_by?: number;
    provider?: number;
    modified_by?: number;
}

export interface ClaimFileSerializer {
    id?: number;
    task_ids?: any;
    created_at?: Date | string;
    status?: string;
    error_message?: string;
    claim?: number;
    created_by?: number;
    file?: number;
}

export interface ClaimHistoryEventSerializer {
    id?: number;
    user?: UserSimpleSerializer;
    messages?: string[];
    primary_status_codes?: any;
    secondary_status_codes?: any;
    created_at?: Date | string;
    status?: string;
    primary_status_qualifier?: number;
    secondary_status_qualifier?: number;
    description?: string;
    transaction_id?: string;
    status_hash?: string;
    claim?: number;
}

export interface ClaimHistoryEventSimpleSerializer {
    id?: number;
    messages?: string[];
}

export interface ClaimInvalidityReasonSerializer {
    id?: number;
    status?: string;
    invalidity_reasons?: any;
}

export interface ClaimItemSerializer {
    id?: number;
    status?: string;
    service_from?: Date | string;
    pos?: string;
    proc_code?: string;
    modifier_codes?: any;
    charge?: number;
    units?: string;
    sort?: number;
    diag_codes?: any;
    paid?: number;
    allowed_amount?: number;
    deduct?: number;
    deduct_paid?: number;
    deduct_paid_date?: Date | string;
    coins?: number;
    coins_paid?: number;
    coins_paid_date?: Date | string;
    copay?: number;
    copay_paid?: number;
    copay_paid_date?: Date | string;
    secondary_denial?: boolean;
    secondary_insurance?: boolean;
    other_reductions_by_payer?: number;
    denial_reason?: any;
}

export interface ClaimListSerializer {
    id?: number;
    facility?: FacilityAddressSerializer;
    primary_payer_alt_name?: PayerAltNameDetailSerializer;
    secondary_payer_alt_name?: PayerAltNameDetailSerializer;
    invalidity_reasons?: any;
    charge_sum?: any;
    diag_codes?: any;
    patient?: ClaimPatientSerializer;
    tasks?: Array<ClaimTaskSerializer>;
    items?: Array<ClaimItemSerializer>;
    remits?: Array<RemitSimpleSerializer>;
    last_primary_eligibility_request?: EligibilityRequestSimpleSerializer;
    last_claim_history_event?: ClaimHistoryEventSimpleSerializer;
    is_deleted?: boolean;
    claim_ref?: string;
    status?: string;
    type?: string;
    created_at?: Date | string;
    modified_at?: Date | string;
    sbr_id?: string;
    secondary_sbr_id?: string;
    pat_last_name?: string;
    pat_middle_name?: string;
    pat_first_name?: string;
    pat_birthdate?: Date | string;
    pat_sex?: string;
    pat_suffix?: string;
    pat_phone?: string;
    admission_from?: Date | string;
    sbr_last_name?: string;
    sbr_middle_name?: string;
    sbr_first_name?: string;
    sbr_suffix?: string;
    sbr_phone?: string;
    pat_address?: string;
    pat_city?: string;
    pat_state?: string;
    pat_zip?: string;
    pat_rel?: string;
    sbr_address?: string;
    sbr_city?: string;
    sbr_state?: string;
    sbr_zip?: string;
    sbr_birthdate?: Date | string;
    sbr_sex?: string;
    bill_tin?: string;
    bill_tin_type?: string;
    bill_taxonomy_code?: string;
    bill_name?: string;
    bill_add1?: string;
    bill_city?: string;
    bill_state?: string;
    bill_zip?: string;
    bill_npi?: string;
    bill_phone?: string;
    sbr_policy_group?: string;
    ren_last_name?: string;
    ren_first_name?: string;
    ren_prov_npi?: string;
    ren_prov_taxonomy_code?: string;
    created_by?: number;
    provider?: number;
    modified_by?: number;
}

export interface ClaimPatientSerializer {
    id?: number;
    level_of_care?: string;
    insurance?: PatientInsuranceSerializer;
}

export interface ClaimSerializer {
    id?: number;
    facility?: FacilityAddressSerializer;
    primary_payer_alt_name?: PayerAltNameDetailSerializer;
    secondary_payer_alt_name?: PayerAltNameDetailSerializer;
    invalidity_reasons?: any;
    charge_sum?: any;
    diag_codes?: any;
    patient?: ClaimPatientSerializer;
    tasks?: Array<ClaimTaskSerializer>;
    items?: Array<ClaimItemSerializer>;
    remits?: Array<RemitSimpleSerializer>;
    last_primary_eligibility_request?: EligibilityRequestDetailSerializer;
    last_claim_history_event?: ClaimHistoryEventSerializer;
    last_other_eligibility_request?: EligibilityRequestDetailSerializer;
    is_deleted?: boolean;
    claim_ref?: string;
    status?: string;
    type?: string;
    created_at?: Date | string;
    modified_at?: Date | string;
    sbr_id?: string;
    secondary_sbr_id?: string;
    pat_last_name?: string;
    pat_middle_name?: string;
    pat_first_name?: string;
    pat_birthdate?: Date | string;
    pat_sex?: string;
    pat_suffix?: string;
    pat_phone?: string;
    admission_from?: Date | string;
    sbr_last_name?: string;
    sbr_middle_name?: string;
    sbr_first_name?: string;
    sbr_suffix?: string;
    sbr_phone?: string;
    pat_address?: string;
    pat_city?: string;
    pat_state?: string;
    pat_zip?: string;
    pat_rel?: string;
    sbr_address?: string;
    sbr_city?: string;
    sbr_state?: string;
    sbr_zip?: string;
    sbr_birthdate?: Date | string;
    sbr_sex?: string;
    bill_tin?: string;
    bill_tin_type?: string;
    bill_taxonomy_code?: string;
    bill_name?: string;
    bill_add1?: string;
    bill_city?: string;
    bill_state?: string;
    bill_zip?: string;
    bill_npi?: string;
    bill_phone?: string;
    sbr_policy_group?: string;
    ren_last_name?: string;
    ren_first_name?: string;
    ren_prov_npi?: string;
    ren_prov_taxonomy_code?: string;
    created_by?: number;
    provider?: number;
    modified_by?: number;
}

export interface ClaimSimpleSerializer {
    id?: number;
    patient?: number;
    status?: string;
    type?: string;
    tasks?: number[];
}

export interface ClaimTaskSerializer {
    id?: number;
    history_of_present_illness?: HistoryOfPresentIllnessSimpleReadSerializer;
    assessment_plan?: AssessmentPlanSimpleReadSerializer;
    level_of_care?: string;
    user?: UserSearchSerializer;
    user_id?: any;
    physician_team?: number;
}

export interface ClaimUpdateSerializer {
    id?: number;
    status_message?: string;
    items?: Array<ClaimItemSerializer>;
    is_deleted?: boolean;
    claim_ref?: string;
    status?: string;
    created_at?: Date | string;
    modified_at?: Date | string;
    sbr_id?: string;
    secondary_sbr_id?: string;
    pat_last_name?: string;
    pat_middle_name?: string;
    pat_first_name?: string;
    pat_birthdate?: Date | string;
    pat_sex?: string;
    pat_suffix?: string;
    pat_phone?: string;
    admission_from?: Date | string;
    sbr_last_name?: string;
    sbr_middle_name?: string;
    sbr_first_name?: string;
    sbr_suffix?: string;
    sbr_phone?: string;
    pat_address?: string;
    pat_city?: string;
    pat_state?: string;
    pat_zip?: string;
    pat_rel?: string;
    sbr_address?: string;
    sbr_city?: string;
    sbr_state?: string;
    sbr_zip?: string;
    sbr_birthdate?: Date | string;
    sbr_sex?: string;
    diag_codes?: any;
    bill_tin?: string;
    bill_tin_type?: string;
    bill_taxonomy_code?: string;
    bill_name?: string;
    bill_add1?: string;
    bill_city?: string;
    bill_state?: string;
    bill_zip?: string;
    bill_npi?: string;
    bill_phone?: string;
    sbr_policy_group?: string;
    ren_last_name?: string;
    ren_first_name?: string;
    ren_prov_npi?: string;
    ren_prov_taxonomy_code?: string;
    created_by?: number;
    provider?: number;
    modified_by?: number;
    primary_payer_alt_name?: number;
    secondary_payer_alt_name?: number;
}

export interface ClaimValidationSerializer {
    status?: string;
    invalid_claims?: Array<ClaimInvalidityReasonSerializer>;
}

export interface ClinicalTestResultActionSerializer {
    id?: number;
    action?: string;
    clinical_test_result?: ClinicalTestResultCreateUpdateSerializer;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
}

export interface ClinicalTestResultCreateUpdateSerializer {
    id?: number;
    status?: string;
    code?: string;
    patient?: number;
    date?: Date | string;
    value_code?: string;
    value_string?: string;
    value_quantity?: string;
    unit?: string;
    order?: number;
}

export interface ClinicalTestResultReviewActionReadSerializer {
    id?: number;
    clinical_test_result?: ClinicalTestResultSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface ClinicalTestResultReviewCreateUpdateSerializer {
    id?: number;
    review?: string;
    actions?: Array<ClinicalTestResultActionSerializer>;
}

export interface ClinicalTestResultReviewReadSerializer {
    id?: number;
    review?: string;
    actions?: Array<ClinicalTestResultReviewActionReadSerializer>;
}

export interface ClinicalTestResultSerializer {
    id?: number;
    status?: string;
    code?: string;
    code_display?: string;
    patient?: number;
    date?: Date | string;
    value_code?: string;
    value_code_display?: string;
    value_string?: string;
    value_quantity?: string;
    unit?: string;
    order?: OtherOrderReadSerializer;
}

export interface CodedNoteCreateUpdateSerializer {
    id?: number;
    note?: string;
    code?: number;
}

export interface CodedNoteReadSerializer {
    id?: number;
    note?: string;
    code?: ValueSetValueSerializer;
}

export interface CognitiveBaselineEvaluationSerializer {
    id?: number;
    intelligence?: string;
    intelligence_note?: string;
    cognition?: string;
    cognition_note?: string;
    concentration?: string;
    concentration_note?: string;
    age?: string;
    age_note?: string;
    physique?: string;
    physique_note?: string;
    insight_and_judgement?: string;
    insight_and_judgement_note?: string;
    memory?: string;
    memory_note?: string;
}

export interface CognitiveCarePlanSerializer {
    id?: number;
    dementia_plan?: string;
    neuro_psychiatric_symptoms?: string;
    safety_management?: string;
    care_giver_support_meeting?: string;
    advanced_care_plan?: string;
}

export interface CognitiveFastScreeningSerializer {
    id?: number;
    no_problem?: boolean;
    forgetting_locations?: boolean;
    decreased_functioning?: boolean;
    problematic_functioning?: boolean;
    needs_help_clothing?: boolean;
    problems_with_adls?: string;
    limited_speech?: string;
    score?: number;
}

export interface CognitiveReasonForVisitSerializer {
    id?: number;
    patient_description?: string;
    high_risk_medications?: string;
    ongoing_medical_problems?: string;
    cognitive_reason_for_visit_social_history?: string;
    cognitive_reason_for_visit_family_history?: string;
}

export interface CognitiveSymptomsOfIllnessSerializer {
    id?: number;
    sleep_disruptions?: string;
    sleep_disruptions_note?: string;
    fatigue?: string;
    fatigue_note?: string;
    impaired_concentration?: string;
    impaired_concentration_note?: string;
    guilt?: string;
    guilt_note?: string;
    lack_of_pleasure?: string;
    lack_of_pleasure_note?: string;
    appetite?: string;
    appetite_note?: string;
    psychomotor?: string;
    psychomotor_note?: string;
    suicidal_ideation?: string;
    suicidal_ideation_note?: string;
}

export interface ConstitutionalSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    awareness?: string;
    weight_change?: string;
    fever?: string;
    chills?: string;
    fatigue?: string;
    malaise?: string;
    comment?: string;
}

export interface CptCodePriceSerializer {
    code?: string;
    facility_price?: number;
    non_facility_price?: number;
}

export interface CptCodeSerializer {
    code?: string;
    description?: string;
    facility_price?: number;
    non_facility_price?: number;
}

export interface CreateClaimFromVisitTaskSerializer {
    task_id?: number;
    status?: string;
}

export interface CreateUserFromPatientSerializer {
    patient?: number;
    email?: string;
}

export interface CriticalVitalSerializer {
    id?: number;
    recorded?: Date | string;
    medical_result_type?: number;
    value_before?: number;
    value?: number;
    days_without_bowel_movement?: number;
    bmi?: number;
    vital_type?: string;
    related_tasks?: Array<TaskAuthorSerializer>;
}

export interface CustomerCreateUpdateSerializer {
    id?: number;
    name?: string;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    npi_number?: string;
    timezone?: string;
    office_contact_user?: number;
}

export interface CustomerOfficeContactSerializer {
    id?: number;
    name?: string;
    office_contact_user?: OfficeContactUserSerializer;
}

export interface CustomerSerializer {
    id?: number;
    name?: string;
    user_count?: number;
    patient_count?: number;
    users?: number[];
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    emails?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    npi_number?: string;
    timezone?: string;
    office_contact_user?: number;
}

export interface CustomerSimpleSerializer {
    id?: number;
    name?: string;
}

export interface DIParserSerializer {
    device_identifier?: string;
    gmdn_terms?: Array<FHIRDeviceNameSerializer>;
    snomed_types?: Array<FHIRDeviceTypeSerializer>;
    contains_nrl?: boolean;
    mri_safety_status?: string;
}

export interface DateDiffSerializer {
    old?: Date | string;
    new?: Date | string;
}

export interface DemographicsSerializer {
    administrative_gender?: string;
    birth_sex?: string;
    gender_identity?: string;
    language?: string;
    races?: string[];
    ethnicities?: string[];
    sexual_orientation?: string;
}

export interface DemographicsSimpleSerializer {
    task?: number;
    administrative_gender?: string;
    birth_sex?: string;
    gender_identity?: string;
    language?: string;
    races?: any;
    ethnicities?: any;
    sexual_orientation?: string;
}

export interface DepartmentSerializer {
    id?: number;
    name?: string;
    organization?: number;
}

export interface DetailedPatientListPatientSerializer {
    patient?: PatientWithPhysicianSerializer;
    treatments?: Array<PsychotropicsTreatmentHistorySerializer>;
    diagnoses?: Array<PatientListDiagnosisSerializer>;
}

export interface DiagnosisActionReadSerializer {
    id?: number;
    diagnosis?: DiagnosisDetailedSerializer;
    action?: string;
    icd?: ICDCodeSerializer;
    icd_update?: ICDCodeSerializer;
    comments?: string;
    snomed_concept?: SnomedConceptSerializer;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
    disease_status?: string;
}

export interface DiagnosisActionSerializer {
    id?: number;
    diagnosis?: DiagnosisCreateUpdateSerializer;
    action?: string;
    icd?: number;
    icd_update?: number;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    snomed_concept?: number;
    disease_status?: string;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    sort?: number;
}

export interface DiagnosisCreateUpdateSerializer {
    id?: number;
    patient?: number;
    icd?: number;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
    snomed_concept?: number;
    is_health_concern?: boolean;
    associated_health_concern?: number;
    is_deleted?: boolean;
    copied_from?: number;
    category?: string;
    patient_document?: number;
    modified_at?: Date | string;
}

export interface DiagnosisDataSerializer {
    icd?: number;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
}

export interface DiagnosisDetailedSerializer {
    id?: number;
    patient?: number;
    icd?: ICDCodeSerializer;
    category?: string;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
    related_treatments?: number[];
    is_deleted?: boolean;
    snomed_concept?: SnomedConceptSerializer;
    is_health_concern?: boolean;
    associated_health_concern?: number;
    patient_document?: number;
    copied_from?: number;
    modified_at?: Date | string;
    last_comment_action?: any;
}

export interface DiagnosisICDDetailSerializer {
    id?: number;
    patient?: number;
    icd?: ICDCodeSerializer;
    category?: string;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
    is_deleted?: boolean;
}

export interface DiagnosisPieChartSerializer {
    patients_with_diagnosis_without_treatment?: IDListWithCountSerializer;
    patients_with_some_mismatch?: IDListWithCountSerializer;
    patients_treated_correctly?: IDListWithCountSerializer;
}

export interface DiagnosisSerializer {
    id?: number;
    patient?: number;
    icd?: number;
    category?: string;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
    is_deleted?: boolean;
    snomed_concept?: number;
    snomed_display?: any;
    is_health_concern?: boolean;
    associated_health_concern?: number;
    patient_document?: number;
    copied_from?: number;
    modified_at?: Date | string;
}

export interface DiagnosisWithPsychotropicGroupsSerializer {
    id?: number;
    patient_id?: any;
    icd?: ICDCodeWithPsychotropicGroupsSerializer;
    category?: string;
    date_diagnosed?: Date | string;
    date_resolved?: Date | string;
    is_health_concern?: boolean;
    associated_health_concern?: number;
    on_facesheet?: boolean;
    is_psychotropic?: boolean;
    indication_type?: string;
}

export interface DocumentSerializer {
    id?: number;
    name?: string;
    size?: number;
    user?: UserSerializer;
    created_at?: Date | string;
    recorded?: boolean;
    organization?: number;
    facility?: number;
}

export interface DownloadBillingInfoRequestSerializer {
    patients?: Array<number>;
}

export interface DrugCategorySerializer {
    id?: number;
    name?: string;
}

export interface DrugNameIngredientSerializer {
    id?: number;
    name?: string;
    drug?: Array<DrugSimpleSerializer>;
    type?: string;
}

export interface DrugNameItemSerializer {
    id?: number;
    name?: string;
}

export interface DrugNameSerializer {
    id?: number;
    name?: string;
    drug?: Array<DrugSerializer>;
    type?: string;
}

export interface DrugNameSimpleSerializer {
    id?: number;
    name?: string;
}

export interface DrugNameWithAntibioticsClassAndRouteSerializer {
    id?: number;
    name?: string;
    drug?: Array<DrugWithAntibioticsClassSerializer>;
    type?: string;
    antibiotics_classes?: any;
    routes?: any;
}

export interface DrugSerializer {
    id?: number;
    name?: string;
    drugbank_id?: string;
    categories?: Array<DrugCategorySerializer>;
}

export interface DrugSimpleSerializer {
    id?: number;
    name?: string;
    drugbank_id?: string;
}

export interface DrugWithAntibioticsClassSerializer {
    id?: number;
    name?: string;
    drugbank_id?: string;
    categories?: Array<DrugCategorySerializer>;
    antibiotics_class?: string;
}

export interface DrugWithPsychotropicGroupsSerializer {
    id?: number;
    name?: string;
    drugbank_id?: string;
    categories?: Array<DrugCategorySerializer>;
    psychotropic_groups?: PsychotropicGroupsSerializer;
}

export interface DrugbankProductConceptRxCuiSerializer {
    id?: number;
    rxcui?: string;
    title?: string;
    product_concept?: DrugbankProductConceptSimpleSerializer;
}

export interface DrugbankProductConceptSimpleSerializer {
    id?: number;
    title?: string;
}

export interface DrugbankProductSimpleSerializer {
    id?: number;
    name?: string;
    drug?: any;
    prescribable_name?: string;
    dosage_form?: string;
    route?: string;
    schedule?: string;
    ayva_ddid?: string;
    legacy_name?: string;
    description?: string;
    usage_count?: number;
}

export interface DrugbankProductWithRxCuiSerializer {
    id?: number;
    name?: string;
    prescribable_name?: string;
    dosage_form?: string;
    route?: string;
    schedule?: string;
    ayva_ddid?: string;
    rxcui?: any;
    description?: string;
}

export interface DrugsCategorySerializer {
    id?: number;
    is_active?: boolean;
    drugcategory?: DrugCategorySerializer;
    drug?: DrugSerializer;
}

export interface EMRImportSerializer {
    id?: number;
    timestamp?: Date | string;
    status?: string;
    user?: UserSimpleSerializer;
}

export interface EMRImportViewSetLogSerializer {
    message?: string;
    task?: string;
    level?: string;
}

export interface EMRImportViewSetTaskLogsSerializer {
    timestamp?: Date | string;
    message?: string;
    level?: string;
}

export interface EMRProfessionalCreateSerializer {
    id?: number;
    name?: string;
    first_name?: string;
    last_name?: string;
    profession?: string;
    facility?: number;
    import_as_user?: boolean;
    auto_assign_to_patient?: boolean;
}

export interface EMRProfessionalSerializer {
    id?: number;
    created_at?: Date | string;
    name?: string;
    first_name?: string;
    last_name?: string;
    profession?: string;
    facility?: number;
    origin?: string;
    import_as_user?: boolean;
    auto_assign_to_patient?: boolean;
}

export interface EMRProfessionalUpdateSerializer {
    id?: number;
    name?: string;
    first_name?: string;
    last_name?: string;
    profession?: string;
    import_as_user?: boolean;
    auto_assign_to_patient?: boolean;
}

export interface EMRReportsSerializer {
    reports?: Array<string>;
}

export interface EMRUploadBatchUpdateSerializer {
    status?: string;
    messages?: Array<number>;
}

export interface EMRUploadObject {
    id?: number;
    type?: string;
}

export interface EMRUploadSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    status?: string;
    facility?: FacilitySimpleSerializer;
    patient?: PatientSimpleSerializer;
    place?: string;
    type?: string;
    error_message?: string;
    upload_id?: number;
    origin?: string;
}

export interface EMRUploadViewSetEMRUploadDetailSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    status?: string;
    facility?: FacilitySimpleSerializer;
    patient?: PatientSimpleSerializer;
    place?: string;
    error_message?: string;
    upload_id?: number;
    hl7_messages?: Array<EMRUploadViewSetHL7MessageDetailSerializer>;
    objects?: Array<EMRUploadObject>;
    origin?: string;
}

export interface EMRUploadViewSetHL7MessageDetailSerializer {
    id?: number;
    type?: string;
    file_name?: string;
    file_location?: string;
}

export interface ENTExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    deformity?: string;
    external_auditory_canal?: string;
    tympanic_membrane?: string;
    assistive_devices?: string;
    comment?: string;
}

export interface ENTOrMouthSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    hearing_changes?: string;
    ear_pain?: string;
    nasal_congestion?: string;
    sinus_pain?: string;
    sore_throat?: string;
    swallowing_difficulty?: string;
    comment?: string;
}

export interface EPrescribingSerializer {
    user_status?: string;
    authorization_url?: string;
    finalize_url?: string;
    error?: string;
}

export interface ERXOriginSerializer {
    id?: number;
    name?: string;
    origin_id?: string;
}

export interface EditingUserSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    organization_group?: number;
    email?: string;
    is_active?: boolean;
    timezone?: string;
    facilities?: number[];
    roles?: string;
    phone?: any;
    origin?: string;
}

export interface EligibilityRequestDataSerializer {
    provider_name?: string;
    payer_name?: string;
    subscriber_id?: string;
    patient_first_name?: string;
    patient_last_name?: string;
    patient_dob?: string;
    patient_relationsip_to_insured?: string;
    other_provider_name?: string;
    date_of_service?: string;
    service_type?: string;
    provider_inquiry_number?: string;
    payer_id?: string;
    account_break_out_value?: string;
    simple_file_format_version?: string;
    provider_entity_id?: string;
    provider_first_name?: string;
    provider_middle_name?: string;
    provider_tax_id?: string;
    provider_payer_assigned_provider_number?: string;
    provider_npi?: string;
}

export interface EligibilityRequestDetailSerializer {
    id?: number;
    timestamp?: Date | string;
    user?: number;
    patient?: number;
    payer_name?: PayerAltNameDetailSerializer;
    request_data?: EligibilityRequestDataSerializer;
    response_data?: any;
    response_data_html?: string;
    response_status?: string;
    transaction_id?: string;
    subscriber_diff?: EligibilityRequestDiffSerializer;
}

export interface EligibilityRequestDiffSerializer {
    subscriber_id?: CharDiffSerializer;
    first_name?: CharDiffSerializer;
    last_name?: CharDiffSerializer;
    patient_dob?: DateDiffSerializer;
    subscriber_address?: FHIRAddressSerializer;
    other_primary_payer_exists?: boolean;
}

export interface EligibilityRequestSerializer {
    id?: number;
    timestamp?: Date | string;
    user?: number;
    patient?: number;
    payer_name?: number;
    request_data?: string;
    response_data?: any;
    response_data_html?: string;
    response_status?: string;
    transaction_id?: string;
}

export interface EligibilityRequestSimpleSerializer {
    id?: number;
    patient?: number;
    timestamp?: Date | string;
    payer_name?: PayerAltNameDetailSerializer;
    response_status?: string;
    request_data?: EligibilityRequestDataSerializer;
}

export interface EmailSerializer {
    email?: string;
}

export interface EncounterContextResponseSerializer {
    fhir_id?: string;
    date?: Date | string;
}

export interface EndocrineSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    polyuria?: string;
    polydipsia?: string;
    temperature_intolerance?: string;
    comment?: string;
}

export interface EntryProposalSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    user?: PhysicianSerializer;
    groups?: number[];
    organization?: number;
    type?: string;
    form_values?: any;
    status?: string;
    labels?: number[];
}

export interface EventActionReadSerializer {
    id?: number;
    event?: EventDetailSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface EventActionSerializer {
    id?: number;
    event?: EventCreateUpdateSerializer;
    action?: string;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    sort?: number;
}

export interface EventActionSimpleReadSerializer {
    id?: number;
    event?: EventSimpleSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    user?: CachedPhysicianNameSimpleSerializer;
}

export interface EventCreateUpdateSerializer {
    id?: number;
    date?: Date | string;
    end_date?: Date | string;
    type?: string;
    patient?: number;
    description?: string;
    evaluation?: string;
    copied_from?: number;
    is_deleted?: boolean;
    sub_type?: string;
    physician?: number;
    original_id?: string;
}

export interface EventDetailSerializer {
    id?: number;
    date?: Date | string;
    type?: string;
    patient?: PatientOversightSerializer;
    description?: string;
    evaluation?: string;
    display_name?: any;
    related_tasks?: Array<TaskAuthorSerializer>;
    copied_from?: number;
}

export interface EventReviewReadForTasksSerializer {
    id?: number;
    review?: string;
    actions?: Array<EventActionSimpleReadSerializer>;
    falls?: string;
    wounds?: string;
    diet?: string;
    infections?: string;
}

export interface EventReviewReadSerializer {
    id?: number;
    review?: string;
    actions?: Array<EventActionReadSerializer>;
    falls?: string;
    wounds?: string;
    diet?: string;
    infections?: string;
}

export interface EventReviewSerializer {
    id?: number;
    review?: string;
    actions?: Array<EventActionSerializer>;
    falls?: string;
    wounds?: string;
    diet?: string;
    infections?: string;
}

export interface EventReviewSimpleReadSerializer {
    review?: string;
}

export interface EventSerializer {
    id?: number;
    date?: Date | string;
    type?: string;
    patient?: number;
    description?: string;
    evaluation?: string;
}

export interface EventSimpleSerializer {
    id?: number;
    date?: Date | string;
    type?: string;
    description?: string;
    evaluation?: string;
    display_name?: any;
    related_tasks?: Array<TaskAuthorSerializer>;
}

export interface ExcludeInactiveFieldsTaskPreviousValues {
    review_of_systems?: ReviewOfSystemsSerializer;
    physical_examination?: PhysicalExaminationReadSerializer;
    past_medical_hx?: PastMedicalHxReadSerializer;
    social_history?: SocialHistoryReadSerializer;
    family_history?: FamilyHistorySerializer;
    history_of_present_illness?: HistoryOfPresentIllnessHoPISerializer;
    problem_list?: ProblemListReadSerializer;
    event_review?: EventReviewSimpleReadSerializer;
    visit_details?: VisitDetailsReasonForNextVisitSerializer;
    patient_complexity?: PatientComplexitySerializer;
    behavioral_physical_examination?: BehavioralPhysicalExaminationReadSerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    behavioral_social_history?: BehavioralSocialHistoryReadSerializer;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    behavioral_review_of_systems?: BehavioralReviewOfSystemsSerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    mental_status_exam?: BehavioralMentalStatusExamReadSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    treatment_review?: TreatmentReviewLastValuesSerializer;
    cognitive_reason_for_visit?: CognitiveReasonForVisitSerializer;
    cognitive_symptoms_of_illness?: CognitiveSymptomsOfIllnessSerializer;
    cognitive_baseline_evaluation?: CognitiveBaselineEvaluationSerializer;
    phq_9?: PHQ9Serializer;
    cognitive_fast_screening?: CognitiveFastScreeningSerializer;
    cognitive_care_plan?: CognitiveCarePlanSerializer;
    patient_information?: PatientInformationSerializer;
    demographics?: DemographicsSerializer;
    procedure_review?: ProcedureReviewReadSerializer;
    imaging_result_review?: ImagingResultReviewReadSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewReadSerializer;
    immunization_review?: ImmunizationReviewReadSerializer;
    patient_allergy_review?: PatientAllergyReviewReadSerializer;
}

export interface EyesExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    sclera_lids?: string;
    extra_ocular_movements?: string;
    pupil?: string;
    eye_glasses?: string;
    comment?: string;
}

export interface EyesSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    eye_pain?: string;
    swelling?: string;
    redness?: string;
    foreign_body?: string;
    discharge?: string;
    vision_changes?: string;
    comment?: string;
}

export interface FHIRAddressCreateUpdateModelSerializer {
    id?: number;
    start?: Date | string;
    end?: Date | string;
    use?: string;
    type?: string;
    text?: string;
    city?: string;
    line?: Array<string>;
    district?: string;
    state?: string;
    postal_code?: string;
    country?: string;
}

export interface FHIRAddressCreateUpdateSerializer {
    start?: Date | string;
    end?: Date | string;
    use?: string;
    type?: string;
    text?: string;
    city?: string;
    line?: Array<string>;
    district?: string;
    state?: string;
    postal_code?: string;
    country?: string;
}

export interface FHIRAddressSerializer {
    line?: Array<string>;
    city?: string;
    postal_code?: string;
    state?: string;
    use?: string;
    type?: string;
    id?: number;
    start?: Date | string;
    end?: Date | string;
}

export interface FHIRAddressWithRelatedPatientAddressModelSerializer {
    id?: number;
    line?: Array<string>;
    use?: string;
    type?: string;
    patient_address_id?: number;
    start?: Date | string;
    end?: Date | string;
    created_at?: Date | string;
    modified_at?: Date | string;
    text?: string;
    city?: string;
    district?: string;
    state?: string;
    postal_code?: string;
    country?: string;
    extension?: number;
}

export interface FHIRContactPointCreateUpdateModelSerializer {
    id?: number;
    start?: Date | string;
    end?: Date | string;
    system?: string;
    value?: string;
    use?: string;
    rank?: number;
}

export interface FHIRContactPointCreateUpdateSerializer {
    start?: Date | string;
    end?: Date | string;
    system?: string;
    value?: string;
    use?: string;
    rank?: number;
}

export interface FHIRContactPointModelSerializer {
    id?: number;
    start?: Date | string;
    end?: Date | string;
    created_at?: Date | string;
    modified_at?: Date | string;
    system?: string;
    value?: string;
    use?: string;
    rank?: number;
    extension?: number;
}

export interface FHIRContactPointWithRelatedPatientContactPointModelSerializer {
    id?: number;
    patient_contact_point_id?: number;
    start?: Date | string;
    end?: Date | string;
    created_at?: Date | string;
    modified_at?: Date | string;
    system?: string;
    value?: string;
    use?: string;
    rank?: number;
    extension?: number;
}

export interface FHIRContactPointWithoutPeriodSerializer {
    system?: string;
    value?: string;
    use?: string;
    rank?: number;
    start?: Date | string;
    end?: Date | string;
}

export interface FHIRDeviceNameSerializer {
    name?: string;
}

export interface FHIRDeviceParserSerializer {
    udi?: UDIParserSerializer;
    di?: DIParserSerializer;
}

export interface FHIRDeviceSerializer {
    id?: number;
    created_at?: Date | string;
    is_active?: boolean;
    modified_at?: Date | string;
    created_by?: UserBaseSerializer;
    device_identifier?: string;
    carrier_hrf?: string;
    distinct_identifier?: string;
    manufacture_date?: Date | string;
    expiration_date?: Date | string;
    lot_number?: string;
    serial_number?: string;
    type_code?: number;
    type_display?: string;
    inactivity_reason?: string;
    patient?: number;
    mri_safety_status?: string;
    contains_nrl?: boolean;
    assigning_authority?: string;
}

export interface FHIRDeviceTypeSerializer {
    snomedCTName?: string;
    snomedIdentifier?: number;
}

export interface FHIRQuestionnaireResponseItemSerializer {
    item_path?: string;
    value_string?: string;
    value_decimal?: number;
    value_coding?: any;
}

export interface FHIRQuestionnaireResponseSerializer {
    id?: number;
    questionnaire?: number;
    items?: Array<FHIRQuestionnaireResponseItemSerializer>;
    task?: number;
}

export interface FHIRQuestionnaireSerializer {
    id?: number;
    code?: string;
    resource?: any;
}

export interface FHIRRelatedPersonPhoneNumberSerializer {
    value?: string;
    use?: string;
    id?: number;
}

export interface FacilityAccessPermissionTypeSerializer {
    id?: number;
    name?: string;
}

export interface FacilityActivePatientStatisticsSerializer {
    id?: number;
    name?: string;
    active_patients_count?: number;
    patients_with_ssn_count?: any;
    patients_with_advanced_directive_code_count?: any;
    patients_with_immunizations_count?: any;
    patients_with_diagnoses_count?: any;
    patients_with_allergies_count?: any;
}

export interface FacilityAddressSerializer {
    id?: number;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    organization?: number;
    mac?: number;
}

export interface FacilityDetailedSerializer {
    id?: number;
    name?: string;
    is_inactive?: boolean;
    created_at?: Date | string;
    size?: number;
    timezone?: string;
    type?: string;
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    organization?: number;
    users_count?: number;
    mac?: number;
    lab_import_account?: number;
    origin?: string;
    import_settings?: FacilityEmrImportSettingAccountSerializer;
    access_permission_type?: string;
    phone_number?: any;
}

export interface FacilityEMRImportSettingsReadSerializer {
    unique_import_settings?: any;
    import_preset_id?: number;
}

export interface FacilityEMRImportSettingsWriteSerializer {
    config?: any;
    import_preset_id?: number;
}

export interface FacilityEmrImportSettingAccountSerializer {
    emr_import_account?: number;
    emr_facility_origin?: number;
}

export interface FacilityEmrImportSettingUpdateSerializer {
    census_status_settings?: any;
}

export interface FacilityEmrUploadSettingsSerializer {
    facility_id?: any;
    emr_upload_categories?: any;
}

export interface FacilityImportInfoSerializer {
    id?: number;
    name?: string;
    organization_id?: any;
    last_import?: EMRImportSerializer;
    import_preset_name?: any;
    groups?: any;
}

export interface FacilityImportSettingsSerializer {
    import_settings?: FacilityEMRImportSettingsReadSerializer;
    available_imports?: any;
    missing_permissions?: any;
    origin?: string;
}

export interface FacilityLabImportAccountFaxOnlySerializer {
    id?: number;
    facility?: number;
    import_account_id?: any;
    fax_only_critical?: boolean;
}

export interface FacilityLabImportAccountSerializer {
    id?: number;
    facility_id?: any;
    import_account_id?: any;
    reporting_lab?: string;
}

export interface FacilityOriginSerializer {
    id?: number;
    name?: string;
    origin_id?: string;
    permissions?: any;
    missing_permissions?: any;
    import_account?: number;
    facilities?: Array<FacilityImportInfoSerializer>;
    lab_facilities?: Array<FacilitySimpleSerializer>;
    order_upload?: boolean;
}

export interface FacilityOriginSimpleSerializer {
    id?: number;
    name?: string;
    origin_id?: string;
    import_account?: number;
    order_upload?: boolean;
}

export interface FacilityOriginStatusCountSerializer {
    missing_permission_count?: number;
    missing_facility_count?: number;
    total_count?: number;
}

export interface FacilityPharmacyImportSettingAccountSerializer {
    pharmacy_import_accounts?: number[];
    pharmacy_facility_origins?: number[];
}

export interface FacilityPhysicianEMRImportSettingAccountSerializer {
    physician_emr_import_accounts?: number[];
    physician_emr_facility_origins?: number[];
}

export interface FacilityProcedureCodeSerializer {
    facility?: FacilitySimpleSerializer;
    procedure_codes?: number;
}

export interface FacilitySerializer {
    id?: number;
    name?: string;
    created_at?: Date | string;
    size?: number;
    timezone?: string;
    type?: string;
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    organization?: number;
    skilled_patients_beds_optimal_gt?: number;
    skilled_patients_beds_critical_lt?: number;
    rehospitalization_rate_optimal_lt?: number;
    rehospitalization_rate_critical_gt?: number;
    actual_hospice_payers_nums_optimal_gt?: number;
    actual_hospice_payers_nums_critical_lt?: number;
    mac?: number;
}

export interface FacilitySimpleDetailSerializer {
    id?: number;
    name?: string;
    he_import_account?: number;
    has_documents?: any;
    has_mds?: any;
    has_care_plan?: any;
    has_patient_abstract?: any;
    has_revolution?: any;
    has_cerner_account?: boolean;
}

export interface FacilitySimpleSerializer {
    id?: number;
    name?: string;
    organization_id?: any;
}

export interface FacilityUploadReportsSerializer {
    id?: number;
    name?: string;
    upload_reports?: any;
}

export interface FacilityUserAuthSerializer {
    id?: number;
    name?: string;
    is_inactive?: boolean;
    organization?: number;
    access_permission_type?: string;
    origin?: string;
    timezone?: string;
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    from_customer_only?: boolean;
}

export interface FacilityWithImportAccountsSerializer {
    id?: number;
    name?: string;
    is_inactive?: boolean;
    created_at?: Date | string;
    size?: number;
    timezone?: string;
    type?: string;
    address?: string;
    place_of_service?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    organization?: number;
    groups?: number[];
    skilled_patients_beds_optimal_gt?: number;
    skilled_patients_beds_critical_lt?: number;
    rehospitalization_rate_optimal_lt?: number;
    rehospitalization_rate_critical_gt?: number;
    actual_hospice_payers_nums_optimal_gt?: number;
    actual_hospice_payers_nums_critical_lt?: number;
    mac?: number;
    lab_import_account?: number;
    lab_import_accounts?: number[];
    lab_facility_origins?: number[];
    he_import_account?: number;
    include_secondary_payers_in_ccm_eligibility_calculation?: boolean;
    unmatched_enabled?: boolean;
    unmatched?: boolean;
    employee_enabled?: boolean;
    employee?: boolean;
    primary_source?: string;
    upload_reports?: any;
    sftp_upload_account?: number;
    sftp_upload_folder?: string;
    emr_upload_account?: number;
    lab_order_upload_facility_origin?: number;
    phone_number?: string;
    import_professions?: any;
    import_settings?: FacilityEmrImportSettingAccountSerializer;
    origin?: string;
    access_permission_type?: string;
    pharmacy_import_settings?: FacilityPharmacyImportSettingAccountSerializer;
    require_association_for_superadmins?: boolean;
    facility_lab_import_account?: Array<FacilityLabImportAccountSerializer>;
    physician_emr_import_settings?: FacilityPhysicianEMRImportSettingAccountSerializer;
    upload_settings?: FacilityEmrUploadSettingsSerializer;
    fax_number?: string;
    should_activate_fax?: any;
    npi_number?: string;
    no_npi?: boolean;
}

export interface FamilyHistorySerializer {
    stroke?: string;
    cad_mi?: string;
    dementia?: string;
    diabetes?: string;
    parkinson_disease?: string;
    alzheimer_disease?: string;
    cholesterol?: string;
    depression?: string;
    alcohol_dependence?: string;
    psychiatric_illness?: string;
    cancers?: string;
    abuse?: string;
    other?: string;
    stroke_relative?: any;
    cad_mi_relative?: any;
    dementia_relative?: any;
    diabetes_relative?: any;
    parkinson_disease_relative?: any;
    alzheimer_disease_relative?: any;
    cholesterol_relative?: any;
    depression_relative?: any;
    alcohol_dependence_relative?: any;
    psychiatric_illness_relative?: any;
    cancers_relative?: any;
    abuse_relative?: any;
    alzheimer_disease_note?: string;
    cad_mi_note?: string;
    cancers_note?: string;
    cholesterol_note?: string;
    diabetes_note?: string;
    parkinson_disease_note?: string;
    stroke_note?: string;
    dementia_note?: string;
    depression_note?: string;
    alcohol_dependence_note?: string;
    psychiatric_illness_note?: string;
    abuse_note?: string;
}

export interface FaxNumberSerializer {
    name?: string;
    fax_number?: string;
    is_primary?: boolean;
}

export interface FaxSerializer {
    id?: number;
    created_at?: Date | string;
    created_by?: number;
    modified_at?: Date | string;
    modified_by?: number;
    number?: string;
    confirmation_token?: string;
    confirmation_token_time?: Date | string;
    facility?: number;
    units?: number[];
    locations?: number[];
    is_active?: boolean;
}

export interface FaxStatusSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    created_by?: CachedPhysicianNameSimpleSerializer;
    fax_number?: string;
    status?: string;
    error_code?: string;
    template?: string;
    response_time?: Date | string;
    notification_sent?: boolean;
}

export interface FilteredTreatmentOverviewSerializer {
    pie_chart?: TreatmentDistributionSerializer;
    patients_with_treatment_without_diagnosis?: IDListWithCountSerializer;
}

export interface FirstDemographicsSerializer {
    races?: Array<string>;
    ethnicities?: Array<string>;
    administrative_gender?: string;
    birth_sex?: string;
    gender_identity?: string;
    language?: string;
    sexual_orientation?: string;
}

export interface FirstPatientAdditionalNameSerializer {
    use?: string;
    qualifier?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    prefix?: string;
    suffix?: string;
    start?: Date | string;
    end?: Date | string;
}

export interface FloorPlanSerializer {
    id?: number;
    facility?: number;
    name?: string;
    created_at?: Date | string;
}

export interface FrequentDrugSerializer {
    drug?: DrugWithPsychotropicGroupsSerializer;
    count?: number;
}

export interface FrequentPhysicianSerializer {
    physician?: UserSimpleSerializer;
    count?: number;
}

export interface GUExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    bladder?: string;
    cva?: string;
    incontinence_assistance?: string;
    comment?: string;
}

export interface GastrointestinalSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    nausea?: string;
    vomiting?: string;
    difficulty_with_bowels?: string;
    pain?: string;
    heartburn?: string;
    dysphagia?: string;
    comment?: string;
}

export interface GeneralExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    alertness?: string;
    nourishment?: string;
    acute_distress?: string;
    normocephalic_atraumatic?: string;
    comment?: string;
}

export interface GenitourinarySystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    dysuria?: string;
    urinary_frequency?: string;
    difficulty_with_urination?: string;
    hematuria?: string;
    flank_pain?: string;
    comment?: string;
}

export interface GetClaimFromTaskSerializer {
    task_id?: number;
}

export interface GoalCreateUpdateSerializer {
    lifecycle_status?: string;
    achievement_status?: string;
    goal_category?: string[];
    continuous?: boolean;
    goal_priority?: string;
    description_text?: string;
    description_code?: string;
    subject_patient?: number;
    start_date?: Date | string;
    status_date?: Date | string;
    status_reason?: string;
    source_user?: number;
    goal_targets?: Array<GoalTargetCreateUpdateSerializer>;
}

export interface GoalReadSerializer {
    lifecycle_status?: string;
    achievement_status?: string;
    goal_category?: string[];
    continuous?: boolean;
    goal_priority?: string;
    description_text?: string;
    description_code?: string;
    subject_patient?: number;
    start_date?: Date | string;
    status_date?: Date | string;
    status_reason?: string;
    source_user?: number;
    goal_targets?: Array<GoalTargetReadSerializer>;
}

export interface GoalTargetCreateUpdateSerializer {
    measure_text?: string;
    measure_decimal?: MeasureDecimalSerializer;
    measure_range?: MeasureRangeSerializer;
    measure_snomed?: number;
    detail?: string;
    due_date?: Date | string;
}

export interface GoalTargetReadSerializer {
    measure_text?: string;
    measure_decimal?: MeasureDecimalSerializer;
    measure_range?: MeasureRangeSerializer;
    measure_snomed?: SnomedConceptSerializer;
    detail?: string;
    due_date?: Date | string;
}

export interface GroupSerializer {
    id?: number;
    name?: string;
    description?: string;
    facilities?: number[];
    user_count?: any;
    active_user_count?: any;
}

export interface HL7BatchUpdateSerializer {
    status?: string;
    messages?: Array<number>;
}

export interface HL7DashboardSerializer {
    source?: string;
    unmatched_patients?: any;
    recheck_later?: any;
    actions_needed?: any;
    unresolved?: any;
    qa?: any;
    folder_size?: number;
    queue_size?: number;
    success?: number;
    error?: number;
    avg_traffic?: number;
    current_traffic?: number;
    flag?: string;
    error_message?: string;
    alert?: string;
    last_processed_date?: Date | string;
    created_at?: Date | string;
    modified_at?: Date | string;
}

export interface HL7MessageListSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    status?: string;
    facility?: FacilitySimpleSerializer;
    patient?: PatientSimpleSerializer;
    type?: string;
    message_id?: string;
    error_code?: string;
    facility_origin?: FacilityOriginSimpleSerializer;
    sftp_username?: string;
    lab_provider?: string;
}

export interface HeartExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    rate_rhythm?: string;
    murmur?: string;
    lower_extremity_edema?: string;
    pedal_pulses?: string;
    supplemental_oxygen_required?: string;
    comment?: string;
}

export interface HemORLymphSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    bruising?: string;
    bleeding?: string;
    lymphadenopathy?: string;
    comment?: string;
}

export interface HistoryOfPresentIllnessHoPISerializer {
    symptoms?: string;
    relevant_past_medical_history?: string;
    goals_of_care?: string;
}

export interface HistoryOfPresentIllnessLastVisitSerializer {
    goals_of_care?: string;
}

export interface HistoryOfPresentIllnessReadSerializer {
    id?: number;
    chief_complaint?: string;
    symptoms?: string;
    relevant_past_medical_history?: string;
    goals_of_care?: string;
    old_records_acf_review_summary?: string;
}

export interface HistoryOfPresentIllnessSerializer {
    id?: number;
    chief_complaint?: string;
    symptoms?: string;
    relevant_past_medical_history?: string;
    goals_of_care?: string;
    old_records_acf_review_summary?: string;
}

export interface HistoryOfPresentIllnessSimpleReadSerializer {
    id?: number;
    chief_complaint?: string;
    goals_of_care?: string;
}

export interface Hl7MessageSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    status?: string;
    facility?: FacilitySimpleSerializer;
    patient?: PatientSimpleSerializer;
    type?: string;
    message_id?: string;
    error_code?: string;
    facility_origin?: FacilityOriginSimpleSerializer;
    file_name?: string;
    file_location?: string;
    error_message?: string;
    object_id?: number;
    is_archive?: boolean;
    sftp_username?: string;
    lab_provider?: string;
}

export interface HospitalSerializer {
    name?: string;
    location?: string;
    type?: string;
}

export interface ICDCodeGroupSerializer {
    id?: number;
    name?: string;
    category?: number;
    is_infection?: boolean;
}

export interface ICDCodeIndicationItemSerializer {
    id?: number;
    drug_name?: DrugNameIngredientSerializer;
    indication_type?: string;
}

export interface ICDCodeIndicationsSerializer {
    id?: number;
    name?: string;
    description?: string;
    indications?: Array<ICDCodeIndicationItemSerializer>;
}

export interface ICDCodeMedicalSpecialitySerializer {
    id?: number;
    icd?: number;
    priority?: string;
    specialty?: number;
}

export interface ICDCodeNameSerializer {
    id?: number;
    name?: string;
    description?: string;
}

export interface ICDCodeSerializer {
    id?: number;
    name?: string;
    infection?: boolean;
    description?: string;
    icd_groups?: Array<ICDCodeGroupSerializer>;
    display_name?: any;
    is_chronic?: boolean;
    is_effective?: boolean;
    name_with_decimals?: any;
    specialties?: number[];
}

export interface ICDCodeWithPsychotropicGroupsSerializer {
    id?: number;
    name?: string;
    description?: string;
    display_name?: any;
    name_with_decimals?: any;
    psychotropic_groups?: PsychotropicGroupsSerializer;
}

export interface IDListWithCountSerializer {
    count?: number;
    ids?: Array<number>;
}

export interface ImagingResultActionSerializer {
    id?: number;
    action?: string;
    imaging_result?: ImagingResultCreateUpdateSerializer;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
}

export interface ImagingResultCreateUpdateSerializer {
    id?: number;
    status?: string;
    code?: string;
    patient?: number;
    date?: Date | string;
    value_code?: string;
    value_string?: string;
    value_quantity?: string;
    unit?: string;
    order?: number;
}

export interface ImagingResultReadSerializer {
    id?: number;
    status?: string;
    code?: string;
    code_display?: string;
    patient?: number;
    date?: Date | string;
    value_code?: string;
    value_code_display?: string;
    value_string?: string;
    value_quantity?: string;
    unit?: string;
    order?: OtherOrderReadSerializer;
}

export interface ImagingResultReviewActionReadSerializer {
    id?: number;
    imaging_result?: ImagingResultSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface ImagingResultReviewCreateUpdateSerializer {
    id?: number;
    review?: string;
    actions?: Array<ImagingResultActionSerializer>;
}

export interface ImagingResultReviewReadSerializer {
    id?: number;
    review?: string;
    actions?: Array<ImagingResultReviewActionReadSerializer>;
}

export interface ImagingResultSerializer {
    id?: number;
    status?: string;
    code?: string;
    code_display?: string;
    patient?: number;
    date?: Date | string;
    value_code?: string;
    value_code_display?: string;
    value_string?: string;
    value_quantity?: string;
    unit?: string;
    order?: OtherOrderReadSerializer;
}

export interface ImmunizationActionSerializer {
    id?: number;
    action?: string;
    immunization?: ImmunizationCreateUpdateSerializer;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
}

export interface ImmunizationCreateUpdateSerializer {
    id?: number;
    date?: Date | string;
    description?: string;
    name?: string;
    step?: string;
    code?: number;
    site?: string;
    expiration?: Date | string;
    patient?: number;
    status?: string;
    status_reason?: number;
    type?: string;
    result?: string;
    consent_status?: string;
    is_covid_related?: boolean;
    procedure_type?: string;
    copied_from?: number;
    staff_name?: string;
    lot_number?: string;
    is_deleted?: boolean;
}

export interface ImmunizationReadSerializer {
    id?: number;
    date?: Date | string;
    description?: string;
    name?: string;
    step?: string;
    code?: number;
    site?: string;
    expiration?: Date | string;
    status?: string;
    status_reason?: number;
    type?: string;
    result?: string;
    consent_status?: string;
    is_covid_related?: boolean;
    procedure_type?: string;
}

export interface ImmunizationReviewActionReadSerializer {
    id?: number;
    immunization?: ImmunizationSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface ImmunizationReviewCreateUpdateSerializer {
    id?: number;
    review?: string;
    actions?: Array<ImmunizationActionSerializer>;
}

export interface ImmunizationReviewReadSerializer {
    id?: number;
    review?: string;
    actions?: Array<ImmunizationReviewActionReadSerializer>;
}

export interface ImmunizationSerializer {
    id?: number;
    name?: string;
    date?: Date | string;
    expiration?: Date | string;
    step?: string;
    type?: string;
    description?: string;
    result?: string;
    staff_name?: string;
    consent_status?: string;
    site?: string;
    lot_number?: string;
    facility?: number;
    patient?: PatientSerializer;
    is_covid_related?: boolean;
    procedure_type?: string;
    code?: number;
    status?: string;
    status_reason?: number;
}

export interface ImportAccountDetailSerializer {
    id?: number;
    created_by?: number;
    modified_by?: number;
    username?: string;
    raw_password?: string;
    origin?: string;
    import_type?: string;
    status?: string;
    captcha_status?: string;
    name?: string;
    url?: string;
    facility_origins?: Array<FacilityOriginSerializer>;
    is_active?: boolean;
    is_renewable?: boolean;
    password_change_date?: Date | string;
    is_okta_login?: boolean;
    emr_facilities?: Array<FacilityImportInfoSerializer>;
    comment?: string;
    last_status?: ImportAccountStatusHistorySerializer;
    last_captcha_status?: ImportAccountStatusHistorySerializer;
    last_import?: any;
    last_successful_import?: any;
    account_type?: any;
}

export interface ImportAccountSecretSerializer {
    id?: number;
    plain_password?: string;
    security_answer_1?: string;
    security_answer_2?: string;
    security_answer_3?: string;
    password_change_date?: Date | string;
}

export interface ImportAccountSerializer {
    captcha_status?: string;
    comment?: string;
    created_by?: number;
    emr_facilities?: Array<FacilitySimpleSerializer>;
    facility_origin_status_count?: FacilityOriginStatusCountSerializer;
    id?: number;
    import_type?: string;
    is_active?: boolean;
    is_okta_login?: boolean;
    is_renewable?: boolean;
    modified_by?: number;
    name?: string;
    origin?: string;
    password_change_date?: Date | string;
    raw_password?: string;
    status?: string;
    url?: string;
    username?: string;
    labels?: number[];
}

export interface ImportAccountStatusHistorySerializer {
    id?: number;
    timestamp?: Date | string;
    account_status?: string;
    captcha_status?: string;
    comment?: string;
}

export interface ImportFileUploadFormSerializer {
    name?: string;
    type?: string;
}

export interface ImportPresetSerializer {
    id?: number;
    name?: string;
    origin?: string;
    settings?: any;
}

export interface ImportValidationHistoryReadOnlySerializer {
    type?: string;
    status?: string;
    timestamp?: Date | string;
}

export interface IndicationCreateSerializer {
    id?: number;
    drug_name_id?: number;
    icd_id?: number;
    indication_type?: string;
}

export interface IndicationReadSerializer {
    id?: number;
    drug_name?: DrugNameSerializer;
    icd?: ICDCodeSerializer;
    indication_type?: string;
}

export interface IndicationUpdateSerializer {
    id?: number;
    drug_name_id?: any;
    icd_id?: any;
    indication_type?: string;
}

export interface InfectionCultureSerializer {
    id?: number;
    name?: string;
    organization?: number;
    classification?: string;
}

export interface InfectionCultureSimpleSerializer {
    id?: number;
    name?: string;
}

export interface InfectionEntryViewSetCensusHistorySerializer {
    id?: number;
    status?: string;
    timestamp?: Date | string;
}

export interface InfectionEntryViewSetInfectionInformationSerializer {
    id?: number;
    symptom_onset_date?: Date | string;
    type_of_acquisition?: string;
    type_of_isolation?: string;
    date_of_order?: Date | string;
    date_of_result?: Date | string;
    confirmed?: boolean;
    resolved?: Date | string;
    icd?: ICDCodeSerializer;
    treatment_history?: Array<InfectionEntryViewSetTreatmentHistorySerializer>;
    lab_tests?: Array<InfectionEntryViewSetLabTestSerializer>;
    recurrence_days?: number;
    infection_information_verifications?: Array<InfectionEntryViewSetInfectionInformationVerificationSerializer>;
    created_at?: Date | string;
    modified_at?: Date | string;
}

export interface InfectionEntryViewSetInfectionInformationVerificationSerializer {
    id?: number;
    created_by?: InfectionEntryViewUserNameSerializer;
    created_at?: Date | string;
    dynamic_form_values?: any;
    verified?: boolean;
    infection_information?: number;
}

export interface InfectionEntryViewSetLabResultSerializer {
    id?: number;
    infection_culture?: InfectionCultureSerializer;
    is_abnormal?: boolean;
    is_critical?: boolean;
    is_inconclusive?: boolean;
    is_no_result?: boolean;
}

export interface InfectionEntryViewSetLabTestSerializer {
    id?: number;
    name?: string;
    lab_results?: Array<InfectionEntryViewSetLabResultSerializer>;
    lab_type?: string;
}

export interface InfectionEntryViewSetPatientSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    facility?: number;
    physician?: number;
    physician_teams?: number[];
}

export interface InfectionEntryViewSetSerializer {
    id?: number;
    infection_information?: Array<InfectionEntryViewSetInfectionInformationSerializer>;
    location?: LocationWithUnitSerializer;
    patient?: InfectionEntryViewSetPatientSerializer;
    start_date?: Date | string;
    end_date?: Date | string;
    created_at?: Date | string;
}

export interface InfectionEntryViewSetTreatmentHistorySerializer {
    id?: number;
    drug?: string;
    drug_name?: DrugNameSerializer;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    physician?: InfectionEntryViewUserNameSerializer;
    physician_name?: string;
    prn?: string;
    indications_for_use?: string;
    admit_census?: InfectionEntryViewSetCensusHistorySerializer;
    discharge_census?: InfectionEntryViewSetCensusHistorySerializer;
    flags?: number[];
    product_type?: string;
    product_type_category?: string;
    icd?: number;
    modified_by?: number;
}

export interface InfectionEntryViewUserNameSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
}

export interface InfectionEntryWithLocationSerializer {
    location?: LocationSimpleSerializer;
}

export interface InfectionInformationCreateUpdateSerializer {
    id?: number;
    infection?: number;
    infection_site?: number;
    patient?: number;
    symptom_onset_date?: Date | string;
    type_of_acquisition?: string;
    type_of_isolation?: string;
    date_of_order?: Date | string;
    date_of_result?: Date | string;
    confirmed?: boolean;
    resolved?: Date | string;
    icd?: number;
    recurrence_days?: number;
}

export interface InfectionInformationPatientSerializer {
    id?: number;
    patient_id?: any;
    symptom_onset_date?: Date | string;
    resolved?: Date | string;
    type_of_acquisition?: string;
    icd?: ICDCodeSerializer;
}

export interface InfectionInformationSerializer {
    id?: number;
    infection?: number;
    infection_entries?: number[];
    symptom_onset_date?: Date | string;
    type_of_acquisition?: string;
    date_of_order?: Date | string;
    date_of_result?: Date | string;
    confirmed?: boolean;
    resolved?: Date | string;
    icd?: ICDCodeSerializer;
    treatment_history?: Array<TreatmentHistoryDetailedSerializer>;
    lab_tests?: Array<LabTestDetailedSerializer>;
    recurrence_days?: number;
    modified_by?: number;
    infection_information_verifications?: Array<InfectionInformationVerificationSerializer>;
}

export interface InfectionInformationSimpleSerializer {
    infection_entries?: Array<InfectionEntryWithLocationSerializer>;
}

export interface InfectionInformationTreatmentSerializer {
    id?: number;
    infection?: number;
    symptom_onset_date?: Date | string;
    type_of_acquisition?: string;
    icd?: ICDCodeSerializer;
}

export interface InfectionInformationVerificationDetailedSerializer {
    id?: number;
    created_by?: UserSerializer;
    created_by_id?: number;
    created_at?: Date | string;
    modified_by?: UserSerializer;
    modified_by_id?: number;
    modified_at?: Date | string;
    verified?: boolean;
    dynamic_form_values?: any;
    infection_information?: number;
}

export interface InfectionInformationVerificationSerializer {
    id?: number;
    created_by?: UserSerializer;
    created_by_id?: number;
    created_at?: Date | string;
    modified_by?: UserSerializer;
    modified_by_id?: number;
    modified_at?: Date | string;
    verified?: boolean;
    infection_information?: number;
    dynamic_form_values?: any;
}

export interface InitialImportHistoryDataSerializer {
    facility_id?: number;
    initial_imports?: Array<InitialImportSerializer>;
}

export interface InitialImportPatientDataSerializer {
    name?: string;
    extra_data?: string;
}

export interface InitialImportPatientListSerializer {
    patients?: Array<InitialImportPatientDataSerializer>;
}

export interface InitialImportSerializer {
    sync_process_id?: number;
    import_validations?: Array<ImportValidationHistoryReadOnlySerializer>;
}

export interface InitialImportSyncDetailSimpleSerializer {
    id?: number;
    status?: string;
    facility?: FacilitySimpleSerializer;
    reports?: any;
    has_error?: any;
    has_fatal?: any;
    completed?: any;
    timestamp?: Date | string;
    user?: UserBaseSerializer;
    sync_process?: number;
    sync_process_id?: number;
}

export interface InteractionSerializer {
    code?: string;
}

export interface LabCreateCovidOrderSerializer {
    patient?: number;
    lab_shipment?: number;
    status?: string;
    barcode?: string;
    encounter_id?: string;
    related_icd_diagnoses?: number[];
    lab_order_group?: number;
}

export interface LabDetailedResultBaseSerializer {
    id?: number;
    susceptibility?: string;
    interpretation?: string;
    mic?: string;
}

export interface LabDetailedResultSerializer {
    id?: number;
    susceptibility?: string;
    interpretation?: string;
    mic?: string;
    copied_from?: number;
    is_deleted?: boolean;
}

export interface LabOrderDetailedSerializer {
    id?: number;
    lab_test_synonym?: number;
    patient?: PatientOversightSerializer;
    encounter_id?: string;
    order_id?: string;
    barcode?: string;
    status?: string;
    created_at?: Date | string;
    history?: Array<LabOrderStatusHistorySerializer>;
    lab_shipment?: number;
    lab_shipment_bag?: number;
    lab_report?: number[];
    related_icd_diagnoses?: number[];
    lab_order_group?: number;
}

export interface LabOrderGroupSerializer {
    id?: number;
    name?: string;
    lab_shipment?: number;
}

export interface LabOrderReadSerializer {
    id?: number;
    related_icd_diagnoses?: Array<CachedICDCodeNameSerializer>;
    lab_test_synonym?: CachedLabTestSynonymSerializer;
    urgency?: string;
    start?: Date | string;
    order_interval?: OrderIntervalSerializer;
    end?: Date | string;
}

export interface LabOrderSerializer {
    id?: number;
    related_icd_diagnoses?: number[];
    lab_test_synonym?: number;
    urgency?: string;
    start?: Date | string;
    order_interval?: OrderIntervalSerializer;
    end?: Date | string;
    patient?: number;
    order_id?: string;
    barcode?: string;
    user?: number;
}

export interface LabOrderSimpleSerializer {
    id?: number;
    external_id?: any;
    encounter_id?: string;
    order_id?: string;
    barcode?: string;
    status?: string;
}

export interface LabOrderStatusHistorySerializer {
    id?: number;
    status?: string;
    date?: Date | string;
    user?: PhysicianSerializer;
    comment?: string;
}

export interface LabOrderStatusSerializer {
    id?: number;
    status?: string;
    patient?: number;
}

export interface LabReportBasicSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    order_id?: string;
    status?: string;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    physician?: PhysicianSerializer;
    origin?: string;
}

export interface LabReportBillingSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    order_id?: string;
    status?: string;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    patient?: PatientNameSerializer;
    physician?: PhysicianSerializer;
    origin?: string;
}

export interface LabReportCreateUpdateSerializer {
    id?: number;
    order_id?: string;
    status?: string;
    patient?: number;
    physician?: number;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    lab_order?: LabOrderSerializer;
    origin?: string;
    reporting_laboratories?: string;
    lab_provider?: string;
    performing_locations?: string;
    specimen_collector?: string;
    copied_from?: number;
    is_deleted?: boolean;
    received_date?: Date | string;
    emr_upload_status?: string;
    emr_upload_id?: number;
    notifications_sent?: boolean;
}

export interface LabReportDetailedSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    order_id?: string;
    status?: string;
    patient?: PatientOversightSerializer;
    physician?: PhysicianSerializer;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    lab_results?: number[];
    lab_tests?: Array<LabTestHistoricalDetailedSerializer>;
    origin?: string;
    has_inconclusive_result?: any;
    has_no_result?: any;
    lab_order?: number;
    fax_status?: FaxStatusSerializer;
}

export interface LabReportSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    order_id?: string;
    status?: string;
    patient?: PatientOversightSerializer;
    physician?: UserSerializer;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    lab_results?: number[];
    origin?: string;
    has_inconclusive_result?: any;
    has_no_result?: any;
    has_corrected_result?: any;
    lab_order?: number;
    fax_status?: FaxStatusSerializer;
}

export interface LabReportSimpleReadSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    order_id?: string;
    status?: string;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    physician?: number;
    origin?: string;
}

export interface LabReportSimpleSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    order_id?: string;
    status?: string;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    description?: string;
    patient?: PatientOversightSerializer;
    physician?: PhysicianSerializer;
    origin?: string;
    lab_order?: LabOrderSimpleSerializer;
    fax_status?: FaxStatusSerializer;
}

export interface LabResultBillingSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    lab_report?: LabReportBillingSerializer;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: number;
    is_critical?: boolean;
    is_abnormal?: boolean;
    mdro?: string;
}

export interface LabResultCreateUpdateSerializer {
    id?: number;
    lab_report?: LabReportCreateUpdateSerializer;
    lab_test?: number;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: InfectionCultureSerializer;
    is_abnormal?: boolean;
    lab_detailed_results?: Array<LabDetailedResultSerializer>;
    description?: string;
    status?: string;
    is_critical?: boolean;
    is_inconclusive?: boolean;
    is_no_result?: boolean;
    mdro?: string;
    code?: string;
    loinc_code?: string;
    snomed_code?: string;
    copied_from?: number;
    effective_time?: Date | string;
    is_deleted?: boolean;
    critical_notifications_sent?: boolean;
}

export interface LabResultDetailedSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    lab_report?: number;
    culture_type?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: InfectionCultureSerializer;
    is_abnormal?: boolean;
    lab_detailed_results?: Array<LabDetailedResultSerializer>;
    description?: string;
    lab_attachments?: number[];
    flag?: string;
    is_critical?: boolean;
    is_inconclusive?: boolean;
    is_no_result?: boolean;
    is_corrected?: any;
    mdro?: string;
}

export interface LabResultForTasksSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    lab_report?: LabReportSimpleReadSerializer;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: InfectionCultureSimpleSerializer;
    is_abnormal?: boolean;
    lab_detailed_results?: Array<CachedLabDetailedResultSerializer>;
    description?: string;
    is_critical?: boolean;
    is_inconclusive?: boolean;
    is_no_result?: boolean;
    mdro?: string;
}

export interface LabResultSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    lab_report?: number;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: InfectionCultureSerializer;
    is_abnormal?: boolean;
    lab_detailed_results?: Array<LabDetailedResultSerializer>;
    description?: string;
    is_critical?: boolean;
    is_inconclusive?: boolean;
    is_no_result?: boolean;
    is_corrected?: any;
    mdro?: string;
}

export interface LabResultSimpleSerializer {
    id?: number;
    is_abnormal?: boolean;
}

export interface LabResultSummarySerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    lab_report?: number;
    lab_test?: number;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    is_abnormal?: boolean;
    is_critical?: boolean;
    is_no_result?: boolean;
    is_inconclusive?: boolean;
}

export interface LabResultTrendsSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    lab_report?: number;
    lab_test?: LabTestSimpleSerializer;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: InfectionCultureSerializer;
    is_abnormal?: boolean;
    lab_detailed_results?: Array<LabDetailedResultSerializer>;
    description?: string;
    is_critical?: boolean;
    is_no_result?: boolean;
    is_inconclusive?: boolean;
    mdro?: string;
}

export interface LabResultWithoutReportSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    culture_type?: string;
    flag?: string;
    quantity?: string;
    unit?: string;
    ref_range?: string;
    infection_culture?: InfectionCultureSerializer;
    is_abnormal?: boolean;
    lab_detailed_results?: Array<LabDetailedResultBaseSerializer>;
    description?: string;
    is_critical?: boolean;
    is_inconclusive?: boolean;
    is_no_result?: boolean;
    is_corrected?: any;
    mdro?: string;
}

export interface LabResultsSummarySerializer {
    a1c?: LabResultSummarySerializer;
    creatinine?: LabResultSummarySerializer;
    gfr_aa?: LabResultSummarySerializer;
    gfr_non_aa?: LabResultSummarySerializer;
    inr?: LabResultSummarySerializer;
}

export interface LabShipmentBagSerializer {
    id?: number;
    identifier?: string;
    lab_shipment?: number;
    registered_at?: Date | string;
    registered_by?: PhysicianSerializer;
    lab_shipment_box?: number;
    lab_orders?: number[];
}

export interface LabShipmentBoxSerializer {
    id?: number;
    identifier?: string;
    lab_shipment?: number;
    registered_at?: Date | string;
    registered_by?: PhysicianSerializer;
    bags?: number[];
}

export interface LabShipmentCreateWithLabOrdersSerializer {
    lab_orders?: Array<LabShipmentOrderSerializer>;
    facility?: number;
    date?: Date | string;
    status?: string;
    courier?: string;
    ordered_test?: string;
    icd_diagnosis?: number;
    lab_order_group_name?: string;
}

export interface LabShipmentDetailedSerializer {
    id?: number;
    identifier?: string;
    facility?: number;
    date?: Date | string;
    status?: string;
    last_lab_shipment_status?: LabShipmentStatusHistorySerializer;
    history?: Array<LabShipmentStatusHistorySerializer>;
    ordered_at?: Date | string;
    ordered_by?: PhysicianSerializer;
    lab_orders?: Array<LabOrderDetailedSerializer>;
    boxes?: Array<LabShipmentBoxSerializer>;
    bags?: Array<LabShipmentBagSerializer>;
    lab_provider?: string;
    courier?: string;
    ordered_test?: string;
    lab_order_groups?: Array<LabOrderGroupSerializer>;
}

export interface LabShipmentOrderSerializer {
    patient?: number;
}

export interface LabShipmentSerializer {
    id?: number;
    identifier?: string;
    facility?: number;
    date?: Date | string;
    status?: string;
    lab_orders?: Array<LabOrderStatusSerializer>;
    lab_provider?: string;
    courier?: string;
    ordered_test?: string;
}

export interface LabShipmentStatusHistorySerializer {
    id?: number;
    status?: string;
    date?: Date | string;
    user?: PhysicianSerializer;
}

export interface LabTestActionReadForTasksSerializer {
    id?: number;
    lab_test?: LabTestForTasksSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
}

export interface LabTestActionReadSerializer {
    id?: number;
    lab_test?: LabTestSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface LabTestActionSerializer {
    id?: number;
    lab_test?: LabTestCreateUpdateSerializer;
    action?: string;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    sort?: number;
}

export interface LabTestCreateUpdateSerializer {
    id?: number;
    name?: string;
    lab_type?: string;
    lab_report?: LabReportCreateUpdateSerializer;
    infection_information?: InfectionInformationCreateUpdateSerializer;
    source?: string;
    description?: string;
    lab_results?: Array<LabResultCreateUpdateSerializer>;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    status?: string;
    code?: string;
    loinc_code?: string;
    copied_from?: number;
    is_deleted?: boolean;
    critical_notifications_sent?: boolean;
    corrected_notifications_sent?: boolean;
}

export interface LabTestDetailedSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    name?: string;
    lab_type?: string;
    lab_report?: LabReportSerializer;
    lab_results?: Array<LabResultDetailedSerializer>;
    infection_information?: number;
    source?: string;
    description?: string;
    resulted_date?: Date | string;
    collected_date?: Date | string;
    has_no_result?: any;
    has_corrected_result?: any;
    has_inconclusive_result?: any;
}

export interface LabTestForTasksSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    name?: string;
    display_name?: any;
    lab_type?: string;
    lab_report?: LabReportSimpleReadSerializer;
    source?: string;
    lab_results?: Array<LabResultForTasksSerializer>;
    related_tasks?: Array<TaskAuthorSerializer>;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    has_no_result?: any;
    has_inconclusive_result?: any;
    has_corrected_result?: any;
}

export interface LabTestHistoricalDetailedSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    name?: string;
    lab_type?: string;
    lab_report?: LabReportSerializer;
    lab_results?: Array<LabResultDetailedSerializer>;
    infection_information?: number;
    source?: string;
    description?: string;
    resulted_date?: Date | string;
    collected_date?: Date | string;
    lab_test_histories?: Array<LabTestDetailedSerializer>;
    loinc_code?: string;
}

export interface LabTestReviewReadForTasksSerializer {
    id?: number;
    actions?: Array<LabTestActionReadForTasksSerializer>;
    comments?: string;
    x_ray_review?: string;
}

export interface LabTestReviewReadSerializer {
    id?: number;
    actions?: Array<LabTestActionReadSerializer>;
    comments?: string;
    x_ray_review?: string;
}

export interface LabTestReviewSerializer {
    id?: number;
    actions?: Array<LabTestActionSerializer>;
    comments?: string;
    x_ray_review?: string;
}

export interface LabTestSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    name?: string;
    display_name?: any;
    lab_type?: string;
    lab_report?: LabReportSimpleSerializer;
    source?: string;
    lab_results?: Array<LabResultSerializer>;
    related_tasks?: Array<TaskAuthorSerializer>;
    collected_date?: Date | string;
    resulted_date?: Date | string;
    has_no_result?: any;
    has_inconclusive_result?: any;
    has_corrected_result?: any;
    status?: string;
    infection_information?: InfectionInformationSimpleSerializer;
    loinc_code?: string;
}

export interface LabTestSimpleSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    name?: string;
    lab_type?: string;
    lab_report?: LabReportSimpleSerializer;
    source?: string;
    lab_results?: Array<LabResultSimpleSerializer>;
    display_name?: any;
    resulted_date?: Date | string;
    collected_date?: Date | string;
}

export interface LabTestSynonymSerializer {
    id?: number;
    name?: string;
    lab_code?: string;
    display_name?: any;
}

export interface LabTestWithReportSerializer {
    id?: number;
    name?: string;
    lab_report?: LabReportBasicSerializer;
    lab_results?: Array<LabResultWithoutReportSerializer>;
    lab_type?: string;
    display_name?: any;
    description?: string;
    resulted_date?: Date | string;
    collected_date?: Date | string;
    loinc_code?: string;
    is_critical?: any;
    is_abnormal?: any;
}

export interface LabelDetailSerializer {
    id?: number;
    import_accounts?: number[];
    entry_proposals?: number[];
    name?: string;
    description?: string;
    color_class?: string;
    color_hex?: string;
}

export interface LabelSerializer {
    id?: number;
    name?: string;
    description?: string;
    color_class?: string;
    color_hex?: string;
}

export interface LastEmrUploadSerializer {
    created_at?: Date | string;
    status?: string;
}

export interface LastSyncDetailsSerializer {
    last_adt_upload?: LastEmrUploadSerializer;
    last_ccd_upload?: LastEmrUploadSerializer;
}

export interface LastVisitTaskSerializer {
    id?: number;
    user?: UserNameSerializer;
    type?: string;
    date?: Date | string;
    status?: string;
    level_of_care?: string;
    description?: string;
    note?: string;
    visit_details?: VisitDetailsLastVisitSerializer;
    history_of_present_illness?: HistoryOfPresentIllnessLastVisitSerializer;
}

export interface LastVitalExaminationsResponseSerializer {
    discharged?: Date | string;
    results?: Array<VitalExaminationSerializer>;
}

export interface LevelOfCarePayerSerializer {
    id?: number;
    level_of_care?: string;
    payer?: string;
}

export interface LocationListSerializer {
    id?: number;
    name?: string;
}

export interface LocationNameSerializer {
    id?: number;
    name?: string;
}

export interface LocationSerializer {
    id?: number;
    name?: string;
    facility?: number;
    floor_plan?: number;
    x_percentage?: number;
    y_percentage?: number;
    room_type?: string;
    unit?: number;
    angle?: number;
}

export interface LocationSimpleSerializer {
    id?: number;
    name?: string;
    unit?: UnitSimpleSerializer;
}

export interface LocationWithUnitSerializer {
    id?: number;
    name?: string;
    facility?: number;
    floor_plan?: number;
    x_percentage?: number;
    y_percentage?: number;
    room_type?: string;
    unit?: UnitSerializer;
    angle?: number;
}

export interface LogDateSerializer {
    timestamp?: Date | string;
}

export interface LogSerializer {
    id?: number;
    timestamp?: Date | string;
    level?: string;
    message?: string;
    type?: string;
    sync_detail?: number;
}

export interface LoincAnswerSerializer {
    code?: string;
    display?: string;
}

export interface LoincCodeSerializer {
    code?: string;
    description?: string;
}

export interface LungsExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    auscultation?: string;
    accessory_muscle_use?: string;
    supplemental_oxygen_required?: string;
    comment?: string;
}

export interface MACLocalityBillingCodeDetailedSerializer {
    mac?: MACSerializer;
    billing_code?: string;
    non_facility_price?: number;
    facility_price?: number;
}

export interface MACSerializer {
    id?: number;
    state?: string;
    number?: string;
}

export interface MacBillingCodePricesSyncResultCountsSerializer {
    updated?: number;
    added?: number;
}

export interface MeasureDecimalSerializer {
    value?: number;
    unit?: string;
}

export interface MeasureRangeSerializer {
    low?: number;
    high?: number;
    unit?: string;
}

export interface MedicaidPriceSettingBatchUpdateSerializer {
    medicaid_price_settings?: Array<MedicaidPriceSettingUpdateSerializer>;
}

export interface MedicaidPriceSettingSerializer {
    id?: number;
    cpt_code?: string;
    price?: number;
}

export interface MedicaidPriceSettingUpdateSerializer {
    id?: number;
    cpt_code?: string;
    price?: number;
}

export interface MedicalProgramAdvancedFilterSerializer {
    id?: number;
    type?: string;
    eligible?: boolean;
    consent?: boolean;
}

export interface MedicalProgramSerializer {
    id?: number;
    specialty?: MedicalSpecialtySerializer;
    name?: string;
    type?: string;
    display_name?: string;
}

export interface MedicalResultTypeSerializer {
    id?: number;
    name?: string;
    number_of_values?: number;
    matrix_separator?: string;
    unit?: string;
    critical?: number;
    priority?: number;
}

export interface MedicalSpecialtySerializer {
    id?: number;
    name?: string;
    profession_name?: string;
    display_name?: string;
    display_profession_name?: string;
    has_diagnostic_categorization?: any;
}

export interface MedicationWithPsychotropicGroupsSerializer {
    id?: number;
    name?: string;
    drug?: Array<DrugSerializer>;
    psychotropic_groups?: Array<string>;
}

export interface ModifiedByUserSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    email?: string;
    is_active?: boolean;
    timezone?: string;
    roles?: any;
    phone?: any;
    origin?: string;
}

export interface MultipleUserFacilityTaskCreateSerializer {
    id?: number;
    user?: PhysicianSerializer;
    facility?: FacilitySimpleSerializer;
}

export interface MuscularExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    cyanosis?: string;
    clubbing?: string;
    edema?: string;
    strength_and_movement?: string;
    tone_mass?: string;
    muscle_mass?: string;
    dme?: string;
    comment?: string;
}

export interface MusculoskeletalSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    diffuse_pain?: string;
    joint_pain?: string;
    muscle_pain?: string;
    neck_or_back_pain?: string;
    joint_swelling?: string;
    joint_stiffness?: string;
    injury_history?: string;
    comment?: string;
}

export interface NeckExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    mobility?: string;
    trachea?: string;
    thyroid_mass_tenderness?: string;
    lymphadenopathy?: string;
    jvd?: string;
    comment?: string;
}

export interface NegationReasonReadSerializer {
    id?: number;
    reason?: SnomedConceptSerializer;
    section?: string;
    subject?: string;
}

export interface NegationReasonSerializer {
    id?: number;
    section?: string;
    subject?: string;
    reason?: number;
}

export interface NeuroExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    alertness?: string;
    speech?: string;
    memory?: string;
    hemiparesis?: string;
    focal_deficits?: string;
    comment?: string;
}

export interface NeuroSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    weakness?: string;
    numbness?: string;
    loss_of_consciousness?: string;
    dizziness?: string;
    difficulty_with_speech?: string;
    difficulty_with_memory?: string;
    comment?: string;
}

export interface NewPasswordRequestSerializer {
    created_at?: Date | string;
}

export interface NonModelUserSerializer {
    roles?: any;
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    email?: string;
    is_active?: boolean;
    timezone?: string;
    facilities?: Array<number>;
    phone?: string;
    origin?: string;
}

export interface NotificationReadSerializer {
    id?: number;
    message?: string;
    html_message?: string;
    timestamp?: Date | string;
    timesince?: string;
    notification_type?: string;
    target_object_id?: number;
    unread?: boolean;
    patient?: number;
    facility?: number;
    person?: string;
}

export interface NurseStationDetailedSerializer {
    id?: number;
    created_at?: Date | string;
    created_by?: number;
    modified_at?: Date | string;
    modified_by?: number;
    name?: string;
    facility?: FacilitySimpleSerializer;
    units?: Array<UnitSerializer>;
    locations?: Array<LocationSimpleSerializer>;
    fax?: FaxSerializer;
    sms?: string;
    is_active?: boolean;
    facility_origins?: Array<FacilityOriginSimpleSerializer>;
}

export interface NurseStationHistorySerializer {
    id?: number;
    new_fax?: FaxSerializer;
    new_facility_origins?: Array<FacilityOriginSimpleSerializer>;
    changed_by?: UserSimpleSerializer;
    timestamp?: Date | string;
    comment?: string;
    nurse_station?: number;
}

export interface NurseStationSerializer {
    id?: number;
    created_at?: Date | string;
    created_by?: number;
    modified_at?: Date | string;
    modified_by?: number;
    name?: string;
    facility?: number;
    units?: number[];
    locations?: number[];
    fax?: number;
    sms?: string;
    is_active?: boolean;
    facility_origins?: number[];
    comment?: string;
}

export interface OAuthRedirectURISerializer {
    redirect_uri?: string;
}

export interface OfficeContactUserSerializer {
    id?: number;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    phone?: any;
    address?: FHIRAddressSerializer;
}

export interface OperationEMRImportResultSerializer {
    id?: number;
    facility?: number;
    task?: string;
    created_at?: Date | string;
    status?: string;
    sync_detail?: number;
    sync_process?: number;
}

export interface OperationEMRUploadSerializer {
    id?: number;
    place?: string;
    status?: string;
    modified_at?: Date | string;
}

export interface OperationFacilityDetailSerializer {
    facility_id?: number;
    emr_import_history?: Array<OperationEMRImportResultSerializer>;
    queued_lab_uploads?: Array<OperationEMRUploadSerializer>;
    faxes?: Array<OperationTaskFaxSerializer>;
    emr_uploads?: Array<OperationEMRUploadSerializer>;
    hl7_messages?: Array<OperationsHL7MessageSerializer>;
}

export interface OperationFacilitySerializer {
    id?: number;
    name?: string;
    origin?: string;
    emr_import_account?: OperationImportAccountSerializer;
    emr_export_account?: OperationImportAccountSerializer;
    number_of_users?: number;
    number_of_active_users?: number;
    number_of_suspended_users?: number;
    import_preset?: OperationImportPresetSerializer;
}

export interface OperationImportAccountSerializer {
    id?: number;
    name?: string;
    origin?: string;
    status?: any;
    is_active?: boolean;
    password_change_date?: Date | string;
}

export interface OperationImportPresetSerializer {
    id?: number;
    name?: string;
    origin?: string;
}

export interface OperationOrganizationSerializer {
    id?: number;
    name?: string;
    visible_facilities?: Array<OperationFacilitySerializer>;
    facilities_with_emr_import_error?: number[];
    facilities_with_emr_upload_error?: number[];
    facilities_with_fax_error?: number[];
    percentage_of_queued_lab_uploads?: any;
    facilities_with_hl7_message_errors?: number[];
}

export interface OperationTaskFaxSerializer {
    id?: number;
    fax_status?: string;
    modified_at?: Date | string;
    is_queued_for_more_than_ten_minutes?: boolean;
}

export interface OperationsHL7MessageSerializer {
    id?: number;
    status?: string;
    modified_at?: Date | string;
}

export interface OrderCreateUpdateSerializer {
    id?: number;
    patient?: number;
    flow_sheet_type?: string;
    order_type?: string;
    order_date?: Date | string;
    start_date?: Date | string;
    end_date?: Date | string;
    end_date_expected?: Date | string;
    description?: string;
    physician?: number;
    original_id?: string;
    indications_for_use?: string;
    copied_from?: number;
    is_deleted?: boolean;
}

export interface OrderDetailSerializer {
    id?: number;
    order_date?: Date | string;
    start_date?: Date | string;
    end_date?: Date | string;
    flow_sheet_type?: string;
    order_type?: string;
    patient?: PatientOversightSerializer;
    description?: string;
    physician?: PhysicianSerializer;
    related_tasks?: Array<TaskAuthorSerializer>;
    active_status?: OrderStatusHistorySerializer;
    created_at?: Date | string;
}

export interface OrderIntervalSerializer {
    id?: number;
    amount?: number;
    measure?: string;
    days?: any;
    period_of_day?: string;
}

export interface OrderReviewActionReadSerializer {
    id?: number;
    order?: CachedOrderSimpleSerializer;
    action?: string;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
}

export interface OrderReviewActionSerializer {
    id?: number;
    order?: OrderCreateUpdateSerializer;
    action?: string;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
}

export interface OrderReviewReadSerializer {
    id?: number;
    actions?: Array<OrderReviewActionReadSerializer>;
}

export interface OrderReviewSerializer {
    id?: number;
    actions?: Array<OrderReviewActionSerializer>;
}

export interface OrderSerializer {
    id?: number;
    order_date?: Date | string;
    start_date?: Date | string;
    end_date?: Date | string;
    flow_sheet_type?: string;
    order_type?: string;
    patient?: number;
    description?: string;
    physician?: PhysicianSerializer;
    active_status?: OrderStatusHistorySerializer;
    created_at?: Date | string;
}

export interface OrderStatusHistoryCreateSerializer {
    status?: string;
}

export interface OrderStatusHistorySerializer {
    id?: number;
    date?: Date | string;
    status?: string;
    user?: PhysicianSerializer;
}

export interface OrganizationGroupSerializer {
    id?: number;
    name?: string;
    organization?: number;
}

export interface OrganizationSerializer {
    id?: number;
    name?: string;
    description?: string;
    timezone?: string;
    facilities?: Array<FacilityDetailedSerializer>;
    infection_report_types?: any;
}

export interface OrganizationSimpleSerializer {
    id?: number;
    name?: string;
    description?: string;
    timezone?: string;
}

export interface OrganizationUserAuthSerializer {
    id?: number;
    name?: string;
    description?: string;
    timezone?: string;
    facilities?: Array<FacilityUserAuthSerializer>;
    infection_report_types?: any;
}

export interface OtherOrderReadSerializer {
    id?: number;
    type?: string;
    comments?: string;
    related_icd_diagnoses?: Array<CachedICDCodeNameSerializer>;
    snomed_concept?: SnomedConceptSerializer;
    related_snomed_diagnosis?: SnomedConceptSerializer;
}

export interface OtherOrderSerializer {
    id?: number;
    type?: string;
    comments?: string;
    related_icd_diagnoses?: number[];
    snomed_concept?: number;
    related_snomed_diagnosis?: number;
}

export interface PHQ9Serializer {
    id?: number;
    interest?: string;
    interest_note?: string;
    depression?: string;
    depression_note?: string;
    sleeping?: string;
    sleeping_note?: string;
    tiredness?: string;
    tiredness_note?: string;
    appetite?: string;
    appetite_note?: string;
    self_hate?: string;
    self_hate_note?: string;
    concentration?: string;
    concentration_note?: string;
    slowness?: string;
    slowness_note?: string;
    suicidal_tendencies?: string;
    suicidal_tendencies_note?: string;
    score?: number;
    score_note?: string;
}

export interface PIMeasureSerializer {
    headers?: Array<string>;
    data?: Array<Array<string>>;
}

export interface PastMedicalHxReadSerializer {
    id?: number;
    reason_for_admission?: string;
    history_of_surgery_procedures?: string;
    dietary_restrictions?: string;
    comments?: string;
    primary_diagnosis?: ICDCodeNameSerializer;
    primary_diagnosis_comment?: string;
}

export interface PastMedicalHxSerializer {
    reason_for_admission?: string;
    history_of_surgery_procedures?: string;
    dietary_restrictions?: string;
    comments?: string;
    primary_diagnosis?: number;
    primary_diagnosis_comment?: string;
}

export interface PatientActivePeriodsSerializer {
    periods?: Array<ActiveCensusStatisticsSerializer>;
    total_count?: number;
}

export interface PatientAdditionalNameSerializer {
    use?: string;
    qualifier?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    prefix?: string;
    suffix?: string;
    start?: Date | string;
    end?: Date | string;
}

export interface PatientAdditionalNameSimpleSerializer {
    use?: string;
    qualifier?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    prefix?: string;
    suffix?: string;
    start?: Date | string;
    end?: Date | string;
}

export interface PatientAddressCreateSerializer {
    patient?: number;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
}

export interface PatientAddressCreateUpdateModelSerializer {
    patient?: number;
    address?: FHIRAddressCreateUpdateModelSerializer;
}

export interface PatientAddressSerializer {
    patient?: number;
    address?: FHIRAddressSerializer;
    id?: number;
}

export interface PatientAddressUpdateSerializer {
    patient?: number;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
}

export interface PatientAdvancedDirectiveEditSerializer {
    patient?: number;
    code?: string;
    description?: string;
}

export interface PatientAdvancedDirectiveSerializer {
    code?: string;
    description?: string;
}

export interface PatientAdvancedMonitoringSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    last_phase_of_decline?: string;
    last_phase_of_decline_task?: number;
    last_phase_of_decline_date?: Date | string;
    last_symptom_burden_intolerance?: string;
    last_symptom_burden_intolerance_date?: Date | string;
    last_symptom_burden_intolerance_task?: number;
    last_exacerbation_diagnosis_icd?: string;
    in_exacerbation_diagnoses?: any;
}

export interface PatientAllergyActionSerializer {
    id?: number;
    action?: string;
    patient_allergy?: PatientAllergyCreateUpdateSerializer;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
}

export interface PatientAllergyCreateUpdateSerializer {
    id?: number;
    patient?: number;
    type?: string;
    sub_type?: string;
    reaction_note?: string;
    severity?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    status?: string;
    is_active?: boolean;
    verification_status?: string;
    allergy_type_snomed_concept?: number;
    snomed_concept?: number;
    drugbank_product_concept?: number;
    drugbank_product_concept_rxcui?: number;
    allergy?: number;
    reactions?: Array<PatientAllergyReactionCreateUpdateSerializer>;
    patient_document?: number;
    copied_from?: number;
}

export interface PatientAllergyReactionCreateUpdateSerializer {
    id?: number;
    severity?: string;
    date?: Date | string;
    snomed_concept?: number;
    note?: string;
}

export interface PatientAllergyReactionReadSerializer {
    id?: number;
    severity?: string;
    date?: Date | string;
    snomed_concept?: SnomedConceptSerializer;
    note?: string;
}

export interface PatientAllergyReactionSerializer {
    id?: number;
    severity?: string;
    date?: Date | string;
    snomed_concept?: number;
    note?: string;
}

export interface PatientAllergyReadSerializer {
    id?: number;
    type?: string;
    sub_type?: string;
    reaction_note?: string;
    severity?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    status?: string;
    is_active?: boolean;
    verification_status?: string;
    allergy_type_snomed_concept?: SnomedConceptSerializer;
    snomed_concept?: SnomedConceptSerializer;
    drugbank_product_concept?: number;
    drugbank_product_concept_rxcui?: DrugbankProductConceptRxCuiSerializer;
    reactions?: Array<PatientAllergyReactionReadSerializer>;
    allergy?: AllergyReadSerializer;
    patient_document?: number;
    copied_from?: number;
}

export interface PatientAllergyReviewActionReadSerializer {
    id?: number;
    patient_allergy?: PatientAllergyReadSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface PatientAllergyReviewCreateUpdateSerializer {
    id?: number;
    review?: string;
    actions?: Array<PatientAllergyActionSerializer>;
    no_known_allergies?: boolean;
}

export interface PatientAllergyReviewReadSerializer {
    id?: number;
    review?: string;
    actions?: Array<PatientAllergyReviewActionReadSerializer>;
    no_known_allergies?: boolean;
}

export interface PatientAllergySerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    physician?: number;
    physician_teams?: number[];
    allergies?: Array<AllergySerializer>;
    customer?: number;
    origin?: string;
    person?: number;
}

export interface PatientAndPhysicianTeamCreateSerializer {
    patient?: number;
    physician_team?: number;
    reason?: string;
}

export interface PatientAndPhysicianTeamListSerializer {
    access_permissions?: Array<PatientAndPhysicianTeamCreateSerializer>;
}

export interface PatientAndPhysicianTeamListUpdateSerializer {
    access_permissions?: Array<PatientAndPhysicianTeamUpdateSerializer>;
}

export interface PatientAndPhysicianTeamOverwriteAccessSerializer {
    patient?: number;
    physician_team?: number;
    status?: string;
    reason?: string;
}

export interface PatientAndPhysicianTeamUpdateSerializer {
    id?: number;
    reason?: string;
}

export interface PatientAvailableToRequestAccessPermissionListSerializer {
    access_permissions?: Array<PatientPhysicianTeamAccessPermissionSimpleSerializer>;
    id?: number;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    age?: number;
    patient_physician_teams?: Array<PatientPhysicianTeamIsDiscontinuableSerializer>;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    last_admission_date?: Date | string;
    location_name?: string;
    census_payer?: string;
    primary_physician_team?: PatientPhysicianTeamUserFacilitySerializer;
    level_of_care?: string;
    patient_emr_physician_teams?: Array<PatientEMRPhysicianTeamSerializer>;
    customer?: number;
    last_census_hospital?: HospitalSerializer;
    last_census_status_detail?: string;
    patient_medical_specialties?: Array<PatientMedicalSpecialtySerializer>;
}

export interface PatientAvailableToRequestAccessPermissionSerializer {
    access_permissions?: Array<PatientPhysicianTeamAccessPermissionSimpleSerializer>;
    chronic_diagnoses?: Array<ChronicDiagnosisSerializer>;
    id?: number;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    age?: number;
    patient_physician_teams?: Array<PatientPhysicianTeamIsDiscontinuableSerializer>;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    last_admission_date?: Date | string;
    location_name?: string;
    census_payer?: string;
    primary_physician_team?: PatientPhysicianTeamUserFacilitySerializer;
    level_of_care?: string;
    patient_emr_physician_teams?: Array<PatientEMRPhysicianTeamSerializer>;
    customer?: number;
    last_census_hospital?: HospitalSerializer;
    last_census_status_detail?: string;
    specialties?: Array<SpecialtyWithDiagnosisAndTreatmentsAndLabsSerializer>;
}

export interface PatientBillingInfoSerializer {
    medicare_a_id?: string;
    medicare_b_id?: string;
    medicaid_id?: string;
    insurance_information?: any;
}

export interface PatientCMRNResponseSerializer {
    patient?: number;
    cmrn?: PersonIdSerializer;
}

export interface PatientCMRNSerializer {
    patient?: number;
    cmrn?: string;
    person?: number;
}

export interface PatientCensusDetailSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    physician_name?: any;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    active_infections?: any;
    antibiotics?: any;
    recurring_infections?: any;
    physician?: number;
    physician_teams?: number[];
    chronic_diagnoses?: any;
    bed_name?: any;
    location_name?: any;
    last_payer?: any;
    last_status?: any;
    returned_from_hospital?: any;
    hospital_id?: any;
    status_detail?: any;
    admission_date?: any;
    last_status_timestamp?: any;
    unit_name?: any;
    days_since_admission?: any;
    days_since_last_status?: any;
    days_on_last_payer?: any;
    census_history?: Array<CensusHistorySerializer>;
}

export interface PatientCensusListSerializer {
    id?: number;
    census_history?: Array<CensusHistorySerializer>;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    allergies?: Array<AllergySerializer>;
    active_infections?: Array<InfectionInformationPatientSerializer>;
    antibiotics?: Array<TreatmentHistoryPatientSerializer>;
    recurring_infections?: Array<InfectionInformationPatientSerializer>;
    physician?: PhysicianSerializer;
    physician_teams?: number[];
    chronic_diagnoses?: Array<ChronicDiagnosisSerializer>;
    bed_name?: string;
    location_name?: string;
    last_payer?: any;
    last_status?: any;
    returned_from_hospital?: boolean;
    details?: PatientDetailsSerializer;
    hospital_id?: number;
    status_detail?: string;
    admission_date?: Date | string;
    last_status_timestamp?: Date | string;
    person?: number;
    origin?: string;
    customer?: number;
}

export interface PatientCensusSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    physician_name?: any;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    facility?: number;
    physician?: number;
    physician_teams?: number[];
    bed_name?: any;
    location_name?: any;
    last_payer?: any;
    last_status?: any;
    returned_from_hospital?: any;
    hospital_id?: any;
    status_detail?: any;
    admission_date?: any;
    last_status_timestamp?: any;
    unit_name?: any;
    days_since_admission?: any;
    days_since_last_status?: any;
    days_on_last_payer?: any;
    is_followed?: boolean;
    level_of_care?: string;
}

export interface PatientComplexitySerializer {
    phase_of_decline?: string;
    symptom_burden_intolerance?: string;
    advanced_care_plan?: string;
    complexity_statement?: string;
    health_status?: number;
    health_status_comments?: string;
}

export interface PatientContactPointCreateSerializer {
    patient?: number;
    contact_points?: Array<FHIRContactPointCreateUpdateSerializer>;
}

export interface PatientContactPointCreateUpdateModelSerializer {
    patient?: number;
    contact_point?: FHIRContactPointCreateUpdateModelSerializer;
}

export interface PatientContactPointSerializer {
    patient?: number;
    contact_point?: FHIRContactPointModelSerializer;
}

export interface PatientContactPointUpdateSerializer {
    patient?: number;
    contact_points?: Array<FHIRContactPointCreateUpdateSerializer>;
}

export interface PatientCopySerializer {
    source_patient?: number;
    physician_teams?: Array<number>;
}

export interface PatientCreateSerializer {
    patient?: PatientDefaultDataSerializer;
    room?: number;
    bed?: number;
    census_admission_date?: Date | string;
    status_detail?: string;
    payer?: string;
    patient_details?: PatientDetailsCreateSerializer;
    patient_insurance?: PatientInsuranceSerializer;
    diagnoses?: Array<DiagnosisDataSerializer>;
    allergies?: Array<AllergiesPatientsSerializer>;
    physician_teams?: Array<PhysicianTeamAssignSerializer>;
    first_demographics?: FirstDemographicsSerializer;
    addresses?: Array<FHIRAddressCreateUpdateSerializer>;
    phones?: Array<FHIRContactPointCreateUpdateSerializer>;
    emails?: Array<FHIRContactPointCreateUpdateSerializer>;
    patient_additional_names?: Array<FirstPatientAdditionalNameSerializer>;
}

export interface PatientDefaultDataSerializer {
    facility?: number;
    mrid?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    title_name?: string;
    name_suffix?: string;
    source_patient?: number;
}

export interface PatientDetailSerializer {
    id?: number;
    census_history?: Array<CensusHistorySerializer>;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    comment?: string;
    facility?: FacilitySimpleDetailSerializer;
    organization?: number;
    allergies?: Array<AllergySerializer>;
    active_infections?: Array<InfectionInformationPatientSerializer>;
    antibiotics?: Array<TreatmentHistoryPatientSerializer>;
    recurring_infections?: Array<InfectionInformationPatientSerializer>;
    physician?: PhysicianSerializer;
    chronic_diagnoses?: Array<ChronicDiagnosisSerializer>;
    details?: PatientDetailsSerializer;
    last_visit_task?: LastVisitTaskSerializer;
    last_visit_task_by_physician?: LastVisitTaskSerializer;
    current_treatments?: Array<TreatmentHistoryPatientSerializer>;
    payers?: Array<PayerHistorySerializer>;
    is_patient_assigned_to_physician_team?: boolean;
    is_followed?: boolean;
    user_is_md?: boolean;
    height?: number;
    weight?: number;
    bmi?: any;
    physician_teams?: number[];
    immunizations?: any;
    level_of_care?: string;
    advanced_directive?: PatientAdvancedDirectiveSerializer;
    advanced_directives?: any;
    person?: number;
    patient_physician_teams?: Array<PatientPhysicianTeamDetailedSerializer>;
    origin?: string;
    reason_of_decease?: string;
    demographics?: DemographicsSimpleSerializer;
    patient_additional_names?: Array<PatientAdditionalNameSerializer>;
    customer?: CustomerOfficeContactSerializer;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    census_payer?: string;
    user_as_patient?: any;
    no_known_allergies?: Date | string;
    title_name?: string;
    name_suffix?: string;
    no_known_medications?: Date | string;
    no_known_problems?: Date | string;
    marital_status?: any;
    current_living_situation?: any;
    primary_caregiver?: any;
    smoking_status?: any;
    tobacco_use?: any;
    complexity_statement?: any;
}

export interface PatientDetailTreatmentSerializer {
    diagnoses?: Array<DiagnosisWithPsychotropicGroupsSerializer>;
    treatment?: PsychotropicsTreatmentHistorySerializer;
}

export interface PatientDetailsCreateSerializer {
    ssn?: string;
}

export interface PatientDetailsForCopySerializer {
    bed?: number;
    location?: number;
    census_admission_date?: Date | string;
}

export interface PatientDetailsMedicationServiceDetailSerializer {
    race?: string;
}

export interface PatientDetailsSerializer {
    calculated_ccm_eligible?: boolean;
    calculated_ccm_not_eligible_reason?: any;
    aco?: boolean;
    is_complex?: boolean;
    ccm_consent_received?: boolean;
    fall_risk?: number;
}

export interface PatientDetailsUpdateSerializer {
    ssn?: string;
    patient?: number;
}

export interface PatientDocumentCDAAssignSerializer {
    patient_id?: number;
    document_id?: number;
}

export interface PatientDocumentCDAAssignUserDirectAddressRequestSerializer {
    direct_address?: string;
    user_id?: number;
}

export interface PatientDocumentCDAAssignUserDirectAddressResponseSerializer {
    success?: boolean;
}

export interface PatientDocumentCDADetailedResponseSerializer {
    id?: number;
    sender_address?: string;
    patient?: PatientSuperSimpleReadSerializer;
    name?: string;
    size?: number;
    created_at?: Date | string;
    type?: string;
    sub_type?: string;
    location?: string;
    original_id?: string;
    modified_at?: Date | string;
    location_type?: string;
    description?: string;
    content_type?: string;
    facility?: number;
    created_by?: number;
}

export interface PatientDocumentCDAEmailSerializer {
    address_recipient?: string;
    subject?: string;
    body?: string;
}

export interface PatientDocumentCDAGetUnassignedDocumentsResponseSerializer {
    document_id?: number;
    name?: string;
    content_type?: string;
    receive_date?: TimeStampSerializer;
    size?: number;
    description?: string;
    recipient_address?: string;
    sender_address?: string;
    status?: string;
    status_display?: string;
    cda_type_code?: string;
    patient_date_of_birth?: string;
    patient_name?: string;
    mail?: PatientDocumentCDAEmailSerializer;
}

export interface PatientDocumentCDASerializer {
    id?: number;
    sender_address?: string;
    name?: string;
    size?: number;
    created_at?: Date | string;
    type?: string;
    sub_type?: string;
    location?: string;
    original_id?: string;
    modified_at?: Date | string;
    location_type?: string;
    description?: string;
    content_type?: string;
    patient?: number;
    facility?: number;
    created_by?: number;
}

export interface PatientDocumentCDATransmitSerializer {
    address_recipient?: string;
    subject?: string;
    body?: string;
    direct_message?: boolean;
    encryption_key?: string;
}

export interface PatientDocumentCDAUploadSerializer {
    id?: number;
    description?: string;
    name?: string;
    patient?: number;
}

export interface PatientDocumentCDAValidationResultSerializer {
    status?: number;
    status_display?: string;
    validation_result?: string;
}

export interface PatientDocumentRemoteLinkSerializer {
    name?: string;
    type?: string;
    sub_type?: string;
    patient?: number;
    location?: string;
    location_type?: string;
    description?: string;
}

export interface PatientDocumentSerializer {
    id?: number;
    name?: string;
    type?: string;
    target?: string;
    trigger?: string;
    sub_type?: string;
    uploaded_by?: string;
    upload_date?: Date | string;
    effective_date?: Date | string;
    location?: string;
    location_type?: string;
    description?: string;
    size?: number;
    content_type?: string;
}

export interface PatientDocumentUploadSerializer {
    name?: string;
    type?: string;
    sub_type?: string;
    patient?: number;
    location?: string;
    location_type?: string;
    size?: number;
    facility?: number;
    content_type?: string;
    ccda_start_date?: Date | string;
    ccda_end_date?: Date | string;
    description?: string;
}

export interface PatientEMRPhysicianTeamSerializer {
    id?: number;
    user_facility?: PatientPhysicianTeamUserFacilitySerializer;
    is_primary?: boolean;
}

export interface PatientEncounterURLSerializer {
    patient_encounter_url?: string;
}

export interface PatientErxStatusSerializer {
    patient_encounter_url?: string;
    is_patient_in_erx?: boolean;
    user_status?: string;
    authorization_url?: string;
}

export interface PatientExtraSerializer {
    id?: number;
    facility?: number;
    physician?: number;
    physician_teams?: number[];
    details?: PatientDetailsSerializer;
    is_patient_assigned_to_physician_team?: boolean;
    is_followed?: boolean;
}

export interface PatientForPersonSerializer {
    id?: number;
    facility?: number;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    source_patient?: number;
    mrid?: string;
    origin?: string;
    customer?: number;
    physician_teams?: number[];
    level_of_care?: string;
}

export interface PatientImmunizationSerializer {
    id?: number;
    name?: string;
    date?: Date | string;
    expiration?: Date | string;
    step?: string;
    type?: string;
    description?: string;
    result?: string;
    staff_name?: string;
    site?: string;
    lot_number?: string;
    facility?: number;
    is_covid_related?: boolean;
    procedure_type?: string;
    is_expired?: boolean;
    code?: number;
    status?: string;
    status_reason?: number;
}

export interface PatientInformationAuditSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    gender?: string;
    census_payer?: string;
    physician?: PhysicianNameSerializer;
    census_location?: LocationSimpleSerializer;
    census_bed?: BedSerializer;
    audit?: AuditSerializer;
}

export interface PatientInformationSerializer {
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    reason_of_decease?: string;
    name_prefix?: string;
    name_suffix?: string;
    patient_additional_names?: Array<PatientAdditionalNameSerializer>;
}

export interface PatientInsuranceCreateSerializer {
    id?: number;
    mco_additional_name?: PatientAdditionalNameSimpleSerializer;
    medicare_additional_name?: PatientAdditionalNameSimpleSerializer;
    medicaid_additional_name?: PatientAdditionalNameSimpleSerializer;
    direct_bill?: boolean;
    mco_company?: string;
    mco_policy?: string;
    mco_group?: string;
    mco_effective_date?: Date | string;
    mco_priority?: string;
    medicare_beneficiary_id?: string;
    medicare_coverage?: string;
    medicare_priority?: string;
    medicaid_company_name?: string;
    medicaid_policy?: string;
    medicaid_priority?: string;
    biller_record_identifier?: string;
    patient?: number;
    mco_company_payer_alt_name?: number;
    medicare_jurisdiction_alt_name?: number;
    medicaid_company_alt_name?: number;
}

export interface PatientInsuranceEligibilityResponseSerializer {
    id?: number;
    direct_bill?: boolean;
    mco_company?: string;
    mco_company_payer_alt_name?: PayerAltNameDetailSerializer;
    mco_policy?: string;
    mco_group?: string;
    mco_effective_date?: Date | string;
    mco_priority?: string;
    medicare_jurisdiction_alt_name?: PayerAltNameDetailSerializer;
    medicare_beneficiary_id?: string;
    medicare_coverage?: string;
    medicare_priority?: string;
    medicaid_company_name?: string;
    medicaid_company_alt_name?: PayerAltNameDetailSerializer;
    medicaid_policy?: string;
    medicaid_priority?: string;
    biller_record_identifier?: string;
    mco_additional_name?: PatientAdditionalNameSimpleSerializer;
    medicare_additional_name?: PatientAdditionalNameSimpleSerializer;
    medicaid_additional_name?: PatientAdditionalNameSimpleSerializer;
    last_mco_eligibility_request?: EligibilityRequestDetailSerializer;
    last_medicare_eligibility_request?: EligibilityRequestDetailSerializer;
    last_mco_eligibility_request_with_current_insurance_data?: EligibilityRequestDetailSerializer;
    last_medicare_eligibility_request_with_current_insurance_data?: EligibilityRequestDetailSerializer;
}

export interface PatientInsuranceSerializer {
    id?: number;
    direct_bill?: boolean;
    mco_company?: string;
    mco_company_payer_alt_name?: PayerAltNameDetailSerializer;
    mco_policy?: string;
    mco_group?: string;
    mco_effective_date?: Date | string;
    mco_priority?: string;
    medicare_jurisdiction_alt_name?: PayerAltNameDetailSerializer;
    medicare_beneficiary_id?: string;
    medicare_coverage?: string;
    medicare_priority?: string;
    medicaid_company_name?: string;
    medicaid_company_alt_name?: PayerAltNameDetailSerializer;
    medicaid_policy?: string;
    medicaid_priority?: string;
    biller_record_identifier?: string;
    mco_additional_name?: PatientAdditionalNameSimpleSerializer;
    medicare_additional_name?: PatientAdditionalNameSimpleSerializer;
    medicaid_additional_name?: PatientAdditionalNameSimpleSerializer;
}

export interface PatientListDiagnosisSerializer {
    diagnosis?: DiagnosisWithPsychotropicGroupsSerializer;
    icd?: ICDCodeWithPsychotropicGroupsSerializer;
    has_correct_treatment?: boolean;
    is_psychotropic_diagnosis?: boolean;
    on_facesheet?: boolean;
    strongest_indication?: string;
}

export interface PatientListSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    mrid?: string;
    level_of_care?: string;
    census_admission_date?: Date | string;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    census_location?: LocationSimpleSerializer;
    census_bed?: BedSerializer;
    physician?: UserNameSerializer;
    physician_teams?: number[];
    person?: PersonWithPatientsSerializer;
    origin?: string;
    customer?: number;
    mco_company?: string;
}

export interface PatientListWithLastVisitSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    mrid?: string;
    level_of_care?: string;
    census_admission_date?: Date | string;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    census_location?: LocationSimpleSerializer;
    census_bed?: BedSerializer;
    physician?: UserNameSerializer;
    physician_teams?: number[];
    person?: PersonWithPatientsSerializer;
    origin?: string;
    customer?: number;
    mco_company?: string;
    last_visit_task_id?: number;
    last_visit_task_by_physician_id?: number;
    last_visit_task_by_filters_id?: number;
}

export interface PatientMDSAnswerBIMSSerializer {
    date_of_assessment?: Date | string;
    patient_mds_answer_id?: number;
    item_id?: string;
    answer?: SeverityAnswerSerializer;
    value?: string;
    label?: string;
}

export interface PatientMDSAnswerPHQ9ItemSerializer {
    date_of_assessment?: Date | string;
    patient_mds_answer_id?: number;
    item_id?: string;
    answer?: SeverityAnswerSerializer;
    value?: string;
    label?: string;
}

export interface PatientMDSAnswerPHQ9Serializer {
    self_assessment?: PatientMDSAnswerPHQ9ItemSerializer;
    staff_assessment?: PatientMDSAnswerPHQ9ItemSerializer;
}

export interface PatientMDSAnswerSerializer {
    date_of_assessment?: Date | string;
    patient_mds_answer_id?: number;
    item_id?: string;
    answer?: string;
    value?: string;
    label?: string;
}

export interface PatientMDSSerializer {
    appliances?: Array<PatientMDSAnswerSerializer>;
    tobacco_use?: PatientMDSAnswerSerializer;
    prognosis?: PatientMDSAnswerSerializer;
    bims?: PatientMDSAnswerBIMSSerializer;
    phq_9?: PatientMDSAnswerPHQ9Serializer;
}

export interface PatientMedicalSpecialtySerializer {
    specialty?: MedicalSpecialtySerializer;
    score?: number;
    primary_diagnosis_count?: number;
    supplementary_diagnosis_count?: number;
    treatment_count?: number;
    lab_test_count?: number;
    critical_lab_test_count?: number;
    is_high_risk?: boolean;
    high_risk_warnings?: any;
}

export interface PatientMedicationServiceDetailsSerializer {
    date_ob?: Date | string;
    gender?: string;
    details?: PatientDetailsMedicationServiceDetailSerializer;
    patient_creatinine_level?: number;
}

export interface PatientNPIProcedureCodeSerializer {
    patient?: PatientNameSerializer;
    winning_procedure_code?: ProcedureCodeSerializer;
    physician_team_procedure_codes?: Array<PhysicianTeamProcedureCodeSerializer>;
    billing_npi_number?: string;
}

export interface PatientNPIProcedureCodeWithFacilitySerializer {
    patient?: PatientNameWithFacilitySerializer;
    winning_procedure_code?: ProcedureCodeSerializer;
    physician_team_procedure_codes?: Array<PhysicianTeamProcedureCodeSerializer>;
    billing_npi_number?: string;
}

export interface PatientNameSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    origin?: string;
    customer?: number;
}

export interface PatientNameWithFacilitySerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    facility?: FacilitySimpleSerializer;
    date_ob?: Date | string;
    physician_teams?: number[];
    origin?: string;
    customer?: number;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    level_of_care?: string;
}

export interface PatientNoteDetailedSerializer {
    id?: number;
    patient?: PatientSerializer;
    user?: PhysicianSerializer;
    note?: string;
    date?: Date | string;
    created_at?: Date | string;
    modified_at?: Date | string;
}

export interface PatientNoteSerializer {
    id?: number;
    patient?: number;
    user?: number;
    note?: string;
    date?: Date | string;
    created_at?: Date | string;
    modified_at?: Date | string;
}

export interface PatientOversightSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_location?: LocationSimpleSerializer;
    level_of_care?: string;
    census_bed?: CachedBedSimpleSerializer;
    facility?: FacilitySimpleSerializer;
    physician?: number;
    physician_teams?: number[];
    person?: number;
    origin?: string;
    customer?: number;
}

export interface PatientPayerCategoryChangeSerializer {
    id?: number;
    patient?: PatientNameWithFacilitySerializer;
    current_payer_category?: any;
    previous_payer_category?: any;
    start_date?: Date | string;
    days_since_start?: any;
    location?: LocationSerializer;
}

export interface PatientPersonUpdateSerializer {
    person?: number;
}

export interface PatientPhaseOfDeclineChangeSerializer {
    id?: number;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    current_phase_of_decline?: string;
    previous_phase_of_decline?: string;
    changed_date?: Date | string;
}

export interface PatientPhysicianTeamAccessPermissionHistorySerializer {
    id?: number;
    patient?: AnonymizablePatientSerializer;
    created_by?: UserWithoutRelatedSerializer;
    modified_by?: UserWithoutRelatedSerializer;
    physician_team?: PatientPhysicianTeamUserFacilitySerializer;
    status?: string;
    history_date?: Date | string;
}

export interface PatientPhysicianTeamAccessPermissionSerializer {
    id?: number;
    status?: string;
    patient?: AnonymizablePatientSerializer;
    physician_team?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    created_by?: UserWithoutRelatedSerializer;
    modified_by?: UserWithoutRelatedSerializer;
    reason?: string;
    is_discontinueable?: boolean;
}

export interface PatientPhysicianTeamAccessPermissionSimpleSerializer {
    id?: number;
    status?: string;
    patient?: number;
    physician_team?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    created_by?: UserWithoutRelatedSerializer;
    modified_by?: UserWithoutRelatedSerializer;
    reason?: string;
    is_discontinueable?: boolean;
}

export interface PatientPhysicianTeamAccessPermissionWhatsNewSerializer {
    id?: number;
    created_by?: UserWithoutRelatedSerializer;
    modified_by?: UserWithoutRelatedSerializer;
    patient?: AnonymizablePatientSerializerWithConnections;
    created_at?: Date | string;
    modified_at?: Date | string;
    status?: string;
    reason?: string;
    physician_team?: number;
}

export interface PatientPhysicianTeamCreateSerializer {
    id?: number;
    patient?: number;
    user_facility?: number;
    is_primary?: boolean;
}

export interface PatientPhysicianTeamDetailedSerializer {
    id?: number;
    user_facility?: UserFacilitySerializer;
    is_primary?: boolean;
    access_permission?: number;
    created_at?: Date | string;
}

export interface PatientPhysicianTeamIsDiscontinuableSerializer {
    id?: number;
    user_facility?: PatientPhysicianTeamUserFacilitySerializer;
    is_primary?: boolean;
    access_permission?: number;
    is_discontinueable?: boolean;
}

export interface PatientPhysicianTeamMedicalProgramCreateSerializer {
    patient?: number;
    physician_team?: number;
    medical_program?: number;
    icd_codes?: any;
    consent_received?: boolean;
    consent_status_changed_date?: Date | string;
}

export interface PatientPhysicianTeamMedicalProgramHistorySerializer {
    id?: number;
    ineligibility_reasons?: any;
    icd_codes?: Array<ICDCodeNameSerializer>;
    modified_by?: ModifiedByUserSerializer;
    modified_at?: Date | string;
    consent_received?: boolean;
    consent_status_changed_date?: Date | string;
    calculated_eligibility?: boolean;
    calculated_eligibility_date?: Date | string;
    history_date?: Date | string;
    patient?: number;
    physician_team?: number;
    medical_program?: number;
}

export interface PatientPhysicianTeamMedicalProgramSerializer {
    id?: number;
    medical_program?: MedicalProgramSerializer;
    patient_physician_team?: PatientPhysicianTeamSerializer;
    ineligibility_reasons?: any;
    icd_codes?: any;
    modified_by?: ModifiedByUserSerializer;
    created_at?: Date | string;
    modified_at?: Date | string;
    consent_received?: boolean;
    consent_status_changed_date?: Date | string;
    calculated_eligibility?: boolean;
    calculated_eligibility_date?: Date | string;
}

export interface PatientPhysicianTeamMedicalProgramUpdateSerializer {
    consent_received?: boolean;
    consent_status_changed_date?: Date | string;
    icd_codes?: any;
}

export interface PatientPhysicianTeamSerializer {
    id?: number;
    user_facility?: PatientPhysicianTeamUserFacilitySerializer;
    is_primary?: boolean;
    access_permission?: number;
    created_at?: Date | string;
}

export interface PatientPhysicianTeamUserFacilitySerializer {
    id?: number;
    user?: PatientPhysicianTeamUserFacilitySerializerUserSerializer;
    facility?: number;
    origin?: string;
    medical_director?: boolean;
    is_rural?: boolean;
    can_save_conversation_as_task?: boolean;
    specialty?: MedicalSpecialtySerializer;
}

export interface PatientPhysicianTeamUserFacilitySerializerUserSerializer {
    id?: number;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    is_active?: boolean;
    email?: string;
    origin?: string;
    password_requests?: number[];
    billing_npi_number?: string;
}

export interface PatientRepresentativeReadSerializer {
    id?: number;
    patient?: number;
    user?: UserNameSerializer;
    relationship?: string;
}

export interface PatientRepresentativeSerializer {
    id?: number;
    patient?: number;
    user?: number;
    relationship?: string;
}

export interface PatientSearchSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    facility?: number;
    in_house?: boolean;
    physician?: number;
    physician_teams?: number[];
    census_admission_date?: Date | string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    level_of_care?: string;
    customer?: number;
    origin?: string;
    person?: number;
}

export interface PatientSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    comment?: string;
    facility?: number;
    organization?: number;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    census_location?: LocationSimpleSerializer;
    census_bed?: BedSerializer;
    census_payer?: string;
    level_of_care?: string;
    physician?: number;
    physician_teams?: number[];
    person?: number;
    origin?: string;
    reason_of_decease?: string;
    demographics?: DemographicsSimpleSerializer;
    customer?: number;
}

export interface PatientSimpleReadSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    comment?: string;
    gender?: string;
    census_location?: LocationNameSerializer;
    census_bed?: CachedBedSimpleSerializer;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_payer?: string;
    level_of_care?: string;
    facility?: CachedFacilitySimpleSerializer;
    physician?: number;
    physician_teams?: number[];
    person?: number;
    origin?: string;
    payers?: Array<CachedPayerHistorySerializer>;
    addresses?: Array<FHIRAddressSerializer>;
    phones?: Array<FHIRContactPointWithoutPeriodSerializer>;
    emails?: Array<FHIRContactPointWithoutPeriodSerializer>;
    customer?: number;
}

export interface PatientSimpleSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    physician?: number;
    physician_teams?: number[];
    facility?: number;
    payers?: Array<PayerHistorySerializer>;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    level_of_care?: string;
    origin?: string;
    customer?: number;
}

export interface PatientSimpleWithDurationSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    physician_id?: any;
    duration?: number;
}

export interface PatientSimpleWithPatientPhysicianTeamsReadSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    facility?: CachedFacilitySimpleSerializer;
    patient_physician_teams?: Array<PatientPhysicianTeamSerializer>;
    person?: number;
    origin?: string;
    customer?: number;
    date_ob?: Date | string;
    census_admission_date?: Date | string;
    gender?: string;
}

export interface PatientSuperSimpleReadSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_ob?: Date | string;
}

export interface PatientTaskDetailsSerializer {
    sms?: boolean;
    fax?: boolean;
    billing_validation?: TaskBillingStatusSerializer;
    taggable_users?: Array<NonModelUserSerializer>;
}

export interface PatientTaskInfoSerializer {
    id?: number;
    first_name?: string;
    last_name?: string;
    chronic_diagnoses?: string[];
    facility?: FacilitySimpleSerializer;
    details?: PatientBillingInfoSerializer;
    date_ob?: Date | string;
    physician?: PhysicianNameSerializer;
    physician_teams?: number[];
}

export interface PatientTaskInfoWithPhysicianSerializer {
    id?: number;
    first_name?: string;
    last_name?: string;
    chronic_diagnoses?: string[];
    facility?: FacilitySimpleSerializer;
    details?: PatientBillingInfoSerializer;
    date_ob?: Date | string;
    physician?: PhysicianNameSerializer;
    physician_teams?: number[];
}

export interface PatientTaskProposalSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    comment?: string;
    gender?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_location?: LocationNameSerializer;
    census_bed?: CachedBedSimpleSerializer;
    level_of_care?: string;
    facility?: CachedFacilitySimpleSerializer;
    physician?: number;
    physician_teams?: number[];
    payers?: number[];
    task_proposals?: Array<TaskProposalForPatientsSerializer>;
    person?: PersonWithPatientsSerializer;
    customer?: number;
}

export interface PatientWithCMRNSerializer {
    id?: number;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    cmrns?: Array<PersonIdSerializer>;
    person?: number;
    origin?: string;
    customer?: number;
}

export interface PatientWithFacilitySerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    facility?: FacilitySimpleSerializer;
    physician?: number;
    physician_teams?: number[];
    census_admission_date?: Date | string;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    level_of_care?: string;
    census_location?: LocationSimpleSerializer;
    census_bed?: BedSerializer;
    person?: number;
    origin?: string;
    customer?: number;
}

export interface PatientWithPhysicianSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    physician?: UserSimpleSerializer;
    physician_teams?: number[];
    facility?: number;
    payers?: Array<PayerHistorySerializer>;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    level_of_care?: string;
    origin?: string;
    customer?: number;
}

export interface PatientWithPhysicianSimpleReadSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    comment?: string;
    gender?: string;
    census_location?: LocationNameSerializer;
    census_bed?: CachedBedSimpleSerializer;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_payer?: string;
    level_of_care?: string;
    facility?: FacilitySimpleSerializer;
    physician?: PhysicianSerializer;
    physician_teams?: number[];
    person?: number;
    origin?: string;
    payers?: Array<CachedPayerHistorySerializer>;
    addresses?: Array<FHIRAddressSerializer>;
    phones?: Array<FHIRContactPointWithoutPeriodSerializer>;
    emails?: Array<FHIRContactPointWithoutPeriodSerializer>;
    customer?: number;
}

export interface PatientsWithObjectCountSerializer {
    patient_ids?: IDListWithCountSerializer;
    object_count?: number;
}

export interface PatientsWithoutCMRNSerializer {
    physician_with_cmrn_billing_patients_exists?: boolean;
    patients_without_cmrn?: Array<number>;
}

export interface PayerAltNameDetailSerializer {
    id?: number;
    payer?: PayerSerializer;
    name?: string;
}

export interface PayerHistorySerializer {
    name?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    company_name?: string;
    primary?: boolean;
}

export interface PayerSerializer {
    id?: number;
    name?: string;
    waystar_id?: string;
    professional_claims?: boolean;
    institutional_claims?: boolean;
    remits?: boolean;
    claims_attachment?: boolean;
    claims_monitoring?: boolean;
    eligibility?: boolean;
    estimation?: boolean;
    secondary_claim_format?: string;
    dual_clearing_house?: string;
    class?: string;
    visible?: boolean;
}

export interface PaymentSerializer {
    id?: number;
    payer?: PayerSerializer;
    created_at?: Date | string;
    modified_at?: Date | string;
    origin?: string;
    transaction_id?: string;
    transaction_handling_code?: string;
    monetary_amount?: number;
    credit_debit_flag?: string;
    payment_method_code?: string;
    payment_format_code?: string;
    payer_dfi_qualifier?: string;
    payer_dfi_id?: string;
    payer_account_number?: string;
    payer_account_number_qualifier?: string;
    originating_company_id?: string;
    receiver_dfi_qualifier?: string;
    receiver_dfi_id?: string;
    receiver_account_number_qualifier?: string;
    receiver_account_number?: string;
    payer_name?: string;
    date?: Date | string;
    hl7_message?: number;
}

export interface PersonCreateSerializer {
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    name_suffix?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    ids?: Array<PersonIdCreateSerializer>;
}

export interface PersonDetailedSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    patients?: Array<PatientNameWithFacilitySerializer>;
}

export interface PersonIdCreateSerializer {
    id?: number;
    name?: string;
    code?: string;
    person?: number;
    created_by?: number;
}

export interface PersonIdCreateSerializer {
    name?: string;
    code?: string;
}

export interface PersonIdSerializer {
    id?: number;
    name?: string;
    code?: string;
    person?: number;
    created_by?: number;
}

export interface PersonIdUpdateSerializer {
    id?: number;
    name?: string;
    code?: string;
    person?: number;
    created_by?: number;
}

export interface PersonMatchSerializer {
    matched_subject?: SubjectSerializer;
    score?: number;
}

export interface PersonMergeEventConfirmInputSerializer {
    id?: number;
    subject_id?: number;
    subject_id_type?: string;
    matched_id?: number;
    matched_id_type?: string;
    comment?: string;
    new_person?: PersonCreateSerializer;
}

export interface PersonMergeEventConfirmSerializer {
    person_1?: number;
    patient_1?: number;
    person_2?: number;
    patient_2?: number;
    new_person?: PersonCreateSerializer;
}

export interface PersonMergeEventInputSerializer {
    id?: number;
    subject_id?: number;
    subject_id_type?: string;
    matched_id?: number;
    matched_id_type?: string;
    comment?: string;
}

export interface PersonMergeEventRejectSerializer {
    id?: number;
    person_1?: number;
    patient_1?: number;
    person_2?: number;
    patient_2?: number;
    new_person?: number;
    comment?: string;
}

export interface PersonMergeEventSerializer {
    id?: number;
    created_by?: UserSimpleSerializer;
    created_at?: Date | string;
    subject?: SubjectSerializer;
    matched?: SubjectSerializer;
    status?: string;
    new_person?: PersonSerializer;
    comment?: string;
    origin?: string;
}

export interface PersonMergeProposalDetailSerializer {
    subject?: SubjectSerializer;
    num_matches?: number;
    highest_score?: number;
    matches?: Array<PersonMatchSerializer>;
}

export interface PersonMergeProposalListSerializer {
    proposals?: Array<PersonMergeProposalSerializer>;
    last_index?: number;
}

export interface PersonMergeProposalSerializer {
    subject?: SubjectSerializer;
    num_matches?: number;
    highest_score?: number;
}

export interface PersonPatientsSerializer {
    similarities?: Array<SimilaritySerializer>;
}

export interface PersonSearchSerializer {
    persons?: Array<PersonWithRelatedPatientsSerializer>;
    patients_without_person?: Array<PatientSerializer>;
}

export interface PersonSerializer {
    id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    name_suffix?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
}

export interface PersonWithPatientsSerializer {
    id?: number;
    patients?: Array<PatientForPersonSerializer>;
}

export interface PersonWithRelatedPatientsSerializer {
    person?: PersonSerializer;
    related_patients?: Array<PatientSerializer>;
}

export interface PhysicalExaminationCreateUpdateSerializer {
    general_exam?: GeneralExamSerializer;
    skin_exam?: SkinExamSerializer;
    eyes_exam?: EyesExamSerializer;
    ent_exam?: ENTExamSerializer;
    neck_exam?: NeckExamSerializer;
    lungs_exam?: LungsExamSerializer;
    heart_exam?: HeartExamSerializer;
    abdomen_exam?: AbdomenExamSerializer;
    gu_exam?: GUExamSerializer;
    muscular_exam?: MuscularExamSerializer;
    neuro_exam?: NeuroExamSerializer;
    psych_exam?: PsychExamSerializer;
    limited?: boolean;
    limited_due_to?: string;
    status_observations?: Array<StatusObservationCreateUpdateSerializer>;
}

export interface PhysicalExaminationReadSerializer {
    general_exam?: GeneralExamSerializer;
    skin_exam?: SkinExamSerializer;
    eyes_exam?: EyesExamSerializer;
    ent_exam?: ENTExamSerializer;
    neck_exam?: NeckExamSerializer;
    lungs_exam?: LungsExamSerializer;
    heart_exam?: HeartExamSerializer;
    abdomen_exam?: AbdomenExamSerializer;
    gu_exam?: GUExamSerializer;
    muscular_exam?: MuscularExamSerializer;
    neuro_exam?: NeuroExamSerializer;
    psych_exam?: PsychExamSerializer;
    limited?: boolean;
    limited_due_to?: string;
    status_observations?: Array<StatusObservationReadSerializer>;
}

export interface PhysicianCreateSerializer {
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    facilities?: Array<number>;
    npi_number?: string;
    email?: string;
    billing_npi_number?: string;
    tin_number?: string;
    ccn_number?: string;
    customer_id?: number;
    provider?: string;
}

export interface PhysicianNameSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    has_npi?: boolean;
    npi_number?: string;
    billing_npi_number?: string;
}

export interface PhysicianOrderActionReadSerializer {
    id?: number;
    physician_order?: number;
    action?: string;
    icd?: number;
    icd_update?: number;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface PhysicianOrderActionSerializer {
    id?: number;
    assessment_plan?: number;
    action?: string;
    comments?: string;
    event?: number;
    vital?: number;
    treatment_history?: number;
    lab_test?: number;
    icd?: number;
    icd_update?: number;
    diagnosis?: number;
    hash?: string;
    hidden_in_plan?: boolean;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    sort?: number;
}

export interface PhysicianOrderReadSerializer {
    id?: number;
    actions?: Array<PhysicianOrderActionReadSerializer>;
}

export interface PhysicianOrderSerializer {
    id?: number;
    actions?: Array<PhysicianOrderActionSerializer>;
}

export interface PhysicianSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    is_active?: boolean;
    email?: string;
    origin?: string;
    active_patients?: any;
    billing_npi_number?: string;
    physicians?: Array<SimplePhysicianTeamSerializer>;
    memberships?: Array<WithMembershipSerializer>;
    customer?: number;
    npi_number?: string;
    tin_number?: string;
    provider?: string;
}

export interface PhysicianTeamAddRemoveSerializer {
    physician_team?: number;
}

export interface PhysicianTeamAssignSerializer {
    physician_team?: number;
    is_primary?: boolean;
}

export interface PhysicianTeamAssignUserRequestByEmailSerializer {
    email?: string;
}

export interface PhysicianTeamMemberSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    is_active?: boolean;
    email?: string;
    origin?: string;
    password_requests?: Array<NewPasswordRequestSerializer>;
    npi_number?: string;
    taxonomy_code?: string;
    has_npi?: boolean;
    customer?: number;
    profession_snomed?: SnomedConceptSerializer;
}

export interface PhysicianTeamMembershipSerializer {
    id?: number;
    care_team_lead?: boolean;
    on_call?: boolean;
    user?: PhysicianTeamMemberSerializer;
    nucc_roles?: string[];
}

export interface PhysicianTeamProcedureCodeSerializer {
    physician_team?: UserFacilitySimpleSerializer;
    procedure_codes?: Array<ProcedureCodeSerializer>;
    winning_procedure_code?: ProcedureCodeSerializer;
}

export interface PhysicianTeamUnassignUserRequestSerializer {
    user?: number;
}

export interface PhysicianTeamUserSerializer {
    id?: number;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    is_active?: boolean;
    email?: string;
    origin?: string;
    profession_snomed?: SnomedConceptSerializer;
    password_requests?: Array<NewPasswordRequestSerializer>;
    customer?: number;
    is_placeholder?: any;
}

export interface PhysicianTeamUserSimpleSerializer {
    id?: number;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    is_active?: boolean;
    email?: string;
    origin?: string;
    customer?: number;
}

export interface PhysicianWithVisitDaysDetailSerializer {
    assignee_or_author?: UserSimpleSerializer;
    days?: Array<VisitDayDetailSerializer>;
}

export interface PhysicianWithVisitDaysSerializer {
    assignee_or_author?: UserSimpleSerializer;
    days?: Array<VisitDaySerializer>;
}

export interface PhysiciansForAuditDetailSerializer {
    physicians?: Array<PhysicianWithVisitDaysDetailSerializer>;
}

export interface PhysiciansForAuditSerializer {
    physicians?: Array<PhysicianWithVisitDaysSerializer>;
}

export interface PlanActionSerializer {
    id?: number;
    action?: string;
    goal?: GoalCreateUpdateSerializer;
    diagnosis?: DiagnosisCreateUpdateSerializer;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
    assessment_plan?: number;
    event?: EventCreateUpdateSerializer;
    vital?: number;
    treatment_history?: TreatmentHistoryCreateSerializer;
    lab_test?: LabTestCreateUpdateSerializer;
    icd?: number;
    icd_update?: number;
    procedure?: ProcedureCreateUpdateSerializer;
    patient_allergy?: PatientAllergyCreateUpdateSerializer;
    immunization?: ImmunizationCreateUpdateSerializer;
    clinical_test_result?: ClinicalTestResultCreateUpdateSerializer;
    imaging_result?: ImagingResultCreateUpdateSerializer;
    negation_reason?: NegationReasonSerializer;
    disease_status?: string;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
}

export interface ProblemListReadSerializer {
    id?: number;
    actions?: Array<DiagnosisActionReadSerializer>;
    no_known_problems?: boolean;
}

export interface ProblemListSerializer {
    actions?: Array<DiagnosisActionSerializer>;
    no_known_problems?: boolean;
}

export interface ProcedureActionReadSerializer {
    id?: number;
    procedure?: ProcedureReadOnlySerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface ProcedureActionSerializer {
    id?: number;
    procedure?: ProcedureCreateUpdateSerializer;
    action?: string;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    sort?: number;
}

export interface ProcedureCodeSerializer {
    physician_team?: UserFacilitySimpleSerializer;
    procedure_code?: any;
    medical_program_types?: Array<string>;
    duration?: number;
    has_invalid?: boolean;
    descriptions?: string;
    task_types?: string;
    min_duration?: number;
    price?: number;
    is_fulfilled?: boolean;
    duration_difference?: number;
}

export interface ProcedureCreateUpdateSerializer {
    id?: number;
    code?: number;
    patient?: number;
    status?: string;
    procedure_related_devices?: Array<ProcedureRelatedDeviceCreateUpdateSerializer>;
    performed?: Date | string;
    target_sites?: number[];
    related_icd_diagnosis?: number;
}

export interface ProcedureReadOnlySerializer {
    id?: number;
    status?: string;
    code?: SnomedConceptSerializer;
    patient?: number;
    performed?: Date | string;
    procedure_related_devices?: Array<ProcedureRelatedDeviceReadSerializer>;
    target_sites?: Array<SnomedConceptSerializer>;
    related_icd_diagnosis?: CachedICDCodeNameSerializer;
}

export interface ProcedureRelatedDeviceCreateUpdateSerializer {
    id?: number;
    device?: number;
    device_action?: string;
}

export interface ProcedureRelatedDeviceReadSerializer {
    id?: number;
    device?: FHIRDeviceSerializer;
    device_action?: string;
}

export interface ProcedureReviewReadSerializer {
    id?: number;
    actions?: Array<ProcedureActionReadSerializer>;
    review?: string;
}

export interface ProcedureReviewSerializer {
    id?: number;
    actions?: Array<ProcedureActionSerializer>;
    review?: string;
}

export interface ProgressNoteSerializer {
    id?: number;
    date?: Date | string;
    category?: string;
    patient?: number;
    description?: string;
    modified_by?: string;
}

export interface ProgressNoteSummarySectionSerializer {
    title?: string;
    content?: string;
    references?: Array<number>;
}

export interface ProgressNoteSummarySerializer {
    sections?: Array<ProgressNoteSummarySectionSerializer>;
    status?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    notes_in_period?: number;
    notes_used?: number;
}

export interface PsychExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    orientedness?: string;
    affect?: string;
    mood?: string;
    resistive?: string;
    comment?: string;
}

export interface PsychSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    anxiety_or_panic?: string;
    depression?: string;
    insomnia?: string;
    delusions?: string;
    violence_or_abuse_hx?: string;
    eating_concerns?: string;
    comment?: string;
}

export interface PsychotropicGroupCreateOrUpdateSerializer {
    id?: number;
    icd?: number;
    drug?: number;
    drug_name?: number;
    psychosis_group?: number;
    bipolar_group?: number;
    depression_group?: number;
    anxiety_group?: number;
    insomnia_group?: number;
    dementia_group?: number;
    parkinson_s_group?: number;
    epilepsy_group?: number;
    hallucinations_group?: number;
}

export interface PsychotropicGroupReadSerializer {
    id?: number;
    icd?: ICDCodeNameSerializer;
    drug?: DrugSerializer;
    drug_name?: DrugNameSimpleSerializer;
    psychosis_group?: number;
    bipolar_group?: number;
    depression_group?: number;
    anxiety_group?: number;
    insomnia_group?: number;
    dementia_group?: number;
    parkinson_s_group?: number;
    epilepsy_group?: number;
    hallucinations_group?: number;
}

export interface PsychotropicGroupUpdateSerializer {
    id?: number;
    psychosis_group?: number;
    bipolar_group?: number;
    depression_group?: number;
    anxiety_group?: number;
    insomnia_group?: number;
    dementia_group?: number;
    parkinson_s_group?: number;
    epilepsy_group?: number;
    hallucinations_group?: number;
    icd?: number;
    drug_name?: number;
    drug?: number;
}

export interface PsychotropicGroupsSerializer {
    groups?: Array<string>;
}

export interface PsychotropicsFilteredDiagnosisOverviewSerializer {
    patients_without_treatment?: IDListWithCountSerializer;
    patients_with_some_mistreated_diagnoses?: IDListWithCountSerializer;
    patients_with_correctly_treated_diagnoses_only?: IDListWithCountSerializer;
}

export interface PsychotropicsFilteredPopulationOverviewSerializer {
    total_in_group_patients?: IDListWithCountSerializer;
    patients_with_in_group_diagnosis?: IDListWithCountSerializer;
    patients_with_in_group_treatment?: IDListWithCountSerializer;
    group_patients_with_group_treatment?: IDListWithCountSerializer;
    population_distribution?: PsychotropicsPopulationDistributionSerializer;
    diagnosis_pie_chart?: DiagnosisPieChartSerializer;
    total_treatment_count?: number;
    prn_group_treatments?: PatientsWithObjectCountSerializer;
    patients_with_treatment_without_diagnosis?: IDListWithCountSerializer;
    no_end_date_prn_treatments?: PatientsWithObjectCountSerializer;
    patients_without_any_treatment?: IDListWithCountSerializer;
    patients_treated_correctly?: IDListWithCountSerializer;
    patients_with_diagnosis_without_treatment?: IDListWithCountSerializer;
    psychotropic_patients_group_treatment_distribution?: TreatmentDistributionSerializer;
    most_frequent_drugs?: Array<FrequentDrugSerializer>;
    most_frequent_physicians?: Array<FrequentPhysicianSerializer>;
    group_patients_with_multiple_group_treatments?: IDListWithCountSerializer;
    group_patients_with_multiple_psychotropic_treatments?: IDListWithCountSerializer;
    group_patients_with_multiple_bedtime_group_treatment?: IDListWithCountSerializer;
    average_group_treatments_per_group_patient?: number;
    average_psychotropic_treatments_per_group_patient?: number;
}

export interface PsychotropicsGlobalPopulationOverviewSerializer {
    total_patients?: IDListWithCountSerializer;
    total_psychotropic_patients?: IDListWithCountSerializer;
    patients_with_psychotropic_diagnosis?: IDListWithCountSerializer;
    patients_with_psychotropic_treatment?: IDListWithCountSerializer;
}

export interface PsychotropicsHeatmapPointSerializer {
    treatment_group?: string;
    diagnosis_group?: string;
    patients?: IDListWithCountSerializer;
}

export interface PsychotropicsHeatmapSerializer {
    points?: Array<PsychotropicsHeatmapPointSerializer>;
}

export interface PsychotropicsIndicationSerializer {
    name?: string;
    icd_groups?: Array<string>;
    icds?: Array<ICDCodeWithPsychotropicGroupsSerializer>;
    indication_type?: string;
}

export interface PsychotropicsPatientTreatmentSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    physician?: UserSerializer;
    physician_teams?: number[];
    facility?: number;
    payers?: Array<PayerHistorySerializer>;
    census_payer?: string;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    level_of_care?: string;
    origin?: string;
    customer?: number;
    treatmenthistory?: Array<PsychotropicsTreatmentHistorySerializer>;
    diagnoses?: Array<DiagnosisWithPsychotropicGroupsSerializer>;
}

export interface PsychotropicsPopulationDistributionSerializer {
    patients_with_treatment_and_diagnosis?: IDListWithCountSerializer;
    patients_with_treatment_without_diagnosis?: IDListWithCountSerializer;
    patients_without_treatment_with_diagnosis?: IDListWithCountSerializer;
    patients_without_treatment_or_diagnosis?: IDListWithCountSerializer;
}

export interface PsychotropicsQuerySerializer {
    patient_ids?: Array<number>;
}

export interface PsychotropicsTreatmentHistorySerializer {
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    physician?: UserBaseSerializer;
    icd?: ICDCodeWithPsychotropicGroupsSerializer;
    freq?: string;
    frequency?: number;
    frequency_unit?: string;
    frequency_event?: string;
    dose_quantity?: number;
    dose_unit?: string;
    route_code?: string;
    route_code_system?: string;
    prn?: string;
    order_type?: string;
    indications_for_use?: string;
    product_type?: string;
    order_date?: Date | string;
    discontinued_reason?: string;
    end_date_expected?: Date | string;
    is_valid_psychotropic_treatment?: any;
    medication?: MedicationWithPsychotropicGroupsSerializer;
    indication_type?: string;
    is_bedtime?: boolean;
    is_prn?: boolean;
    is_long_acting?: boolean;
    is_routine?: boolean;
}

export interface PsychotropicsTreatmentOverviewOutputSerializer {
    drug?: DrugWithPsychotropicGroupsSerializer;
    patients?: IDListWithCountSerializer;
    patients_with_primary_txs?: IDListWithCountSerializer;
    patients_with_secondary_txs?: IDListWithCountSerializer;
    patients_with_off_label_txs?: IDListWithCountSerializer;
    patients_with_missing_txs?: IDListWithCountSerializer;
    patients_with_incorrect_treatment?: IDListWithCountSerializer;
    average_treatment_length?: number;
    indications?: Array<PsychotropicsIndicationSerializer>;
    brand_names?: Array<string>;
    groups?: Array<string>;
}

export interface QueueCCDSerializer {
    last_ccd_upload?: LastEmrUploadSerializer;
}

export interface QueueEMRImportSerializer {
    facilities?: Array<number>;
    sync_group?: string;
    reports?: Array<string>;
    start_date?: Date | string;
    end_date?: Date | string;
}

export interface QueueEMRPatientImportSerializer {
    url?: string;
}

export interface RXOrderReadSerializer {
    id?: number;
    medication?: string;
    amount?: string;
    instructions?: string;
    related_icd_diagnoses?: Array<CachedICDCodeNameSerializer>;
    start?: Date | string;
    end?: Date | string;
    drug_code?: string;
    disp_amount?: number;
    disp_units?: string;
    quantity?: number;
    dosage?: string;
    dosage_uid?: number;
    freq?: string;
    duration?: number;
    refills?: number;
    earliest_fill_date?: Date | string;
    substitution_allowed?: boolean;
    notes?: string;
    e_prescribing_status?: string;
    controlled_substance?: boolean;
    route?: string;
    pharmacy?: string;
    drugbank_product?: DrugbankProductSimpleSerializer;
}

export interface RXOrderSerializer {
    id?: number;
    drugbank_product?: number;
    medication?: string;
    amount?: string;
    instructions?: string;
    related_icd_diagnoses?: number[];
    start?: Date | string;
    end?: Date | string;
    drug_code?: string;
    disp_amount?: number;
    disp_units?: string;
    quantity?: number;
    dosage?: string;
    dosage_uid?: number;
    freq?: string;
    duration?: number;
    refills?: number;
    earliest_fill_date?: Date | string;
    substitution_allowed?: boolean;
    notes?: string;
    e_prescribing_status?: string;
    controlled_substance?: boolean;
    route?: string;
    pharmacy?: string;
    dictation_id?: number;
}

export interface RefreshTokenSerializer {
    id?: number;
    token?: string;
    client_id?: any;
    created?: Date | string;
    user?: UserBaseSerializer;
}

export interface RehospitalizationSummaryItemSerializer {
    where_to?: string;
    avg_length_of_stay?: number;
    median_length_of_stay?: number;
    patient_count?: number;
}

export interface RehospitalizationSummarySerializer {
    items?: Array<RehospitalizationSummaryItemSerializer>;
}

export interface RelatedPersonSerializer {
    id?: number;
    active?: boolean;
    given_names?: Array<string>;
    last_name?: string;
    patient?: number;
    relationship?: any;
    phones?: Array<FHIRRelatedPersonPhoneNumberSerializer>;
    email?: string;
    addresses?: Array<FHIRAddressSerializer>;
    relationship_role?: Array<string>;
    comments?: string;
    is_primary?: boolean;
    date_of_birth?: Date | string;
    gender?: string;
}

export interface RemitClaimSerializer {
    id?: number;
    patient?: PatientSimpleSerializer;
}

export interface RemitDetailSerializer {
    id?: number;
    payment?: PaymentSerializer;
    remit_service_lines?: Array<RemitServiceLineSerializer>;
    claim?: RemitClaimSerializer;
    first_name?: string;
    last_name?: string;
    origin_claim_id?: string;
    pos?: string;
    allowed_amount?: number;
    paid?: number;
    patient_responsibility?: number;
}

export interface RemitSerializer {
    id?: number;
    payment?: PaymentSerializer;
    claim?: RemitClaimSerializer;
    first_name?: string;
    last_name?: string;
    origin_claim_id?: string;
    pos?: string;
    allowed_amount?: number;
    paid?: number;
    patient_responsibility?: number;
}

export interface RemitServiceLineSerializer {
    billing_code?: string;
    billed_amount?: number;
    allowed_amount?: number;
    coins?: number;
    copay?: number;
    deduct?: number;
    paid?: number;
    date?: Date | string;
    quantity?: number;
    procedure_modifiers?: any;
    adjustments?: Array<ServiceAdjustmentSerializer>;
    pos?: string;
}

export interface RemitSimpleSerializer {
    id?: number;
    payment?: PaymentSerializer;
    claim?: number;
    first_name?: string;
    last_name?: string;
    origin_claim_id?: string;
    pos?: string;
    allowed_amount?: number;
    paid?: number;
    patient_responsibility?: number;
}

export interface ReportQueueCreateSerializer {
    facility_ids?: Array<number>;
    start_date?: Date | string;
    end_date?: Date | string;
}

export interface ReportQueueSerializer {
    id?: number;
    options?: any;
    created_at?: Date | string;
    modified_at?: Date | string;
    status?: string;
    report?: string;
    download_count?: number;
    user?: number;
    file?: number;
}

export interface ReportQueueStatSerializer {
    in_progress?: number;
    finished_not_downloaded?: number;
}

export interface ResidentNoteSerializer {
    date?: Date | string;
    category?: string;
    patient?: number;
    description?: string;
    modified_by?: string;
}

export interface ResolveAllNonDraftNotesSerializer {
    user?: number;
}

export interface RespiratorySystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    cough?: string;
    wheezing?: string;
    dyspnea?: string;
    pleurisy?: string;
    comment?: string;
}

export interface ResultSummarySerialier {
    critical?: string;
    abnormal?: string;
    normal?: string;
    sources?: Array<string>;
}

export interface ReviewOfSystemsSerializer {
    constitutional?: ConstitutionalSystemReviewSerializer;
    ent_or_mouth?: ENTOrMouthSystemReviewSerializer;
    eyes?: EyesSystemReviewSerializer;
    cardiovascular?: CardiovascularSystemReviewSerializer;
    respiratory?: RespiratorySystemReviewSerializer;
    gastrointestinal?: GastrointestinalSystemReviewSerializer;
    genitourinary?: GenitourinarySystemReviewSerializer;
    musculoskeletal?: MusculoskeletalSystemReviewSerializer;
    skin?: SkinSystemReviewSerializer;
    neuro?: NeuroSystemReviewSerializer;
    psych?: PsychSystemReviewSerializer;
    hem_lymph?: HemORLymphSystemReviewSerializer;
    endocrine?: EndocrineSystemReviewSerializer;
    limited?: boolean;
    limited_due_to?: string;
}

export interface RxNormCodeSerializer {
    id?: number;
    term?: string;
}

export interface SMARTUserInfoSerializer {
    as_patient?: any;
    as_practitioner?: any;
}

export interface SchedulingReviewCreateSerializer {
    task_proposal?: number;
}

export interface SchedulingReviewSerializer {
    task?: number;
    user?: number;
    task_proposal?: number;
    created_at?: Date | string;
}

export interface SearchParamSerializer {
    name?: string;
    type?: string;
    definition?: string;
}

export interface SendMailSerializer {
    subject?: string;
    message?: string;
}

export interface ServiceAdjustmentSerializer {
    id?: number;
    adjustment_reason_code?: string;
    reason_code_display?: any;
    group_code?: string;
    amount?: number;
}

export interface ServiceCodeSerializer {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    start?: Date | string;
    end?: Date | string;
}

export interface SetPrimaryPhysicianSerializer {
    user_facility_id?: number;
}

export interface SeverityAnswerSerializer {
    detail?: string;
    instruction?: string;
    status?: string;
}

export interface SimilaritySerializer {
    person_1?: PersonSerializer;
    patient_1?: PatientSimpleSerializer;
    person_2?: PersonSerializer;
    patient_2?: PatientSimpleSerializer;
    score?: number;
}

export interface SimplePhysicianTeamMemberSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    is_active?: boolean;
    email?: string;
    origin?: string;
    npi_number?: string;
    has_npi?: boolean;
}

export interface SimplePhysicianTeamMembershipSerializer {
    id?: number;
    user?: SimplePhysicianTeamMemberSerializer;
}

export interface SimplePhysicianTeamSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    user?: number;
    facility?: number;
    specialty?: number;
    memberships?: Array<SimplePhysicianTeamMembershipSerializer>;
    organization?: any;
}

export interface SkinExamSerializer {
    global_value?: string;
    no_problems?: boolean;
    integrity?: string;
    warm?: string;
    color?: string;
    rashes?: string;
    indurations?: string;
    wounds_reported?: string;
    comment?: string;
}

export interface SkinSystemReviewSerializer {
    global_value?: string;
    no_problems?: boolean;
    skin_lesions?: string;
    pruritus?: string;
    rash?: string;
    skin_changes?: string;
    comment?: string;
}

export interface SnomedCTConceptSearchResultSerializer {
    id?: number;
    term?: string;
    same_as?: string;
}

export interface SnomedConceptSerializer {
    id?: number;
    term?: string;
}

export interface SocialHistoryReadSerializer {
    administrative_gender?: string;
    race?: string;
    marital_status?: string;
    current_living_situation?: string;
    primary_caregiver?: string;
    others_in_support_group?: string;
    educational_level_health_literacy?: string;
    language?: string;
    hobbies?: string;
    activity_exercise?: string;
    spirituality?: string;
    occupation_employment_history?: string;
    financial_status?: string;
    involved_services_community_agencies?: string;
    advanced_directives?: string;
    other?: string;
    alcohol_use?: string;
    substance_abuse?: string;
    tobacco_use?: string;
    pronouns?: string;
    identity?: string;
    snomed_smoking_status?: SnomedConceptSerializer;
    snomed_smoking_status_date?: Date | string;
    snomed_sexual_orientation?: number;
    races?: string[];
    ethnicities?: string[];
    gender_identity?: string;
    birth_sex?: string;
    financial_hardship?: string;
    stress?: string;
}

export interface SocialHistorySerializer {
    administrative_gender?: string;
    race?: string;
    marital_status?: string;
    current_living_situation?: string;
    primary_caregiver?: string;
    others_in_support_group?: string;
    educational_level_health_literacy?: string;
    language?: string;
    hobbies?: string;
    activity_exercise?: string;
    spirituality?: string;
    occupation_employment_history?: string;
    financial_status?: string;
    involved_services_community_agencies?: string;
    advanced_directives?: string;
    other?: string;
    alcohol_use?: string;
    substance_abuse?: string;
    tobacco_use?: string;
    pronouns?: string;
    identity?: string;
    snomed_smoking_status?: number;
    snomed_smoking_status_date?: Date | string;
    snomed_sexual_orientation?: number;
    races?: string[];
    ethnicities?: string[];
    gender_identity?: string;
    birth_sex?: string;
    financial_hardship?: string;
    stress?: string;
}

export interface SourcesSummarySerializer {
    blood?: number;
    sputum?: number;
    stool?: number;
    urine?: number;
    wound?: number;
    misc?: number;
}

export interface SpecialtyWithDiagnosisAndTreatmentsAndLabsSerializer {
    id?: number;
    name?: string;
    profession_name?: string;
    primary_diagnoses?: Array<DiagnosisICDDetailSerializer>;
    supplementary_diagnoses?: Array<DiagnosisICDDetailSerializer>;
    treatments?: Array<TreatmentHistoryDrugDetailSerializer>;
    lab_tests?: Array<LabTestWithReportSerializer>;
}

export interface StatusCountSerializer {
    status?: string;
    count?: number;
}

export interface StatusObservationCreateUpdateSerializer {
    id?: number;
    patient?: number;
    snomed_concept?: number;
    observation_date?: Date | string;
    mental_status_exam?: number;
    physical_examination?: number;
    type?: string;
}

export interface StatusObservationReadSerializer {
    id?: number;
    snomed_concept?: SnomedConceptSerializer;
    created_at?: Date | string;
    observation_date?: Date | string;
    type?: string;
    patient?: number;
    physical_examination?: number;
    mental_status_exam?: number;
}

export interface SubjectIdSerializer {
    name?: string;
    code?: string;
}

export interface SubjectSerializer {
    id?: number;
    subject_type?: string;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    name_suffix?: string;
    date_ob?: Date | string;
    gender?: string;
    patients?: Array<PatientSimpleWithPatientPhysicianTeamsReadSerializer>;
    ids?: Array<SubjectIdSerializer>;
}

export interface SyncDetailCaptchaSerializer {
    id?: number;
    created_at?: Date | string;
    sync_detail?: number;
    text?: string;
    image?: string;
}

export interface SyncDetailSerializer {
    timestamp?: Date | string;
    user?: number;
    status?: string;
    account_status?: string;
    facility?: number;
    origin?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    sync_process?: number;
    reports?: any;
    reports_failed?: any;
}

export interface SyncDetailSimpleSerializer {
    id?: number;
    status?: string;
    facility?: FacilitySimpleSerializer;
    reports?: any;
    has_error?: any;
    has_fatal?: any;
    completed?: any;
    timestamp?: Date | string;
    user?: UserBaseSerializer;
    sync_process?: number;
}

export interface SyncProcessSerializer {
    id?: number;
    timestamp?: Date | string;
    origin?: string;
    status?: string;
    facilities?: string;
    sync_group?: string;
    has_error?: boolean;
    interrupted_sync_details?: number;
    total_sync_details?: number;
    in_progress_sync_details?: number;
    queued_sync_details?: number;
}

export interface SyncSerializer {
    id?: number;
    timestamp?: Date | string;
    user?: UserBaseSerializer;
    status?: string;
    account_status?: string;
    facility?: FacilitySimpleSerializer;
}

export interface SystemAlertSerializer {
    id?: number;
    start_date?: Date | string;
    end_date?: Date | string;
    message?: string;
    title?: string;
    type?: string;
}

export interface TaskActionSerializer {
    id?: number;
    user?: PhysicianNameSerializer;
    type?: string;
    duration?: number;
    category?: any;
    date?: Date | string;
    patient?: PatientWithFacilitySerializer;
    source_patient?: number;
    physician_team?: number;
    status?: string;
    description?: string;
    note?: string;
    includes_e_prescription?: boolean;
}

export interface TaskAssignedUserBatchUpdateSerializer {
    id?: number;
    done?: boolean;
}

export interface TaskAssignedUserCreateSerializer {
    user?: number;
    task?: number;
    done?: boolean;
}

export interface TaskAssignedUserSerializer {
    id?: number;
    user?: PhysicianSerializer;
    done?: boolean;
}

export interface TaskAssignedUserSimpleSerializer {
    id?: number;
    user?: CachedPhysicianNameSimpleSerializer;
    done?: boolean;
}

export interface TaskAuthorSerializer {
    id?: number;
    user?: UserNameSerializer;
    edited_by?: UserNameSerializer;
    review?: boolean;
    created_at?: Date | string;
}

export interface TaskBehavioralChartAuditSerializer {
    past_medical_hx?: PastMedicalHxSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    problem_list?: ProblemListSerializer;
    behavioral_social_history?: BehavioralSocialHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    vital_examination?: VitalExaminationSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    patient_complexity?: PatientComplexitySerializer;
}

export interface TaskBehavioralVisitNoteSerializer {
    history_of_present_illness?: HistoryOfPresentIllnessSerializer;
    event_review?: EventReviewSerializer;
    order_review?: OrderReviewSerializer;
    past_medical_hx?: PastMedicalHxSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    problem_list?: ProblemListSerializer;
    behavioral_social_history?: BehavioralSocialHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    procedure_review?: ProcedureReviewSerializer;
    imaging_result_review?: ImagingResultReviewCreateUpdateSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewCreateUpdateSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    behavioral_review_of_systems?: BehavioralReviewOfSystemsSerializer;
    mental_status_exam?: BehavioralMentalStatusExamCreateUpdateSerializer;
    vital_examination?: VitalExaminationSerializer;
    behavioral_physical_examination?: BehavioralPhysicalExaminationCreateUpdateSerializer;
    assessment_plan?: AssessmentPlanSerializer;
    patient_complexity?: PatientComplexitySerializer;
    visit_details?: VisitDetailsSerializer;
}

export interface TaskBillingPhysicianTeamSerializer {
    id?: number;
    facility?: number;
}

export interface TaskBillingSerializer {
    assessment_plan_no_of_dx?: any;
    billable?: boolean;
    created_at?: Date | string;
    date?: Date | string;
    description?: string;
    duration?: number;
    edited_by?: number;
    id?: number;
    includes_e_prescription?: boolean;
    level_of_care?: string;
    modified_at?: Date | string;
    note?: string;
    patient?: PatientListSerializer;
    source_patient?: number;
    status?: string;
    type?: string;
    user?: PhysicianNameSerializer;
    verify_comment?: string;
    physician_team?: TaskBillingPhysicianTeamSerializer;
    visit?: VisitBaseSerializer;
    visit_details?: VisitDetailsLastVisitSerializer;
    task_download_history?: Array<TaskDownloadHistorySerializer>;
    is_valid_for_billing?: boolean;
    billing_invalidity_reasons?: string[];
    last_billing_status?: TaskStatusHistorySerializer;
    signed_at?: any;
    claims?: Array<ClaimSimpleSerializer>;
}

export interface TaskBillingStatusSerializer {
    medical_programs?: Array<MedicalProgramSerializer>;
    task_type?: string;
    is_valid_for_billing?: boolean;
    billing_invalidity_reasons?: Array<string>;
}

export interface TaskCdaReconcileSerializer {
    problem_list?: ProblemListSerializer;
    treatment_review?: TreatmentReviewSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
}

export interface TaskClinicNoteSerializer {
    past_medical_hx?: PastMedicalHxSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    physical_examination?: PhysicalExaminationCreateUpdateSerializer;
    assessment_plan?: AssessmentPlanSerializer;
    visit_details?: VisitDetailsSerializer;
}

export interface TaskClinicalStatusReviewSerializer {
    problem_list?: ProblemListSerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    procedure_review?: ProcedureReviewSerializer;
}

export interface TaskCognitiveAssessmentSerializer {
    cognitive_reason_for_visit?: CognitiveReasonForVisitSerializer;
    problem_list?: ProblemListSerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    vital_examination?: VitalExaminationSerializer;
    cognitive_symptoms_of_illness?: CognitiveSymptomsOfIllnessSerializer;
    cognitive_baseline_evaluation?: CognitiveBaselineEvaluationSerializer;
    mental_status_exam?: BehavioralMentalStatusExamCreateUpdateSerializer;
    phq_9?: PHQ9Serializer;
    cognitive_fast_screening?: CognitiveFastScreeningSerializer;
    cognitive_care_plan?: CognitiveCarePlanSerializer;
    visit_details?: VisitDetailsSerializer;
}

export interface TaskCreateSerializer {
    id?: number;
    status?: string;
    patient?: number;
    source_patient?: number;
    assigned_users?: Array<number>;
    duration?: number;
    date?: Date | string;
    type?: string;
    description?: string;
    note?: string;
    created_at?: Date | string;
    modified_at?: Date | string;
    need_to_fax?: boolean;
    need_to_sms?: boolean;
    billable?: boolean;
    verify_comment?: string;
    visit?: number;
    vital_review?: VitalReviewSerializer;
    event_review?: EventReviewSerializer;
    treatment_review?: TreatmentReviewSerializer;
    history_of_present_illness?: HistoryOfPresentIllnessSerializer;
    lab_test_review?: LabTestReviewSerializer;
    physical_examination?: PhysicalExaminationCreateUpdateSerializer;
    visit_details?: VisitDetailsSerializer;
    patient_complexity?: PatientComplexitySerializer;
    order_review?: OrderReviewSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    vital_examination?: VitalExaminationSerializer;
    social_history?: SocialHistorySerializer;
    family_history?: FamilyHistorySerializer;
    past_medical_hx?: PastMedicalHxSerializer;
    problem_list?: ProblemListSerializer;
    assessment_plan?: AssessmentPlanSerializer;
    fax_template?: string;
    physician_order?: PhysicianOrderSerializer;
    work_list_item?: number;
    physician_team?: number;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    mental_status_exam?: BehavioralMentalStatusExamCreateUpdateSerializer;
    behavioral_social_history?: BehavioralSocialHistorySerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    behavioral_review_of_systems?: BehavioralReviewOfSystemsSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    behavioral_physical_examination?: BehavioralPhysicalExaminationCreateUpdateSerializer;
    cognitive_reason_for_visit?: CognitiveReasonForVisitSerializer;
    cognitive_symptoms_of_illness?: CognitiveSymptomsOfIllnessSerializer;
    cognitive_baseline_evaluation?: CognitiveBaselineEvaluationSerializer;
    phq_9?: PHQ9Serializer;
    cognitive_fast_screening?: CognitiveFastScreeningSerializer;
    cognitive_care_plan?: CognitiveCarePlanSerializer;
    questionnaire_responses?: FHIRQuestionnaireResponseSerializer;
    patient_information?: PatientInformationSerializer;
    demographics?: DemographicsSerializer;
    procedure_review?: ProcedureReviewSerializer;
    imaging_result_review?: ImagingResultReviewCreateUpdateSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    coded_notes?: Array<CodedNoteCreateUpdateSerializer>;
    patient_document?: number;
}

export interface TaskCriticalVitalSerializer {
    vital_examination?: VitalExaminationSerializer;
    vital_review?: VitalReviewSerializer;
}

export interface TaskDemographicsSerializer {
    patient_information?: PatientInformationSerializer;
    demographics?: DemographicsSerializer;
}

export interface TaskDownloadHistorySerializer {
    user?: PhysicianNameSerializer;
    timestamp?: Date | string;
    type?: string;
}

export interface TaskDxReviewSerializer {
    past_medical_hx?: PastMedicalHxSerializer;
    problem_list?: ProblemListSerializer;
}

export interface TaskGeneralReviewSerializer {
    event_review?: EventReviewSerializer;
    order_review?: OrderReviewSerializer;
    vital_examination?: VitalExaminationSerializer;
}

export interface TaskLabReviewSerializer {
    lab_test_review?: LabTestReviewSerializer;
    imaging_result_review?: ImagingResultReviewReadSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewReadSerializer;
    physician_order?: PhysicianOrderSerializer;
}

export interface TaskPatientComplexitySerializer {
    patient_complexity?: PatientComplexitySerializer;
}

export interface TaskPhysicianOrderSerializer {
    physician_order?: PhysicianOrderSerializer;
}

export interface TaskPreviousValuesSerializer {
    review_of_systems?: ReviewOfSystemsSerializer;
    physical_examination?: PhysicalExaminationReadSerializer;
    past_medical_hx?: PastMedicalHxReadSerializer;
    social_history?: SocialHistoryReadSerializer;
    family_history?: FamilyHistorySerializer;
    history_of_present_illness?: HistoryOfPresentIllnessHoPISerializer;
    problem_list?: ProblemListReadSerializer;
    event_review?: EventReviewSimpleReadSerializer;
    visit_details?: VisitDetailsReasonForNextVisitSerializer;
    patient_complexity?: PatientComplexitySerializer;
    behavioral_physical_examination?: BehavioralPhysicalExaminationReadSerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    behavioral_social_history?: BehavioralSocialHistoryReadSerializer;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    behavioral_review_of_systems?: BehavioralReviewOfSystemsSerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    mental_status_exam?: BehavioralMentalStatusExamReadSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    treatment_review?: TreatmentReviewLastValuesSerializer;
    cognitive_reason_for_visit?: CognitiveReasonForVisitSerializer;
    cognitive_symptoms_of_illness?: CognitiveSymptomsOfIllnessSerializer;
    cognitive_baseline_evaluation?: CognitiveBaselineEvaluationSerializer;
    phq_9?: PHQ9Serializer;
    cognitive_fast_screening?: CognitiveFastScreeningSerializer;
    cognitive_care_plan?: CognitiveCarePlanSerializer;
    patient_information?: PatientInformationSerializer;
    demographics?: DemographicsSerializer;
    procedure_review?: ProcedureReviewReadSerializer;
    imaging_result_review?: ImagingResultReviewReadSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewReadSerializer;
    immunization_review?: ImmunizationReviewReadSerializer;
    patient_allergy_review?: PatientAllergyReviewReadSerializer;
}

export interface TaskProductivitySerializer {
    id?: number;
    user?: PhysicianNameSerializer;
    type?: string;
    duration?: number;
    category?: any;
    date?: Date | string;
    patient?: PatientWithFacilitySerializer;
    source_patient?: number;
    visit_details?: VisitDetailsBillingCodeReadOnlySerializer;
}

export interface TaskProposalCreateSerializer {
    id?: number;
    patient?: number;
    type?: string;
    confirmed?: boolean;
    visit?: number;
    visit_type?: string;
    details?: any;
    comment?: string;
    created_by?: number;
    physician_team?: number;
}

export interface TaskProposalForPatientsSerializer {
    id?: number;
    type?: string;
    physician_team?: number;
    confirmed?: boolean;
    sort?: number;
    visit?: VisitSimpleSerializer;
    visit_type?: string;
    details?: any;
    comment?: string;
    created_by?: CachedPhysicianNameSimpleSerializer;
    created_at?: Date | string;
}

export interface TaskProposalSerializer {
    id?: number;
    patient?: PatientListSerializer;
    type?: string;
    confirmed?: boolean;
    sort?: number;
    visit?: VisitSerializer;
    visit_type?: string;
    details?: any;
    comment?: string;
    created_by?: PhysicianNameSerializer;
    created_at?: Date | string;
    physician_team?: number;
}

export interface TaskQuestionnaireSerializer {
    questionnaire_responses?: FHIRQuestionnaireResponseSerializer;
}

export interface TaskRXReconcileSerializer {
    treatment_review?: TreatmentReviewSerializer;
}

export interface TaskReadOnlySerializer {
    assessment_plan?: AssessmentPlanReadSerializer;
    assigned_users?: Array<TaskAssignedUserSimpleSerializer>;
    billable?: boolean;
    created_at?: Date | string;
    date?: Date | string;
    description?: string;
    duration?: number;
    e_prescribing?: EPrescribingSerializer;
    edited_by?: CachedPhysicianNameSimpleSerializer;
    event_review?: EventReviewReadSerializer;
    fax_template?: string;
    id?: number;
    includes_e_prescription?: boolean;
    lab_orders?: Array<LabOrderReadSerializer>;
    lab_test_review?: LabTestReviewReadSerializer;
    fax_status?: FaxStatusSerializer;
    last_billing_status?: CachedTaskStatusHistorySerializer;
    level_of_care?: string;
    modified_at?: Date | string;
    need_to_fax?: boolean;
    need_to_sms?: boolean;
    note?: string;
    order_review?: OrderReviewReadSerializer;
    other_orders?: Array<OtherOrderReadSerializer>;
    past_medical_hx?: PastMedicalHxReadSerializer;
    problem_list?: ProblemListReadSerializer;
    patient?: PatientOversightSerializer;
    source_patient?: number;
    physical_examination?: PhysicalExaminationReadSerializer;
    physician_order?: PhysicianOrderReadSerializer;
    history_of_present_illness?: HistoryOfPresentIllnessReadSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    rx_orders?: Array<RXOrderReadSerializer>;
    social_history?: SocialHistoryReadSerializer;
    family_history?: FamilyHistorySerializer;
    status?: string;
    treatment_review?: TreatmentReviewReadSerializer;
    type?: string;
    user?: CachedPhysicianNameSimpleSerializer;
    verify_comment?: string;
    visit?: number;
    visit_details?: VisitDetailsReadOnlySerializer;
    patient_complexity?: PatientComplexitySerializer;
    vital_examination?: VitalExaminationSerializer;
    vital_review?: VitalReviewReadSerializer;
    work_list_item?: number;
    physician_team?: number;
    signed_at?: Date | string;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    mental_status_exam?: BehavioralMentalStatusExamReadSerializer;
    behavioral_social_history?: BehavioralSocialHistoryReadSerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    behavioral_review_of_systems?: BehavioralReviewOfSystemsSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    behavioral_physical_examination?: BehavioralPhysicalExaminationReadSerializer;
    cognitive_reason_for_visit?: CognitiveReasonForVisitSerializer;
    cognitive_symptoms_of_illness?: CognitiveSymptomsOfIllnessSerializer;
    cognitive_baseline_evaluation?: CognitiveBaselineEvaluationSerializer;
    phq_9?: PHQ9Serializer;
    cognitive_fast_screening?: CognitiveFastScreeningSerializer;
    cognitive_care_plan?: CognitiveCarePlanSerializer;
    questionnaire_responses?: FHIRQuestionnaireResponseSerializer;
    patient_information?: PatientInformationSerializer;
    demographics?: DemographicsSerializer;
    procedure_review?: ProcedureReviewReadSerializer;
    imaging_result_review?: ImagingResultReviewReadSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewReadSerializer;
    immunization_review?: ImmunizationReviewReadSerializer;
    patient_allergy_review?: PatientAllergyReviewReadSerializer;
    coded_notes?: Array<CodedNoteReadSerializer>;
    patient_document?: number;
    is_valid_for_billing?: boolean;
    billing_invalidity_reasons?: any;
    approved_for_billing?: any;
}

export interface TaskReasonForVisitSerializer {
    history_of_present_illness?: HistoryOfPresentIllnessSerializer;
    event_review?: EventReviewSerializer;
    order_review?: OrderReviewSerializer;
}

export interface TaskReferralNoteSerializer {
    history_of_present_illness?: HistoryOfPresentIllnessSerializer;
    event_review?: EventReviewSerializer;
    order_review?: OrderReviewSerializer;
    past_medical_hx?: PastMedicalHxSerializer;
    problem_list?: ProblemListSerializer;
    social_history?: SocialHistorySerializer;
    family_history?: FamilyHistorySerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    imaging_result_review?: ImagingResultReviewCreateUpdateSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewCreateUpdateSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    vital_examination?: VitalExaminationSerializer;
    physical_examination?: PhysicalExaminationCreateUpdateSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    procedure_review?: ProcedureReviewSerializer;
    assessment_plan?: AssessmentPlanSerializer;
    patient_complexity?: PatientComplexitySerializer;
    visit_details?: VisitDetailsSerializer;
    questionnaire_responses?: FHIRQuestionnaireResponseSerializer;
    mental_status_exam?: BehavioralMentalStatusExamCreateUpdateSerializer;
}

export interface TaskSerializer {
    assessment_plan?: AssessmentPlanReadSerializer;
    assigned_users?: Array<TaskAssignedUserSimpleSerializer>;
    billable?: boolean;
    created_at?: Date | string;
    date?: Date | string;
    description?: string;
    duration?: number;
    e_prescribing?: EPrescribingSerializer;
    edited_by?: CachedPhysicianNameSimpleSerializer;
    event_review?: EventReviewReadSerializer;
    fax_template?: string;
    id?: number;
    includes_e_prescription?: boolean;
    lab_orders?: Array<LabOrderReadSerializer>;
    lab_test_review?: LabTestReviewReadSerializer;
    fax_status?: FaxStatusSerializer;
    last_billing_status?: CachedTaskStatusHistorySerializer;
    level_of_care?: string;
    modified_at?: Date | string;
    need_to_fax?: boolean;
    need_to_sms?: boolean;
    note?: string;
    order_review?: OrderReviewReadSerializer;
    other_orders?: Array<OtherOrderReadSerializer>;
    past_medical_hx?: PastMedicalHxReadSerializer;
    problem_list?: ProblemListReadSerializer;
    patient?: PatientOversightSerializer;
    source_patient?: number;
    physical_examination?: PhysicalExaminationReadSerializer;
    physician_order?: PhysicianOrderReadSerializer;
    history_of_present_illness?: HistoryOfPresentIllnessReadSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    rx_orders?: Array<RXOrderReadSerializer>;
    social_history?: SocialHistoryReadSerializer;
    family_history?: FamilyHistorySerializer;
    status?: string;
    treatment_review?: TreatmentReviewReadSerializer;
    type?: string;
    user?: CachedPhysicianNameSimpleSerializer;
    verify_comment?: string;
    visit?: number;
    visit_details?: VisitDetailsSerializer;
    patient_complexity?: PatientComplexitySerializer;
    vital_examination?: VitalExaminationSerializer;
    vital_review?: VitalReviewReadSerializer;
    work_list_item?: number;
    physician_team?: number;
    signed_at?: Date | string;
    trauma_evaluation?: BehavioralTraumaEvaluationSerializer;
    baseline_evaluation?: BehavioralBaselineEvaluationSerializer;
    awareness_of_consequences?: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    symptoms_of_illness?: BehavioralSymptomsOfIllnessSerializer;
    mental_status_exam?: BehavioralMentalStatusExamReadSerializer;
    behavioral_social_history?: BehavioralSocialHistoryReadSerializer;
    developmental_history?: BehavioralDevelopmentalHistorySerializer;
    behavioral_family_history?: BehavioralFamilyHistorySerializer;
    psychosocial_circumstances?: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    behavioral_review_of_systems?: BehavioralReviewOfSystemsSerializer;
    past_psychiatric_history?: BehavioralPastPsychiatricHistorySerializer;
    behavioral_physical_examination?: BehavioralPhysicalExaminationReadSerializer;
    cognitive_reason_for_visit?: CognitiveReasonForVisitSerializer;
    cognitive_symptoms_of_illness?: CognitiveSymptomsOfIllnessSerializer;
    cognitive_baseline_evaluation?: CognitiveBaselineEvaluationSerializer;
    phq_9?: PHQ9Serializer;
    cognitive_fast_screening?: CognitiveFastScreeningSerializer;
    cognitive_care_plan?: CognitiveCarePlanSerializer;
    questionnaire_responses?: FHIRQuestionnaireResponseSerializer;
    patient_information?: PatientInformationSerializer;
    demographics?: DemographicsSerializer;
    procedure_review?: ProcedureReviewReadSerializer;
    imaging_result_review?: ImagingResultReviewReadSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewReadSerializer;
    immunization_review?: ImmunizationReviewReadSerializer;
    patient_allergy_review?: PatientAllergyReviewReadSerializer;
    coded_notes?: Array<CodedNoteReadSerializer>;
    patient_document?: number;
}

export interface TaskSimpleSerializer {
    assessment_plan?: AssessmentPlanSimpleReadSerializer;
    assigned_users?: Array<TaskAssignedUserSimpleSerializer>;
    billable?: boolean;
    created_at?: Date | string;
    date?: Date | string;
    description?: string;
    duration?: number;
    edited_by?: number;
    event_review?: EventReviewReadForTasksSerializer;
    id?: number;
    includes_e_prescription?: boolean;
    lab_test_review?: LabTestReviewReadForTasksSerializer;
    fax_status?: FaxStatusSerializer;
    last_billing_status?: CachedTaskStatusHistorySerializer;
    level_of_care?: string;
    modified_at?: Date | string;
    note?: string;
    order_review?: OrderReviewReadSerializer;
    past_medical_hx?: PastMedicalHxReadSerializer;
    patient?: PatientSimpleReadSerializer;
    source_patient?: number;
    physician_team?: number;
    physician_order?: PhysicianOrderReadSerializer;
    history_of_present_illness?: HistoryOfPresentIllnessSimpleReadSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    status?: string;
    treatment_review?: TreatmentReviewSimpleReadSerializer;
    type?: string;
    user?: CachedPhysicianNameSimpleSerializer;
    verify_comment?: string;
    visit?: VisitSimpleSerializer;
    visit_details?: VisitDetailsSimpleSerializer;
    signed_at?: Date | string;
    procedure_review?: ProcedureReviewReadSerializer;
    imaging_result_review?: ImagingResultReviewReadSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewReadSerializer;
    immunization_review?: ImmunizationReviewReadSerializer;
    approved_for_billing?: any;
}

export interface TaskStatusHistorySerializer {
    id?: number;
    timestamp?: Date | string;
    status?: string;
    user?: PhysicianSerializer;
    fax?: boolean;
    sms?: boolean;
    is_sms_sent?: boolean;
    comment?: string;
}

export interface TaskSummarySerializer {
    id?: number;
    user?: PhysicianNameSerializer;
    patient?: PatientTaskInfoSerializer;
    duration?: number;
    date?: Date | string;
    category?: any;
    description?: string;
    note?: string;
    procedure_code?: number;
}

export interface TaskSummaryWithBillingValidationSerializer {
    id?: number;
    patient?: PatientSimpleSerializer;
    duration?: number;
    procedure_code?: string;
    date?: Date | string;
    has_invalid?: boolean;
    min_duration?: number;
}

export interface TaskTherapeuticInteractionReviewSerializer {
    past_medical_hx?: PastMedicalHxSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    physical_examination?: PhysicalExaminationCreateUpdateSerializer;
    assessment_plan?: AssessmentPlanSerializer;
}

export interface TaskUrgentAcute {
    history_of_present_illness?: HistoryOfPresentIllnessSerializer;
    event_review?: EventReviewSerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    imaging_result_review?: ImagingResultReviewCreateUpdateSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewCreateUpdateSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    vital_examination?: VitalExaminationSerializer;
    physical_examination?: PhysicalExaminationCreateUpdateSerializer;
    assessment_plan?: AssessmentPlanSerializer;
    visit_details?: VisitDetailsSerializer;
}

export interface TaskValidatedBillingSerializer {
    id?: number;
    user?: PhysicianNameSerializer;
    physician_team?: number;
    patient?: PatientNameSerializer;
    duration?: number;
    date?: Date | string;
    category?: any;
    description?: string;
    note?: string;
    billable?: boolean;
    verify_comment?: string;
    is_valid_for_billing?: boolean;
    billing_invalidity_reasons?: any;
}

export interface TaskVisitBillingStatusBatchUpdateSerializer {
    ids?: Array<number>;
    billing_status?: string;
    comment?: string;
}

export interface TaskVisitBillingStatusSerializer {
    billing_status?: string;
    comment?: string;
}

export interface TaskVisitDetailSerializer {
    visit_details?: VisitDetailsSerializer;
}

export interface TaskVisitNoteSerializer {
    history_of_present_illness?: HistoryOfPresentIllnessSerializer;
    event_review?: EventReviewSerializer;
    order_review?: OrderReviewSerializer;
    past_medical_hx?: PastMedicalHxSerializer;
    problem_list?: ProblemListSerializer;
    social_history?: SocialHistorySerializer;
    family_history?: FamilyHistorySerializer;
    treatment_review?: TreatmentReviewSerializer;
    lab_test_review?: LabTestReviewSerializer;
    imaging_result_review?: ImagingResultReviewCreateUpdateSerializer;
    clinical_test_result_review?: ClinicalTestResultReviewCreateUpdateSerializer;
    review_of_systems?: ReviewOfSystemsSerializer;
    vital_examination?: VitalExaminationSerializer;
    physical_examination?: PhysicalExaminationCreateUpdateSerializer;
    patient_allergy_review?: PatientAllergyReviewCreateUpdateSerializer;
    immunization_review?: ImmunizationReviewCreateUpdateSerializer;
    procedure_review?: ProcedureReviewSerializer;
    assessment_plan?: AssessmentPlanSerializer;
    patient_complexity?: PatientComplexitySerializer;
    visit_details?: VisitDetailsSerializer;
}

export interface TasksForSchedulerSerializer {
    billable?: boolean;
    created_at?: Date | string;
    date?: Date | string;
    description?: string;
    duration?: number;
    edited_by?: number;
    id?: number;
    includes_e_prescription?: boolean;
    level_of_care?: string;
    modified_at?: Date | string;
    note?: string;
    patient?: PatientTaskProposalSerializer;
    source_patient?: PatientTaskProposalSerializer;
    status?: string;
    type?: string;
    user?: CachedPhysicianNameSimpleSerializer;
    verify_comment?: string;
    visit?: VisitSimpleSerializer;
    visit_details?: VisitDetailsSimpleSerializer;
    task_download_history?: Array<TaskDownloadHistorySerializer>;
    scheduling_review?: CachedSchedulingReviewSerializer;
    physician_team?: number;
}

export interface TimeStampSerializer {
    seconds?: number;
    nanos?: number;
    is_null?: boolean;
}

export interface TokenObtainPairSerializer {
    email?: string;
    password?: string;
}

export interface TokenRefreshSerializer {
    token?: string;
    refresh_token?: string;
}

export interface TokenVerifySerializer {
    token?: string;
}

export interface TreatmentActionReadSerializer {
    id?: number;
    treatment_history?: TreatmentHistoryRelatedTasksSerializer;
    action?: string;
    icd?: ICDCodeSerializer;
    icd_update?: ICDCodeSerializer;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface TreatmentActionSerializer {
    id?: number;
    treatment_history?: TreatmentHistoryCreateSerializer;
    action?: string;
    icd?: number;
    icd_update?: number;
    comments?: string;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface TreatmentActionSimpleReadSerializer {
    id?: number;
    treatment_history?: TreatmentHistoryRelatedTasksSimpleReadSerializer;
    action?: string;
    icd?: CachedICDCodeNameSerializer;
    icd_update?: ICDCodeSerializer;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface TreatmentDistributionSerializer {
    prn_treatments?: PatientsWithObjectCountSerializer;
    routine_treatments?: PatientsWithObjectCountSerializer;
    long_acting_treatments?: PatientsWithObjectCountSerializer;
}

export interface TreatmentHistoryAuditSerializer {
    id?: number;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    drug_name?: DrugNameSerializer;
    created_at?: Date | string;
    physician?: PhysicianSerializer;
    patient?: PatientSerializer;
    icd?: ICDCodeSerializer;
    prn?: string;
    indications_for_use?: string;
}

export interface TreatmentHistoryBatchDeleteSerializer {
    ids?: Array<number>;
}

export interface TreatmentHistoryCreateSerializer {
    id?: number;
    infection_information?: number;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    parent?: number;
    drug_name?: number;
    drugbank_product?: number;
    rx_norm_code?: number;
    physician?: number;
    patient?: number;
    icd?: number;
    freq?: string;
    frequency?: number;
    frequency_unit?: string;
    frequency_event?: string;
    dose_quantity?: number;
    dose_unit?: string;
    route_code?: string;
    route_code_system?: string;
    copied_from?: number;
    prn?: string;
    is_deleted?: boolean;
    medication_class?: string;
    order_type?: string;
    is_infection?: boolean;
    indications_for_use?: string;
    product_type?: string;
    original_id?: string;
    order_date?: Date | string;
    discontinued_reason?: string;
    end_date_expected?: Date | string;
    patient_document?: number;
}

export interface TreatmentHistoryDetailedRelatedTasksSerializer {
    id?: number;
    modified_by?: number;
    infection_information?: InfectionInformationTreatmentSerializer;
    drug?: string;
    amount?: string;
    freq?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: DrugNameSerializer;
    physician?: PhysicianSerializer;
    physician_name?: string;
    patient?: PatientSerializer;
    icd?: ICDCodeSerializer;
    prn?: string;
    indications_for_use?: string;
    admit_census?: CensusHistorySimpleSerializer;
    discharge_census?: CensusHistorySimpleSerializer;
    flags?: Array<TreatmentHistoryFlagSerializer>;
    product_type_category?: string;
    product_type?: string;
    drugbank_product?: DrugbankProductSimpleSerializer;
    rx_norm_code?: RxNormCodeSerializer;
    dose_quantity?: number;
    dose_unit?: string;
    patient_document?: number;
    copied_from?: number;
    created_at?: Date | string;
    order_status?: string;
    related_tasks?: Array<TaskAuthorSerializer>;
    route_code?: string;
}

export interface TreatmentHistoryDetailedSerializer {
    id?: number;
    modified_by?: number;
    infection_information?: InfectionInformationTreatmentSerializer;
    drug?: string;
    amount?: string;
    freq?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: DrugNameSerializer;
    physician?: PhysicianSerializer;
    physician_name?: string;
    patient?: PatientSerializer;
    icd?: ICDCodeSerializer;
    prn?: string;
    indications_for_use?: string;
    admit_census?: CensusHistorySimpleSerializer;
    discharge_census?: CensusHistorySimpleSerializer;
    flags?: Array<TreatmentHistoryFlagSerializer>;
    product_type_category?: string;
    product_type?: string;
    drugbank_product?: DrugbankProductSimpleSerializer;
    rx_norm_code?: RxNormCodeSerializer;
    dose_quantity?: number;
    dose_unit?: string;
    patient_document?: number;
    copied_from?: number;
    created_at?: Date | string;
    order_status?: string;
}

export interface TreatmentHistoryDrugDetailSerializer {
    id?: number;
    drug?: string;
    amount?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    instructions?: string;
    drug_name?: DrugNameSerializer;
    icd?: ICDCodeSerializer;
}

export interface TreatmentHistoryFlagSerializer {
    id?: number;
    type?: string;
    description?: string;
    object_id?: number;
}

export interface TreatmentHistoryOriginSerializer {
    id?: number;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    drug_name?: DrugNameSerializer;
    created_at?: Date | string;
    physician?: PhysicianSerializer;
    patient?: PatientSerializer;
    icd?: ICDCodeSerializer;
    prn?: string;
    indications_for_use?: string;
}

export interface TreatmentHistoryPatientSerializer {
    id?: number;
    patient_id?: any;
    infection_information?: number;
    amount?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug?: string;
    drug_name?: DrugNameSerializer;
}

export interface TreatmentHistoryRelatedTasksSerializer {
    id?: number;
    infection_information?: InfectionInformationSimpleSerializer;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: DrugNameItemSerializer;
    patient?: PatientOversightSerializer;
    prn?: string;
    flags?: Array<CachedTreatmentHistoryFlagSerializer>;
    physician?: PhysicianSerializer;
    icd?: ICDCodeSerializer;
    display_name?: any;
    drugbank_product?: DrugbankProductSimpleSerializer;
    rx_norm_code?: RxNormCodeSerializer;
    medication_class?: string;
    order_type?: string;
    is_infection?: boolean;
    indications_for_use?: string;
    dose_quantity?: number;
    dose_unit?: string;
    product_type?: string;
    frequency?: number;
    frequency_unit?: string;
    discontinued_reason?: string;
    route_code?: string;
    patient_document?: number;
    copied_from?: number;
    created_at?: Date | string;
    related_tasks?: Array<TaskAuthorSerializer>;
}

export interface TreatmentHistoryRelatedTasksSimpleReadSerializer {
    id?: number;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: CachedDrugNameItemSerializer;
    prn?: string;
    flags?: Array<CachedTreatmentHistoryFlagSerializer>;
    physician?: number;
    icd?: CachedICDCodeNameSerializer;
    display_name?: any;
    related_tasks?: Array<TaskAuthorSerializer>;
    drugbank_product?: DrugbankProductSimpleSerializer;
    rx_norm_code?: RxNormCodeSerializer;
    freq?: string;
    frequency?: number;
    frequency_unit?: string;
    frequency_event?: string;
    dose_quantity?: number;
    dose_unit?: string;
    route_code?: string;
    route_code_system?: string;
}

export interface TreatmentHistorySerializer {
    id?: number;
    infection_information?: number;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: number;
    drugbank_product?: number;
    rx_norm_code?: number;
    physician?: number;
    patient?: number;
}

export interface TreatmentHistoryUpdateICDSerializer {
    id?: number;
    icd?: number;
}

export interface TreatmentHistoryWithLengthSerializer {
    id?: number;
    infection_information?: number;
    drug?: string;
    amount?: string;
    instructions?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    calculated_end_date?: Date | string;
    parent?: number;
    drug_name?: number;
    drugbank_product?: number;
    rx_norm_code?: number;
    physician?: UserSimpleSerializer;
    patient?: number;
    treatment_length?: any;
}

export interface TreatmentReviewLastValuesSerializer {
    medication_hx?: string;
}

export interface TreatmentReviewReadSerializer {
    id?: number;
    actions?: Array<TreatmentActionReadSerializer>;
    medication_hx?: string;
    reconciliation_complete?: boolean;
    no_known_medications?: boolean;
}

export interface TreatmentReviewSerializer {
    id?: number;
    actions?: Array<TreatmentActionSerializer>;
    medication_hx?: string;
    reconciliation_complete?: boolean;
    no_known_medications?: boolean;
}

export interface TreatmentReviewSimpleReadSerializer {
    id?: number;
    actions?: Array<TreatmentActionSimpleReadSerializer>;
    medication_hx?: string;
    reconciliation_complete?: boolean;
    no_known_medications?: boolean;
}

export interface UDIParserSerializer {
    carrier_hrf?: string;
    device_identifier?: string;
    manufacture_date?: Date | string;
    expiration_date?: Date | string;
    lot_number?: string;
    serial_number?: string;
    gmdn_terms?: Array<FHIRDeviceNameSerializer>;
    snomed_types?: Array<FHIRDeviceTypeSerializer>;
    contains_nrl?: boolean;
    mri_safety_status?: string;
}

export interface UnitSerializer {
    id?: number;
    name?: string;
    facility?: number;
}

export interface UnitSimpleSerializer {
    id?: number;
    name?: string;
}

export interface UnmatchedPatientActionSerializer {
    id?: number;
    patient?: PatientWithPhysicianSimpleReadSerializer;
    patient_id?: number;
    created_at?: Date | string;
    modified_at?: Date | string;
    category?: string;
    sub_category?: string;
    comment?: string;
    facility?: number;
}

export interface UnmatchedPatientDetailSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    mrid?: string;
    date_ob?: Date | string;
    date_of_decease?: Date | string;
    gender?: string;
    created_at?: Date | string;
    comment?: string;
    facility?: FacilitySimpleSerializer;
    organization?: number;
    census_status?: string;
    census_date?: Date | string;
    active_census_date?: Date | string;
    census_admission_date?: Date | string;
    census_location?: LocationSimpleSerializer;
    census_bed?: BedSerializer;
    level_of_care?: string;
    physician?: PhysicianSerializer;
    physician_teams?: number[];
    person?: number;
    origin?: string;
    reason_of_decease?: string;
    demographics?: DemographicsSimpleSerializer;
    customer?: number;
    unmatched_patient_actions?: Array<UnmatchedPatientActionSerializer>;
    results?: Array<ResultSummarySerialier>;
    received_at?: Date | string;
}

export interface UserAccessLogSerializer {
    id?: number;
    level?: string;
    user?: UserNameSerializer;
    message?: any;
    type?: string;
    timestamp?: Date | string;
}

export interface UserAuthSerializer {
    id?: number;
    organization?: number;
    facilities?: number[];
    email?: string;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    organization_group?: number;
    roles?: any;
    organizations?: Array<OrganizationUserAuthSerializer>;
    permissions?: any;
    smart_user_info?: SMARTUserInfoSerializer;
    customer?: number;
}

export interface UserBaseSerializer {
    id?: number;
    roles?: any;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    is_active?: boolean;
    is_suspended?: boolean;
    customer?: number;
}

export interface UserBillingDownloadSerializer {
    user?: PhysicianNameSerializer;
    patient_procedure_codes?: Array<PatientNPIProcedureCodeWithFacilitySerializer>;
}

export interface UserDetailedSerializer {
    id?: number;
    is_active?: boolean;
    is_suspended?: boolean;
    created_at?: Date | string;
    organization?: OrganizationSimpleSerializer;
    email?: string;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    job_title?: string;
    organization_group?: OrganizationGroupSerializer;
    groups?: number[];
    roles?: any;
    departments?: number[];
    facilities?: Array<FacilitySerializer>;
    admin_departments?: number[];
    admin_organization_groups?: number[];
    settings?: UserSettingsSerializer;
    last_signin?: Date | string;
    lead_physicians?: Array<UserSimpleSerializer>;
    number_of_logins?: number;
    customer?: CustomerSimpleSerializer;
    smart_user_info?: SMARTUserInfoSerializer;
    tin_number?: string;
    ccn_number?: string;
    first_signin?: Date | string;
    password_requests?: Array<NewPasswordRequestSerializer>;
    employee_id?: string;
    npi_number?: string;
    billing_npi_number?: string;
    taxonomy_code?: string;
    phone?: any;
    is_np?: boolean;
    _permissions?: string[];
    ip_filter?: string;
    allowed_domain?: string;
    has_npi?: boolean;
    timezone?: string;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    billing_group?: number;
    erx_origin?: ERXOriginSerializer;
}

export interface UserFacilityDetailSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    user?: PhysicianNameSerializer;
    origin?: string;
    medical_director?: boolean;
    facility?: FacilitySimpleSerializer;
    is_rural?: boolean;
    can_save_conversation_as_task?: boolean;
}

export interface UserFacilityMemberUpdateSerializer {
    id?: number;
    care_team_lead?: boolean;
    on_call?: boolean;
    nucc_roles?: string[];
}

export interface UserFacilitySerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    user?: PhysicianTeamUserSerializer;
    origin?: string;
    memberships?: Array<PhysicianTeamMembershipSerializer>;
    medical_director?: boolean;
    facility?: FacilitySimpleSerializer;
    is_rural?: boolean;
    can_save_conversation_as_task?: boolean;
    specialty?: number;
    auto_assign_to_all_patients?: boolean;
    auto_assign_to_user_facilities?: number[];
}

export interface UserFacilitySimpleSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    user?: PhysicianTeamUserSerializer;
    origin?: string;
    medical_director?: boolean;
    facility?: number;
    is_rural?: boolean;
    can_save_conversation_as_task?: boolean;
}

export interface UserLogSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    job_title?: string;
    employee_id?: string;
    organization_group?: number;
    email?: string;
    is_active?: boolean;
    is_suspended?: boolean;
    is_np?: boolean;
    created_at?: Date | string;
    timezone?: string;
    groups?: number[];
    facilities?: number[];
    departments?: number[];
    roles?: any;
    _permissions?: any;
    phone?: string;
    password_requests?: Array<NewPasswordRequestSerializer>;
    origin?: string;
    ip_filter?: string;
    npi_number?: string;
    has_npi?: boolean;
    taxonomy_code?: string;
    allowed_domain?: string;
    last_signin?: Date | string;
    billing_npi_number?: string;
    customer?: number;
    tin_number?: string;
    ccn_number?: string;
    provider?: string;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    billing_group?: number;
    erx_origin?: number;
    first_signin?: Date | string;
    total_signin_count?: number;
}

export interface UserLogWithSigninsSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    job_title?: string;
    employee_id?: string;
    organization_group?: number;
    email?: string;
    is_active?: boolean;
    is_suspended?: boolean;
    is_np?: boolean;
    created_at?: Date | string;
    timezone?: string;
    groups?: number[];
    facilities?: number[];
    departments?: number[];
    roles?: any;
    _permissions?: any;
    phone?: string;
    password_requests?: Array<NewPasswordRequestSerializer>;
    origin?: string;
    ip_filter?: string;
    npi_number?: string;
    has_npi?: boolean;
    taxonomy_code?: string;
    allowed_domain?: string;
    last_signin?: Date | string;
    billing_npi_number?: string;
    customer?: number;
    tin_number?: string;
    ccn_number?: string;
    provider?: string;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    billing_group?: number;
    erx_origin?: number;
    first_signin?: Date | string;
    total_signin_count?: number;
    signins?: Array<LogSerializer>;
}

export interface UserNameSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
}

export interface UserPasscodeSerializer {
    created_at?: Date | string;
    two_factor_authentication_type?: string;
}

export interface UserPatientProcedureCodeSerializer {
    user?: PhysicianNameSerializer;
    patient_procedure_codes?: Array<PatientNPIProcedureCodeWithFacilitySerializer>;
}

export interface UserSearchSerializer {
    id?: number;
    email?: string;
    title_name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    facilities?: number[];
    customer?: number;
}

export interface UserSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    job_title?: string;
    employee_id?: string;
    organization_group?: number;
    email?: string;
    is_active?: boolean;
    is_suspended?: boolean;
    is_np?: boolean;
    created_at?: Date | string;
    timezone?: string;
    groups?: number[];
    facilities?: number[];
    departments?: number[];
    roles?: any;
    _permissions?: any;
    phone?: string;
    password_requests?: Array<NewPasswordRequestSerializer>;
    origin?: string;
    ip_filter?: string;
    npi_number?: string;
    has_npi?: boolean;
    taxonomy_code?: string;
    allowed_domain?: string;
    last_signin?: Date | string;
    billing_npi_number?: string;
    customer?: number;
    tin_number?: string;
    ccn_number?: string;
    provider?: string;
    addresses?: Array<FHIRAddressCreateUpdateModelSerializer>;
    contact_points?: Array<FHIRContactPointCreateUpdateModelSerializer>;
    billing_group?: number;
    erx_origin?: number;
}

export interface UserSettingsSerializer {
    notifications_sounds?: boolean;
    notifications_lab_report_patients_assigned?: boolean;
    notifications_lab_report_patients_followed?: boolean;
    notifications_ccm_note_patients_assigned?: boolean;
    notifications_ccm_note_patients_followed?: boolean;
    notifications_critical_lab_report_patients_all?: boolean;
    notifications_critical_lab_report_patients_assigned?: boolean;
    notifications_critical_lab_report_patients_followed?: boolean;
    email_notifications?: boolean;
    two_factor_authentication_type?: string;
    auto_captcha_popup?: boolean;
}

export interface UserSimpleSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    organization_group?: number;
    email?: string;
    is_active?: boolean;
    timezone?: string;
    facilities?: number[];
    roles?: any;
    phone?: any;
    origin?: string;
    customer?: number;
}

export interface UserStatusChangeReadOnlySerializer {
    id?: number;
    changed_by?: UserSimpleSerializer;
    change?: string;
    changed_at?: Date | string;
    user?: number;
}

export interface UserTimeSpentOnLocationSerializer {
    id?: number;
}

export interface UserWithoutRelatedSerializer {
    id?: number;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title_name?: string;
    email?: string;
    is_active?: boolean;
    timezone?: string;
    roles?: any;
    phone?: any;
    origin?: string;
    customer?: number;
}

export interface ValidatedBillingTaskSerializer {
    id?: number;
    user?: PhysicianNameSerializer;
    physician_team?: number;
    patient?: PatientSimpleSerializer;
    duration?: number;
    date?: Date | string;
    category?: any;
    description?: string;
    note?: string;
    billing_validation?: BillingValidationSerializer;
    billable?: boolean;
    verify_comment?: string;
}

export interface ValueSetValueSerializer {
    id?: number;
    code?: string;
    display_name?: string;
    same_as?: string;
    value_set_oid?: number;
}

export interface VisitBaseSerializer {
    id?: number;
    date?: Date | string;
    user_facility?: number;
    physician?: number;
    done?: boolean;
}

export interface VisitBillingCodeSerializer {
    code?: string;
    description?: string;
    category?: string;
}

export interface VisitCountSerializer {
    total_visits?: number;
    draft_visits?: number;
    reported_visits?: number;
    pending_visits?: number;
    updated_visits?: number;
    invalid_visits?: number;
    to_review?: number;
    valid_visits?: number;
    validated_visits?: number;
    verified_visits?: number;
    no_charge_visits?: number;
    complex_visits_with_hqw?: number;
    complex_visits_without_hqw?: number;
    visits_with_claim?: number;
}

export interface VisitCreateSerializer {
    id?: number;
    date?: Date | string;
    user_facility?: number;
    physician?: number;
    done?: boolean;
    secondary_assignees?: number[];
}

export interface VisitDayDetailSerializer {
    date?: Date | string;
    facilities?: Array<FacilitySimpleSerializer>;
    authors?: Array<UserSimpleSerializer>;
    visit_counts?: VisitCountSerializer;
    invalid_reasons?: Array<string>;
    visit_occasions?: Array<VisitForVisitDaySerializer>;
}

export interface VisitDaySerializer {
    date?: Date | string;
    facilities?: Array<FacilitySimpleSerializer>;
    authors?: Array<UserSimpleSerializer>;
    visit_counts?: VisitCountSerializer;
}

export interface VisitDetailsBillingCodeReadOnlySerializer {
    billing_code?: string;
}

export interface VisitDetailsLastVisitSerializer {
    billing_code?: string;
    addon_code?: string[];
    next_appointment_in?: string;
    next_appointment_with?: string;
    next_appointment_date?: Date | string;
    comments?: string;
    visit_comments?: string;
    reason_for_next_visit?: string;
    next_appointment_type?: string;
    procedure?: number;
}

export interface VisitDetailsReadOnlySerializer {
    billing_code?: string;
    addon_code?: string[];
    next_appointment_in?: string;
    next_appointment_with?: string;
    next_appointment_date?: Date | string;
    comments?: string;
    visit_comments?: string;
    reason_for_next_visit?: string;
    next_appointment_type?: string;
    related_person?: Array<RelatedPersonSerializer>;
    procedure?: number;
    visit?: number;
}

export interface VisitDetailsReasonForNextVisitSerializer {
    reason_for_next_visit?: string;
    comments?: string;
    visit_comments?: string;
}

export interface VisitDetailsSerializer {
    billing_code?: string;
    addon_code?: string[];
    next_appointment_in?: string;
    next_appointment_with?: string;
    next_appointment_date?: Date | string;
    comments?: string;
    visit_comments?: string;
    reason_for_next_visit?: string;
    next_appointment_type?: string;
    related_person?: number[];
    procedure?: number;
    visit?: number;
}

export interface VisitDetailsSimpleSerializer {
    billing_code?: string;
    next_appointment_in?: string;
    next_appointment_with?: string;
    next_appointment_date?: Date | string;
    reason_for_next_visit?: string;
    next_appointment_type?: string;
}

export interface VisitForVisitDaySerializer {
    id?: number;
    date?: Date | string;
    user_facility?: UserFacilityDetailSerializer;
    physician?: PhysicianTeamUserSimpleSerializer;
    done?: boolean;
    secondary_assignees?: Array<PhysicianTeamUserSimpleSerializer>;
}

export interface VisitSerializer {
    id?: number;
    date?: Date | string;
    user_facility?: number;
    physician?: PhysicianTeamUserSimpleSerializer;
    done?: boolean;
    task_count?: number;
    task_proposal_count?: number;
    task_saved_count?: number;
    secondary_assignees?: Array<PhysicianTeamUserSimpleSerializer>;
}

export interface VisitSimpleSerializer {
    id?: number;
    date?: Date | string;
    done?: boolean;
    physician?: PhysicianTeamUserSimpleSerializer;
}

export interface VitalExaminationSerializer {
    id?: number;
    blood_pressure_value?: number;
    blood_pressure_value_secondary?: number;
    blood_pressure_comment?: string;
    pulse_value?: number;
    pulse_comment?: string;
    temperature_value?: number;
    temperature_comment?: string;
    respirations_value?: number;
    respirations_comment?: string;
    o2_saturation_value?: number;
    o2_saturation_comment?: string;
    blood_sugar_value?: number;
    blood_sugar_comment?: string;
    weight_value?: number;
    weight_comment?: string;
    height_value?: number;
    height_comment?: string;
    pediatric_head_circumference_percentile_value?: number;
    pediatric_head_circumference_percentile_comment?: string;
    head_circumference_value?: number;
    head_circumference_comment?: string;
    pediatric_bmi_for_age_percentile_value?: number;
    pediatric_bmi_for_age_percentile_comment?: string;
    pediatric_weight_for_height_percentile_value?: number;
    pediatric_weight_for_height_percentile_comment?: string;
    temperature_unit?: string;
    weight_unit?: string;
    height_unit?: string;
    inhaled_oxygen_concentration_value?: number;
    inhaled_oxygen_concentration_comment?: string;
    inhaled_oxygen_flow_rate_value?: number;
    inhaled_oxygen_flow_rate_comment?: string;
    bmi_value?: number;
    bmi_comment?: string;
    bmi_screening?: boolean;
    bmi_negation_reason?: number;
    recorded?: any;
}

export interface VitalReviewActionReadSerializer {
    id?: number;
    vital?: VitalSimpleSerializer;
    action?: string;
    comments?: string;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;
    hash?: string;
    hidden_in_plan?: boolean;
    sort?: number;
}

export interface VitalReviewActionSerializer {
    vital?: number;
    action?: string;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    lab_order?: LabOrderSerializer;
    rx_order?: RXOrderSerializer;
    other_order?: OtherOrderSerializer;
    sort?: number;
}

export interface VitalReviewReadSerializer {
    id?: number;
    actions?: Array<VitalReviewActionReadSerializer>;
}

export interface VitalReviewSerializer {
    actions?: Array<VitalReviewActionSerializer>;
}

export interface VitalSerializer {
    id?: number;
    patient_id?: any;
    recorded?: Date | string;
    medical_result_type?: number;
    value?: number;
    value_secondary?: number;
    details?: string;
    taken_by?: number;
    vital_type?: string;
    value_before?: number;
}

export interface VitalSimpleSerializer {
    id?: number;
    recorded?: Date | string;
    medical_result_type?: number;
    value?: number;
    value_secondary?: number;
    vital_type?: string;
    related_tasks?: Array<TaskAuthorSerializer>;
}

export interface WithMembershipSerializer {
    id?: number;
    user_facility?: number;
    facility?: number;
    organization?: number;
}

export interface WorkListItemReadSerializer {
    id?: number;
    patient?: PatientSimpleSerializer;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    medication_name?: string;
    instructions?: string;
    physician?: string;
    physician_group?: string;
    request_type?: string;
    status?: string;
    task?: number;
    received_date?: number;
    created_at?: Date | string;
    request_uid?: number;
    notes?: string;
    refills?: number;
    duration?: number;
    substitution_allowed?: boolean;
    disp_amount?: number;
    pharmacy_name?: string;
    effective_date?: Date | string;
    reason?: string;
}

export interface WorkListItemRejectReadSerializer {
    id?: number;
    patient?: PatientSimpleSerializer;
    first_name?: string;
    last_name?: string;
    date_ob?: Date | string;
    medication_name?: string;
    instructions?: string;
    physician?: string;
    physician_group?: string;
    request_type?: string;
    status?: string;
    task?: number;
    received_date?: number;
    created_at?: Date | string;
    request_uid?: number;
    notes?: string;
    refills?: number;
    duration?: number;
    substitution_allowed?: boolean;
    disp_amount?: number;
    pharmacy_name?: string;
    effective_date?: Date | string;
    reason?: string;
    user_status?: string;
    finalize_url?: string;
    authorization_url?: string;
}

@Injectable({
    providedIn: 'root'
})

// @ts-ignore
export class APIService {
    static httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        APIService.httpClient = httpClient;
    }

    static init(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

        static AccessTokenViewSet = {
        create: (data: AccessTokenSerializer, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<AccessTokenSerializer>(AP + `/api/oauth/access-tokens/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/oauth/access-tokens/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AccessTokenSerializer[]>(AP + `/api/oauth/access-tokens/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: AccessTokenSerializer, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<AccessTokenSerializer>(AP + `/api/oauth/access-tokens/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AccessTokenSerializer>(AP + `/api/oauth/access-tokens/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: AccessTokenSerializer, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<AccessTokenSerializer>(AP + `/api/oauth/access-tokens/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    AccessTokenViewSet = APIService.AccessTokenViewSet;

    static ActionViewSet = {
        list: (queryParams?: ActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ActionReadSerializerWithTask[]>>(AP + `/api/v4/actions/`, createReqSettings(queryParams, headers)),
        resolve: (pk: any, data: ActionReadSerializerWithTask, queryParams?: ActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ActionReadSerializerWithTask>(AP + `/api/v4/actions/${pk}/resolve/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ActionReadSerializerWithTask>(AP + `/api/v4/actions/${pk}/`, createReqSettings(queryParams, headers)),
    };

    ActionViewSet = APIService.ActionViewSet;

    static AddressViewSet = {
        create: (data: FHIRAddressCreateUpdateModelSerializer, queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FHIRAddressCreateUpdateModelSerializer>(AP + `/api/v4/addresses/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/addresses/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRAddressSerializer[]>(AP + `/api/v4/addresses/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRAddressSerializer[]>(AP + `/api/v4/addresses/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FHIRAddressCreateUpdateModelSerializer, queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FHIRAddressCreateUpdateModelSerializer>(AP + `/api/v4/addresses/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRAddressSerializer>(AP + `/api/v4/addresses/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRAddressSerializer>(AP + `/api/v4/addresses/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRAddressSerializer>(AP + `/api/v4/addresses/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FHIRAddressCreateUpdateModelSerializer, queryParams?: AddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FHIRAddressCreateUpdateModelSerializer>(AP + `/api/v4/addresses/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    AddressViewSet = APIService.AddressViewSet;

    static AllPayers = {
        get: (queryParams?: AllPayersQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/all-payers`, createReqSettings(queryParams, headers)),
    };

    AllPayers = APIService.AllPayers;

    static AllergyViewSet = {
        list: (queryParams?: AllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<AllergySerializer[]>>(AP + `/api/v4/allergies/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: AllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergySerializer>(AP + `/api/v4/allergies/${pk}/`, createReqSettings(queryParams, headers)),
    };

    AllergyViewSet = APIService.AllergyViewSet;

    static AntibioticsPatientViewSet = {
        active_census: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientActivePeriodsSerializer>(AP + `/api/v4/antibiotics-patients/active_census/`, createReqSettings(queryParams, headers)),
        patients_with_active_treatments: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientActivePeriodsSerializer>(AP + `/api/v4/antibiotics-patients/patients_with_active_treatments/`, createReqSettings(queryParams, headers)),
        patients_with_long_treatments: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<AntibioticsPatientSerializer[]>>(AP + `/api/v4/antibiotics-patients/patients_with_long_treatments/`, createReqSettings(queryParams, headers)),
        patients_with_multiple_treatments: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<AntibioticsPatientSerializer[]>>(AP + `/api/v4/antibiotics-patients/patients_with_multiple_treatments/`, createReqSettings(queryParams, headers)),
        summary_statistics: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AntibioticsPatientSummarySerializer>(AP + `/api/v4/antibiotics-patients/summary_statistics/`, createReqSettings(queryParams, headers)),
    };

    AntibioticsPatientViewSet = APIService.AntibioticsPatientViewSet;

    static AntibioticsTreatmentHistoryViewSet = {
        active_treatments: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AntibioticsActiveTreatmentsSerializer>(AP + `/api/v4/antibiotics-history/active_treatments/`, createReqSettings(queryParams, headers)),
        by_class: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AntibioticsByClassWithMostCommonSerializer>(AP + `/api/v4/antibiotics-history/by_class/`, createReqSettings(queryParams, headers)),
        by_name: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AntibioticsByNameWithMostCommonSerializer>(AP + `/api/v4/antibiotics-history/by_name/`, createReqSettings(queryParams, headers)),
        by_physician: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AntibioticsByPhysicianWithMostCommonSerializer>(AP + `/api/v4/antibiotics-history/by_physician/`, createReqSettings(queryParams, headers)),
        by_property: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<AntibioticsTreatmentHistorySerializer[]>>(AP + `/api/v4/antibiotics-history/by_property/`, createReqSettings(queryParams, headers)),
        by_route: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AntibioticsByRouteWithMostCommonSerializer>(AP + `/api/v4/antibiotics-history/by_route/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<AntibioticsTreatmentHistorySerializer[]>>(AP + `/api/v4/antibiotics-history/`, createReqSettings(queryParams, headers)),
        long_treatments: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<AntibioticsTreatmentHistorySerializer[]>>(AP + `/api/v4/antibiotics-history/long_treatments/`, createReqSettings(queryParams, headers)),
    };

    AntibioticsTreatmentHistoryViewSet = APIService.AntibioticsTreatmentHistoryViewSet;

    static AntibioticsViewSet = {
        bydoctor: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/antibiotics/bydoctor/`, createReqSettings(queryParams, headers)),
        bypatient: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/antibiotics/bypatient/`, createReqSettings(queryParams, headers)),
        forinfection: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/antibiotics/forinfection/`, createReqSettings(queryParams, headers)),
        overall: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/antibiotics/overall/`, createReqSettings(queryParams, headers)),
        resistance: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/antibiotics/resistance/`, createReqSettings(queryParams, headers)),
    };

    AntibioticsViewSet = APIService.AntibioticsViewSet;

    static ApplicationLaunchView = {
        get: (queryParams?: ApplicationLaunchViewQueryParams, headers: Headers = null) => APIService.httpClient.get<OAuthRedirectURISerializer>(AP + `/api/oauth/launch`, createReqSettings(queryParams, headers)),
    };

    ApplicationLaunchView = APIService.ApplicationLaunchView;

    static ApplicationViewSet = {
        create: (data: ApplicationSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<ApplicationCreateResponseSerializer>(AP + `/api/oauth/applications/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/oauth/applications/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ApplicationSerializer[]>(AP + `/api/oauth/applications/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ApplicationSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<ApplicationSerializer>(AP + `/api/oauth/applications/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ApplicationSerializer>(AP + `/api/oauth/applications/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ApplicationSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<ApplicationSerializer>(AP + `/api/oauth/applications/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ApplicationViewSet = APIService.ApplicationViewSet;

    static AuthCurrentUser = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<UserAuthSerializer>(AP + `/api/v4/auth/`, createReqSettings(queryParams, headers)),
    };

    AuthCurrentUser = APIService.AuthCurrentUser;

    static AvailableFacilityViewSet = {
        list: (queryParams?: AvailableFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilitySerializer[]>(AP + `/api/v4/available-facilities/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: AvailableFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilitySerializer>(AP + `/api/v4/available-facilities/${pk}/`, createReqSettings(queryParams, headers)),
    };

    AvailableFacilityViewSet = APIService.AvailableFacilityViewSet;

    static AvailableProvidersViewSet = {
        get: (queryParams?: AvailableProvidersViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/available-lab-providers`, createReqSettings(queryParams, headers)),
    };

    AvailableProvidersViewSet = APIService.AvailableProvidersViewSet;

    static AvailableQuestionnairesView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/available-questionnaires`, createReqSettings(queryParams, headers)),
    };

    AvailableQuestionnairesView = APIService.AvailableQuestionnairesView;

    static BedBedOriginViewSet = {
        create: (data: BedBedOriginSerializer, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BedBedOriginSerializer>(AP + `/api/v4/beds-bedorigins/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/beds-bedorigins/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedBedOriginSerializer[]>(AP + `/api/v4/beds-bedorigins/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedBedOriginDetailedSerializer[]>(AP + `/api/v4/beds-bedorigins/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: BedBedOriginSerializer, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<BedBedOriginSerializer>(AP + `/api/v4/beds-bedorigins/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedBedOriginSerializer>(AP + `/api/v4/beds-bedorigins/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedBedOriginSerializer>(AP + `/api/v4/beds-bedorigins/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedBedOriginSerializer>(AP + `/api/v4/beds-bedorigins/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: BedBedOriginSerializer, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<BedBedOriginSerializer>(AP + `/api/v4/beds-bedorigins/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    BedBedOriginViewSet = APIService.BedBedOriginViewSet;

    static BedOriginViewSet = {
        create: (data: BedOriginSerializer, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BedOriginSerializer>(AP + `/api/v4/bedorigins/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/bedorigins/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedOriginSerializer[]>(AP + `/api/v4/bedorigins/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedOriginSerializer[]>(AP + `/api/v4/bedorigins/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: BedOriginSerializer, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<BedOriginSerializer>(AP + `/api/v4/bedorigins/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedOriginSerializer>(AP + `/api/v4/bedorigins/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedOriginSerializer>(AP + `/api/v4/bedorigins/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedOriginSerializer>(AP + `/api/v4/bedorigins/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: BedOriginSerializer, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<BedOriginSerializer>(AP + `/api/v4/bedorigins/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    BedOriginViewSet = APIService.BedOriginViewSet;

    static BedViewSet = {
        create: (data: BedSerializer, queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BedSerializer>(AP + `/api/v4/beds/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/beds/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedSerializer[]>(AP + `/api/v4/beds/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedSerializer[]>(AP + `/api/v4/beds/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: BedSerializer, queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<BedSerializer>(AP + `/api/v4/beds/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedSerializer>(AP + `/api/v4/beds/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedSerializer>(AP + `/api/v4/beds/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BedSerializer>(AP + `/api/v4/beds/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: BedSerializer, queryParams?: BedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<BedSerializer>(AP + `/api/v4/beds/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    BedViewSet = APIService.BedViewSet;

    static BillingCodeCategoryViewSet = {
        create: (data: BillingCodeCategorySerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<BillingCodeCategorySerializer>(AP + `/api/v4/billing-code-categories/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/billing-code-categories/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeCategorySerializer[]>(AP + `/api/v4/billing-code-categories/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: BillingCodeCategorySerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<BillingCodeCategorySerializer>(AP + `/api/v4/billing-code-categories/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeCategorySerializer>(AP + `/api/v4/billing-code-categories/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: BillingCodeCategorySerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<BillingCodeCategorySerializer>(AP + `/api/v4/billing-code-categories/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    BillingCodeCategoryViewSet = APIService.BillingCodeCategoryViewSet;

    static BillingCodePriceViewSet = {
        list: (queryParams?: BillingCodePriceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CptCodePriceSerializer[]>(AP + `/api/v4/neo-billing-code-prices/`, createReqSettings(queryParams, headers)),
    };

    BillingCodePriceViewSet = APIService.BillingCodePriceViewSet;

    static BillingCodeViewSet = {
        create: (data: BillingCodeSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<BillingCodeSerializer>(AP + `/api/v4/billing-codes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/billing-codes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeSerializer[]>(AP + `/api/v4/billing-codes/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeDetailedListSerializer[]>(AP + `/api/v4/billing-codes/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: BillingCodeSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<BillingCodeSerializer>(AP + `/api/v4/billing-codes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeSerializer>(AP + `/api/v4/billing-codes/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeSerializer>(AP + `/api/v4/billing-codes/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<BillingCodeSerializer>(AP + `/api/v4/billing-codes/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: BillingCodeSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<BillingCodeSerializer>(AP + `/api/v4/billing-codes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    BillingCodeViewSet = APIService.BillingCodeViewSet;

    static BillingGroupViewSet = {
        create: (data: BillingGroupSerializer, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BillingGroupSerializer>(AP + `/api/v4/billing-group/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/billing-group/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingGroupWithChildrenSerializer[]>(AP + `/api/v4/billing-group/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: BillingGroupSerializer, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<BillingGroupSerializer>(AP + `/api/v4/billing-group/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingGroupWithChildrenSerializer>(AP + `/api/v4/billing-group/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: BillingGroupSerializer, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<BillingGroupSerializer>(AP + `/api/v4/billing-group/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    BillingGroupViewSet = APIService.BillingGroupViewSet;

    static BillingInfoApprovalViewSet = {
        approve: (data: BatchApproveBillingInfoRequestSerializer, queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BillingInfoRequestSerializer>(AP + `/api/v4/billing-info-approval/approve/`, data, createReqSettings(queryParams, headers)),
        list: (queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingInfoRequestSerializer[]>(AP + `/api/v4/billing-info-approval/`, createReqSettings(queryParams, headers)),
        refuse: (data: BatchApproveBillingInfoRequestSerializer, queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BillingInfoRequestSerializer>(AP + `/api/v4/billing-info-approval/refuse/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingInfoRequestSerializer>(AP + `/api/v4/billing-info-approval/${pk}/`, createReqSettings(queryParams, headers)),
    };

    BillingInfoApprovalViewSet = APIService.BillingInfoApprovalViewSet;

    static BillingInfoNotRequestedPatientsModelViewSet = {
        list: (queryParams?: BillingInfoNotRequestedPatientsModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSimpleSerializer[]>(AP + `/api/v4/not-requested-billing-info/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BillingInfoNotRequestedPatientsModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSimpleSerializer>(AP + `/api/v4/not-requested-billing-info/${pk}/`, createReqSettings(queryParams, headers)),
    };

    BillingInfoNotRequestedPatientsModelViewSet = APIService.BillingInfoNotRequestedPatientsModelViewSet;

    static BillingInfoRequestViewSet = {
        batch_create: (data: BatchCreateBillingInfoRequestSerializer, queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BillingInfoRequestSerializer>(AP + `/api/v4/billing-info/batch_create/`, data, createReqSettings(queryParams, headers)),
        download_billing_info: (data: DownloadBillingInfoRequestSerializer, queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BillingInfoRequestSerializer>(AP + `/api/v4/billing-info/download_billing_info/`, data, createReqSettings(queryParams, headers)),
        list: (queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingInfoRequestSerializer[]>(AP + `/api/v4/billing-info/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingInfoRequestSerializer>(AP + `/api/v4/billing-info/${pk}/`, createReqSettings(queryParams, headers)),
    };

    BillingInfoRequestViewSet = APIService.BillingInfoRequestViewSet;

    static CDSInfobuttonSourceViewSet = {
        create: (data: CDSInfobuttonSourceSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<CDSInfobuttonSourceSerializer>(AP + `/api/v4/cds-infobutton-source/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/cds-infobutton-source/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CDSInfobuttonSourceSerializer[]>(AP + `/api/v4/cds-infobutton-source/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CDSInfobuttonSourceSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<CDSInfobuttonSourceSerializer>(AP + `/api/v4/cds-infobutton-source/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CDSInfobuttonSourceSerializer>(AP + `/api/v4/cds-infobutton-source/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CDSInfobuttonSourceSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<CDSInfobuttonSourceSerializer>(AP + `/api/v4/cds-infobutton-source/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CDSInfobuttonSourceViewSet = APIService.CDSInfobuttonSourceViewSet;

    static CDSInterventionConditionViewSet = {
        create: (data: CDSInterventionConditionSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<CDSInterventionConditionSerializer>(AP + `/api/v4/cds-intervention-condition/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/cds-intervention-condition/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CDSInterventionConditionSerializer[]>(AP + `/api/v4/cds-intervention-condition/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CDSInterventionConditionSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<CDSInterventionConditionSerializer>(AP + `/api/v4/cds-intervention-condition/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CDSInterventionConditionSerializer>(AP + `/api/v4/cds-intervention-condition/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CDSInterventionConditionSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<CDSInterventionConditionSerializer>(AP + `/api/v4/cds-intervention-condition/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CDSInterventionConditionViewSet = APIService.CDSInterventionConditionViewSet;

    static CDSInterventionViewSet = {
        create: (data: CDSInterventionSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<CDSInterventionSerializer>(AP + `/api/v4/cds-intervention/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/cds-intervention/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CDSInterventionSerializer[]>(AP + `/api/v4/cds-intervention/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CDSInterventionSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<CDSInterventionSerializer>(AP + `/api/v4/cds-intervention/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CDSInterventionSerializer>(AP + `/api/v4/cds-intervention/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CDSInterventionSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<CDSInterventionSerializer>(AP + `/api/v4/cds-intervention/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CDSInterventionViewSet = APIService.CDSInterventionViewSet;

    static CalendarEventViewSet = {
        create: (data: CalendarEventEditSerializer, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<CalendarEventSerializer>(AP + `/api/v4/calendar-events/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/calendar-events/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CalendarEventSerializer[]>(AP + `/api/v4/calendar-events/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CalendarEventEditSerializer, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<CalendarEventSerializer>(AP + `/api/v4/calendar-events/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CalendarEventSerializer>(AP + `/api/v4/calendar-events/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CalendarEventEditSerializer, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<CalendarEventSerializer>(AP + `/api/v4/calendar-events/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CalendarEventViewSet = APIService.CalendarEventViewSet;

    static CapabilityStatementView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CapabilityStatementSerializer>(AP + `/api/fhir/metadata`, createReqSettings(queryParams, headers)),
    };

    CapabilityStatementView = APIService.CapabilityStatementView;

    static CaptchaViewSet = {
        list: (queryParams?: CaptchaViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncDetailCaptchaSerializer[]>(AP + `/api/v4/captcha/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: SyncDetailCaptchaSerializer, queryParams?: CaptchaViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<SyncDetailCaptchaSerializer>(AP + `/api/v4/captcha/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: CaptchaViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncDetailCaptchaSerializer>(AP + `/api/v4/captcha/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: SyncDetailCaptchaSerializer, queryParams?: CaptchaViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<SyncDetailCaptchaSerializer>(AP + `/api/v4/captcha/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CaptchaViewSet = APIService.CaptchaViewSet;

    static CensusHistoryRehospitalizationViewSet = {
        create: (data: CensusHistorySerializer, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<CensusHistorySerializer>(AP + `/api/v4/rehospitalization/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/rehospitalization/${pk}/`, createReqSettings(queryParams, headers)),
        length_of_stay: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RehospitalizationSummarySerializer>(AP + `/api/v4/rehospitalization/length_of_stay/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusHistorySerializer[]>(AP + `/api/v4/rehospitalization/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CensusHistorySerializer, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<CensusHistorySerializer>(AP + `/api/v4/rehospitalization/${pk}/`, data, createReqSettings(queryParams, headers)),
        readmissions: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/rehospitalization/readmissions/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusHistorySerializer>(AP + `/api/v4/rehospitalization/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CensusHistorySerializer, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<CensusHistorySerializer>(AP + `/api/v4/rehospitalization/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CensusHistoryRehospitalizationViewSet = APIService.CensusHistoryRehospitalizationViewSet;

    static CensusHistoryViewSet = {
        create: (data: CensusHistoryCreateUpdateSerializer, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<CensusHistoryCreateUpdateSerializer>(AP + `/api/v4/census-history/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/census-history/${pk}/`, createReqSettings(queryParams, headers)),
        history: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusHistoryEditHistorySerializer[]>(AP + `/api/v4/census-history/${pk}/history/`, createReqSettings(queryParams, headers)),
        hospitals: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<HospitalSerializer[]>(AP + `/api/v4/census-history/hospitals/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<CensusHistorySerializer[]>>(AP + `/api/v4/census-history/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<CensusHistorySerializer[]>>(AP + `/api/v4/census-history/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CensusHistoryCreateUpdateSerializer, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<CensusHistoryCreateUpdateSerializer>(AP + `/api/v4/census-history/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusHistorySerializer>(AP + `/api/v4/census-history/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusHistorySerializer>(AP + `/api/v4/census-history/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusHistorySerializer>(AP + `/api/v4/census-history/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CensusHistoryCreateUpdateSerializer, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<CensusHistoryCreateUpdateSerializer>(AP + `/api/v4/census-history/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CensusHistoryViewSet = APIService.CensusHistoryViewSet;

    static CensusOversightView = {
        get: (queryParams?: CensusOversightViewQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/census-oversight`, createReqSettings(queryParams, headers)),
    };

    CensusOversightView = APIService.CensusOversightView;

    static CensusStatusCheckViewSet = {
        get_last_update: (queryParams?: CensusStatusCheckViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusStatusCheckReadOnlySerializer>(AP + `/api/v4/update-census/get_last_update/`, createReqSettings(queryParams, headers)),
        start_update: (queryParams?: CensusStatusCheckViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CensusStatusCheckReadOnlySerializer>(AP + `/api/v4/update-census/start_update/`, createReqSettings(queryParams, headers)),
    };

    CensusStatusCheckViewSet = APIService.CensusStatusCheckViewSet;

    static ChargeAmountSettingViewSet = {
        create: (data: ChargeAmountSettingCreateSerializer, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ChargeAmountSettingCreateSerializer>(AP + `/api/v4/charge-amount-settings/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/charge-amount-settings/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ChargeAmountSettingSerializer[]>(AP + `/api/v4/charge-amount-settings/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ChargeAmountSettingCreateSerializer, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ChargeAmountSettingCreateSerializer>(AP + `/api/v4/charge-amount-settings/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ChargeAmountSettingSerializer>(AP + `/api/v4/charge-amount-settings/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ChargeAmountSettingCreateSerializer, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ChargeAmountSettingCreateSerializer>(AP + `/api/v4/charge-amount-settings/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ChargeAmountSettingViewSet = APIService.ChargeAmountSettingViewSet;

    static CheckVerificationCode = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/new-password`, createReqSettings(queryParams, headers)),
        post: (data: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/new-password`, data, createReqSettings(queryParams, headers)),
    };

    CheckVerificationCode = APIService.CheckVerificationCode;

    static ClaimFileViewSet = {
        create: (data: ClaimFileSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<ClaimFileSerializer>(AP + `/api/v4/claim-files/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/claim-files/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ClaimFileSerializer[]>(AP + `/api/v4/claim-files/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ClaimFileSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<ClaimFileSerializer>(AP + `/api/v4/claim-files/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ClaimFileSerializer>(AP + `/api/v4/claim-files/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ClaimFileSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<ClaimFileSerializer>(AP + `/api/v4/claim-files/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ClaimFileViewSet = APIService.ClaimFileViewSet;

    static ClaimViewSet = {
        batch_create_from_visits: (data: BatchTaskIdSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimSerializer[]>(AP + `/api/v4/claims/batch-create-from-visits/`, data, createReqSettings(queryParams, headers)),
        batch_delete_for_visits: (data: BatchTaskIdSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimSerializer>(AP + `/api/v4/claims/batch-delete-for-visits/`, data, createReqSettings(queryParams, headers)),
        batch_submit: (data: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimValidationSerializer>(AP + `/api/v4/claims/batch-submit/`, data, createReqSettings(queryParams, headers)),
        batch_submit_by_ids: (data: ClaimBatchSubmitSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimValidationSerializer>(AP + `/api/v4/claims/batch-submit-by-ids/`, data, createReqSettings(queryParams, headers)),
        claim_history: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClaimHistoryEventSerializer[]>(AP + `/api/v4/claims/${pk}/history/`, createReqSettings(queryParams, headers)),
        create: (data: ClaimSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimSerializer>(AP + `/api/v4/claims/`, data, createReqSettings(queryParams, headers)),
        create_from_visit: (data: CreateClaimFromVisitTaskSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimSerializer>(AP + `/api/v4/claims/create-from-visit/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/claims/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ClaimListSerializer[]>>(AP + `/api/v4/claims/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ClaimUpdateSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ClaimDiffViewSerializer>(AP + `/api/v4/claims/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClaimDiffViewSerializer>(AP + `/api/v4/claims/${pk}/`, createReqSettings(queryParams, headers)),
        set_visit_to_pending_state: (pk: any, data: ClaimSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TaskStatusHistorySerializer>(AP + `/api/v4/claims/${pk}/set-visit-to-pending-state/`, data, createReqSettings(queryParams, headers)),
        status_count: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<StatusCountSerializer[]>(AP + `/api/v4/claims/status-count/`, createReqSettings(queryParams, headers)),
        submit: (pk: any, data: ClaimSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimValidationSerializer>(AP + `/api/v4/claims/${pk}/submit/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ClaimUpdateSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ClaimDiffViewSerializer>(AP + `/api/v4/claims/${pk}/`, data, createReqSettings(queryParams, headers)),
        visit_claim_preview: (data: GetClaimFromTaskSerializer, queryParams?: ClaimViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClaimDiffViewSerializer>(AP + `/api/v4/claims/visit_claim_preview/`, data, createReqSettings(queryParams, headers)),
    };

    ClaimViewSet = APIService.ClaimViewSet;

    static ClinicalDashboardView = {
        get: (queryParams?: ClinicalDashboardViewQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/dashboard/clinical`, createReqSettings(queryParams, headers)),
    };

    ClinicalDashboardView = APIService.ClinicalDashboardView;

    static ClinicalTestResultViewSet = {
        create: (data: ClinicalTestResultCreateUpdateSerializer, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ClinicalTestResultCreateUpdateSerializer>(AP + `/api/v4/clinical-test-results/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/clinical-test-results/${pk}/`, createReqSettings(queryParams, headers)),
        get_loinc_answers: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LoincAnswerSerializer[]>(AP + `/api/v4/clinical-test-results/get_loinc_answers/`, createReqSettings(queryParams, headers)),
        get_loinc_code_description: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LoincCodeSerializer>(AP + `/api/v4/clinical-test-results/get_loinc_code_description/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClinicalTestResultSerializer[]>(AP + `/api/v4/clinical-test-results/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClinicalTestResultSerializer[]>(AP + `/api/v4/clinical-test-results/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ClinicalTestResultCreateUpdateSerializer, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ClinicalTestResultCreateUpdateSerializer>(AP + `/api/v4/clinical-test-results/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClinicalTestResultSerializer>(AP + `/api/v4/clinical-test-results/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClinicalTestResultSerializer>(AP + `/api/v4/clinical-test-results/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ClinicalTestResultSerializer>(AP + `/api/v4/clinical-test-results/search/`, createReqSettings(queryParams, headers)),
        search_loinc_codes: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LoincCodeSerializer[]>(AP + `/api/v4/clinical-test-results/search_loinc_codes/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ClinicalTestResultCreateUpdateSerializer, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ClinicalTestResultCreateUpdateSerializer>(AP + `/api/v4/clinical-test-results/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ClinicalTestResultViewSet = APIService.ClinicalTestResultViewSet;

    static CoachingViewSet = {
        infections: (queryParams?: CoachingViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/coaching/infections/`, createReqSettings(queryParams, headers)),
    };

    CoachingViewSet = APIService.CoachingViewSet;

    static CodedNoteViewSet = {
        list: (queryParams?: CodedNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CodedNoteReadSerializer[]>(AP + `/api/v4/coded-notes/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: CodedNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CodedNoteReadSerializer>(AP + `/api/v4/coded-notes/${pk}/`, createReqSettings(queryParams, headers)),
    };

    CodedNoteViewSet = APIService.CodedNoteViewSet;

    static ContactPointViewSet = {
        create: (data: FHIRContactPointCreateUpdateModelSerializer, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FHIRContactPointCreateUpdateModelSerializer>(AP + `/api/v4/contact-points/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/contact-points/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRContactPointModelSerializer[]>(AP + `/api/v4/contact-points/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRContactPointModelSerializer[]>(AP + `/api/v4/contact-points/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FHIRContactPointCreateUpdateModelSerializer, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FHIRContactPointCreateUpdateModelSerializer>(AP + `/api/v4/contact-points/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRContactPointModelSerializer>(AP + `/api/v4/contact-points/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRContactPointModelSerializer>(AP + `/api/v4/contact-points/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRContactPointModelSerializer>(AP + `/api/v4/contact-points/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FHIRContactPointCreateUpdateModelSerializer, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FHIRContactPointCreateUpdateModelSerializer>(AP + `/api/v4/contact-points/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ContactPointViewSet = APIService.ContactPointViewSet;

    static CptCodeDetailViewSet = {
        list: (queryParams?: CptCodeDetailViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CptCodeSerializer[]>(AP + `/api/v4/neo-cpt-code/`, createReqSettings(queryParams, headers)),
    };

    CptCodeDetailViewSet = APIService.CptCodeDetailViewSet;

    static CreateUserFromPatientView = {
        post: (data: CreateUserFromPatientSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<UserSimpleSerializer>(AP + `/api/v4/create-user-from-patient`, data, createReqSettings(queryParams, headers)),
    };

    CreateUserFromPatientView = APIService.CreateUserFromPatientView;

    static CriticalValueView = {
        list: (queryParams?: CriticalValueViewQueryParams, headers: Headers = null) => APIService.httpClient.get<CriticalVitalSerializer[]>(AP + `/api/v4/criticals/`, createReqSettings(queryParams, headers)),
    };

    CriticalValueView = APIService.CriticalValueView;

    static CustomerViewSet = {
        create: (data: CustomerCreateUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<CustomerSerializer>(AP + `/api/v4/customers/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/customers/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CustomerSerializer[]>(AP + `/api/v4/customers/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CustomerSerializer[]>(AP + `/api/v4/customers/list-detail/`, createReqSettings(queryParams, headers)),
        list_simple: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CustomerSimpleSerializer[]>(AP + `/api/v4/customers/list_simple/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: CustomerSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<CustomerSerializer>(AP + `/api/v4/customers/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CustomerSerializer>(AP + `/api/v4/customers/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CustomerSerializer>(AP + `/api/v4/customers/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<CustomerSerializer>(AP + `/api/v4/customers/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: CustomerCreateUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<CustomerSerializer>(AP + `/api/v4/customers/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    CustomerViewSet = APIService.CustomerViewSet;

    static DailyVisitReportViewSet = {
        audit_for_days: (queryParams?: DailyVisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PhysiciansForAuditSerializer>(AP + `/api/v4/daily-visit-reports/audit_for_days/`, createReqSettings(queryParams, headers)),
        audit_for_days_detail: (queryParams?: DailyVisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PhysiciansForAuditDetailSerializer>(AP + `/api/v4/daily-visit-reports/audit_for_days_detail/`, createReqSettings(queryParams, headers)),
    };

    DailyVisitReportViewSet = APIService.DailyVisitReportViewSet;

    static DepartmentViewSet = {
        create: (data: DepartmentSerializer, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DepartmentSerializer>(AP + `/api/v4/departments/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/departments/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DepartmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DepartmentSerializer[]>>(AP + `/api/v4/departments/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DepartmentSerializer, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DepartmentSerializer>(AP + `/api/v4/departments/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DepartmentSerializer>(AP + `/api/v4/departments/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DepartmentSerializer, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DepartmentSerializer>(AP + `/api/v4/departments/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DepartmentViewSet = APIService.DepartmentViewSet;

    static DiagnosisViewSet = {
        create: (data: DiagnosisSerializer, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DiagnosisSerializer>(AP + `/api/v4/diagnoses/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/diagnoses/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DiagnosisSerializer[]>(AP + `/api/v4/diagnoses/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DiagnosisDetailedSerializer[]>(AP + `/api/v4/diagnoses/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DiagnosisSerializer, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DiagnosisSerializer>(AP + `/api/v4/diagnoses/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DiagnosisSerializer>(AP + `/api/v4/diagnoses/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DiagnosisDetailedSerializer>(AP + `/api/v4/diagnoses/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DiagnosisSerializer>(AP + `/api/v4/diagnoses/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DiagnosisSerializer, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DiagnosisSerializer>(AP + `/api/v4/diagnoses/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DiagnosisViewSet = APIService.DiagnosisViewSet;

    static DocumentViewSet = {
        create: (data: DocumentSerializer, queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DocumentSerializer>(AP + `/api/v4/documents/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/documents/${pk}/`, createReqSettings(queryParams, headers)),
        download: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DocumentSerializer>(AP + `/api/v4/documents/${pk}/download/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DocumentSerializer[]>>(AP + `/api/v4/documents/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DocumentSerializer, queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DocumentSerializer>(AP + `/api/v4/documents/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DocumentSerializer>(AP + `/api/v4/documents/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DocumentSerializer, queryParams?: DocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DocumentSerializer>(AP + `/api/v4/documents/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DocumentViewSet = APIService.DocumentViewSet;

    static DrugCategoryViewSet = {
        create: (data: DrugCategorySerializer, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DrugCategorySerializer>(AP + `/api/v4/drug-categories/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/drug-categories/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugCategorySerializer[]>>(AP + `/api/v4/drug-categories/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DrugCategorySerializer, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DrugCategorySerializer>(AP + `/api/v4/drug-categories/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugCategorySerializer>(AP + `/api/v4/drug-categories/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DrugCategorySerializer, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DrugCategorySerializer>(AP + `/api/v4/drug-categories/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DrugCategoryViewSet = APIService.DrugCategoryViewSet;

    static DrugNameViewSet = {
        create: (data: DrugNameSimpleSerializer, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DrugNameSimpleSerializer>(AP + `/api/v4/drugs/names/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/drugs/names/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DrugNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugNameSimpleSerializer[]>>(AP + `/api/v4/drugs/names/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DrugNameSimpleSerializer, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DrugNameSimpleSerializer>(AP + `/api/v4/drugs/names/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugNameSimpleSerializer>(AP + `/api/v4/drugs/names/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DrugNameSimpleSerializer, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DrugNameSimpleSerializer>(AP + `/api/v4/drugs/names/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DrugNameViewSet = APIService.DrugNameViewSet;

    static DrugViewSet = {
        list: (queryParams?: DrugViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugSerializer[]>>(AP + `/api/v4/drugs/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugSerializer>(AP + `/api/v4/drugs/${pk}/`, createReqSettings(queryParams, headers)),
    };

    DrugViewSet = APIService.DrugViewSet;

    static DrugbankProductConceptRxCuiViewSet = {
        create: (data: DrugbankProductConceptRxCuiSerializer, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DrugbankProductConceptRxCuiSerializer>(AP + `/api/v4/drug-product-concept-rxcuis/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugbankProductConceptRxCuiSerializer[]>>(AP + `/api/v4/drug-product-concept-rxcuis/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DrugbankProductConceptRxCuiSerializer, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DrugbankProductConceptRxCuiSerializer>(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugbankProductConceptRxCuiSerializer>(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DrugbankProductConceptRxCuiSerializer, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DrugbankProductConceptRxCuiSerializer>(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DrugbankProductConceptRxCuiViewSet = APIService.DrugbankProductConceptRxCuiViewSet;

    static DrugbankProductConceptViewSet = {
        create: (data: DrugbankProductConceptSimpleSerializer, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DrugbankProductConceptSimpleSerializer>(AP + `/api/v4/drug-product-concepts/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/drug-product-concepts/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugbankProductConceptSimpleSerializer[]>>(AP + `/api/v4/drug-product-concepts/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DrugbankProductConceptSimpleSerializer, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DrugbankProductConceptSimpleSerializer>(AP + `/api/v4/drug-product-concepts/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugbankProductConceptSimpleSerializer>(AP + `/api/v4/drug-product-concepts/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DrugbankProductConceptSimpleSerializer, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DrugbankProductConceptSimpleSerializer>(AP + `/api/v4/drug-product-concepts/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DrugbankProductConceptViewSet = APIService.DrugbankProductConceptViewSet;

    static DrugbankProductViewSet = {
        create: (data: DrugbankProductSimpleSerializer, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DrugbankProductSimpleSerializer>(AP + `/api/v4/drug-products/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/drug-products/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugbankProductSimpleSerializer[]>>(AP + `/api/v4/drug-products/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DrugbankProductSimpleSerializer, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DrugbankProductSimpleSerializer>(AP + `/api/v4/drug-products/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugbankProductSimpleSerializer>(AP + `/api/v4/drug-products/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DrugbankProductSimpleSerializer, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DrugbankProductSimpleSerializer>(AP + `/api/v4/drug-products/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DrugbankProductViewSet = APIService.DrugbankProductViewSet;

    static DrugbankValueSetViewSet = {
        list: (queryParams?: DrugbankValueSetViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugbankProductWithRxCuiSerializer[]>>(AP + `/api/v4/drugbank-product-valuesets/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugbankValueSetViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugbankProductWithRxCuiSerializer>(AP + `/api/v4/drugbank-product-valuesets/${pk}/`, createReqSettings(queryParams, headers)),
    };

    DrugbankValueSetViewSet = APIService.DrugbankValueSetViewSet;

    static DrugsCategoryViewSet = {
        create: (data: DrugsCategorySerializer, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DrugsCategorySerializer>(AP + `/api/v4/drugs-category/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/drugs-category/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DrugsCategorySerializer[]>>(AP + `/api/v4/drugs-category/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DrugsCategorySerializer, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DrugsCategorySerializer>(AP + `/api/v4/drugs-category/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DrugsCategorySerializer>(AP + `/api/v4/drugs-category/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DrugsCategorySerializer, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DrugsCategorySerializer>(AP + `/api/v4/drugs-category/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    DrugsCategoryViewSet = APIService.DrugsCategoryViewSet;

    static EMRImportViewSet = {
        list_last_logs: (queryParams?: EMRImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRImportViewSetLogSerializer[]>(AP + `/api/v4/emr-import/list_last_logs/`, createReqSettings(queryParams, headers)),
        list_logs_for_task: (queryParams?: EMRImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRImportViewSetTaskLogsSerializer[]>(AP + `/api/v4/emr-import/list_logs_for_task/`, createReqSettings(queryParams, headers)),
    };

    EMRImportViewSet = APIService.EMRImportViewSet;

    static EMRProfessionalViewSet = {
        create: (data: EMRProfessionalCreateSerializer, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EMRProfessionalCreateSerializer>(AP + `/api/v4/emr_professionals/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/emr_professionals/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<EMRProfessionalSerializer[]>>(AP + `/api/v4/emr_professionals/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: EMRProfessionalUpdateSerializer, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<EMRProfessionalUpdateSerializer>(AP + `/api/v4/emr_professionals/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRProfessionalSerializer>(AP + `/api/v4/emr_professionals/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: EMRProfessionalSerializer, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<EMRProfessionalSerializer>(AP + `/api/v4/emr_professionals/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    EMRProfessionalViewSet = APIService.EMRProfessionalViewSet;

    static EMRUploadViewSet = {
        batch_update: (data: EMRUploadBatchUpdateSerializer, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EMRUploadSerializer[]>(AP + `/api/v4/emr_uploads/batch_update/`, data, createReqSettings(queryParams, headers)),
        create: (data: EMRUploadSerializer, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EMRUploadSerializer>(AP + `/api/v4/emr_uploads/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/emr_uploads/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<EMRUploadSerializer[]>>(AP + `/api/v4/emr_uploads/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<EMRUploadSerializer[]>>(AP + `/api/v4/emr_uploads/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: EMRUploadSerializer, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<EMRUploadSerializer>(AP + `/api/v4/emr_uploads/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRUploadSerializer>(AP + `/api/v4/emr_uploads/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRUploadViewSetEMRUploadDetailSerializer>(AP + `/api/v4/emr_uploads/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRUploadSerializer>(AP + `/api/v4/emr_uploads/search/`, createReqSettings(queryParams, headers)),
        summary: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityUploadReportsSerializer[]>(AP + `/api/v4/emr_uploads/summary/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: EMRUploadSerializer, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<EMRUploadSerializer>(AP + `/api/v4/emr_uploads/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    EMRUploadViewSet = APIService.EMRUploadViewSet;

    static ERXOriginViewSet = {
        create: (data: ERXOriginSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<ERXOriginSerializer>(AP + `/api/v4/erx-origins/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/erx-origins/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ERXOriginSerializer[]>(AP + `/api/v4/erx-origins/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ERXOriginSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<ERXOriginSerializer>(AP + `/api/v4/erx-origins/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ERXOriginSerializer>(AP + `/api/v4/erx-origins/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ERXOriginSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<ERXOriginSerializer>(AP + `/api/v4/erx-origins/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ERXOriginViewSet = APIService.ERXOriginViewSet;

    static EligibilityRequestViewSet = {
        check_eligibility: (data: CheckEligibilitySerializer, queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EligibilityRequestDetailSerializer>(AP + `/api/v4/eligibility-requests/check-eligibility/`, data, createReqSettings(queryParams, headers)),
        check_eligibility_free_text: (data: CheckEligibilityFreeTextSerializer, queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EligibilityRequestDetailSerializer>(AP + `/api/v4/eligibility-requests/check-eligibility-free-text/`, data, createReqSettings(queryParams, headers)),
        last_eligibility_request: (data: CheckEligibilitySerializer, queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EligibilityRequestDetailSerializer>(AP + `/api/v4/eligibility-requests/last-eligibility-request/`, data, createReqSettings(queryParams, headers)),
        list: (queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EligibilityRequestSerializer[]>(AP + `/api/v4/eligibility-requests/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EligibilityRequestDetailSerializer>(AP + `/api/v4/eligibility-requests/${pk}/`, createReqSettings(queryParams, headers)),
    };

    EligibilityRequestViewSet = APIService.EligibilityRequestViewSet;

    static EncounterView = {
        get: (queryParams?: EncounterViewQueryParams, headers: Headers = null) => APIService.httpClient.get<EncounterContextResponseSerializer[]>(AP + `/api/v4/encounters`, createReqSettings(queryParams, headers)),
    };

    EncounterView = APIService.EncounterView;

    static EntryProposalViewSet = {
        create: (data: EntryProposalSerializer, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EntryProposalSerializer>(AP + `/api/v4/entry-proposals/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/entry-proposals/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EntryProposalSerializer[]>(AP + `/api/v4/entry-proposals/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: EntryProposalSerializer, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<EntryProposalSerializer>(AP + `/api/v4/entry-proposals/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EntryProposalSerializer>(AP + `/api/v4/entry-proposals/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: EntryProposalSerializer, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<EntryProposalSerializer>(AP + `/api/v4/entry-proposals/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    EntryProposalViewSet = APIService.EntryProposalViewSet;

    static EventViewSet = {
        create: (data: EventSerializer, queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<EventSerializer>(AP + `/api/v4/events/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/events/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EventDetailSerializer[]>(AP + `/api/v4/events/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EventDetailSerializer[]>(AP + `/api/v4/events/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: EventSerializer, queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<EventSerializer>(AP + `/api/v4/events/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EventDetailSerializer>(AP + `/api/v4/events/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EventDetailSerializer>(AP + `/api/v4/events/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EventDetailSerializer>(AP + `/api/v4/events/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: EventSerializer, queryParams?: EventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<EventSerializer>(AP + `/api/v4/events/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    EventViewSet = APIService.EventViewSet;

    static FHIRDeviceParserView = {
        get: (queryParams?: FHIRDeviceParserViewQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRDeviceParserSerializer>(AP + `/api/v4/fhir-device-parser`, createReqSettings(queryParams, headers)),
    };

    FHIRDeviceParserView = APIService.FHIRDeviceParserView;

    static FHIRDeviceViewSet = {
        create: (data: FHIRDeviceSerializer, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FHIRDeviceSerializer>(AP + `/api/v4/fhir-device/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/fhir-device/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRDeviceSerializer[]>(AP + `/api/v4/fhir-device/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FHIRDeviceSerializer, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FHIRDeviceSerializer>(AP + `/api/v4/fhir-device/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRDeviceSerializer>(AP + `/api/v4/fhir-device/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FHIRDeviceSerializer, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FHIRDeviceSerializer>(AP + `/api/v4/fhir-device/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FHIRDeviceViewSet = APIService.FHIRDeviceViewSet;

    static FHIRExportStatusPollingView = {
        delete: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<any>(AP + `/api/poll-export-status`, createReqSettings(queryParams, headers)),
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/poll-export-status`, createReqSettings(queryParams, headers)),
    };

    FHIRExportStatusPollingView = APIService.FHIRExportStatusPollingView;

    static FHIRFileDownloadView = {
        get: (export_uuid: any, file_name: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/export/download/${export_uuid}/${file_name}`, createReqSettings(queryParams, headers)),
    };

    FHIRFileDownloadView = APIService.FHIRFileDownloadView;

    static FHIRQuestionnaireResponseViewSet = {
        create: (data: FHIRQuestionnaireResponseSerializer, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FHIRQuestionnaireResponseSerializer>(AP + `/api/v4/fhir-questionnaire-response/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRQuestionnaireResponseSerializer[]>(AP + `/api/v4/fhir-questionnaire-response/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FHIRQuestionnaireResponseSerializer, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FHIRQuestionnaireResponseSerializer>(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRQuestionnaireResponseSerializer>(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FHIRQuestionnaireResponseSerializer, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FHIRQuestionnaireResponseSerializer>(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FHIRQuestionnaireResponseViewSet = APIService.FHIRQuestionnaireResponseViewSet;

    static FHIRQuestionnaireViewSet = {
        create: (data: FHIRQuestionnaireSerializer, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FHIRQuestionnaireSerializer>(AP + `/api/v4/fhir-questionnaire/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/fhir-questionnaire/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRQuestionnaireSerializer[]>(AP + `/api/v4/fhir-questionnaire/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FHIRQuestionnaireSerializer, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FHIRQuestionnaireSerializer>(AP + `/api/v4/fhir-questionnaire/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRQuestionnaireSerializer>(AP + `/api/v4/fhir-questionnaire/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FHIRQuestionnaireSerializer, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FHIRQuestionnaireSerializer>(AP + `/api/v4/fhir-questionnaire/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FHIRQuestionnaireViewSet = APIService.FHIRQuestionnaireViewSet;

    static FacilityAccessPermissionTypeView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<FacilityAccessPermissionTypeSerializer[]>(AP + `/api/v4/facility-access-permission-types`, createReqSettings(queryParams, headers)),
    };

    FacilityAccessPermissionTypeView = APIService.FacilityAccessPermissionTypeView;

    static FacilityActivePatientStatisticsViewSet = {
        list: (queryParams?: FacilityActivePatientStatisticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityActivePatientStatisticsSerializer[]>(AP + `/api/v4/facility-active-patient-statistics/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FacilityActivePatientStatisticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityActivePatientStatisticsSerializer>(AP + `/api/v4/facility-active-patient-statistics/${pk}/`, createReqSettings(queryParams, headers)),
    };

    FacilityActivePatientStatisticsViewSet = APIService.FacilityActivePatientStatisticsViewSet;

    static FacilityCensusDashboardView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/dashboard/facility-census`, createReqSettings(queryParams, headers)),
    };

    FacilityCensusDashboardView = APIService.FacilityCensusDashboardView;

    static FacilityEMRImportSettingsViewSet = {
        partial_update: (pk: any, data: FacilityEmrImportSettingUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<FacilityEmrImportSettingUpdateSerializer>(AP + `/api/v4/facility-emr-import-settings/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<FacilityEmrImportSettingUpdateSerializer>(AP + `/api/v4/facility-emr-import-settings/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FacilityEmrImportSettingUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<FacilityEmrImportSettingUpdateSerializer>(AP + `/api/v4/facility-emr-import-settings/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FacilityEMRImportSettingsViewSet = APIService.FacilityEMRImportSettingsViewSet;

    static FacilityEMRImportViewSet = {
        partial_update: (pk: any, data: FacilityImportSettingsSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<FacilityImportSettingsSerializer>(AP + `/api/v4/facility_imports/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<FacilityImportSettingsSerializer>(AP + `/api/v4/facility_imports/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FacilityEMRImportSettingsWriteSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<FacilityImportSettingsSerializer>(AP + `/api/v4/facility_imports/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FacilityEMRImportViewSet = APIService.FacilityEMRImportViewSet;

    static FacilityLabImportAccountViewSet = {
        create: (data: FacilityLabImportAccountFaxOnlySerializer, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FacilityLabImportAccountFaxOnlySerializer>(AP + `/api/v4/facilities/lab-import-accounts/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityLabImportAccountFaxOnlySerializer[]>(AP + `/api/v4/facilities/lab-import-accounts/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FacilityLabImportAccountFaxOnlySerializer, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FacilityLabImportAccountFaxOnlySerializer>(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityLabImportAccountFaxOnlySerializer>(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_by_facility_id: (queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityLabImportAccountFaxOnlySerializer>(AP + `/api/v4/facilities/lab-import-accounts/by-facility-id/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FacilityLabImportAccountFaxOnlySerializer, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FacilityLabImportAccountFaxOnlySerializer>(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, data, createReqSettings(queryParams, headers)),
        update_fax_only_critical: (pk: any, data: FacilityLabImportAccountFaxOnlySerializer, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FacilityLabImportAccountFaxOnlySerializer>(AP + `/api/v4/facilities/lab-import-accounts/${pk}/update-fax-only-critical/`, data, createReqSettings(queryParams, headers)),
    };

    FacilityLabImportAccountViewSet = APIService.FacilityLabImportAccountViewSet;

    static FacilityOriginViewSet = {
        create: (data: FacilityOriginSerializer, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FacilityOriginSerializer>(AP + `/api/v4/facility-origins/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/facility-origins/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityOriginSerializer[]>(AP + `/api/v4/facility-origins/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FacilityOriginSerializer, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FacilityOriginSerializer>(AP + `/api/v4/facility-origins/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityOriginSerializer>(AP + `/api/v4/facility-origins/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FacilityOriginSerializer, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FacilityOriginSerializer>(AP + `/api/v4/facility-origins/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FacilityOriginViewSet = APIService.FacilityOriginViewSet;

    static FacilityViewSet = {
        activate_fax: (pk: any, data: ActivateFacilityFaxSerializer, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/${pk}/activate_fax/`, data, createReqSettings(queryParams, headers)),
        create: (data: FacilityWithImportAccountsSerializer, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/facilities/${pk}/`, createReqSettings(queryParams, headers)),
        download_census_xls: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/${pk}/download_census_xls/`, createReqSettings(queryParams, headers)),
        download_interact: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/${pk}/download_interact/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityWithImportAccountsSerializer[]>(AP + `/api/v4/facilities/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FacilityWithImportAccountsSerializer, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FacilityWithImportAccountsSerializer, queryParams?: FacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FacilityWithImportAccountsSerializer>(AP + `/api/v4/facilities/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FacilityViewSet = APIService.FacilityViewSet;

    static FaxStatusHistoryViewSet = {
        list: (queryParams?: FaxStatusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxStatusSerializer[]>(AP + `/api/v4/fax-status-history/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FaxStatusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxStatusSerializer>(AP + `/api/v4/fax-status-history/${pk}/`, createReqSettings(queryParams, headers)),
    };

    FaxStatusHistoryViewSet = APIService.FaxStatusHistoryViewSet;

    static FaxViewSet = {
        create: (data: FaxSerializer, queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FaxSerializer>(AP + `/api/v4/fax/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/fax/${pk}/`, createReqSettings(queryParams, headers)),
        get_fax_number_by: (queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxNumberSerializer[]>(AP + `/api/v4/fax/by/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxSerializer[]>(AP + `/api/v4/fax/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxSerializer[]>(AP + `/api/v4/fax/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FaxSerializer, queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FaxSerializer>(AP + `/api/v4/fax/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxSerializer>(AP + `/api/v4/fax/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxSerializer>(AP + `/api/v4/fax/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FaxSerializer>(AP + `/api/v4/fax/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FaxSerializer, queryParams?: FaxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FaxSerializer>(AP + `/api/v4/fax/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FaxViewSet = APIService.FaxViewSet;

    static FileView = {
        get: (file_id: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/file-download/${file_id}/`, createReqSettings(queryParams, headers)),
    };

    FileView = APIService.FileView;

    static FloorPlanViewSet = {
        create: (data: FloorPlanSerializer, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FloorPlanSerializer>(AP + `/api/v4/floor-plans/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/floor-plans/${pk}/`, createReqSettings(queryParams, headers)),
        download: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FloorPlanSerializer>(AP + `/api/v4/floor-plans/${pk}/download/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FloorPlanSerializer[]>(AP + `/api/v4/floor-plans/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FloorPlanSerializer, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FloorPlanSerializer>(AP + `/api/v4/floor-plans/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FloorPlanSerializer>(AP + `/api/v4/floor-plans/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FloorPlanSerializer, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FloorPlanSerializer>(AP + `/api/v4/floor-plans/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    FloorPlanViewSet = APIService.FloorPlanViewSet;

    static FollowedPatientViewSet = {
        list: (queryParams?: FollowedPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientCensusSerializer[]>>(AP + `/api/v4/followed-patients/`, createReqSettings(queryParams, headers)),
    };

    FollowedPatientViewSet = APIService.FollowedPatientViewSet;

    static ForgotPasswordView = {
        post: (data: EmailSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<EmailSerializer>(AP + `/api/v4/forgot-password`, data, createReqSettings(queryParams, headers)),
    };

    ForgotPasswordView = APIService.ForgotPasswordView;

    static FrontendParametersViewSet = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/frontend-parameters`, createReqSettings(queryParams, headers)),
    };

    FrontendParametersViewSet = APIService.FrontendParametersViewSet;

    static GeneratePassCodeView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<UserPasscodeSerializer>(AP + `/api/v4/auth/generate-passcode`, createReqSettings(queryParams, headers)),
    };

    GeneratePassCodeView = APIService.GeneratePassCodeView;

    static GetLanguageFromCode = {
        get: (queryParams?: GetLanguageFromCodeQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/language-valueset/get-language-from-code`, createReqSettings(queryParams, headers)),
    };

    GetLanguageFromCode = APIService.GetLanguageFromCode;

    static GroupViewSet = {
        create: (data: GroupSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<GroupSerializer>(AP + `/api/v4/groups/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/groups/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<GroupSerializer[]>(AP + `/api/v4/groups/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: GroupSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<GroupSerializer>(AP + `/api/v4/groups/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<GroupSerializer>(AP + `/api/v4/groups/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: GroupSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<GroupSerializer>(AP + `/api/v4/groups/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    GroupViewSet = APIService.GroupViewSet;

    static HL7DashboardViewSet = {
        create: (data: HL7DashboardSerializer, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<HL7DashboardSerializer>(AP + `/api/v4/hl7_dashboard/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/hl7_dashboard/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<HL7DashboardSerializer[]>>(AP + `/api/v4/hl7_dashboard/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<HL7DashboardSerializer[]>>(AP + `/api/v4/hl7_dashboard/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: HL7DashboardSerializer, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<HL7DashboardSerializer>(AP + `/api/v4/hl7_dashboard/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<HL7DashboardSerializer>(AP + `/api/v4/hl7_dashboard/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<HL7DashboardSerializer>(AP + `/api/v4/hl7_dashboard/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<HL7DashboardSerializer>(AP + `/api/v4/hl7_dashboard/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: HL7DashboardSerializer, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<HL7DashboardSerializer>(AP + `/api/v4/hl7_dashboard/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    HL7DashboardViewSet = APIService.HL7DashboardViewSet;

    static HL7MessageViewSet = {
        batch_update: (data: HL7BatchUpdateSerializer, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<HL7MessageListSerializer[]>(AP + `/api/v4/hl7_messages/batch_update/`, data, createReqSettings(queryParams, headers)),
        create: (data: Hl7MessageSerializer, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<Hl7MessageSerializer>(AP + `/api/v4/hl7_messages/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/hl7_messages/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<Hl7MessageSerializer[]>>(AP + `/api/v4/hl7_messages/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<HL7MessageListSerializer[]>>(AP + `/api/v4/hl7_messages/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: Hl7MessageSerializer, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<Hl7MessageSerializer>(AP + `/api/v4/hl7_messages/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Hl7MessageSerializer>(AP + `/api/v4/hl7_messages/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Hl7MessageSerializer>(AP + `/api/v4/hl7_messages/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Hl7MessageSerializer>(AP + `/api/v4/hl7_messages/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: Hl7MessageSerializer, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<Hl7MessageSerializer>(AP + `/api/v4/hl7_messages/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    HL7MessageViewSet = APIService.HL7MessageViewSet;

    static HL7UnmatchedViewSet = {
        create: (data: UnmatchedPatientDetailSerializer, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<UnmatchedPatientDetailSerializer>(AP + `/api/v4/hl7_unmatched/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/hl7_unmatched/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UnmatchedPatientDetailSerializer[]>>(AP + `/api/v4/hl7_unmatched/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UnmatchedPatientDetailSerializer[]>>(AP + `/api/v4/hl7_unmatched/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: UnmatchedPatientDetailSerializer, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<UnmatchedPatientDetailSerializer>(AP + `/api/v4/hl7_unmatched/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnmatchedPatientDetailSerializer>(AP + `/api/v4/hl7_unmatched/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnmatchedPatientDetailSerializer>(AP + `/api/v4/hl7_unmatched/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnmatchedPatientDetailSerializer>(AP + `/api/v4/hl7_unmatched/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UnmatchedPatientDetailSerializer, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<UnmatchedPatientDetailSerializer>(AP + `/api/v4/hl7_unmatched/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    HL7UnmatchedViewSet = APIService.HL7UnmatchedViewSet;

    static ICDCodeAllLatestViewSet = {
        create: (data: ICDCodeSerializer, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ICDCodeSerializer>(AP + `/api/v4/icd-codes-all/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/icd-codes-all/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer[]>(AP + `/api/v4/icd-codes-all/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ICDCodeSerializer, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ICDCodeSerializer>(AP + `/api/v4/icd-codes-all/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer>(AP + `/api/v4/icd-codes-all/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ICDCodeSerializer, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ICDCodeSerializer>(AP + `/api/v4/icd-codes-all/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ICDCodeAllLatestViewSet = APIService.ICDCodeAllLatestViewSet;

    static ICDCodeGroupViewSet = {
        create: (data: ICDCodeGroupSerializer, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ICDCodeGroupSerializer>(AP + `/api/v4/icd-code-groups/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/icd-code-groups/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ICDCodeGroupSerializer[]>>(AP + `/api/v4/icd-code-groups/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ICDCodeGroupSerializer[]>>(AP + `/api/v4/icd-code-groups/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ICDCodeGroupSerializer, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ICDCodeGroupSerializer>(AP + `/api/v4/icd-code-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeGroupSerializer>(AP + `/api/v4/icd-code-groups/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeGroupSerializer>(AP + `/api/v4/icd-code-groups/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeGroupSerializer>(AP + `/api/v4/icd-code-groups/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ICDCodeGroupSerializer, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ICDCodeGroupSerializer>(AP + `/api/v4/icd-code-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ICDCodeGroupViewSet = APIService.ICDCodeGroupViewSet;

    static ICDCodeIndicationsViewSet = {
        create: (data: ICDCodeIndicationsSerializer, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ICDCodeIndicationsSerializer>(AP + `/api/v4/icd-code-indications/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/icd-code-indications/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ICDCodeIndicationsSerializer[]>>(AP + `/api/v4/icd-code-indications/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ICDCodeIndicationsSerializer, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ICDCodeIndicationsSerializer>(AP + `/api/v4/icd-code-indications/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeIndicationsSerializer>(AP + `/api/v4/icd-code-indications/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ICDCodeIndicationsSerializer, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ICDCodeIndicationsSerializer>(AP + `/api/v4/icd-code-indications/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ICDCodeIndicationsViewSet = APIService.ICDCodeIndicationsViewSet;

    static ICDCodeMedicalSpecialtyViewSet = {
        list: (queryParams?: ICDCodeMedicalSpecialtyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeMedicalSpecialitySerializer[]>(AP + `/api/v4/icd-code-medical-specialties/`, createReqSettings(queryParams, headers)),
    };

    ICDCodeMedicalSpecialtyViewSet = APIService.ICDCodeMedicalSpecialtyViewSet;

    static ICDCodeSuggestionViewSet = {
        get: (queryParams?: ICDCodeSuggestionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer[]>(AP + `/api/v4/icd-code-suggestion`, createReqSettings(queryParams, headers)),
    };

    ICDCodeSuggestionViewSet = APIService.ICDCodeSuggestionViewSet;

    static ICDCodeViewSet = {
        create: (data: ICDCodeSerializer, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ICDCodeSerializer>(AP + `/api/v4/icd-codes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/icd-codes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ICDCodeSerializer[]>>(AP + `/api/v4/icd-codes/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ICDCodeSerializer[]>>(AP + `/api/v4/icd-codes/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ICDCodeSerializer, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ICDCodeSerializer>(AP + `/api/v4/icd-codes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer>(AP + `/api/v4/icd-codes/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer>(AP + `/api/v4/icd-codes/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer>(AP + `/api/v4/icd-codes/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ICDCodeSerializer, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ICDCodeSerializer>(AP + `/api/v4/icd-codes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ICDCodeViewSet = APIService.ICDCodeViewSet;

    static ICDValueSetViewSet = {
        list: (queryParams?: ICDValueSetViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer[]>(AP + `/api/v4/icd-valuesets/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ICDValueSetViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ICDCodeSerializer>(AP + `/api/v4/icd-valuesets/${pk}/`, createReqSettings(queryParams, headers)),
    };

    ICDValueSetViewSet = APIService.ICDValueSetViewSet;

    static ImagingResultViewSet = {
        create: (data: ImagingResultCreateUpdateSerializer, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ImagingResultCreateUpdateSerializer>(AP + `/api/v4/imaging-results/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/imaging-results/${pk}/`, createReqSettings(queryParams, headers)),
        get_loinc_answers: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LoincAnswerSerializer[]>(AP + `/api/v4/imaging-results/get_loinc_answers/`, createReqSettings(queryParams, headers)),
        get_loinc_code_description: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LoincCodeSerializer>(AP + `/api/v4/imaging-results/get_loinc_code_description/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImagingResultSerializer[]>(AP + `/api/v4/imaging-results/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImagingResultSerializer[]>(AP + `/api/v4/imaging-results/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ImagingResultCreateUpdateSerializer, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ImagingResultCreateUpdateSerializer>(AP + `/api/v4/imaging-results/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImagingResultSerializer>(AP + `/api/v4/imaging-results/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImagingResultSerializer>(AP + `/api/v4/imaging-results/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImagingResultSerializer>(AP + `/api/v4/imaging-results/search/`, createReqSettings(queryParams, headers)),
        search_loinc_codes: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LoincCodeSerializer[]>(AP + `/api/v4/imaging-results/search_loinc_codes/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ImagingResultCreateUpdateSerializer, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ImagingResultCreateUpdateSerializer>(AP + `/api/v4/imaging-results/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ImagingResultViewSet = APIService.ImagingResultViewSet;

    static ImmunizationViewSet = {
        create: (data: ImmunizationCreateUpdateSerializer, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ImmunizationCreateUpdateSerializer>(AP + `/api/v4/immunizations/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/immunizations/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ImmunizationSerializer[]>>(AP + `/api/v4/immunizations/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ImmunizationSerializer[]>>(AP + `/api/v4/immunizations/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ImmunizationCreateUpdateSerializer, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ImmunizationCreateUpdateSerializer>(AP + `/api/v4/immunizations/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImmunizationSerializer>(AP + `/api/v4/immunizations/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImmunizationSerializer>(AP + `/api/v4/immunizations/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImmunizationSerializer>(AP + `/api/v4/immunizations/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ImmunizationCreateUpdateSerializer, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ImmunizationCreateUpdateSerializer>(AP + `/api/v4/immunizations/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ImmunizationViewSet = APIService.ImmunizationViewSet;

    static ImportAccountStatusHistoryViewSet = {
        list: (queryParams?: ImportAccountStatusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ImportAccountStatusHistorySerializer[]>>(AP + `/api/v4/import-accounts/status-history/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ImportAccountStatusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountStatusHistorySerializer>(AP + `/api/v4/import-accounts/status-history/${pk}/`, createReqSettings(queryParams, headers)),
    };

    ImportAccountStatusHistoryViewSet = APIService.ImportAccountStatusHistoryViewSet;

    static ImportAccountViewSet = {
        account_details: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountDetailSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/account_details/`, createReqSettings(queryParams, headers)),
        cancel_login: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/cancel_login/`, createReqSettings(queryParams, headers)),
        change_password: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountSecretSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/change_password/`, createReqSettings(queryParams, headers)),
        create: (data: ImportAccountSerializer, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ImportAccountSerializer>(AP + `/api/v4/import-accounts/import-accounts/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, createReqSettings(queryParams, headers)),
        get_connection: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AccountLoginSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/get_connection/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountSerializer[]>(AP + `/api/v4/import-accounts/import-accounts/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ImportAccountSerializer, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ImportAccountSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, data, createReqSettings(queryParams, headers)),
        queue_emr_import: (pk: any, data: QueueEMRImportSerializer, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<SyncDetailSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/queue_emr_import/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, createReqSettings(queryParams, headers)),
        show_secret: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountSecretSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/show_secret/`, createReqSettings(queryParams, headers)),
        start_login: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AccountLoginSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/start_login/`, createReqSettings(queryParams, headers)),
        test_account: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportAccountSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/test_account/`, createReqSettings(queryParams, headers)),
        test_permissions: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityOriginStatusCountSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/test_permissions/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ImportAccountSerializer, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ImportAccountSerializer>(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ImportAccountViewSet = APIService.ImportAccountViewSet;

    static ImportFileUploadViewSet = {
        create: (data: DocumentSerializer, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DocumentSerializer>(AP + `/api/v4/upload-file/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/upload-file/${pk}/`, createReqSettings(queryParams, headers)),
        download: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DocumentSerializer>(AP + `/api/v4/upload-file/${pk}/download/`, createReqSettings(queryParams, headers)),
        form: (queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportFileUploadFormSerializer[]>(AP + `/api/v4/upload-file/form/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<DocumentSerializer[]>>(AP + `/api/v4/upload-file/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: DocumentSerializer, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<DocumentSerializer>(AP + `/api/v4/upload-file/${pk}/`, data, createReqSettings(queryParams, headers)),
        report_patient_import: (data: DocumentSerializer, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<DocumentSerializer>(AP + `/api/v4/upload-file/admission_patient_import/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<DocumentSerializer>(AP + `/api/v4/upload-file/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: DocumentSerializer, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<DocumentSerializer>(AP + `/api/v4/upload-file/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ImportFileUploadViewSet = APIService.ImportFileUploadViewSet;

    static ImportLogViewSet = {
        last_success_for_task: (queryParams?: ImportLogViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LogDateSerializer>(AP + `/api/v4/import-logs/last_success_for_task/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ImportLogViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LogSerializer[]>(AP + `/api/v4/import-logs/`, createReqSettings(queryParams, headers)),
    };

    ImportLogViewSet = APIService.ImportLogViewSet;

    static ImportMacLocalityView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<MacBillingCodePricesSyncResultCountsSerializer>(AP + `/api/v4/import-mac-localities`, createReqSettings(queryParams, headers)),
    };

    ImportMacLocalityView = APIService.ImportMacLocalityView;

    static ImportPresetViewSet = {
        create: (data: ImportPresetSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<ImportPresetSerializer>(AP + `/api/v4/import-presets/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/import-presets/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ImportPresetSerializer[]>(AP + `/api/v4/import-presets/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ImportPresetSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<ImportPresetSerializer>(AP + `/api/v4/import-presets/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ImportPresetSerializer>(AP + `/api/v4/import-presets/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ImportPresetSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<ImportPresetSerializer>(AP + `/api/v4/import-presets/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ImportPresetViewSet = APIService.ImportPresetViewSet;

    static ImportViewSet = {
        create: (data: FacilityWithImportAccountsSerializer, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FacilityWithImportAccountsSerializer>(AP + `/api/v4/import/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/import/${pk}/`, createReqSettings(queryParams, headers)),
        get_available_reports: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EMRReportsSerializer>(AP + `/api/v4/import/${pk}/available_reports/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityWithImportAccountsSerializer[]>(AP + `/api/v4/import/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: FacilityWithImportAccountsSerializer, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<FacilityWithImportAccountsSerializer>(AP + `/api/v4/import/${pk}/`, data, createReqSettings(queryParams, headers)),
        queue_emr_import: (pk: any, data: QueueEMRImportSerializer, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<SyncDetailSerializer>(AP + `/api/v4/import/${pk}/queue_emr_import/`, data, createReqSettings(queryParams, headers)),
        queue_patient_import: (pk: any, data: QueueEMRPatientImportSerializer, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<SyncDetailSerializer>(AP + `/api/v4/import/${pk}/queue_emr_patient_import/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FacilityWithImportAccountsSerializer>(AP + `/api/v4/import/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: FacilityWithImportAccountsSerializer, queryParams?: ImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<FacilityWithImportAccountsSerializer>(AP + `/api/v4/import/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ImportViewSet = APIService.ImportViewSet;

    static IndicationViewSet = {
        create: (data: IndicationCreateSerializer, queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<IndicationCreateSerializer>(AP + `/api/v4/indications/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/indications/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<IndicationReadSerializer[]>>(AP + `/api/v4/indications/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<IndicationReadSerializer[]>>(AP + `/api/v4/indications/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: IndicationUpdateSerializer, queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<IndicationUpdateSerializer>(AP + `/api/v4/indications/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<IndicationReadSerializer>(AP + `/api/v4/indications/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<IndicationReadSerializer>(AP + `/api/v4/indications/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<IndicationReadSerializer>(AP + `/api/v4/indications/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: IndicationUpdateSerializer, queryParams?: IndicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<IndicationUpdateSerializer>(AP + `/api/v4/indications/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    IndicationViewSet = APIService.IndicationViewSet;

    static InfectionEntryViewSet = {
        list: (queryParams?: InfectionEntryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<InfectionEntryViewSetSerializer[]>>(AP + `/api/v4/infection-entries/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: InfectionEntryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InfectionEntryViewSetSerializer>(AP + `/api/v4/infection-entries/${pk}/`, createReqSettings(queryParams, headers)),
    };

    InfectionEntryViewSet = APIService.InfectionEntryViewSet;

    static InfectionInformationVerificationViewSet = {
        create: (data: InfectionInformationVerificationDetailedSerializer, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<InfectionInformationVerificationDetailedSerializer>(AP + `/api/v4/infection-information-verification/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/infection-information-verification/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InfectionInformationVerificationDetailedSerializer[]>(AP + `/api/v4/infection-information-verification/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: InfectionInformationVerificationDetailedSerializer, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<InfectionInformationVerificationDetailedSerializer>(AP + `/api/v4/infection-information-verification/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InfectionInformationVerificationDetailedSerializer>(AP + `/api/v4/infection-information-verification/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: InfectionInformationVerificationDetailedSerializer, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<InfectionInformationVerificationDetailedSerializer>(AP + `/api/v4/infection-information-verification/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    InfectionInformationVerificationViewSet = APIService.InfectionInformationVerificationViewSet;

    static InfectionInformationViewSet = {
        create: (data: InfectionInformationSerializer, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<InfectionInformationSerializer>(AP + `/api/v4/infection-information/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/infection-information/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InfectionInformationSerializer[]>(AP + `/api/v4/infection-information/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: InfectionInformationSerializer, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<InfectionInformationSerializer>(AP + `/api/v4/infection-information/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InfectionInformationSerializer>(AP + `/api/v4/infection-information/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: InfectionInformationSerializer, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<InfectionInformationSerializer>(AP + `/api/v4/infection-information/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    InfectionInformationViewSet = APIService.InfectionInformationViewSet;

    static InitialImportViewSet = {
        get_patient_list: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InitialImportPatientListSerializer>(AP + `/api/v4/initial-import/get_patient_list/`, createReqSettings(queryParams, headers)),
        get_sync_details_by_type: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InitialImportSyncDetailSimpleSerializer[]>(AP + `/api/v4/initial-import/get_sync_details_by_type/`, createReqSettings(queryParams, headers)),
        import_validations_history: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ImportValidationHistoryReadOnlySerializer[]>(AP + `/api/v4/initial-import/import_validations_history/`, createReqSettings(queryParams, headers)),
        initial_imports_by_facility: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<InitialImportHistoryDataSerializer[]>(AP + `/api/v4/initial-import/initial_imports_by_facility/`, createReqSettings(queryParams, headers)),
        reset_all_initial_imports: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/initial-import/reset_all_initial_imports/`, createReqSettings(queryParams, headers)),
        start_download_census_statuses_from_emr: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/initial-import/start_download_census_statuses_from_emr/`, createReqSettings(queryParams, headers)),
        start_download_patient_list: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/initial-import/start_download_patient_list/`, createReqSettings(queryParams, headers)),
        start_import_data: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/initial-import/start_import_data/`, createReqSettings(queryParams, headers)),
        start_import_patients: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/initial-import/start_import_patients/`, createReqSettings(queryParams, headers)),
        user_verification: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/initial-import/user_verification/`, createReqSettings(queryParams, headers)),
    };

    InitialImportViewSet = APIService.InitialImportViewSet;

    static InsuranceCompanies = {
        get: (queryParams?: InsuranceCompaniesQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/insurance-companies`, createReqSettings(queryParams, headers)),
    };

    InsuranceCompanies = APIService.InsuranceCompanies;

    static LabAntibiogramViewSet = {
        antibiogram_download: (queryParams?: LabAntibiogramViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabDetailedResultSerializer>(AP + `/api/v4/lab-antibiogram/antibiogram_download/`, createReqSettings(queryParams, headers)),
        antimicrobic_sensitivity_download: (queryParams?: LabAntibiogramViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabDetailedResultSerializer>(AP + `/api/v4/lab-antibiogram/antimicrobic_sensitivity_download/`, createReqSettings(queryParams, headers)),
    };

    LabAntibiogramViewSet = APIService.LabAntibiogramViewSet;

    static LabOrderGroupViewSet = {
        create: (data: LabOrderGroupSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<LabOrderGroupSerializer>(AP + `/api/v4/lab-order-groups/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-order-groups/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<LabOrderGroupSerializer[]>(AP + `/api/v4/lab-order-groups/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabOrderGroupSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<LabOrderGroupSerializer>(AP + `/api/v4/lab-order-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<LabOrderGroupSerializer>(AP + `/api/v4/lab-order-groups/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabOrderGroupSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<LabOrderGroupSerializer>(AP + `/api/v4/lab-order-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabOrderGroupViewSet = APIService.LabOrderGroupViewSet;

    static LabOrderViewSet = {
        barcode: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/${pk}/barcode/`, createReqSettings(queryParams, headers)),
        create: (data: LabOrderDetailedSerializer, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/`, data, createReqSettings(queryParams, headers)),
        create_covid: (data: LabCreateCovidOrderSerializer, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/create-covid/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-orders/${pk}/`, createReqSettings(queryParams, headers)),
        download: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/download/`, createReqSettings(queryParams, headers)),
        download_barcodes: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/download_barcodes/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabOrderDetailedSerializer[]>(AP + `/api/v4/lab-orders/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabOrderDetailedSerializer, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabOrderDetailedSerializer, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabOrderDetailedSerializer>(AP + `/api/v4/lab-orders/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabOrderViewSet = APIService.LabOrderViewSet;

    static LabReportViewSet = {
        batch_lab_test_review: (pk: any, data: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabReportDetailedSerializer>(AP + `/api/v4/lab-reports/${pk}/batch_lab_test_review/`, data, createReqSettings(queryParams, headers)),
        create: (data: LabReportSerializer, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabReportSerializer>(AP + `/api/v4/lab-reports/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-reports/${pk}/`, createReqSettings(queryParams, headers)),
        download: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabReportSerializer>(AP + `/api/v4/lab-reports/download/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LabReportSerializer[]>>(AP + `/api/v4/lab-reports/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LabReportDetailedSerializer[]>>(AP + `/api/v4/lab-reports/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabReportSerializer, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabReportSerializer>(AP + `/api/v4/lab-reports/${pk}/`, data, createReqSettings(queryParams, headers)),
        resend_fax: (pk: any, data: FaxNumberSerializer, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabReportDetailedSerializer>(AP + `/api/v4/lab-reports/${pk}/resend_fax/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabReportSerializer>(AP + `/api/v4/lab-reports/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabReportDetailedSerializer>(AP + `/api/v4/lab-reports/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabReportSerializer>(AP + `/api/v4/lab-reports/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabReportSerializer, queryParams?: LabReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabReportSerializer>(AP + `/api/v4/lab-reports/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabReportViewSet = APIService.LabReportViewSet;

    static LabResultBillingReportViewSet = {
        download_lab_result_billing: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabResultBillingSerializer>(AP + `/api/v4/lab-result-billing-reports/download_lab_result_billing/`, createReqSettings(queryParams, headers)),
        download_productivity: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabResultBillingSerializer>(AP + `/api/v4/lab-result-billing-reports/download_productivity/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabResultBillingSerializer[]>(AP + `/api/v4/lab-result-billing-reports/`, createReqSettings(queryParams, headers)),
        productivity: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingProductivitySerializer[]>(AP + `/api/v4/lab-result-billing-reports/productivity/`, createReqSettings(queryParams, headers)),
    };

    LabResultBillingReportViewSet = APIService.LabResultBillingReportViewSet;

    static LabResultViewSet = {
        create: (data: LabResultTrendsSerializer, queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabResultTrendsSerializer>(AP + `/api/v4/lab-results/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-results/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LabResultTrendsSerializer[]>>(AP + `/api/v4/lab-results/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabResultTrendsSerializer, queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabResultTrendsSerializer>(AP + `/api/v4/lab-results/${pk}/`, data, createReqSettings(queryParams, headers)),
        recent_summary: (queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabResultsSummarySerializer>(AP + `/api/v4/lab-results/recent_summary/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabResultTrendsSerializer>(AP + `/api/v4/lab-results/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabResultTrendsSerializer, queryParams?: LabResultViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabResultTrendsSerializer>(AP + `/api/v4/lab-results/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabResultViewSet = APIService.LabResultViewSet;

    static LabShipmentBagViewSet = {
        create: (data: LabShipmentBagSerializer, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabShipmentBagSerializer>(AP + `/api/v4/lab-shipment-bags/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-shipment-bags/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentBagSerializer[]>(AP + `/api/v4/lab-shipment-bags/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabShipmentBagSerializer, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabShipmentBagSerializer>(AP + `/api/v4/lab-shipment-bags/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentBagSerializer>(AP + `/api/v4/lab-shipment-bags/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabShipmentBagSerializer, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabShipmentBagSerializer>(AP + `/api/v4/lab-shipment-bags/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabShipmentBagViewSet = APIService.LabShipmentBagViewSet;

    static LabShipmentBoxViewSet = {
        create: (data: LabShipmentBoxSerializer, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabShipmentBoxSerializer>(AP + `/api/v4/lab-shipment-boxes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-shipment-boxes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentBoxSerializer[]>(AP + `/api/v4/lab-shipment-boxes/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabShipmentBoxSerializer, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabShipmentBoxSerializer>(AP + `/api/v4/lab-shipment-boxes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentBoxSerializer>(AP + `/api/v4/lab-shipment-boxes/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabShipmentBoxSerializer, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabShipmentBoxSerializer>(AP + `/api/v4/lab-shipment-boxes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabShipmentBoxViewSet = APIService.LabShipmentBoxViewSet;

    static LabShipmentViewSet = {
        create: (data: LabShipmentCreateWithLabOrdersSerializer, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabShipmentDetailedSerializer>(AP + `/api/v4/lab-shipments/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-shipments/${pk}/`, createReqSettings(queryParams, headers)),
        download: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentSerializer>(AP + `/api/v4/lab-shipments/${pk}/download/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentSerializer[]>(AP + `/api/v4/lab-shipments/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabShipmentSerializer, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabShipmentDetailedSerializer>(AP + `/api/v4/lab-shipments/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabShipmentDetailedSerializer>(AP + `/api/v4/lab-shipments/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabShipmentSerializer, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabShipmentSerializer>(AP + `/api/v4/lab-shipments/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabShipmentViewSet = APIService.LabShipmentViewSet;

    static LabSponsoredInfectionControlViewSet = {
        culture_specimen_dashboard: (queryParams?: LabSponsoredInfectionControlViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BacteriaSummarySerializer[]>(AP + `/api/v4/lab-sponsored-infection-control/culture_specimen_dashboard/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabSponsoredInfectionControlViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LabResultTrendsSerializer[]>>(AP + `/api/v4/lab-sponsored-infection-control/`, createReqSettings(queryParams, headers)),
        list_download: (queryParams?: LabSponsoredInfectionControlViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabResultTrendsSerializer>(AP + `/api/v4/lab-sponsored-infection-control/list_download/`, createReqSettings(queryParams, headers)),
    };

    LabSponsoredInfectionControlViewSet = APIService.LabSponsoredInfectionControlViewSet;

    static LabTestTypesViewSet = {
        create: (data: LabTestSynonymSerializer, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabTestSynonymSerializer>(AP + `/api/v4/lab-test-types/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-test-types/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSynonymSerializer[]>(AP + `/api/v4/lab-test-types/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSynonymSerializer[]>(AP + `/api/v4/lab-test-types/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabTestSynonymSerializer, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabTestSynonymSerializer>(AP + `/api/v4/lab-test-types/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSynonymSerializer>(AP + `/api/v4/lab-test-types/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSynonymSerializer>(AP + `/api/v4/lab-test-types/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSynonymSerializer>(AP + `/api/v4/lab-test-types/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabTestSynonymSerializer, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabTestSynonymSerializer>(AP + `/api/v4/lab-test-types/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabTestTypesViewSet = APIService.LabTestTypesViewSet;

    static LabTestViewSet = {
        attachments: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSerializer>(AP + `/api/v4/lab-tests/${pk}/attachments/`, createReqSettings(queryParams, headers)),
        create: (data: LabTestCreateUpdateSerializer, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabTestCreateUpdateSerializer>(AP + `/api/v4/lab-tests/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/lab-tests/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LabTestSerializer[]>>(AP + `/api/v4/lab-tests/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LabTestSerializer[]>>(AP + `/api/v4/lab-tests/list-detail/`, createReqSettings(queryParams, headers)),
        list_download: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSerializer>(AP + `/api/v4/lab-tests/list_download/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabTestCreateUpdateSerializer, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabTestCreateUpdateSerializer>(AP + `/api/v4/lab-tests/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSerializer>(AP + `/api/v4/lab-tests/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestHistoricalDetailedSerializer>(AP + `/api/v4/lab-tests/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabTestSerializer>(AP + `/api/v4/lab-tests/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabTestCreateUpdateSerializer, queryParams?: LabTestViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabTestCreateUpdateSerializer>(AP + `/api/v4/lab-tests/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabTestViewSet = APIService.LabTestViewSet;

    static LabelViewSet = {
        create: (data: LabelSerializer, queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LabelSerializer>(AP + `/api/v4/labels/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/labels/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabelSerializer[]>(AP + `/api/v4/labels/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabelDetailSerializer[]>(AP + `/api/v4/labels/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LabelSerializer, queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LabelSerializer>(AP + `/api/v4/labels/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LabelSerializer>(AP + `/api/v4/labels/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LabelSerializer, queryParams?: LabelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LabelSerializer>(AP + `/api/v4/labels/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LabelViewSet = APIService.LabelViewSet;

    static LanguagesView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/language-valueset`, createReqSettings(queryParams, headers)),
    };

    LanguagesView = APIService.LanguagesView;

    static LastVisitTaskViewSet = {
        list: (queryParams?: LastVisitTaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LastVisitTaskSerializer[]>(AP + `/api/v4/tasks/last-visit/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LastVisitTaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LastVisitTaskSerializer>(AP + `/api/v4/tasks/last-visit/${pk}/`, createReqSettings(queryParams, headers)),
    };

    LastVisitTaskViewSet = APIService.LastVisitTaskViewSet;

    static LevelOfCarePayerViewSet = {
        create: (data: LevelOfCarePayerSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<LevelOfCarePayerSerializer>(AP + `/api/v4/level-of-care/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/level-of-care/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<LevelOfCarePayerSerializer[]>(AP + `/api/v4/level-of-care/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LevelOfCarePayerSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<LevelOfCarePayerSerializer>(AP + `/api/v4/level-of-care/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<LevelOfCarePayerSerializer>(AP + `/api/v4/level-of-care/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LevelOfCarePayerSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<LevelOfCarePayerSerializer>(AP + `/api/v4/level-of-care/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LevelOfCarePayerViewSet = APIService.LevelOfCarePayerViewSet;

    static LocationViewSet = {
        create: (data: LocationSerializer, queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<LocationSerializer>(AP + `/api/v4/locations/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/locations/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LocationSerializer[]>>(AP + `/api/v4/locations/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<LocationListSerializer[]>>(AP + `/api/v4/locations/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: LocationSerializer, queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<LocationSerializer>(AP + `/api/v4/locations/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LocationSerializer>(AP + `/api/v4/locations/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LocationSerializer>(AP + `/api/v4/locations/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LocationSerializer>(AP + `/api/v4/locations/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: LocationSerializer, queryParams?: LocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<LocationSerializer>(AP + `/api/v4/locations/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    LocationViewSet = APIService.LocationViewSet;

    static LogViewSet = {
        list: (queryParams?: LogViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LogSerializer[]>(AP + `/api/v4/logs/`, createReqSettings(queryParams, headers)),
    };

    LogViewSet = APIService.LogViewSet;

    static MACLocalityBillingCodeViewSet = {
        billing_codes: (queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillingCodesSerializer>(AP + `/api/v4/macs-billing-codes/billing-codes/`, createReqSettings(queryParams, headers)),
        create: (data: MACLocalityBillingCodeDetailedSerializer, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<MACLocalityBillingCodeDetailedSerializer>(AP + `/api/v4/macs-billing-codes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/macs-billing-codes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<MACLocalityBillingCodeDetailedSerializer[]>(AP + `/api/v4/macs-billing-codes/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: MACLocalityBillingCodeDetailedSerializer, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<MACLocalityBillingCodeDetailedSerializer>(AP + `/api/v4/macs-billing-codes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<MACLocalityBillingCodeDetailedSerializer>(AP + `/api/v4/macs-billing-codes/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: MACLocalityBillingCodeDetailedSerializer, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<MACLocalityBillingCodeDetailedSerializer>(AP + `/api/v4/macs-billing-codes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    MACLocalityBillingCodeViewSet = APIService.MACLocalityBillingCodeViewSet;

    static MACViewSet = {
        create: (data: MACSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<MACSerializer>(AP + `/api/v4/medicare-administrative-contractors/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<MACSerializer[]>(AP + `/api/v4/medicare-administrative-contractors/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: MACSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<MACSerializer>(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<MACSerializer>(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: MACSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<MACSerializer>(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    MACViewSet = APIService.MACViewSet;

    static ManualImportView = {
        import_from_document: (queryParams?: ManualImportViewQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncDetailSimpleSerializer>(AP + `/api/v4/manual-import/import_from_document/`, createReqSettings(queryParams, headers)),
    };

    ManualImportView = APIService.ManualImportView;

    static MedicaidPriceSettingViewSet = {
        batch_update: (data: MedicaidPriceSettingBatchUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<MedicaidPriceSettingSerializer[]>(AP + `/api/v4/medicaid-price-settings/batch-update/`, data, createReqSettings(queryParams, headers)),
        create: (data: MedicaidPriceSettingSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<MedicaidPriceSettingSerializer>(AP + `/api/v4/medicaid-price-settings/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/medicaid-price-settings/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<MedicaidPriceSettingSerializer[]>(AP + `/api/v4/medicaid-price-settings/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: MedicaidPriceSettingSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<MedicaidPriceSettingSerializer>(AP + `/api/v4/medicaid-price-settings/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<MedicaidPriceSettingSerializer>(AP + `/api/v4/medicaid-price-settings/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: MedicaidPriceSettingSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<MedicaidPriceSettingSerializer>(AP + `/api/v4/medicaid-price-settings/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    MedicaidPriceSettingViewSet = APIService.MedicaidPriceSettingViewSet;

    static MedicalProgramBillingFacilityViewSet = {
        summary: (queryParams?: MedicalProgramBillingFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<FacilityProcedureCodeSerializer[]>>(AP + `/api/v4/medical-program-billing-facilities/summary/`, createReqSettings(queryParams, headers)),
    };

    MedicalProgramBillingFacilityViewSet = APIService.MedicalProgramBillingFacilityViewSet;

    static MedicalProgramBillingPatientViewSet = {
        all_patients: (queryParams?: MedicalProgramBillingPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientNPIProcedureCodeSerializer[]>>(AP + `/api/v4/medical-program-billing-patients/all_patients/`, createReqSettings(queryParams, headers)),
        billable_patients: (queryParams?: MedicalProgramBillingPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientNPIProcedureCodeSerializer[]>>(AP + `/api/v4/medical-program-billing-patients/billable_patients/`, createReqSettings(queryParams, headers)),
        download_billable_patients: (queryParams?: MedicalProgramBillingPatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<BillablePatientBillingDownloadSerializer>(AP + `/api/v4/medical-program-billing-patients/download_billable_patients/`, createReqSettings(queryParams, headers)),
    };

    MedicalProgramBillingPatientViewSet = APIService.MedicalProgramBillingPatientViewSet;

    static MedicalProgramBillingTaskViewSet = {
        download_all_tasks: (queryParams?: MedicalProgramBillingTaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/medical-program-billing-tasks/download_all_tasks/`, createReqSettings(queryParams, headers)),
        invalid_tasks: (queryParams?: MedicalProgramBillingTaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TaskValidatedBillingSerializer[]>>(AP + `/api/v4/medical-program-billing-tasks/invalid_tasks/`, createReqSettings(queryParams, headers)),
        patient_tasks: (queryParams?: MedicalProgramBillingTaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TaskValidatedBillingSerializer[]>>(AP + `/api/v4/medical-program-billing-tasks/patient_tasks/`, createReqSettings(queryParams, headers)),
    };

    MedicalProgramBillingTaskViewSet = APIService.MedicalProgramBillingTaskViewSet;

    static MedicalProgramBillingUserViewSet = {
        download_summary: (queryParams?: MedicalProgramBillingUserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserBillingDownloadSerializer>(AP + `/api/v4/medical-program-billing-users/download_summary/`, createReqSettings(queryParams, headers)),
        summary: (queryParams?: MedicalProgramBillingUserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UserPatientProcedureCodeSerializer[]>>(AP + `/api/v4/medical-program-billing-users/summary/`, createReqSettings(queryParams, headers)),
    };

    MedicalProgramBillingUserViewSet = APIService.MedicalProgramBillingUserViewSet;

    static MedicalProgramViewSet = {
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<MedicalProgramSerializer[]>(AP + `/api/v4/medical-programs/`, createReqSettings(queryParams, headers)),
    };

    MedicalProgramViewSet = APIService.MedicalProgramViewSet;

    static MedicalResultTypeViewSet = {
        create: (data: MedicalResultTypeSerializer, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<MedicalResultTypeSerializer>(AP + `/api/v4/medical-result-types/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/medical-result-types/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<MedicalResultTypeSerializer[]>>(AP + `/api/v4/medical-result-types/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: MedicalResultTypeSerializer, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<MedicalResultTypeSerializer>(AP + `/api/v4/medical-result-types/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<MedicalResultTypeSerializer>(AP + `/api/v4/medical-result-types/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: MedicalResultTypeSerializer, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<MedicalResultTypeSerializer>(AP + `/api/v4/medical-result-types/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    MedicalResultTypeViewSet = APIService.MedicalResultTypeViewSet;

    static MedicalSpecialtyViewSet = {
        list: (queryParams?: MedicalSpecialtyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<MedicalSpecialtySerializer[]>(AP + `/api/v4/medical-specialties/`, createReqSettings(queryParams, headers)),
    };

    MedicalSpecialtyViewSet = APIService.MedicalSpecialtyViewSet;

    static MedicationsViewSet = {
        create: (data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/medications/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/medications/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: MedicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/medications/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/medications/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/medications/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/medications/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    MedicationsViewSet = APIService.MedicationsViewSet;

    static NotificationViewSet = {
        create: (data: NotificationReadSerializer, queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<NotificationReadSerializer>(AP + `/api/v4/notifications/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/notifications/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<NotificationReadSerializer[]>>(AP + `/api/v4/notifications/`, createReqSettings(queryParams, headers)),
        mark_all_as_read: (queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NotificationReadSerializer>(AP + `/api/v4/notifications/mark_all_as_read/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: NotificationReadSerializer, queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<NotificationReadSerializer>(AP + `/api/v4/notifications/${pk}/`, data, createReqSettings(queryParams, headers)),
        read: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NotificationReadSerializer>(AP + `/api/v4/notifications/${pk}/read/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NotificationReadSerializer>(AP + `/api/v4/notifications/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: NotificationReadSerializer, queryParams?: NotificationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<NotificationReadSerializer>(AP + `/api/v4/notifications/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    NotificationViewSet = APIService.NotificationViewSet;

    static NurseStationViewSet = {
        create: (data: NurseStationSerializer, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<NurseStationSerializer>(AP + `/api/v4/nurse-station/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/nurse-station/${pk}/`, createReqSettings(queryParams, headers)),
        history: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NurseStationHistorySerializer[]>(AP + `/api/v4/nurse-station/${pk}/history/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NurseStationSerializer[]>(AP + `/api/v4/nurse-station/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NurseStationDetailedSerializer[]>(AP + `/api/v4/nurse-station/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: NurseStationSerializer, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<NurseStationSerializer>(AP + `/api/v4/nurse-station/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NurseStationSerializer>(AP + `/api/v4/nurse-station/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NurseStationDetailedSerializer>(AP + `/api/v4/nurse-station/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NurseStationSerializer>(AP + `/api/v4/nurse-station/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: NurseStationSerializer, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<NurseStationSerializer>(AP + `/api/v4/nurse-station/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    NurseStationViewSet = APIService.NurseStationViewSet;

    static OperationViewSet = {
        facility_details: (pk: any, queryParams?: OperationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OperationFacilityDetailSerializer[]>(AP + `/api/v4/operation/${pk}/facility_details/`, createReqSettings(queryParams, headers)),
        organization_list: (queryParams?: OperationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OperationOrganizationSerializer[]>(AP + `/api/v4/operation/organization_list/`, createReqSettings(queryParams, headers)),
    };

    OperationViewSet = APIService.OperationViewSet;

    static OrderDownloadViewSet = {
        get: (queryParams?: OrderDownloadViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/orders_report`, createReqSettings(queryParams, headers)),
    };

    OrderDownloadViewSet = APIService.OrderDownloadViewSet;

    static OrderViewSet = {
        list: (queryParams?: OrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<OrderDetailSerializer[]>>(AP + `/api/v4/orders/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: OrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OrderSerializer>(AP + `/api/v4/orders/${pk}/`, createReqSettings(queryParams, headers)),
        set_active_status: (pk: any, data: OrderStatusHistoryCreateSerializer, queryParams?: OrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<OrderSerializer>(AP + `/api/v4/orders/${pk}/set_active_status/`, data, createReqSettings(queryParams, headers)),
        status_history: (pk: any, queryParams?: OrderViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OrderStatusHistorySerializer[]>(AP + `/api/v4/orders/${pk}/status_history/`, createReqSettings(queryParams, headers)),
    };

    OrderViewSet = APIService.OrderViewSet;

    static OrganizationGroupViewSet = {
        create: (data: OrganizationGroupSerializer, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<OrganizationGroupSerializer>(AP + `/api/v4/organization-groups/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/organization-groups/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OrganizationGroupSerializer[]>(AP + `/api/v4/organization-groups/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: OrganizationGroupSerializer, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<OrganizationGroupSerializer>(AP + `/api/v4/organization-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OrganizationGroupSerializer>(AP + `/api/v4/organization-groups/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: OrganizationGroupSerializer, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<OrganizationGroupSerializer>(AP + `/api/v4/organization-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    OrganizationGroupViewSet = APIService.OrganizationGroupViewSet;

    static OrganizationViewSet = {
        create: (data: OrganizationSerializer, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<OrganizationSerializer>(AP + `/api/v4/organizations/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/organizations/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: OrganizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OrganizationSerializer[]>(AP + `/api/v4/organizations/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: OrganizationSerializer, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<OrganizationSerializer>(AP + `/api/v4/organizations/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<OrganizationSerializer>(AP + `/api/v4/organizations/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: OrganizationSerializer, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<OrganizationSerializer>(AP + `/api/v4/organizations/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    OrganizationViewSet = APIService.OrganizationViewSet;

    static PIMeasuresViewSet = {
        download_report: (queryParams?: PIMeasuresViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/pi-measures/download_report/`, createReqSettings(queryParams, headers)),
        get_data: (queryParams?: PIMeasuresViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PIMeasureSerializer>(AP + `/api/v4/pi-measures/get_data/`, createReqSettings(queryParams, headers)),
    };

    PIMeasuresViewSet = APIService.PIMeasuresViewSet;

    static PatientAddressViewSet = {
        batch_create: (data: PatientAddressCreateSerializer, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientAddressSerializer[]>(AP + `/api/v4/patient-addresses/batch_create/`, data, createReqSettings(queryParams, headers)),
        batch_update: (data: PatientAddressUpdateSerializer, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientAddressSerializer[]>(AP + `/api/v4/patient-addresses/batch_update/`, data, createReqSettings(queryParams, headers)),
        create: (data: PatientAddressCreateUpdateModelSerializer, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientAddressCreateUpdateModelSerializer>(AP + `/api/v4/patient-addresses/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-addresses/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAddressSerializer[]>(AP + `/api/v4/patient-addresses/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAddressSerializer[]>(AP + `/api/v4/patient-addresses/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientAddressCreateUpdateModelSerializer, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientAddressCreateUpdateModelSerializer>(AP + `/api/v4/patient-addresses/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAddressSerializer>(AP + `/api/v4/patient-addresses/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAddressSerializer>(AP + `/api/v4/patient-addresses/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAddressSerializer>(AP + `/api/v4/patient-addresses/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientAddressCreateUpdateModelSerializer, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientAddressCreateUpdateModelSerializer>(AP + `/api/v4/patient-addresses/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientAddressViewSet = APIService.PatientAddressViewSet;

    static PatientAdvancedDirectiveViewSet = {
        create: (data: PatientAdvancedDirectiveEditSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<PatientAdvancedDirectiveEditSerializer>(AP + `/api/v4/patient-advanced-directive/`, data, createReqSettings(queryParams, headers)),
    };

    PatientAdvancedDirectiveViewSet = APIService.PatientAdvancedDirectiveViewSet;

    static PatientAdvancedMonitoringListViewSet = {
        list: (queryParams?: PatientAdvancedMonitoringListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientAdvancedMonitoringSerializer[]>>(AP + `/api/v4/patient-advanced-monitoring-list/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientAdvancedMonitoringListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAdvancedMonitoringSerializer>(AP + `/api/v4/patient-advanced-monitoring-list/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientAdvancedMonitoringListViewSet = APIService.PatientAdvancedMonitoringListViewSet;

    static PatientAllergyViewSet = {
        create: (data: AllergyPatientSaveSerializer, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<AllergyPatientSaveSerializer>(AP + `/api/v4/patient-allergies/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-allergies/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergyPatientReadSerializer[]>(AP + `/api/v4/patient-allergies/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergyPatientReadSerializer[]>(AP + `/api/v4/patient-allergies/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: AllergyPatientSaveSerializer, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<AllergyPatientSaveSerializer>(AP + `/api/v4/patient-allergies/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergyPatientReadSerializer>(AP + `/api/v4/patient-allergies/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergyPatientReadSerializer>(AP + `/api/v4/patient-allergies/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergyPatientReadSerializer>(AP + `/api/v4/patient-allergies/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: AllergyPatientSaveSerializer, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<AllergyPatientSaveSerializer>(AP + `/api/v4/patient-allergies/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientAllergyViewSet = APIService.PatientAllergyViewSet;

    static PatientCensusView = {
        get: (queryParams?: PatientCensusViewQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientCensusListSerializer[]>>(AP + `/api/v4/patients-by-census`, createReqSettings(queryParams, headers)),
    };

    PatientCensusView = APIService.PatientCensusView;

    static PatientCensusViewSet = {
        batch_create_cmrns: (data: BatchUpdateCMRNSerializer, queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BatchUpdateCMRNResponseSerializer>(AP + `/api/v4/patients-census/batch_create_cmrns/`, data, createReqSettings(queryParams, headers)),
        check_patient_cmrns: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientsWithoutCMRNSerializer>(AP + `/api/v4/patients-census/check_patient_cmrns/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientListWithLastVisitSerializer[]>(AP + `/api/v4/patients-census/`, createReqSettings(queryParams, headers)),
        list_advanced_filtering: (data: AdvancedFilterSerializer, queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<Paginated<PatientListWithLastVisitSerializer[]>>(AP + `/api/v4/patients-census/list_advanced_filtering/`, data, createReqSettings(queryParams, headers)),
        patients_with_cmrn: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientWithCMRNSerializer[]>(AP + `/api/v4/patients-census/patients_with_cmrn/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientListWithLastVisitSerializer>(AP + `/api/v4/patients-census/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientCensusViewSet = APIService.PatientCensusViewSet;

    static PatientComplexSearchViewSet = {
        list: (queryParams?: PatientComplexSearchViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientWithFacilitySerializer[]>(AP + `/api/v4/patient-complex-search/`, createReqSettings(queryParams, headers)),
    };

    PatientComplexSearchViewSet = APIService.PatientComplexSearchViewSet;

    static PatientContactPointViewSet = {
        batch_create: (data: PatientContactPointCreateSerializer, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientContactPointSerializer[]>(AP + `/api/v4/patient-contact-points/batch_create/`, data, createReqSettings(queryParams, headers)),
        batch_update: (data: PatientContactPointUpdateSerializer, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientContactPointSerializer[]>(AP + `/api/v4/patient-contact-points/batch_update/`, data, createReqSettings(queryParams, headers)),
        create: (data: PatientContactPointCreateUpdateModelSerializer, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientContactPointCreateUpdateModelSerializer>(AP + `/api/v4/patient-contact-points/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-contact-points/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientContactPointSerializer[]>(AP + `/api/v4/patient-contact-points/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientContactPointSerializer[]>(AP + `/api/v4/patient-contact-points/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientContactPointCreateUpdateModelSerializer, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientContactPointCreateUpdateModelSerializer>(AP + `/api/v4/patient-contact-points/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientContactPointSerializer>(AP + `/api/v4/patient-contact-points/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientContactPointSerializer>(AP + `/api/v4/patient-contact-points/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientContactPointSerializer>(AP + `/api/v4/patient-contact-points/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientContactPointCreateUpdateModelSerializer, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientContactPointCreateUpdateModelSerializer>(AP + `/api/v4/patient-contact-points/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientContactPointViewSet = APIService.PatientContactPointViewSet;

    static PatientCopyViewSet = {
        copy: (data: PatientCopySerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<PatientSimpleSerializer>(AP + `/api/v4/patient-copy/copy/`, data, createReqSettings(queryParams, headers)),
    };

    PatientCopyViewSet = APIService.PatientCopyViewSet;

    static PatientCreateViewSet = {
        batch_import_from_cda: (data: any, queryParams?: PatientCreateViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientSimpleReadSerializer[]>(AP + `/api/v4/patient-create/batch-import-from-cda/`, data, createReqSettings(queryParams, headers)),
        create: (data: PatientCreateSerializer, queryParams?: PatientCreateViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientSimpleReadSerializer>(AP + `/api/v4/patient-create/`, data, createReqSettings(queryParams, headers)),
        import_from_cda: (data: any, queryParams?: PatientCreateViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientCreateSerializer>(AP + `/api/v4/patient-create/import-from-cda/`, data, createReqSettings(queryParams, headers)),
    };

    PatientCreateViewSet = APIService.PatientCreateViewSet;

    static PatientDetailViewSet = {
        partial_update: (pk: any, data: PatientDetailsSerializer, queryParams?: PatientDetailViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientDetailsSerializer>(AP + `/api/v4/patient-details/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientDetailViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsSerializer>(AP + `/api/v4/patient-details/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientDetailsSerializer, queryParams?: PatientDetailViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientDetailsSerializer>(AP + `/api/v4/patient-details/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientDetailViewSet = APIService.PatientDetailViewSet;

    static PatientDocumentCDAViewSet = {
        assign_to_patient: (data: PatientDocumentCDAAssignSerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentCDADetailedResponseSerializer>(AP + `/api/v4/patient-document-cda/assign-to-patient/`, data, createReqSettings(queryParams, headers)),
        assign_user_to_direct_address: (data: PatientDocumentCDAAssignUserDirectAddressRequestSerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentCDAAssignUserDirectAddressResponseSerializer>(AP + `/api/v4/patient-document-cda/assign-user-to-direct-address/`, data, createReqSettings(queryParams, headers)),
        create: (data: PatientDocumentCDAUploadSerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-document-cda/${pk}/`, createReqSettings(queryParams, headers)),
        download: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/download/`, createReqSettings(queryParams, headers)),
        get_document_validation_results: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentCDAValidationResultSerializer>(AP + `/api/v4/patient-document-cda/get-document-validation-results/`, createReqSettings(queryParams, headers)),
        get_unassigned_ccd_documents: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentCDAGetUnassignedDocumentsResponseSerializer[]>(AP + `/api/v4/patient-document-cda/get-unassigned-ccd-documents/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientDocumentCDADetailedResponseSerializer[]>>(AP + `/api/v4/patient-document-cda/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientDocumentCDASerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/${pk}/`, data, createReqSettings(queryParams, headers)),
        reassign_to_patient: (pk: any, data: PatientDocumentCDAAssignSerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/${pk}/reassign-to-patient/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/${pk}/`, createReqSettings(queryParams, headers)),
        transmit_cda: (pk: any, data: PatientDocumentCDATransmitSerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/${pk}/transmit-cda/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientDocumentCDASerializer, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientDocumentCDASerializer>(AP + `/api/v4/patient-document-cda/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientDocumentCDAViewSet = APIService.PatientDocumentCDAViewSet;

    static PatientDocumentSubtypesView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/patient-document-subtypes`, createReqSettings(queryParams, headers)),
    };

    PatientDocumentSubtypesView = APIService.PatientDocumentSubtypesView;

    static PatientDocumentViewSet = {
        create: (data: PatientDocumentUploadSerializer, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentUploadSerializer>(AP + `/api/v4/patient-documents/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-documents/${pk}/`, createReqSettings(queryParams, headers)),
        download: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentSerializer>(AP + `/api/v4/patient-documents/${pk}/download/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientDocumentSerializer[]>>(AP + `/api/v4/patient-documents/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientDocumentSerializer, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientDocumentSerializer>(AP + `/api/v4/patient-documents/${pk}/`, data, createReqSettings(queryParams, headers)),
        reconcile_ccd: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CCDACCDImporterSerializer>(AP + `/api/v4/patient-documents/${pk}/reconcile-ccd/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentSerializer>(AP + `/api/v4/patient-documents/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientDocumentSerializer, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientDocumentSerializer>(AP + `/api/v4/patient-documents/${pk}/`, data, createReqSettings(queryParams, headers)),
        upload_remote_link: (data: PatientDocumentRemoteLinkSerializer, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDocumentRemoteLinkSerializer>(AP + `/api/v4/patient-documents/upload-remote-link/`, data, createReqSettings(queryParams, headers)),
    };

    PatientDocumentViewSet = APIService.PatientDocumentViewSet;

    static PatientERXViewSet = {
        create_patient_in_erx: (pk: any, queryParams?: PatientERXViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientEncounterURLSerializer>(AP + `/api/v4/patient-erx/${pk}/create_patient_in_erx/`, createReqSettings(queryParams, headers)),
        patient_status_in_erx: (pk: any, queryParams?: PatientERXViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientErxStatusSerializer>(AP + `/api/v4/patient-erx/${pk}/patient_status_in_erx/`, createReqSettings(queryParams, headers)),
    };

    PatientERXViewSet = APIService.PatientERXViewSet;

    static PatientInformationAuditView = {
        list: (queryParams?: PatientInformationAuditViewQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientInformationAuditSerializer[]>(AP + `/api/v4/patient-information-audit/`, createReqSettings(queryParams, headers)),
    };

    PatientInformationAuditView = APIService.PatientInformationAuditView;

    static PatientInsuranceViewSet = {
        create: (data: PatientInsuranceCreateSerializer, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientInsuranceCreateSerializer>(AP + `/api/v4/patient-insurance/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-insurance/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientInsuranceEligibilityResponseSerializer[]>(AP + `/api/v4/patient-insurance/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientInsuranceCreateSerializer, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientInsuranceCreateSerializer>(AP + `/api/v4/patient-insurance/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientInsuranceEligibilityResponseSerializer>(AP + `/api/v4/patient-insurance/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientInsuranceCreateSerializer, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientInsuranceCreateSerializer>(AP + `/api/v4/patient-insurance/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientInsuranceViewSet = APIService.PatientInsuranceViewSet;

    static PatientMedicationServiceDetailsViewSet = {
        retrieve: (pk: any, queryParams?: PatientMedicationServiceDetailsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientMedicationServiceDetailsSerializer>(AP + `/api/v4/patient-medication-service-details/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientMedicationServiceDetailsViewSet = APIService.PatientMedicationServiceDetailsViewSet;

    static PatientMergeViewSet = {
        list: (queryParams?: PatientMergeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientListSerializer[]>(AP + `/api/v4/patient-merge/`, createReqSettings(queryParams, headers)),
        merge: (pk: any, queryParams?: PatientMergeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientListSerializer>(AP + `/api/v4/patient-merge/${pk}/merge/`, createReqSettings(queryParams, headers)),
        suggestions: (queryParams?: PatientMergeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientListSerializer>(AP + `/api/v4/patient-merge/suggestions/`, createReqSettings(queryParams, headers)),
    };

    PatientMergeViewSet = APIService.PatientMergeViewSet;

    static PatientNoteViewSet = {
        create: (data: PatientNoteSerializer, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientNoteSerializer>(AP + `/api/v4/patient-notes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-notes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientNoteSerializer[]>(AP + `/api/v4/patient-notes/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientNoteDetailedSerializer[]>(AP + `/api/v4/patient-notes/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientNoteSerializer, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientNoteSerializer>(AP + `/api/v4/patient-notes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientNoteSerializer>(AP + `/api/v4/patient-notes/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientNoteSerializer>(AP + `/api/v4/patient-notes/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientNoteSerializer>(AP + `/api/v4/patient-notes/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientNoteSerializer, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientNoteSerializer>(AP + `/api/v4/patient-notes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientNoteViewSet = APIService.PatientNoteViewSet;

    static PatientPayerCategoryChangeViewSet = {
        list: (queryParams?: PatientPayerCategoryChangeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientPayerCategoryChangeSerializer[]>>(AP + `/api/v4/patient-payer-category-change/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientPayerCategoryChangeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientPayerCategoryChangeSerializer>(AP + `/api/v4/patient-payer-category-change/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientPayerCategoryChangeViewSet = APIService.PatientPayerCategoryChangeViewSet;

    static PatientPersonViewSet = {
        partial_update: (pk: any, data: PatientPersonUpdateSerializer, queryParams?: PatientPersonViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientPersonUpdateSerializer>(AP + `/api/v4/patient-person/${pk}/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientPersonUpdateSerializer, queryParams?: PatientPersonViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientPersonUpdateSerializer>(AP + `/api/v4/patient-person/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientPersonViewSet = APIService.PatientPersonViewSet;

    static PatientPhaseOfDeclineChangeViewSet = {
        list: (queryParams?: PatientPhaseOfDeclineChangeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientPhaseOfDeclineChangeSerializer[]>(AP + `/api/v4/patient-phase-of-decline-change/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientPhaseOfDeclineChangeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientPhaseOfDeclineChangeSerializer>(AP + `/api/v4/patient-phase-of-decline-change/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientPhaseOfDeclineChangeViewSet = APIService.PatientPhaseOfDeclineChangeViewSet;

    static PatientPhysicianTeamAccessPermissionHistoryViewSet = {
        list: (queryParams?: PatientPhysicianTeamAccessPermissionHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientPhysicianTeamAccessPermissionHistorySerializer[]>>(AP + `/api/v4/access-permission-history/`, createReqSettings(queryParams, headers)),
    };

    PatientPhysicianTeamAccessPermissionHistoryViewSet = APIService.PatientPhysicianTeamAccessPermissionHistoryViewSet;

    static PatientPhysicianTeamAccessPermissionViewSet = {
        add_to_pending: (data: PatientAndPhysicianTeamListSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientPhysicianTeamAccessPermissionSerializer[]>(AP + `/api/v4/access-permissions/add_to_pending/`, data, createReqSettings(queryParams, headers)),
        authorize: (data: PatientAndPhysicianTeamListUpdateSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientPhysicianTeamAccessPermissionSerializer[]>(AP + `/api/v4/access-permissions/authorize/`, data, createReqSettings(queryParams, headers)),
        cancel: (data: PatientAndPhysicianTeamListUpdateSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientAndPhysicianTeamListUpdateSerializer[]>(AP + `/api/v4/access-permissions/cancel/`, data, createReqSettings(queryParams, headers)),
        create: (data: PatientAndPhysicianTeamListSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientPhysicianTeamAccessPermissionSerializer[]>(AP + `/api/v4/access-permissions/`, data, createReqSettings(queryParams, headers)),
        decline: (data: PatientAndPhysicianTeamListUpdateSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientAndPhysicianTeamListUpdateSerializer[]>(AP + `/api/v4/access-permissions/decline/`, data, createReqSettings(queryParams, headers)),
        discard: (data: PatientAndPhysicianTeamListSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientAndPhysicianTeamListSerializer[]>(AP + `/api/v4/access-permissions/discard/`, data, createReqSettings(queryParams, headers)),
        discontinue: (data: PatientAndPhysicianTeamListUpdateSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientAndPhysicianTeamListUpdateSerializer[]>(AP + `/api/v4/access-permissions/discontinue/`, data, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientPhysicianTeamAccessPermissionSerializer[]>>(AP + `/api/v4/access-permissions/`, createReqSettings(queryParams, headers)),
        overwrite: (data: PatientAndPhysicianTeamOverwriteAccessSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientPhysicianTeamAccessPermissionSerializer>(AP + `/api/v4/access-permissions/overwrite/`, data, createReqSettings(queryParams, headers)),
        print: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientPhysicianTeamAccessPermissionSerializer>(AP + `/api/v4/access-permissions/print/`, createReqSettings(queryParams, headers)),
        reject: (data: PatientAndPhysicianTeamListUpdateSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientPhysicianTeamAccessPermissionSerializer[]>(AP + `/api/v4/access-permissions/reject/`, data, createReqSettings(queryParams, headers)),
        revoke: (data: PatientAndPhysicianTeamListUpdateSerializer, queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientPhysicianTeamAccessPermissionSerializer[]>(AP + `/api/v4/access-permissions/revoke/`, data, createReqSettings(queryParams, headers)),
    };

    PatientPhysicianTeamAccessPermissionViewSet = APIService.PatientPhysicianTeamAccessPermissionViewSet;

    static PatientPhysicianTeamAccessPermissionWithConnectionsViewSet = {
        list: (queryParams?: PatientPhysicianTeamAccessPermissionWithConnectionsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientPhysicianTeamAccessPermissionWhatsNewSerializer[]>>(AP + `/api/v4/access-permissions-with-connections/`, createReqSettings(queryParams, headers)),
    };

    PatientPhysicianTeamAccessPermissionWithConnectionsViewSet = APIService.PatientPhysicianTeamAccessPermissionWithConnectionsViewSet;

    static PatientPhysicianTeamMedicalProgramViewSet = {
        create: (data: PatientPhysicianTeamMedicalProgramCreateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<PatientPhysicianTeamMedicalProgramSerializer>(AP + `/api/v4/patient-physician-team-medical-programs/`, data, createReqSettings(queryParams, headers)),
        history: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<PatientPhysicianTeamMedicalProgramHistorySerializer[]>(AP + `/api/v4/patient-physician-team-medical-programs/${pk}/history/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<PatientPhysicianTeamMedicalProgramSerializer[]>(AP + `/api/v4/patient-physician-team-medical-programs/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientPhysicianTeamMedicalProgramUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<PatientPhysicianTeamMedicalProgramUpdateSerializer>(AP + `/api/v4/patient-physician-team-medical-programs/${pk}/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientPhysicianTeamMedicalProgramUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<PatientPhysicianTeamMedicalProgramSerializer>(AP + `/api/v4/patient-physician-team-medical-programs/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientPhysicianTeamMedicalProgramViewSet = APIService.PatientPhysicianTeamMedicalProgramViewSet;

    static PatientPhysicianTeamViewSet = {
        create: (data: PatientPhysicianTeamCreateSerializer, queryParams?: PatientPhysicianTeamViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientPhysicianTeamCreateSerializer>(AP + `/api/v4/patient-physician-team/`, data, createReqSettings(queryParams, headers)),
    };

    PatientPhysicianTeamViewSet = APIService.PatientPhysicianTeamViewSet;

    static PatientQrdaExportViewSet = {
        export: (pk: any, queryParams?: PatientQrdaExportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSimpleSerializer>(AP + `/api/v4/patient-qrda-exports/${pk}/export/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientQrdaExportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSimpleSerializer>(AP + `/api/v4/patient-qrda-exports/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientQrdaExportViewSet = APIService.PatientQrdaExportViewSet;

    static PatientReportViewSet = {
        download_risk_summary: (queryParams?: PatientReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patient-reports/download-visit-summary/`, createReqSettings(queryParams, headers)),
    };

    PatientReportViewSet = APIService.PatientReportViewSet;

    static PatientRepresentativeViewSet = {
        create: (data: PatientRepresentativeSerializer, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientRepresentativeSerializer>(AP + `/api/v4/patient-representatives/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patient-representatives/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientRepresentativeReadSerializer[]>(AP + `/api/v4/patient-representatives/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientRepresentativeReadSerializer, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientRepresentativeReadSerializer>(AP + `/api/v4/patient-representatives/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientRepresentativeReadSerializer>(AP + `/api/v4/patient-representatives/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientRepresentativeReadSerializer, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientRepresentativeReadSerializer>(AP + `/api/v4/patient-representatives/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientRepresentativeViewSet = APIService.PatientRepresentativeViewSet;

    static PatientSpecialtyMedicationViewSet = {
        retrieve: (pk: any, queryParams?: PatientSpecialtyMedicationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAvailableToRequestAccessPermissionSerializer>(AP + `/api/v4/patients-specialty-medication/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientSpecialtyMedicationViewSet = APIService.PatientSpecialtyMedicationViewSet;

    static PatientSyncViewSet = {
        cerner_sync_details: (pk: any, queryParams?: PatientSyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LastSyncDetailsSerializer>(AP + `/api/v4/patient-syncs/${pk}/cerner_sync_details/`, createReqSettings(queryParams, headers)),
        queue_ccd: (pk: any, data: any, queryParams?: PatientSyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<QueueCCDSerializer>(AP + `/api/v4/patient-syncs/${pk}/queue_ccd/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientSyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSimpleSerializer>(AP + `/api/v4/patient-syncs/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PatientSyncViewSet = APIService.PatientSyncViewSet;

    static PatientViewSet = {
        add_physician_team: (pk: any, data: PhysicianTeamAddRemoveSerializer, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientSerializer>(AP + `/api/v4/patients/${pk}/add_physician_team/`, data, createReqSettings(queryParams, headers)),
        addresses: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRAddressWithRelatedPatientAddressModelSerializer[]>(AP + `/api/v4/patients/${pk}/addresses/`, createReqSettings(queryParams, headers)),
        allergies: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAllergySerializer[]>(AP + `/api/v4/patients/allergies/`, createReqSettings(queryParams, headers)),
        assessment: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/assessment/`, createReqSettings(queryParams, headers)),
        available_physician_teams: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<MultipleUserFacilityTaskCreateSerializer[]>(AP + `/api/v4/patients/${pk}/available_physician_teams/`, createReqSettings(queryParams, headers)),
        cerner_community_view_url: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CernerCommunityViewURLSerializer>(AP + `/api/v4/patients/${pk}/cerner_community_view_url/`, createReqSettings(queryParams, headers)),
        contact_points: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<FHIRContactPointWithRelatedPatientContactPointModelSerializer[]>(AP + `/api/v4/patients/${pk}/contact_points/`, createReqSettings(queryParams, headers)),
        create: (data: PatientSerializer, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientSerializer>(AP + `/api/v4/patients/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/patients/${pk}/`, createReqSettings(queryParams, headers)),
        details_for_copy: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsForCopySerializer>(AP + `/api/v4/patients/${pk}/details_for_copy/`, createReqSettings(queryParams, headers)),
        download_facesheet: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/download-facesheet/`, createReqSettings(queryParams, headers)),
        download_patient_list: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/download_patient_list/`, createReqSettings(queryParams, headers)),
        eligibility_check_history: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<EligibilityRequestDetailSerializer[]>(AP + `/api/v4/patients/${pk}/eligibility-check-history/`, createReqSettings(queryParams, headers)),
        export_ehi: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/export-ehi/`, createReqSettings(queryParams, headers)),
        extras: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientExtraSerializer>(AP + `/api/v4/patients/${pk}/extras/`, createReqSettings(queryParams, headers)),
        follow: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/follow/`, createReqSettings(queryParams, headers)),
        generate_ccd: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/generate-ccd/`, createReqSettings(queryParams, headers)),
        generate_upload_ccd: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentCDASerializer>(AP + `/api/v4/patients/${pk}/generate-upload-ccd/`, createReqSettings(queryParams, headers)),
        he_url: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/he_url/`, createReqSettings(queryParams, headers)),
        immunizations: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientImmunizationSerializer[]>(AP + `/api/v4/patients/${pk}/immunizations/`, createReqSettings(queryParams, headers)),
        last_vital_examinations: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<LastVitalExaminationsResponseSerializer>(AP + `/api/v4/patients/${pk}/last-vital-examinations/`, createReqSettings(queryParams, headers)),
        last_vitals: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/last_vitals/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientSerializer[]>>(AP + `/api/v4/patients/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientDetailSerializer[]>>(AP + `/api/v4/patients/list-detail/`, createReqSettings(queryParams, headers)),
        list_simple: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientSimpleWithPatientPhysicianTeamsReadSerializer[]>>(AP + `/api/v4/patients/list_simple/`, createReqSettings(queryParams, headers)),
        mds: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientMDSSerializer>(AP + `/api/v4/patients/${pk}/mds/`, createReqSettings(queryParams, headers)),
        medical_programs: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientPhysicianTeamMedicalProgramSerializer[]>(AP + `/api/v4/patients/${pk}/medical_programs/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientSerializer, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientSerializer>(AP + `/api/v4/patients/${pk}/`, data, createReqSettings(queryParams, headers)),
        patient_allergies: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<AllergySerializer[]>(AP + `/api/v4/patients/${pk}/patient_allergies/`, createReqSettings(queryParams, headers)),
        patient_census_list: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientCensusDetailSerializer[]>(AP + `/api/v4/patients/census/`, createReqSettings(queryParams, headers)),
        person_search: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonSearchSerializer>(AP + `/api/v4/patients/person_search/`, createReqSettings(queryParams, headers)),
        physician_teams: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<MultipleUserFacilityTaskCreateSerializer[]>(AP + `/api/v4/patients/${pk}/physician_teams/`, createReqSettings(queryParams, headers)),
        remove_physician_team: (pk: any, data: PhysicianTeamAddRemoveSerializer, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientSerializer>(AP + `/api/v4/patients/${pk}/remove_physician_team/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailSerializer>(AP + `/api/v4/patients/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSearchSerializer>(AP + `/api/v4/patients/search/`, createReqSettings(queryParams, headers)),
        set_primary_physician: (pk: any, data: SetPrimaryPhysicianSerializer, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientSerializer>(AP + `/api/v4/patients/${pk}/set-primary-physician/`, data, createReqSettings(queryParams, headers)),
        task_details: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientTaskDetailsSerializer>(AP + `/api/v4/patients/${pk}/task_details/`, createReqSettings(queryParams, headers)),
        unfollow: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSerializer>(AP + `/api/v4/patients/${pk}/unfollow/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientSerializer, queryParams?: PatientViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientSerializer>(AP + `/api/v4/patients/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PatientViewSet = APIService.PatientViewSet;

    static PatientsAtRiskView = {
        get: (queryParams?: PatientsAtRiskViewQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/patients-at-risks`, createReqSettings(queryParams, headers)),
    };

    PatientsAtRiskView = APIService.PatientsAtRiskView;

    static PatientsAvailableToRequestAccessPermissionViewSet = {
        list: (queryParams?: PatientsAvailableToRequestAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PatientAvailableToRequestAccessPermissionListSerializer[]>>(AP + `/api/v4/patients-available-to-request-access-permissions/`, createReqSettings(queryParams, headers)),
        print: (queryParams?: PatientsAvailableToRequestAccessPermissionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientAvailableToRequestAccessPermissionListSerializer>(AP + `/api/v4/patients-available-to-request-access-permissions/print/`, createReqSettings(queryParams, headers)),
    };

    PatientsAvailableToRequestAccessPermissionViewSet = APIService.PatientsAvailableToRequestAccessPermissionViewSet;

    static PatientsAvailableToSearchViewSet = {
        list: (queryParams?: PatientsAvailableToSearchViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientListWithLastVisitSerializer[]>(AP + `/api/v4/patients-available-to-search/`, createReqSettings(queryParams, headers)),
    };

    PatientsAvailableToSearchViewSet = APIService.PatientsAvailableToSearchViewSet;

    static PayerAltNameViewSet = {
        list: (queryParams?: PayerAltNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PayerAltNameDetailSerializer[]>>(AP + `/api/v4/payments/payer-names/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PayerAltNameViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PayerAltNameDetailSerializer>(AP + `/api/v4/payments/payer-names/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PayerAltNameViewSet = APIService.PayerAltNameViewSet;

    static PayerViewSet = {
        list: (queryParams?: PayerViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PayerSerializer[]>(AP + `/api/v4/payments/payers/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PayerViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PayerSerializer>(AP + `/api/v4/payments/payers/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PayerViewSet = APIService.PayerViewSet;

    static PersonIdViewSet = {
        create: (data: PersonIdCreateSerializer, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PersonIdCreateSerializer>(AP + `/api/v4/person-ids/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/person-ids/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PersonIdViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonIdSerializer[]>(AP + `/api/v4/person-ids/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PersonIdUpdateSerializer, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PersonIdUpdateSerializer>(AP + `/api/v4/person-ids/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonIdSerializer>(AP + `/api/v4/person-ids/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PersonIdUpdateSerializer, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PersonIdUpdateSerializer>(AP + `/api/v4/person-ids/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PersonIdViewSet = APIService.PersonIdViewSet;

    static PersonMergeEventViewSet = {
        confirm: (data: PersonMergeEventConfirmInputSerializer, queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PersonMergeEventSerializer>(AP + `/api/v4/person-merge-events/confirm/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/person-merge-events/${pk}/`, createReqSettings(queryParams, headers)),
        proposals: (queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PersonMergeEventSerializer[]>>(AP + `/api/v4/person-merge-events/proposals/`, createReqSettings(queryParams, headers)),
        propose: (data: PersonMergeEventInputSerializer, queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PersonMergeEventSerializer>(AP + `/api/v4/person-merge-events/propose/`, data, createReqSettings(queryParams, headers)),
        reject: (data: PersonMergeEventInputSerializer, queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PersonMergeEventSerializer>(AP + `/api/v4/person-merge-events/reject/`, data, createReqSettings(queryParams, headers)),
    };

    PersonMergeEventViewSet = APIService.PersonMergeEventViewSet;

    static PersonMergeProposalViewSet = {
        list: (queryParams?: PersonMergeProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonMergeProposalListSerializer[]>(AP + `/api/v4/person-merge-proposals/`, createReqSettings(queryParams, headers)),
        proposal_details: (queryParams?: PersonMergeProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonMergeProposalDetailSerializer>(AP + `/api/v4/person-merge-proposals/details/`, createReqSettings(queryParams, headers)),
    };

    PersonMergeProposalViewSet = APIService.PersonMergeProposalViewSet;

    static PersonViewSet = {
        list: (queryParams?: PersonViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonDetailedSerializer[]>(AP + `/api/v4/persons/`, createReqSettings(queryParams, headers)),
        patients: (pk: any, queryParams?: PersonViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonPatientsSerializer>(AP + `/api/v4/persons/${pk}/patients/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PersonViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PersonDetailedSerializer>(AP + `/api/v4/persons/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PersonViewSet = APIService.PersonViewSet;

    static PhysicianTeamMemberShipUpdate = {
        partial_update: (pk: any, data: UserFacilityMemberUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<UserFacilityMemberUpdateSerializer>(AP + `/api/v4/users-facilities-members/${pk}/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UserFacilityMemberUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<UserFacilityMemberUpdateSerializer>(AP + `/api/v4/users-facilities-members/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PhysicianTeamMemberShipUpdate = APIService.PhysicianTeamMemberShipUpdate;

    static PhysicianTeamQrda1ExportViewSet = {
        post: (data: CQMCalculateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/physician-team-qrda-export/qrda1-export/`, data, createReqSettings(queryParams, headers)),
    };

    PhysicianTeamQrda1ExportViewSet = APIService.PhysicianTeamQrda1ExportViewSet;

    static PhysicianTeamQrda3ExportViewSet = {
        post: (data: CQMCalculateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/physician-team-qrda-export/qrda3-export/`, data, createReqSettings(queryParams, headers)),
    };

    PhysicianTeamQrda3ExportViewSet = APIService.PhysicianTeamQrda3ExportViewSet;

    static PhysicianTeamUserView = {
        assign_user_by_email: (pk: any, data: PhysicianTeamAssignUserRequestByEmailSerializer, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null) => APIService.httpClient.post<UserFacilitySerializer[]>(AP + `/api/v4/physician-teams/edit-relations/${pk}/assign-user-by-email/`, data, createReqSettings(queryParams, headers)),
        create_membership_by_email: (pk: any, data: PhysicianTeamAssignUserRequestByEmailSerializer, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null) => APIService.httpClient.post<UserFacilitySerializer[]>(AP + `/api/v4/physician-teams/edit-relations/${pk}/create-membership-by-email/`, data, createReqSettings(queryParams, headers)),
        delete_membership: (pk: any, data: PhysicianTeamUnassignUserRequestSerializer, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null) => APIService.httpClient.post<UserFacilitySerializer[]>(AP + `/api/v4/physician-teams/edit-relations/${pk}/delete-membership/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null) => APIService.httpClient.delete<UserFacilitySerializer[]>(AP + `/api/v4/physician-teams/edit-relations/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PhysicianTeamUserView = APIService.PhysicianTeamUserView;

    static PhysicianViewSet = {
        create: (data: PhysicianCreateSerializer, queryParams?: PhysicianViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PhysicianSerializer>(AP + `/api/v4/physicians/`, data, createReqSettings(queryParams, headers)),
        list: (queryParams?: PhysicianViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PhysicianSerializer[]>(AP + `/api/v4/physicians/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PhysicianViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PhysicianSerializer>(AP + `/api/v4/physicians/${pk}/`, createReqSettings(queryParams, headers)),
    };

    PhysicianViewSet = APIService.PhysicianViewSet;

    static Ping = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/ping`, createReqSettings(queryParams, headers)),
    };

    Ping = APIService.Ping;

    static PrimaryVisitBillingCodeViewSet = {
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<VisitBillingCodeSerializer[]>(AP + `/api/v4/visit-billing-primary-codes/`, createReqSettings(queryParams, headers)),
    };

    PrimaryVisitBillingCodeViewSet = APIService.PrimaryVisitBillingCodeViewSet;

    static ProcedureViewSet = {
        create: (data: ProcedureCreateUpdateSerializer, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ProcedureReadOnlySerializer>(AP + `/api/v4/procedures/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/procedures/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProcedureReadOnlySerializer[]>(AP + `/api/v4/procedures/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProcedureReadOnlySerializer[]>(AP + `/api/v4/procedures/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ProcedureCreateUpdateSerializer, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ProcedureCreateUpdateSerializer>(AP + `/api/v4/procedures/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProcedureReadOnlySerializer>(AP + `/api/v4/procedures/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProcedureReadOnlySerializer>(AP + `/api/v4/procedures/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProcedureReadOnlySerializer>(AP + `/api/v4/procedures/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ProcedureCreateUpdateSerializer, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ProcedureReadOnlySerializer>(AP + `/api/v4/procedures/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ProcedureViewSet = APIService.ProcedureViewSet;

    static ProductivityReport = {
        list: (queryParams?: ProductivityReportQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskProductivitySerializer[]>(AP + `/api/v4/tasks/productivity-report/`, createReqSettings(queryParams, headers)),
    };

    ProductivityReport = APIService.ProductivityReport;

    static ProgressNoteViewSet = {
        create: (data: ProgressNoteSerializer, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ProgressNoteSerializer>(AP + `/api/v4/progress-notes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/progress-notes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProgressNoteSerializer[]>(AP + `/api/v4/progress-notes/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ProgressNoteSerializer, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ProgressNoteSerializer>(AP + `/api/v4/progress-notes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProgressNoteSerializer>(AP + `/api/v4/progress-notes/${pk}/`, createReqSettings(queryParams, headers)),
        summary: (queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ProgressNoteSummarySerializer>(AP + `/api/v4/progress-notes/summary/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ProgressNoteSerializer, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ProgressNoteSerializer>(AP + `/api/v4/progress-notes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ProgressNoteViewSet = APIService.ProgressNoteViewSet;

    static PsychotropicGroupsViewSet = {
        create: (data: PsychotropicGroupCreateOrUpdateSerializer, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PsychotropicGroupCreateOrUpdateSerializer>(AP + `/api/v4/psychotropic-groups/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/psychotropic-groups/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PsychotropicGroupReadSerializer[]>>(AP + `/api/v4/psychotropic-groups/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<PsychotropicGroupReadSerializer[]>>(AP + `/api/v4/psychotropic-groups/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PsychotropicGroupUpdateSerializer, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PsychotropicGroupUpdateSerializer>(AP + `/api/v4/psychotropic-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PsychotropicGroupReadSerializer>(AP + `/api/v4/psychotropic-groups/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PsychotropicGroupReadSerializer>(AP + `/api/v4/psychotropic-groups/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PsychotropicGroupReadSerializer>(AP + `/api/v4/psychotropic-groups/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PsychotropicGroupUpdateSerializer, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PsychotropicGroupUpdateSerializer>(AP + `/api/v4/psychotropic-groups/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    PsychotropicGroupsViewSet = APIService.PsychotropicGroupsViewSet;

    static PsychotropicsViewSet = {
        detailed_patient_list: (data: PsychotropicsQuerySerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<Paginated<DetailedPatientListPatientSerializer[]>>(AP + `/api/v4/psychotropics/detailed-patient-list/`, data, createReqSettings(queryParams, headers)),
        filtered_diagnosis_overview: (data: PsychotropicsQuerySerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PsychotropicsFilteredDiagnosisOverviewSerializer>(AP + `/api/v4/psychotropics/filtered-diagnosis-overview/`, data, createReqSettings(queryParams, headers)),
        filtered_population_overview: (data: PsychotropicsQuerySerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PsychotropicsFilteredPopulationOverviewSerializer>(AP + `/api/v4/psychotropics/filtered-population-overview/`, data, createReqSettings(queryParams, headers)),
        filtered_treatment_overview: (data: PsychotropicsQuerySerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<FilteredTreatmentOverviewSerializer>(AP + `/api/v4/psychotropics/filtered-treatment-overview/`, data, createReqSettings(queryParams, headers)),
        global_population_overview: (data: PsychotropicsPatientTreatmentSerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PsychotropicsGlobalPopulationOverviewSerializer>(AP + `/api/v4/psychotropics/global-population-overview/`, data, createReqSettings(queryParams, headers)),
        heatmap: (data: PsychotropicsQuerySerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PsychotropicsHeatmapSerializer>(AP + `/api/v4/psychotropics/heatmap/`, data, createReqSettings(queryParams, headers)),
        patient_details: (pk: any, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailTreatmentSerializer[]>(AP + `/api/v4/psychotropics/${pk}/patient-details/`, createReqSettings(queryParams, headers)),
        treatment_overview: (data: PsychotropicsQuerySerializer, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<Paginated<PsychotropicsTreatmentOverviewOutputSerializer[]>>(AP + `/api/v4/psychotropics/treatment-overview/`, data, createReqSettings(queryParams, headers)),
    };

    PsychotropicsViewSet = APIService.PsychotropicsViewSet;

    static QRDAViewSet = {
        post: (data: any, queryParams?: QRDAViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/qrda-report-generate`, data, createReqSettings(queryParams, headers)),
    };

    QRDAViewSet = APIService.QRDAViewSet;

    static RefreshTokenViewSet = {
        create: (data: RefreshTokenSerializer, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<RefreshTokenSerializer>(AP + `/api/oauth/refresh-tokens/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/oauth/refresh-tokens/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RefreshTokenSerializer[]>(AP + `/api/oauth/refresh-tokens/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: RefreshTokenSerializer, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<RefreshTokenSerializer>(AP + `/api/oauth/refresh-tokens/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RefreshTokenSerializer>(AP + `/api/oauth/refresh-tokens/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: RefreshTokenSerializer, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<RefreshTokenSerializer>(AP + `/api/oauth/refresh-tokens/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    RefreshTokenViewSet = APIService.RefreshTokenViewSet;

    static RehospitalizationView = {
        get: (queryParams?: RehospitalizationViewQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/rehospitalization_deprecated`, createReqSettings(queryParams, headers)),
    };

    RehospitalizationView = APIService.RehospitalizationView;

    static RelatedPersonModelViewSet = {
        create: (data: RelatedPersonSerializer, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<RelatedPersonSerializer>(AP + `/api/v4/related_person/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/related_person/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RelatedPersonSerializer[]>(AP + `/api/v4/related_person/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: RelatedPersonSerializer, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<RelatedPersonSerializer>(AP + `/api/v4/related_person/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RelatedPersonSerializer>(AP + `/api/v4/related_person/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: RelatedPersonSerializer, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<RelatedPersonSerializer>(AP + `/api/v4/related_person/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    RelatedPersonModelViewSet = APIService.RelatedPersonModelViewSet;

    static RemitsViewSet = {
        list: (queryParams?: RemitsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<RemitSerializer[]>>(AP + `/api/v4/remits/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: RemitsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RemitDetailSerializer>(AP + `/api/v4/remits/${pk}/`, createReqSettings(queryParams, headers)),
    };

    RemitsViewSet = APIService.RemitsViewSet;

    static ReportByUnitView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/report-by-units`, createReqSettings(queryParams, headers)),
    };

    ReportByUnitView = APIService.ReportByUnitView;

    static ReportViewSet = {
        cancel: (pk: any, queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ReportQueueSerializer>(AP + `/api/v4/report-queue/${pk}/cancel/`, createReqSettings(queryParams, headers)),
        create: (data: ReportQueueCreateSerializer, queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ReportQueueSerializer>(AP + `/api/v4/report-queue/`, data, createReqSettings(queryParams, headers)),
        list: (queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<ReportQueueSerializer[]>>(AP + `/api/v4/report-queue/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ReportQueueSerializer, queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ReportQueueSerializer>(AP + `/api/v4/report-queue/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ReportQueueSerializer>(AP + `/api/v4/report-queue/${pk}/`, createReqSettings(queryParams, headers)),
        stats: (queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ReportQueueStatSerializer>(AP + `/api/v4/report-queue/stats/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ReportQueueSerializer, queryParams?: ReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ReportQueueSerializer>(AP + `/api/v4/report-queue/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ReportViewSet = APIService.ReportViewSet;

    static ResidentDaysView = {
        get: (queryParams?: ResidentDaysViewQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/resident-days`, createReqSettings(queryParams, headers)),
    };

    ResidentDaysView = APIService.ResidentDaysView;

    static ResidentNoteViewSet = {
        create: (data: ResidentNoteSerializer, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<ResidentNoteSerializer>(AP + `/api/v4/resident-notes/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/resident-notes/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ResidentNoteSerializer[]>(AP + `/api/v4/resident-notes/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: ResidentNoteSerializer, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<ResidentNoteSerializer>(AP + `/api/v4/resident-notes/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ResidentNoteSerializer>(AP + `/api/v4/resident-notes/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: ResidentNoteSerializer, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<ResidentNoteSerializer>(AP + `/api/v4/resident-notes/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    ResidentNoteViewSet = APIService.ResidentNoteViewSet;

    static RxNormViewSet = {
        list: (queryParams?: RxNormViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<RxNormCodeSerializer[]>>(AP + `/api/v4/rx-norm/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: RxNormViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<RxNormCodeSerializer>(AP + `/api/v4/rx-norm/${pk}/`, createReqSettings(queryParams, headers)),
    };

    RxNormViewSet = APIService.RxNormViewSet;

    static SMARTAuthorizationView = {
        get: (queryParams?: SMARTAuthorizationViewQueryParams, headers: Headers = null) => APIService.httpClient.get<AuthorizationGetResponseSerializer>(AP + `/o/smart_authorization/`, createReqSettings(queryParams, headers)),
        post: (data: AuthorizationRequestSerializer, queryParams?: SMARTAuthorizationViewQueryParams, headers: Headers = null) => APIService.httpClient.post<OAuthRedirectURISerializer>(AP + `/o/smart_authorization/`, data, createReqSettings(queryParams, headers)),
    };

    SMARTAuthorizationView = APIService.SMARTAuthorizationView;

    static SSNModelViewSet = {
        create: (data: PatientDetailsUpdateSerializer, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<PatientDetailsUpdateSerializer>(AP + `/api/v4/ssn/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/ssn/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsUpdateSerializer[]>(AP + `/api/v4/ssn/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsUpdateSerializer[]>(AP + `/api/v4/ssn/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: PatientDetailsUpdateSerializer, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<PatientDetailsUpdateSerializer>(AP + `/api/v4/ssn/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsUpdateSerializer>(AP + `/api/v4/ssn/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsUpdateSerializer>(AP + `/api/v4/ssn/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDetailsUpdateSerializer>(AP + `/api/v4/ssn/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: PatientDetailsUpdateSerializer, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<PatientDetailsUpdateSerializer>(AP + `/api/v4/ssn/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    SSNModelViewSet = APIService.SSNModelViewSet;

    static ScopeDescriptionView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<ApplicationScopesSerializer>(AP + `/api/oauth/scopes`, createReqSettings(queryParams, headers)),
    };

    ScopeDescriptionView = APIService.ScopeDescriptionView;

    static SearchBillingCodeViewSet = {
        list: (queryParams?: SearchBillingCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CptCodeSerializer[]>(AP + `/api/v4/neo-billing-codes/`, createReqSettings(queryParams, headers)),
    };

    SearchBillingCodeViewSet = APIService.SearchBillingCodeViewSet;

    static SearchBillingModifierViewSet = {
        list: (queryParams?: SearchBillingModifierViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CptCodeSerializer[]>(AP + `/api/v4/neo-billing-modifiers/`, createReqSettings(queryParams, headers)),
    };

    SearchBillingModifierViewSet = APIService.SearchBillingModifierViewSet;

    static SearchCptCodeViewSet = {
        list: (queryParams?: SearchCptCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CptCodeSerializer[]>(AP + `/api/v4/neo-cpt-codes/`, createReqSettings(queryParams, headers)),
    };

    SearchCptCodeViewSet = APIService.SearchCptCodeViewSet;

    static SearchView = {
        get: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/search`, createReqSettings(queryParams, headers)),
    };

    SearchView = APIService.SearchView;

    static SecondaryVisitBillingCodeViewSet = {
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<VisitBillingCodeSerializer[]>(AP + `/api/v4/visit-billing-secondary-codes/`, createReqSettings(queryParams, headers)),
    };

    SecondaryVisitBillingCodeViewSet = APIService.SecondaryVisitBillingCodeViewSet;

    static SendMailView = {
        post: (data: SendMailSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/send-mail`, data, createReqSettings(queryParams, headers)),
    };

    SendMailView = APIService.SendMailView;

    static ServiceCodeViewSet = {
        list: (queryParams?: ServiceCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ServiceCodeSerializer[]>(AP + `/api/v4/payments/service-codes/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ServiceCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ServiceCodeSerializer>(AP + `/api/v4/payments/service-codes/${pk}/`, createReqSettings(queryParams, headers)),
    };

    ServiceCodeViewSet = APIService.ServiceCodeViewSet;

    static SnomedConceptSearchViewSet = {
        retrieve: (pk: any, queryParams?: SnomedConceptSearchViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SnomedConceptSerializer>(AP + `/api/v4/snomed_concept_search/${pk}/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: SnomedConceptSearchViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SnomedCTConceptSearchResultSerializer[]>(AP + `/api/v4/snomed_concept_search/search/`, createReqSettings(queryParams, headers)),
    };

    SnomedConceptSearchViewSet = APIService.SnomedConceptSearchViewSet;

    static StatusObservationViewSet = {
        create: (data: StatusObservationCreateUpdateSerializer, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<StatusObservationCreateUpdateSerializer>(AP + `/api/v4/status-observations/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/status-observations/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<StatusObservationReadSerializer[]>>(AP + `/api/v4/status-observations/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: StatusObservationCreateUpdateSerializer, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<StatusObservationCreateUpdateSerializer>(AP + `/api/v4/status-observations/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<StatusObservationReadSerializer>(AP + `/api/v4/status-observations/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: StatusObservationCreateUpdateSerializer, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<StatusObservationCreateUpdateSerializer>(AP + `/api/v4/status-observations/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    StatusObservationViewSet = APIService.StatusObservationViewSet;

    static SyncDetailPaginatedViewSet = {
        list: (queryParams?: SyncDetailPaginatedViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<SyncDetailSimpleSerializer[]>>(AP + `/api/v4/sync_detail_paginated/`, createReqSettings(queryParams, headers)),
    };

    SyncDetailPaginatedViewSet = APIService.SyncDetailPaginatedViewSet;

    static SyncDetailViewSet = {
        list: (queryParams?: SyncDetailViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncDetailSimpleSerializer[]>(AP + `/api/v4/sync_detail/`, createReqSettings(queryParams, headers)),
    };

    SyncDetailViewSet = APIService.SyncDetailViewSet;

    static SyncProcessViewSet = {
        list: (queryParams?: SyncProcessViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncProcessSerializer[]>(AP + `/api/v4/sync_process/`, createReqSettings(queryParams, headers)),
    };

    SyncProcessViewSet = APIService.SyncProcessViewSet;

    static SyncViewSet = {
        create: (data: SyncSerializer, queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<SyncSerializer>(AP + `/api/v4/syncs/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/syncs/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<SyncSerializer[]>>(AP + `/api/v4/syncs/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<SyncSerializer[]>>(AP + `/api/v4/syncs/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: SyncSerializer, queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<SyncSerializer>(AP + `/api/v4/syncs/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncSerializer>(AP + `/api/v4/syncs/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncSerializer>(AP + `/api/v4/syncs/${pk}/detail/`, createReqSettings(queryParams, headers)),
        schedule_table: (queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/syncs/schedule-table/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<SyncSerializer>(AP + `/api/v4/syncs/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: SyncSerializer, queryParams?: SyncViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<SyncSerializer>(AP + `/api/v4/syncs/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    SyncViewSet = APIService.SyncViewSet;

    static SystemAlertViewSet = {
        create: (data: SystemAlertSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<SystemAlertSerializer>(AP + `/api/v4/system_alerts/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/system_alerts/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<SystemAlertSerializer[]>(AP + `/api/v4/system_alerts/`, createReqSettings(queryParams, headers)),
        list_active: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<SystemAlertSerializer[]>(AP + `/api/v4/system_alerts/list_active/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: SystemAlertSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<SystemAlertSerializer>(AP + `/api/v4/system_alerts/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<SystemAlertSerializer>(AP + `/api/v4/system_alerts/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: SystemAlertSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<SystemAlertSerializer>(AP + `/api/v4/system_alerts/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    SystemAlertViewSet = APIService.SystemAlertViewSet;

    static TaskAssignedUserViewSet = {
        assign_user: (data: TaskAssignedUserCreateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<TaskSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/assign-user/`, data, createReqSettings(queryParams, headers)),
        batch_update: (data: TaskAssignedUserBatchUpdateSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<TaskSimpleSerializer[]>(AP + `/api/v4/tasks/task-assigned-users/batch-update/`, data, createReqSettings(queryParams, headers)),
        create: (data: TaskAssignedUserSimpleSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<TaskAssignedUserSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<TaskAssignedUserSimpleSerializer[]>(AP + `/api/v4/tasks/task-assigned-users/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<TaskAssignedUserSimpleSerializer[]>(AP + `/api/v4/tasks/task-assigned-users/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TaskAssignedUserSimpleSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.patch<TaskAssignedUserSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<TaskAssignedUserSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<TaskAssignedUserSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<TaskAssignedUserSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TaskAssignedUserSimpleSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.put<TaskSimpleSerializer>(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TaskAssignedUserViewSet = APIService.TaskAssignedUserViewSet;

    static TaskBillingStatusViewSet = {
        batch_update: (data: TaskVisitBillingStatusBatchUpdateSerializer, queryParams?: TaskBillingStatusViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/tasks/billing-status/batch-update/`, data, createReqSettings(queryParams, headers)),
    };

    TaskBillingStatusViewSet = APIService.TaskBillingStatusViewSet;

    static TaskHistory = {
        download_patient: (queryParams?: TaskHistoryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer>(AP + `/api/v4/tasks/task-history/download_patient/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TaskHistoryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer[]>(AP + `/api/v4/tasks/task-history/`, createReqSettings(queryParams, headers)),
        patients: (queryParams?: TaskHistoryQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientSimpleWithDurationSerializer[]>(AP + `/api/v4/tasks/task-history/patients/`, createReqSettings(queryParams, headers)),
    };

    TaskHistory = APIService.TaskHistory;

    static TaskLastValuesView = {
        get: (queryParams?: TaskLastValuesViewQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskPreviousValuesSerializer>(AP + `/api/v4/tasks/last-values`, createReqSettings(queryParams, headers)),
    };

    TaskLastValuesView = APIService.TaskLastValuesView;

    static TaskProposalViewSet = {
        create: (data: TaskProposalCreateSerializer, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TaskProposalSerializer>(AP + `/api/v4/task-proposals/`, data, createReqSettings(queryParams, headers)),
        create_or_update: (data: TaskProposalCreateSerializer, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TaskProposalSerializer>(AP + `/api/v4/task-proposals/create_or_update/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/task-proposals/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskProposalSerializer[]>(AP + `/api/v4/task-proposals/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TaskProposalCreateSerializer, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TaskProposalSerializer>(AP + `/api/v4/task-proposals/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskProposalSerializer>(AP + `/api/v4/task-proposals/${pk}/`, createReqSettings(queryParams, headers)),
        sort: (data: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/task-proposals/sort/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TaskProposalSerializer, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TaskProposalSerializer>(AP + `/api/v4/task-proposals/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TaskProposalViewSet = APIService.TaskProposalViewSet;

    static TaskSchedulerViewSet = {
        list: (queryParams?: TaskSchedulerViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TasksForSchedulerSerializer[]>>(AP + `/api/v4/visit-list/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TaskSchedulerViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TasksForSchedulerSerializer>(AP + `/api/v4/visit-list/${pk}/`, createReqSettings(queryParams, headers)),
    };

    TaskSchedulerViewSet = APIService.TaskSchedulerViewSet;

    static TaskStatusHistoryViewSet = {
        list: (queryParams?: TaskStatusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskStatusHistorySerializer[]>(AP + `/api/v4/tasks/status-history/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TaskStatusHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskStatusHistorySerializer>(AP + `/api/v4/tasks/status-history/${pk}/`, createReqSettings(queryParams, headers)),
    };

    TaskStatusHistoryViewSet = APIService.TaskStatusHistoryViewSet;

    static TaskSummary = {
        download: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer>(AP + `/api/v4/tasks/task-summary/download/`, createReqSettings(queryParams, headers)),
        download_summary: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer>(AP + `/api/v4/tasks/task-summary/download_summary/`, createReqSettings(queryParams, headers)),
        download_summary_by_member: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer>(AP + `/api/v4/tasks/task-summary/download_summary_by_member/`, createReqSettings(queryParams, headers)),
        invalid_tasks: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<ValidatedBillingTaskSerializer[]>(AP + `/api/v4/tasks/task-summary/invalid_tasks/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer[]>(AP + `/api/v4/tasks/task-summary/`, createReqSettings(queryParams, headers)),
        overview: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummaryWithBillingValidationSerializer[]>(AP + `/api/v4/tasks/task-summary/overview/`, createReqSettings(queryParams, headers)),
        patient_tasks: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<ValidatedBillingTaskSerializer[]>(AP + `/api/v4/tasks/task-summary/patient_tasks/`, createReqSettings(queryParams, headers)),
        patients: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummaryWithBillingValidationSerializer[]>(AP + `/api/v4/tasks/task-summary/patients/`, createReqSettings(queryParams, headers)),
        summary_by_code: (queryParams?: TaskSummaryQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSummarySerializer>(AP + `/api/v4/tasks/task-summary/summary_by_code/`, createReqSettings(queryParams, headers)),
    };

    TaskSummary = APIService.TaskSummary;

    static TaskViewSet = {
        approve_for_billing: (pk: any, data: TaskVisitBillingStatusSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TaskStatusHistorySerializer>(AP + `/api/v4/tasks/tasks/${pk}/approve-for-billing/`, data, createReqSettings(queryParams, headers)),
        batch_pdf_download: (queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSerializer>(AP + `/api/v4/tasks/tasks/batch_pdf_download/`, createReqSettings(queryParams, headers)),
        batch_resolve: (data: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TaskAssignedUserSerializer[]>(AP + `/api/v4/tasks/tasks/batch-resolve/`, data, createReqSettings(queryParams, headers)),
        create: (data: TaskCreateSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TaskSerializer>(AP + `/api/v4/tasks/tasks/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/tasks/tasks/${pk}/`, createReqSettings(queryParams, headers)),
        download_as_ccd: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSerializer>(AP + `/api/v4/tasks/tasks/${pk}/download-as-ccd/`, createReqSettings(queryParams, headers)),
        download_history: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskDownloadHistorySerializer[]>(AP + `/api/v4/tasks/tasks/${pk}/download-history/`, createReqSettings(queryParams, headers)),
        download_pdf: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSerializer>(AP + `/api/v4/tasks/tasks/${pk}/download-pdf/`, createReqSettings(queryParams, headers)),
        generate_upload_ccd: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientDocumentCDASerializer>(AP + `/api/v4/tasks/tasks/${pk}/generate-upload-ccd/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TaskSimpleSerializer[]>>(AP + `/api/v4/tasks/tasks/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TaskCreateSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TaskSerializer>(AP + `/api/v4/tasks/tasks/${pk}/`, data, createReqSettings(queryParams, headers)),
        remove_billing_approval: (pk: any, data: TaskSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TaskSerializer>(AP + `/api/v4/tasks/tasks/${pk}/remove-billing-approval/`, data, createReqSettings(queryParams, headers)),
        resolve_all_non_draft_notes: (data: ResolveAllNonDraftNotesSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TaskSimpleSerializer[]>(AP + `/api/v4/tasks/tasks/resolve-all-non-draft-notes/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskReadOnlySerializer>(AP + `/api/v4/tasks/tasks/${pk}/`, createReqSettings(queryParams, headers)),
        review_for_scheduling: (pk: any, data: SchedulingReviewCreateSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<SchedulingReviewSerializer>(AP + `/api/v4/tasks/tasks/${pk}/review_for_scheduling/`, data, createReqSettings(queryParams, headers)),
        set_billing_status: (pk: any, data: TaskVisitBillingStatusSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TaskStatusHistorySerializer>(AP + `/api/v4/tasks/tasks/${pk}/set-billing-status/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TaskCreateSerializer, queryParams?: TaskViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TaskSerializer>(AP + `/api/v4/tasks/tasks/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TaskViewSet = APIService.TaskViewSet;

    static TokenObtainPairView = {
        post: (data: TokenObtainPairSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<TokenObtainPairSerializer>(AP + `/api/v4/auth/login`, data, createReqSettings(queryParams, headers)),
    };

    TokenObtainPairView = APIService.TokenObtainPairView;

    static TokenRefreshView = {
        post: (data: TokenRefreshSerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<TokenRefreshSerializer>(AP + `/api/v4/auth/refresh`, data, createReqSettings(queryParams, headers)),
    };

    TokenRefreshView = APIService.TokenRefreshView;

    static TokenVerifyView = {
        post: (data: TokenVerifySerializer, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<TokenVerifySerializer>(AP + `/api/v4/auth/verify`, data, createReqSettings(queryParams, headers)),
    };

    TokenVerifyView = APIService.TokenVerifyView;

    static TreatmentEntriesBySpecialtyViewSet = {
        list: (queryParams?: TreatmentEntriesBySpecialtyViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistorySerializer[]>(AP + `/api/v4/treatment-history-by-specialty/`, createReqSettings(queryParams, headers)),
    };

    TreatmentEntriesBySpecialtyViewSet = APIService.TreatmentEntriesBySpecialtyViewSet;

    static TreatmentHistoryAntibioticsViewSet = {
        create: (data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antibiotics-treatments/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/antibiotics-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/antibiotics-treatments/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antibiotics-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antibiotics-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antibiotics-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryAntibioticsViewSet = APIService.TreatmentHistoryAntibioticsViewSet;

    static TreatmentHistoryAntidepressantsViewSet = {
        create: (data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antidepressants-treatments/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/antidepressants-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/antidepressants-treatments/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antidepressants-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antidepressants-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antidepressants-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryAntidepressantsViewSet = APIService.TreatmentHistoryAntidepressantsViewSet;

    static TreatmentHistoryAntipsychoticsViewSet = {
        create: (data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antipsychotics-treatments/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/antipsychotics-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/antipsychotics-treatments/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antipsychotics-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antipsychotics-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/antipsychotics-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryAntipsychoticsViewSet = APIService.TreatmentHistoryAntipsychoticsViewSet;

    static TreatmentHistoryAuditViewSet = {
        create: (data: TreatmentHistoryAuditSerializer, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryAuditSerializer>(AP + `/api/v4/treatment-history-audit/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/treatment-history-audit/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryAuditSerializer[]>(AP + `/api/v4/treatment-history-audit/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryAuditSerializer, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryAuditSerializer>(AP + `/api/v4/treatment-history-audit/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryAuditSerializer>(AP + `/api/v4/treatment-history-audit/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryAuditSerializer, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryAuditSerializer>(AP + `/api/v4/treatment-history-audit/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryAuditViewSet = APIService.TreatmentHistoryAuditViewSet;

    static TreatmentHistoryICDUpdateViewSet = {
        partial_update: (pk: any, data: TreatmentHistoryUpdateICDSerializer, queryParams?: TreatmentHistoryICDUpdateViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryUpdateICDSerializer>(AP + `/api/v4/treatment-history-icd-update/${pk}/`, data, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryUpdateICDSerializer, queryParams?: TreatmentHistoryICDUpdateViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryUpdateICDSerializer>(AP + `/api/v4/treatment-history-icd-update/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryICDUpdateViewSet = APIService.TreatmentHistoryICDUpdateViewSet;

    static TreatmentHistoryNarcoticsViewSet = {
        create: (data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/narcotics-treatments/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/narcotics-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/narcotics-treatments/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/narcotics-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/narcotics-treatments/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryDetailedRelatedTasksSerializer, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/narcotics-treatments/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryNarcoticsViewSet = APIService.TreatmentHistoryNarcoticsViewSet;

    static TreatmentHistoryOriginsViewSet = {
        create: (data: TreatmentHistoryOriginSerializer, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryOriginSerializer>(AP + `/api/v4/treatment-history-origins/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/treatment-history-origins/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryOriginSerializer[]>(AP + `/api/v4/treatment-history-origins/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryOriginSerializer, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryOriginSerializer>(AP + `/api/v4/treatment-history-origins/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryOriginSerializer>(AP + `/api/v4/treatment-history-origins/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryOriginSerializer, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryOriginSerializer>(AP + `/api/v4/treatment-history-origins/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryOriginsViewSet = APIService.TreatmentHistoryOriginsViewSet;

    static TreatmentHistoryViewSet = {
        batch_delete: (data: TreatmentHistoryBatchDeleteSerializer, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/treatment-history/batch-delete/`, data, createReqSettings(queryParams, headers)),
        create: (data: TreatmentHistoryCreateSerializer, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<TreatmentHistoryCreateSerializer>(AP + `/api/v4/treatment-history/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/treatment-history/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/treatment-history/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TreatmentHistoryDetailedRelatedTasksSerializer[]>>(AP + `/api/v4/treatment-history/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: TreatmentHistoryCreateSerializer, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<TreatmentHistoryCreateSerializer>(AP + `/api/v4/treatment-history/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/treatment-history/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/treatment-history/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TreatmentHistoryDetailedRelatedTasksSerializer>(AP + `/api/v4/treatment-history/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: TreatmentHistoryCreateSerializer, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<TreatmentHistoryCreateSerializer>(AP + `/api/v4/treatment-history/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    TreatmentHistoryViewSet = APIService.TreatmentHistoryViewSet;

    static TrendViewSet = {
        infections: (queryParams?: TrendViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/trends/infections/`, createReqSettings(queryParams, headers)),
    };

    TrendViewSet = APIService.TrendViewSet;

    static UnitViewSet = {
        create: (data: UnitSerializer, queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<UnitSerializer>(AP + `/api/v4/units/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/units/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UnitSerializer[]>>(AP + `/api/v4/units/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UnitSerializer[]>>(AP + `/api/v4/units/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: UnitSerializer, queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<UnitSerializer>(AP + `/api/v4/units/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnitSerializer>(AP + `/api/v4/units/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnitSerializer>(AP + `/api/v4/units/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnitSerializer>(AP + `/api/v4/units/search/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UnitSerializer, queryParams?: UnitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<UnitSerializer>(AP + `/api/v4/units/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    UnitViewSet = APIService.UnitViewSet;

    static UnmatchedPatientActionViewSet = {
        category_count: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<CategoryCountResponseSerializer[]>(AP + `/api/v4/unmatched-patient-action/category-counts/`, createReqSettings(queryParams, headers)),
        create: (data: UnmatchedPatientActionSerializer, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<UnmatchedPatientActionSerializer>(AP + `/api/v4/unmatched-patient-action/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/unmatched-patient-action/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnmatchedPatientActionSerializer[]>(AP + `/api/v4/unmatched-patient-action/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: UnmatchedPatientActionSerializer, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<UnmatchedPatientActionSerializer>(AP + `/api/v4/unmatched-patient-action/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnmatchedPatientActionSerializer>(AP + `/api/v4/unmatched-patient-action/${pk}/`, createReqSettings(queryParams, headers)),
        source_counts: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UnmatchedPatientActionSerializer>(AP + `/api/v4/unmatched-patient-action/source-counts/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UnmatchedPatientActionSerializer, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<UnmatchedPatientActionSerializer>(AP + `/api/v4/unmatched-patient-action/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    UnmatchedPatientActionViewSet = APIService.UnmatchedPatientActionViewSet;

    static UsedICDCodeViewSet = {
        get: (queryParams?: UsedICDCodeViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/used-icd-codes`, createReqSettings(queryParams, headers)),
    };

    UsedICDCodeViewSet = APIService.UsedICDCodeViewSet;

    static UserAccessViewSet = {
        list: (queryParams?: UserAccessViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UserAccessLogSerializer[]>>(AP + `/api/v4/user-access-log/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UserAccessViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserAccessLogSerializer>(AP + `/api/v4/user-access-log/${pk}/`, createReqSettings(queryParams, headers)),
    };

    UserAccessViewSet = APIService.UserAccessViewSet;

    static UserFacilityViewSet = {
        create: (data: UserFacilitySerializer, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<UserFacilitySerializer>(AP + `/api/v4/users-facilities/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/users-facilities/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserFacilitySerializer[]>(AP + `/api/v4/users-facilities/`, createReqSettings(queryParams, headers)),
        list_filtered: (queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserFacilitySerializer[]>(AP + `/api/v4/users-facilities/list_filtered/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: UserFacilitySerializer, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<UserFacilitySerializer>(AP + `/api/v4/users-facilities/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserFacilitySerializer>(AP + `/api/v4/users-facilities/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UserFacilitySerializer, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<UserFacilitySerializer>(AP + `/api/v4/users-facilities/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    UserFacilityViewSet = APIService.UserFacilityViewSet;

    static UserLogViewSet = {
        list: (queryParams?: UserLogViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UserLogSerializer[]>>(AP + `/api/v4/user-logs/`, createReqSettings(queryParams, headers)),
        logs: (pk: any, queryParams?: UserLogViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserLogSerializer>(AP + `/api/v4/user-logs/${pk}/logs/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UserLogViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserLogWithSigninsSerializer>(AP + `/api/v4/user-logs/${pk}/`, createReqSettings(queryParams, headers)),
    };

    UserLogViewSet = APIService.UserLogViewSet;

    static UserSettingsViewSet = {
        partial_update: (data: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/user-settings`, data, createReqSettings(queryParams, headers)),
        retrieve: (queryParams: any = null, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/user-settings`, createReqSettings(queryParams, headers)),
    };

    UserSettingsViewSet = APIService.UserSettingsViewSet;

    static UserStatusChangesViewSet = {
        list: (queryParams?: UserStatusChangesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserStatusChangeReadOnlySerializer[]>(AP + `/api/v4/user-status-changes/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UserStatusChangesViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserStatusChangeReadOnlySerializer>(AP + `/api/v4/user-status-changes/${pk}/`, createReqSettings(queryParams, headers)),
    };

    UserStatusChangesViewSet = APIService.UserStatusChangesViewSet;

    static UserTimeSpentOnLocationViewSet = {
        create: (data: UserTimeSpentOnLocationSerializer, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<UserTimeSpentOnLocationSerializer>(AP + `/api/v4/user-time-spent-on-locations/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UserTimeSpentOnLocationSerializer[]>>(AP + `/api/v4/user-time-spent-on-locations/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: UserTimeSpentOnLocationSerializer, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<UserTimeSpentOnLocationSerializer>(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserTimeSpentOnLocationSerializer>(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UserTimeSpentOnLocationSerializer, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<UserTimeSpentOnLocationSerializer>(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    UserTimeSpentOnLocationViewSet = APIService.UserTimeSpentOnLocationViewSet;

    static UserViewSet = {
        create: (data: UserSerializer, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<UserSerializer>(AP + `/api/v4/users/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/users/${pk}/`, createReqSettings(queryParams, headers)),
        get_password_change_url: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/users/${pk}/get_password_change_url/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UserSerializer[]>>(AP + `/api/v4/users/`, createReqSettings(queryParams, headers)),
        list_detail: (queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<UserDetailedSerializer[]>>(AP + `/api/v4/users/list-detail/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: UserSerializer, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<UserSerializer>(AP + `/api/v4/users/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserBaseSerializer>(AP + `/api/v4/users/${pk}/`, createReqSettings(queryParams, headers)),
        retrieve_detail: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserDetailedSerializer>(AP + `/api/v4/users/${pk}/detail/`, createReqSettings(queryParams, headers)),
        search: (queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserSearchSerializer>(AP + `/api/v4/users/search/`, createReqSettings(queryParams, headers)),
        send_password_change_mail: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<NewPasswordRequestSerializer>(AP + `/api/v4/users/${pk}/send_password_change_mail/`, createReqSettings(queryParams, headers)),
        status_changes: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<UserStatusChangeReadOnlySerializer[]>(AP + `/api/v4/users/${pk}/status_changes/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: UserSerializer, queryParams?: UserViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<UserSerializer>(AP + `/api/v4/users/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    UserViewSet = APIService.UserViewSet;

    static ValueSetViewSet = {
        list: (queryParams?: ValueSetViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ValueSetValueSerializer[]>(AP + `/api/v4/valuesets/`, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: ValueSetViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<ValueSetValueSerializer>(AP + `/api/v4/valuesets/${pk}/`, createReqSettings(queryParams, headers)),
    };

    ValueSetViewSet = APIService.ValueSetViewSet;

    static VerifyFax = {
        post: (token: any, pk: any, fax_type: any, data: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/ccm/verify-fax/${token}/${pk}/${fax_type}`, data, createReqSettings(queryParams, headers)),
    };

    VerifyFax = APIService.VerifyFax;

    static VerifyFaxNew = {
        post: (token: any, data: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/ccm/verify-fax-new/${token}`, data, createReqSettings(queryParams, headers)),
    };

    VerifyFaxNew = APIService.VerifyFaxNew;

    static VerifyPharmacyOrder = {
        post: (data: any, queryParams: any = null, headers: Headers = null) => APIService.httpClient.post<any>(AP + `/api/v4/verify-pharmacy-order/`, data, createReqSettings(queryParams, headers)),
    };

    VerifyPharmacyOrder = APIService.VerifyPharmacyOrder;

    static VisitReportAuditViewSet = {
        batch_create_cmrns: (data: BatchUpdateCMRNSerializer, queryParams?: VisitReportAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<BatchUpdateCMRNResponseSerializer>(AP + `/api/v4/visit-reports-audit/batch_create_cmrns/`, data, createReqSettings(queryParams, headers)),
        check_patient_cmrns: (queryParams?: VisitReportAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientsWithoutCMRNSerializer>(AP + `/api/v4/visit-reports-audit/check_patient_cmrns/`, createReqSettings(queryParams, headers)),
        patients_with_cmrn: (queryParams?: VisitReportAuditViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<PatientWithCMRNSerializer[]>(AP + `/api/v4/visit-reports-audit/patients_with_cmrn/`, createReqSettings(queryParams, headers)),
    };

    VisitReportAuditViewSet = APIService.VisitReportAuditViewSet;

    static VisitReportViewSet = {
        download_visit_billing: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSerializer>(AP + `/api/v4/visit-reports/download_visit_billing/`, createReqSettings(queryParams, headers)),
        download_visit_billing_extended: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSerializer>(AP + `/api/v4/visit-reports/download_visit_billing_extended/`, createReqSettings(queryParams, headers)),
        download_visit_productivity: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskSerializer>(AP + `/api/v4/visit-reports/download_visit_productivity/`, createReqSettings(queryParams, headers)),
        has_drafts: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/visit-reports/has_drafts/`, createReqSettings(queryParams, headers)),
        summary: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<any>(AP + `/api/v4/visit-reports/summary/`, createReqSettings(queryParams, headers)),
        tasks: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<TaskBillingSerializer[]>(AP + `/api/v4/visit-reports/tasks/`, createReqSettings(queryParams, headers)),
        tasks_for_scheduler_paginated: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TasksForSchedulerSerializer[]>>(AP + `/api/v4/visit-reports/tasks_for_scheduler_paginated/`, createReqSettings(queryParams, headers)),
        tasks_paginated: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<TaskBillingSerializer[]>>(AP + `/api/v4/visit-reports/tasks_paginated/`, createReqSettings(queryParams, headers)),
    };

    VisitReportViewSet = APIService.VisitReportViewSet;

    static VisitViewSet = {
        create: (data: VisitCreateSerializer, queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<VisitSerializer>(AP + `/api/v4/visits/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/visits/${pk}/`, createReqSettings(queryParams, headers)),
        download_visit_summary_by_patient: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<VisitSerializer>(AP + `/api/v4/visits/${pk}/download-visit-summary/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<VisitSerializer[]>(AP + `/api/v4/visits/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: VisitCreateSerializer, queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<VisitSerializer>(AP + `/api/v4/visits/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<VisitSerializer>(AP + `/api/v4/visits/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: VisitCreateSerializer, queryParams?: VisitViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<VisitSerializer>(AP + `/api/v4/visits/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    VisitViewSet = APIService.VisitViewSet;

    static VitalViewSet = {
        create: (data: VitalSerializer, queryParams?: VitalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<VitalSerializer>(AP + `/api/v4/vitals/`, data, createReqSettings(queryParams, headers)),
        destroy: (pk: any, queryParams?: VitalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.delete<void>(AP + `/api/v4/vitals/${pk}/`, createReqSettings(queryParams, headers)),
        list: (queryParams?: VitalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<VitalSerializer[]>>(AP + `/api/v4/vitals/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: VitalSerializer, queryParams?: VitalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<VitalSerializer>(AP + `/api/v4/vitals/${pk}/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: VitalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<VitalSerializer>(AP + `/api/v4/vitals/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: VitalSerializer, queryParams?: VitalViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<VitalSerializer>(AP + `/api/v4/vitals/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    VitalViewSet = APIService.VitalViewSet;

    static WorkListViewSet = {
        list: (queryParams?: WorkListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<Paginated<WorkListItemReadSerializer[]>>(AP + `/api/v4/work-list/`, createReqSettings(queryParams, headers)),
        partial_update: (pk: any, data: WorkListItemReadSerializer, queryParams?: WorkListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.patch<WorkListItemReadSerializer>(AP + `/api/v4/work-list/${pk}/`, data, createReqSettings(queryParams, headers)),
        reject: (pk: any, data: WorkListItemReadSerializer, queryParams?: WorkListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.post<WorkListItemRejectReadSerializer>(AP + `/api/v4/work-list/${pk}/reject/`, data, createReqSettings(queryParams, headers)),
        retrieve: (pk: any, queryParams?: WorkListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.get<WorkListItemReadSerializer>(AP + `/api/v4/work-list/${pk}/`, createReqSettings(queryParams, headers)),
        update: (pk: any, data: WorkListItemReadSerializer, queryParams?: WorkListViewSetQueryParams, headers: Headers = null) => APIService.httpClient.put<WorkListItemReadSerializer>(AP + `/api/v4/work-list/${pk}/`, data, createReqSettings(queryParams, headers)),
    };

    WorkListViewSet = APIService.WorkListViewSet;

    static urls = {
        AccessTokenViewSet: {
            create: (queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/access-tokens/`, queryParams),
            destroy: (pk: any, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/access-tokens/${pk}/`, queryParams),
            list: (queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/access-tokens/`, queryParams),
            partial_update: (pk: any, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/access-tokens/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/access-tokens/${pk}/`, queryParams),
            update: (pk: any, queryParams?: AccessTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/access-tokens/${pk}/`, queryParams),
        },

        ActionViewSet: {
            list: (queryParams?: ActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/actions/`, queryParams),
            resolve: (pk: any, queryParams?: ActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/actions/${pk}/resolve/`, queryParams),
            retrieve: (pk: any, queryParams?: ActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/actions/${pk}/`, queryParams),
        },

        AddressViewSet: {
            create: (queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/`, queryParams),
            destroy: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/${pk}/`, queryParams),
            list: (queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/`, queryParams),
            list_detail: (queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/${pk}/detail/`, queryParams),
            search: (queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/search/`, queryParams),
            update: (pk: any, queryParams?: AddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/addresses/${pk}/`, queryParams),
        },

        AllPayers: {
            get: (queryParams?: AllPayersQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/all-payers`, queryParams),
        },

        AllergyViewSet: {
            list: (queryParams?: AllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/allergies/`, queryParams),
            retrieve: (pk: any, queryParams?: AllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/allergies/${pk}/`, queryParams),
        },

        AntibioticsPatientViewSet: {
            active_census: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-patients/active_census/`, queryParams),
            patients_with_active_treatments: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-patients/patients_with_active_treatments/`, queryParams),
            patients_with_long_treatments: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-patients/patients_with_long_treatments/`, queryParams),
            patients_with_multiple_treatments: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-patients/patients_with_multiple_treatments/`, queryParams),
            summary_statistics: (queryParams?: AntibioticsPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-patients/summary_statistics/`, queryParams),
        },

        AntibioticsTreatmentHistoryViewSet: {
            active_treatments: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/active_treatments/`, queryParams),
            by_class: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/by_class/`, queryParams),
            by_name: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/by_name/`, queryParams),
            by_physician: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/by_physician/`, queryParams),
            by_property: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/by_property/`, queryParams),
            by_route: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/by_route/`, queryParams),
            list: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/`, queryParams),
            long_treatments: (queryParams?: AntibioticsTreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-history/long_treatments/`, queryParams),
        },

        AntibioticsViewSet: {
            bydoctor: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics/bydoctor/`, queryParams),
            bypatient: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics/bypatient/`, queryParams),
            forinfection: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics/forinfection/`, queryParams),
            overall: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics/overall/`, queryParams),
            resistance: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics/resistance/`, queryParams),
        },

        ApplicationLaunchView: {
            get: (queryParams?: ApplicationLaunchViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/launch`, queryParams),
        },

        ApplicationViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/applications/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/applications/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/applications/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/applications/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/applications/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/applications/${pk}/`, queryParams),
        },

        AuthCurrentUser: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/auth/`, queryParams),
        },

        AvailableFacilityViewSet: {
            list: (queryParams?: AvailableFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/available-facilities/`, queryParams),
            retrieve: (pk: any, queryParams?: AvailableFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/available-facilities/${pk}/`, queryParams),
        },

        AvailableProvidersViewSet: {
            get: (queryParams?: AvailableProvidersViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/available-lab-providers`, queryParams),
        },

        AvailableQuestionnairesView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/available-questionnaires`, queryParams),
        },

        BedBedOriginViewSet: {
            create: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/`, queryParams),
            destroy: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/${pk}/`, queryParams),
            list: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/`, queryParams),
            list_detail: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/${pk}/detail/`, queryParams),
            search: (queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/search/`, queryParams),
            update: (pk: any, queryParams?: BedBedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds-bedorigins/${pk}/`, queryParams),
        },

        BedOriginViewSet: {
            create: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/`, queryParams),
            destroy: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/${pk}/`, queryParams),
            list: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/`, queryParams),
            list_detail: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/${pk}/detail/`, queryParams),
            search: (queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/search/`, queryParams),
            update: (pk: any, queryParams?: BedOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/bedorigins/${pk}/`, queryParams),
        },

        BedViewSet: {
            create: (queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/`, queryParams),
            destroy: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/${pk}/`, queryParams),
            list: (queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/`, queryParams),
            list_detail: (queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/${pk}/detail/`, queryParams),
            search: (queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/search/`, queryParams),
            update: (pk: any, queryParams?: BedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/beds/${pk}/`, queryParams),
        },

        BillingCodeCategoryViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-code-categories/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-code-categories/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-code-categories/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-code-categories/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-code-categories/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-code-categories/${pk}/`, queryParams),
        },

        BillingCodePriceViewSet: {
            list: (queryParams?: BillingCodePriceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/neo-billing-code-prices/`, queryParams),
        },

        BillingCodeViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/`, queryParams),
            list_detail: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/${pk}/detail/`, queryParams),
            search: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/search/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-codes/${pk}/`, queryParams),
        },

        BillingGroupViewSet: {
            create: (queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-group/`, queryParams),
            destroy: (pk: any, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-group/${pk}/`, queryParams),
            list: (queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-group/`, queryParams),
            partial_update: (pk: any, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-group/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-group/${pk}/`, queryParams),
            update: (pk: any, queryParams?: BillingGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-group/${pk}/`, queryParams),
        },

        BillingInfoApprovalViewSet: {
            approve: (queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info-approval/approve/`, queryParams),
            list: (queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info-approval/`, queryParams),
            refuse: (queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info-approval/refuse/`, queryParams),
            retrieve: (pk: any, queryParams?: BillingInfoApprovalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info-approval/${pk}/`, queryParams),
        },

        BillingInfoNotRequestedPatientsModelViewSet: {
            list: (queryParams?: BillingInfoNotRequestedPatientsModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/not-requested-billing-info/`, queryParams),
            retrieve: (pk: any, queryParams?: BillingInfoNotRequestedPatientsModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/not-requested-billing-info/${pk}/`, queryParams),
        },

        BillingInfoRequestViewSet: {
            batch_create: (queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info/batch_create/`, queryParams),
            download_billing_info: (queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info/download_billing_info/`, queryParams),
            list: (queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info/`, queryParams),
            retrieve: (pk: any, queryParams?: BillingInfoRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/billing-info/${pk}/`, queryParams),
        },

        CDSInfobuttonSourceViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-infobutton-source/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-infobutton-source/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-infobutton-source/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-infobutton-source/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-infobutton-source/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-infobutton-source/${pk}/`, queryParams),
        },

        CDSInterventionConditionViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention-condition/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention-condition/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention-condition/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention-condition/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention-condition/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention-condition/${pk}/`, queryParams),
        },

        CDSInterventionViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/cds-intervention/${pk}/`, queryParams),
        },

        CalendarEventViewSet: {
            create: (queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/calendar-events/`, queryParams),
            destroy: (pk: any, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/calendar-events/${pk}/`, queryParams),
            list: (queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/calendar-events/`, queryParams),
            partial_update: (pk: any, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/calendar-events/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/calendar-events/${pk}/`, queryParams),
            update: (pk: any, queryParams?: CalendarEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/calendar-events/${pk}/`, queryParams),
        },

        CapabilityStatementView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/fhir/metadata`, queryParams),
        },

        CaptchaViewSet: {
            list: (queryParams?: CaptchaViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/captcha/`, queryParams),
            partial_update: (pk: any, queryParams?: CaptchaViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/captcha/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: CaptchaViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/captcha/${pk}/`, queryParams),
            update: (pk: any, queryParams?: CaptchaViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/captcha/${pk}/`, queryParams),
        },

        CensusHistoryRehospitalizationViewSet: {
            create: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/`, queryParams),
            destroy: (pk: any, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/${pk}/`, queryParams),
            length_of_stay: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/length_of_stay/`, queryParams),
            list: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/`, queryParams),
            partial_update: (pk: any, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/${pk}/`, queryParams),
            readmissions: (queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/readmissions/`, queryParams),
            retrieve: (pk: any, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/${pk}/`, queryParams),
            update: (pk: any, queryParams?: CensusHistoryRehospitalizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization/${pk}/`, queryParams),
        },

        CensusHistoryViewSet: {
            create: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/`, queryParams),
            destroy: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/${pk}/`, queryParams),
            history: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/${pk}/history/`, queryParams),
            hospitals: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/hospitals/`, queryParams),
            list: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/`, queryParams),
            list_detail: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/${pk}/detail/`, queryParams),
            search: (queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/search/`, queryParams),
            update: (pk: any, queryParams?: CensusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-history/${pk}/`, queryParams),
        },

        CensusOversightView: {
            get: (queryParams?: CensusOversightViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/census-oversight`, queryParams),
        },

        CensusStatusCheckViewSet: {
            get_last_update: (queryParams?: CensusStatusCheckViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/update-census/get_last_update/`, queryParams),
            start_update: (queryParams?: CensusStatusCheckViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/update-census/start_update/`, queryParams),
        },

        ChargeAmountSettingViewSet: {
            create: (queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/charge-amount-settings/`, queryParams),
            destroy: (pk: any, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/charge-amount-settings/${pk}/`, queryParams),
            list: (queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/charge-amount-settings/`, queryParams),
            partial_update: (pk: any, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/charge-amount-settings/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/charge-amount-settings/${pk}/`, queryParams),
            update: (pk: any, queryParams?: ChargeAmountSettingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/charge-amount-settings/${pk}/`, queryParams),
        },

        CheckVerificationCode: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/new-password`, queryParams),
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/new-password`, queryParams),
        },

        ClaimFileViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/claim-files/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/claim-files/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/claim-files/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/claim-files/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/claim-files/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/claim-files/${pk}/`, queryParams),
        },

        ClaimViewSet: {
            batch_create_from_visits: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/batch-create-from-visits/`, queryParams),
            batch_delete_for_visits: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/batch-delete-for-visits/`, queryParams),
            batch_submit: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/batch-submit/`, queryParams),
            batch_submit_by_ids: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/batch-submit-by-ids/`, queryParams),
            claim_history: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/history/`, queryParams),
            create: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/`, queryParams),
            create_from_visit: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/create-from-visit/`, queryParams),
            destroy: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/`, queryParams),
            list: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/`, queryParams),
            partial_update: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/`, queryParams),
            set_visit_to_pending_state: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/set-visit-to-pending-state/`, queryParams),
            status_count: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/status-count/`, queryParams),
            submit: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/submit/`, queryParams),
            update: (pk: any, queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/${pk}/`, queryParams),
            visit_claim_preview: (queryParams?: ClaimViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/claims/visit_claim_preview/`, queryParams),
        },

        ClinicalDashboardView: {
            get: (queryParams?: ClinicalDashboardViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/dashboard/clinical`, queryParams),
        },

        ClinicalTestResultViewSet: {
            create: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/`, queryParams),
            destroy: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/${pk}/`, queryParams),
            get_loinc_answers: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/get_loinc_answers/`, queryParams),
            get_loinc_code_description: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/get_loinc_code_description/`, queryParams),
            list: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/`, queryParams),
            list_detail: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/${pk}/detail/`, queryParams),
            search: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/search/`, queryParams),
            search_loinc_codes: (queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/search_loinc_codes/`, queryParams),
            update: (pk: any, queryParams?: ClinicalTestResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/clinical-test-results/${pk}/`, queryParams),
        },

        CoachingViewSet: {
            infections: (queryParams?: CoachingViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/coaching/infections/`, queryParams),
        },

        CodedNoteViewSet: {
            list: (queryParams?: CodedNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/coded-notes/`, queryParams),
            retrieve: (pk: any, queryParams?: CodedNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/coded-notes/${pk}/`, queryParams),
        },

        ContactPointViewSet: {
            create: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/`, queryParams),
            destroy: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/${pk}/`, queryParams),
            list: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/`, queryParams),
            list_detail: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/${pk}/detail/`, queryParams),
            search: (queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/search/`, queryParams),
            update: (pk: any, queryParams?: ContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/contact-points/${pk}/`, queryParams),
        },

        CptCodeDetailViewSet: {
            list: (queryParams?: CptCodeDetailViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/neo-cpt-code/`, queryParams),
        },

        CreateUserFromPatientView: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/create-user-from-patient`, queryParams),
        },

        CriticalValueView: {
            list: (queryParams?: CriticalValueViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/criticals/`, queryParams),
        },

        CustomerViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/`, queryParams),
            list_detail: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/list-detail/`, queryParams),
            list_simple: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/list_simple/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/${pk}/detail/`, queryParams),
            search: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/search/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/customers/${pk}/`, queryParams),
        },

        DailyVisitReportViewSet: {
            audit_for_days: (queryParams?: DailyVisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/daily-visit-reports/audit_for_days/`, queryParams),
            audit_for_days_detail: (queryParams?: DailyVisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/daily-visit-reports/audit_for_days_detail/`, queryParams),
        },

        DepartmentViewSet: {
            create: (queryParams?: DepartmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/departments/`, queryParams),
            destroy: (pk: any, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/departments/${pk}/`, queryParams),
            list: (queryParams?: DepartmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/departments/`, queryParams),
            partial_update: (pk: any, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/departments/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/departments/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DepartmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/departments/${pk}/`, queryParams),
        },

        DiagnosisViewSet: {
            create: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/`, queryParams),
            destroy: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/${pk}/`, queryParams),
            list: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/`, queryParams),
            list_detail: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/${pk}/detail/`, queryParams),
            search: (queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/search/`, queryParams),
            update: (pk: any, queryParams?: DiagnosisViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/diagnoses/${pk}/`, queryParams),
        },

        DocumentViewSet: {
            create: (queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/`, queryParams),
            destroy: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/${pk}/`, queryParams),
            download: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/${pk}/download/`, queryParams),
            list: (queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/`, queryParams),
            partial_update: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/documents/${pk}/`, queryParams),
        },

        DrugCategoryViewSet: {
            create: (queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-categories/`, queryParams),
            destroy: (pk: any, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-categories/${pk}/`, queryParams),
            list: (queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-categories/`, queryParams),
            partial_update: (pk: any, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-categories/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-categories/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DrugCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-categories/${pk}/`, queryParams),
        },

        DrugNameViewSet: {
            create: (queryParams?: DrugNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/names/`, queryParams),
            destroy: (pk: any, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/names/${pk}/`, queryParams),
            list: (queryParams?: DrugNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/names/`, queryParams),
            partial_update: (pk: any, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/names/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/names/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DrugNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/names/${pk}/`, queryParams),
        },

        DrugViewSet: {
            list: (queryParams?: DrugViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs/${pk}/`, queryParams),
        },

        DrugbankProductConceptRxCuiViewSet: {
            create: (queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concept-rxcuis/`, queryParams),
            destroy: (pk: any, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, queryParams),
            list: (queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concept-rxcuis/`, queryParams),
            partial_update: (pk: any, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DrugbankProductConceptRxCuiViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concept-rxcuis/${pk}/`, queryParams),
        },

        DrugbankProductConceptViewSet: {
            create: (queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concepts/`, queryParams),
            destroy: (pk: any, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concepts/${pk}/`, queryParams),
            list: (queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concepts/`, queryParams),
            partial_update: (pk: any, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concepts/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concepts/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DrugbankProductConceptViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-product-concepts/${pk}/`, queryParams),
        },

        DrugbankProductViewSet: {
            create: (queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-products/`, queryParams),
            destroy: (pk: any, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-products/${pk}/`, queryParams),
            list: (queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-products/`, queryParams),
            partial_update: (pk: any, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-products/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-products/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DrugbankProductViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drug-products/${pk}/`, queryParams),
        },

        DrugbankValueSetViewSet: {
            list: (queryParams?: DrugbankValueSetViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugbank-product-valuesets/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugbankValueSetViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugbank-product-valuesets/${pk}/`, queryParams),
        },

        DrugsCategoryViewSet: {
            create: (queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs-category/`, queryParams),
            destroy: (pk: any, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs-category/${pk}/`, queryParams),
            list: (queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs-category/`, queryParams),
            partial_update: (pk: any, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs-category/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs-category/${pk}/`, queryParams),
            update: (pk: any, queryParams?: DrugsCategoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/drugs-category/${pk}/`, queryParams),
        },

        EMRImportViewSet: {
            list_last_logs: (queryParams?: EMRImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr-import/list_last_logs/`, queryParams),
            list_logs_for_task: (queryParams?: EMRImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr-import/list_logs_for_task/`, queryParams),
        },

        EMRProfessionalViewSet: {
            create: (queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_professionals/`, queryParams),
            destroy: (pk: any, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_professionals/${pk}/`, queryParams),
            list: (queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_professionals/`, queryParams),
            partial_update: (pk: any, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_professionals/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_professionals/${pk}/`, queryParams),
            update: (pk: any, queryParams?: EMRProfessionalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_professionals/${pk}/`, queryParams),
        },

        EMRUploadViewSet: {
            batch_update: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/batch_update/`, queryParams),
            create: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/`, queryParams),
            destroy: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/${pk}/`, queryParams),
            list: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/`, queryParams),
            list_detail: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/${pk}/detail/`, queryParams),
            search: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/search/`, queryParams),
            summary: (queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/summary/`, queryParams),
            update: (pk: any, queryParams?: EMRUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/emr_uploads/${pk}/`, queryParams),
        },

        ERXOriginViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/erx-origins/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/erx-origins/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/erx-origins/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/erx-origins/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/erx-origins/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/erx-origins/${pk}/`, queryParams),
        },

        EligibilityRequestViewSet: {
            check_eligibility: (queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/eligibility-requests/check-eligibility/`, queryParams),
            check_eligibility_free_text: (queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/eligibility-requests/check-eligibility-free-text/`, queryParams),
            last_eligibility_request: (queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/eligibility-requests/last-eligibility-request/`, queryParams),
            list: (queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/eligibility-requests/`, queryParams),
            retrieve: (pk: any, queryParams?: EligibilityRequestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/eligibility-requests/${pk}/`, queryParams),
        },

        EncounterView: {
            get: (queryParams?: EncounterViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/encounters`, queryParams),
        },

        EntryProposalViewSet: {
            create: (queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/entry-proposals/`, queryParams),
            destroy: (pk: any, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/entry-proposals/${pk}/`, queryParams),
            list: (queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/entry-proposals/`, queryParams),
            partial_update: (pk: any, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/entry-proposals/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/entry-proposals/${pk}/`, queryParams),
            update: (pk: any, queryParams?: EntryProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/entry-proposals/${pk}/`, queryParams),
        },

        EventViewSet: {
            create: (queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/`, queryParams),
            destroy: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/${pk}/`, queryParams),
            list: (queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/`, queryParams),
            list_detail: (queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/${pk}/detail/`, queryParams),
            search: (queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/search/`, queryParams),
            update: (pk: any, queryParams?: EventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/events/${pk}/`, queryParams),
        },

        FHIRDeviceParserView: {
            get: (queryParams?: FHIRDeviceParserViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device-parser`, queryParams),
        },

        FHIRDeviceViewSet: {
            create: (queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device/`, queryParams),
            destroy: (pk: any, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device/${pk}/`, queryParams),
            list: (queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device/`, queryParams),
            partial_update: (pk: any, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device/${pk}/`, queryParams),
            update: (pk: any, queryParams?: FHIRDeviceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-device/${pk}/`, queryParams),
        },

        FHIRExportStatusPollingView: {
            delete: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/poll-export-status`, queryParams),
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/poll-export-status`, queryParams),
        },

        FHIRFileDownloadView: {
            get: (export_uuid: any, file_name: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/export/download/${export_uuid}/${file_name}`, queryParams),
        },

        FHIRQuestionnaireResponseViewSet: {
            create: (queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire-response/`, queryParams),
            destroy: (pk: any, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, queryParams),
            list: (queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire-response/`, queryParams),
            partial_update: (pk: any, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, queryParams),
            update: (pk: any, queryParams?: FHIRQuestionnaireResponseViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire-response/${pk}/`, queryParams),
        },

        FHIRQuestionnaireViewSet: {
            create: (queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire/`, queryParams),
            destroy: (pk: any, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire/${pk}/`, queryParams),
            list: (queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire/`, queryParams),
            partial_update: (pk: any, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire/${pk}/`, queryParams),
            update: (pk: any, queryParams?: FHIRQuestionnaireViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fhir-questionnaire/${pk}/`, queryParams),
        },

        FacilityAccessPermissionTypeView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-access-permission-types`, queryParams),
        },

        FacilityActivePatientStatisticsViewSet: {
            list: (queryParams?: FacilityActivePatientStatisticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-active-patient-statistics/`, queryParams),
            retrieve: (pk: any, queryParams?: FacilityActivePatientStatisticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-active-patient-statistics/${pk}/`, queryParams),
        },

        FacilityCensusDashboardView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/dashboard/facility-census`, queryParams),
        },

        FacilityEMRImportSettingsViewSet: {
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-emr-import-settings/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-emr-import-settings/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-emr-import-settings/${pk}/`, queryParams),
        },

        FacilityEMRImportViewSet: {
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility_imports/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility_imports/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility_imports/${pk}/`, queryParams),
        },

        FacilityLabImportAccountViewSet: {
            create: (queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/`, queryParams),
            destroy: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, queryParams),
            list: (queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/`, queryParams),
            partial_update: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, queryParams),
            retrieve_by_facility_id: (queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/by-facility-id/`, queryParams),
            update: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/${pk}/`, queryParams),
            update_fax_only_critical: (pk: any, queryParams?: FacilityLabImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/lab-import-accounts/${pk}/update-fax-only-critical/`, queryParams),
        },

        FacilityOriginViewSet: {
            create: (queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-origins/`, queryParams),
            destroy: (pk: any, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-origins/${pk}/`, queryParams),
            list: (queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-origins/`, queryParams),
            partial_update: (pk: any, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-origins/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-origins/${pk}/`, queryParams),
            update: (pk: any, queryParams?: FacilityOriginViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facility-origins/${pk}/`, queryParams),
        },

        FacilityViewSet: {
            activate_fax: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/activate_fax/`, queryParams),
            create: (queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/`, queryParams),
            destroy: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/`, queryParams),
            download_census_xls: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/download_census_xls/`, queryParams),
            download_interact: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/download_interact/`, queryParams),
            list: (queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/`, queryParams),
            partial_update: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/`, queryParams),
            update: (pk: any, queryParams?: FacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/facilities/${pk}/`, queryParams),
        },

        FaxStatusHistoryViewSet: {
            list: (queryParams?: FaxStatusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax-status-history/`, queryParams),
            retrieve: (pk: any, queryParams?: FaxStatusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax-status-history/${pk}/`, queryParams),
        },

        FaxViewSet: {
            create: (queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/`, queryParams),
            destroy: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/${pk}/`, queryParams),
            get_fax_number_by: (queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/by/`, queryParams),
            list: (queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/`, queryParams),
            list_detail: (queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/${pk}/detail/`, queryParams),
            search: (queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/search/`, queryParams),
            update: (pk: any, queryParams?: FaxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/fax/${pk}/`, queryParams),
        },

        FileView: {
            get: (file_id: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/file-download/${file_id}/`, queryParams),
        },

        FloorPlanViewSet: {
            create: (queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/`, queryParams),
            destroy: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/${pk}/`, queryParams),
            download: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/${pk}/download/`, queryParams),
            list: (queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/`, queryParams),
            partial_update: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/${pk}/`, queryParams),
            update: (pk: any, queryParams?: FloorPlanViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/floor-plans/${pk}/`, queryParams),
        },

        FollowedPatientViewSet: {
            list: (queryParams?: FollowedPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/followed-patients/`, queryParams),
        },

        ForgotPasswordView: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/forgot-password`, queryParams),
        },

        FrontendParametersViewSet: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/frontend-parameters`, queryParams),
        },

        GeneratePassCodeView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/auth/generate-passcode`, queryParams),
        },

        GetLanguageFromCode: {
            get: (queryParams?: GetLanguageFromCodeQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/language-valueset/get-language-from-code`, queryParams),
        },

        GroupViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/groups/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/groups/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/groups/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/groups/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/groups/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/groups/${pk}/`, queryParams),
        },

        HL7DashboardViewSet: {
            create: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/`, queryParams),
            destroy: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/${pk}/`, queryParams),
            list: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/`, queryParams),
            list_detail: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/${pk}/detail/`, queryParams),
            search: (queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/search/`, queryParams),
            update: (pk: any, queryParams?: HL7DashboardViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_dashboard/${pk}/`, queryParams),
        },

        HL7MessageViewSet: {
            batch_update: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/batch_update/`, queryParams),
            create: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/`, queryParams),
            destroy: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/${pk}/`, queryParams),
            list: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/`, queryParams),
            list_detail: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/${pk}/detail/`, queryParams),
            search: (queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/search/`, queryParams),
            update: (pk: any, queryParams?: HL7MessageViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_messages/${pk}/`, queryParams),
        },

        HL7UnmatchedViewSet: {
            create: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/`, queryParams),
            destroy: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/${pk}/`, queryParams),
            list: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/`, queryParams),
            list_detail: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/${pk}/detail/`, queryParams),
            search: (queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/search/`, queryParams),
            update: (pk: any, queryParams?: HL7UnmatchedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/hl7_unmatched/${pk}/`, queryParams),
        },

        ICDCodeAllLatestViewSet: {
            create: (queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes-all/`, queryParams),
            destroy: (pk: any, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes-all/${pk}/`, queryParams),
            list: (queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes-all/`, queryParams),
            partial_update: (pk: any, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes-all/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes-all/${pk}/`, queryParams),
            update: (pk: any, queryParams?: ICDCodeAllLatestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes-all/${pk}/`, queryParams),
        },

        ICDCodeGroupViewSet: {
            create: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/`, queryParams),
            destroy: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/${pk}/`, queryParams),
            list: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/`, queryParams),
            list_detail: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/${pk}/detail/`, queryParams),
            search: (queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/search/`, queryParams),
            update: (pk: any, queryParams?: ICDCodeGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-groups/${pk}/`, queryParams),
        },

        ICDCodeIndicationsViewSet: {
            create: (queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-indications/`, queryParams),
            destroy: (pk: any, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-indications/${pk}/`, queryParams),
            list: (queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-indications/`, queryParams),
            partial_update: (pk: any, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-indications/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-indications/${pk}/`, queryParams),
            update: (pk: any, queryParams?: ICDCodeIndicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-indications/${pk}/`, queryParams),
        },

        ICDCodeMedicalSpecialtyViewSet: {
            list: (queryParams?: ICDCodeMedicalSpecialtyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-medical-specialties/`, queryParams),
        },

        ICDCodeSuggestionViewSet: {
            get: (queryParams?: ICDCodeSuggestionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-code-suggestion`, queryParams),
        },

        ICDCodeViewSet: {
            create: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/`, queryParams),
            destroy: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/${pk}/`, queryParams),
            list: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/`, queryParams),
            list_detail: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/${pk}/detail/`, queryParams),
            search: (queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/search/`, queryParams),
            update: (pk: any, queryParams?: ICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-codes/${pk}/`, queryParams),
        },

        ICDValueSetViewSet: {
            list: (queryParams?: ICDValueSetViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-valuesets/`, queryParams),
            retrieve: (pk: any, queryParams?: ICDValueSetViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/icd-valuesets/${pk}/`, queryParams),
        },

        ImagingResultViewSet: {
            create: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/`, queryParams),
            destroy: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/${pk}/`, queryParams),
            get_loinc_answers: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/get_loinc_answers/`, queryParams),
            get_loinc_code_description: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/get_loinc_code_description/`, queryParams),
            list: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/`, queryParams),
            list_detail: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/${pk}/detail/`, queryParams),
            search: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/search/`, queryParams),
            search_loinc_codes: (queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/search_loinc_codes/`, queryParams),
            update: (pk: any, queryParams?: ImagingResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/imaging-results/${pk}/`, queryParams),
        },

        ImmunizationViewSet: {
            create: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/`, queryParams),
            destroy: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/${pk}/`, queryParams),
            list: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/`, queryParams),
            list_detail: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/${pk}/detail/`, queryParams),
            search: (queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/search/`, queryParams),
            update: (pk: any, queryParams?: ImmunizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/immunizations/${pk}/`, queryParams),
        },

        ImportAccountStatusHistoryViewSet: {
            list: (queryParams?: ImportAccountStatusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/status-history/`, queryParams),
            retrieve: (pk: any, queryParams?: ImportAccountStatusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/status-history/${pk}/`, queryParams),
        },

        ImportAccountViewSet: {
            account_details: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/account_details/`, queryParams),
            cancel_login: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/cancel_login/`, queryParams),
            change_password: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/change_password/`, queryParams),
            create: (queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/`, queryParams),
            destroy: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, queryParams),
            get_connection: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/get_connection/`, queryParams),
            list: (queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/`, queryParams),
            partial_update: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, queryParams),
            queue_emr_import: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/queue_emr_import/`, queryParams),
            retrieve: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, queryParams),
            show_secret: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/show_secret/`, queryParams),
            start_login: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/start_login/`, queryParams),
            test_account: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/test_account/`, queryParams),
            test_permissions: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/test_permissions/`, queryParams),
            update: (pk: any, queryParams?: ImportAccountViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-accounts/import-accounts/${pk}/`, queryParams),
        },

        ImportFileUploadViewSet: {
            create: (queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/`, queryParams),
            destroy: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/${pk}/`, queryParams),
            download: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/${pk}/download/`, queryParams),
            form: (queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/form/`, queryParams),
            list: (queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/`, queryParams),
            partial_update: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/${pk}/`, queryParams),
            report_patient_import: (queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/admission_patient_import/`, queryParams),
            retrieve: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/${pk}/`, queryParams),
            update: (pk: any, queryParams?: ImportFileUploadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/upload-file/${pk}/`, queryParams),
        },

        ImportLogViewSet: {
            last_success_for_task: (queryParams?: ImportLogViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-logs/last_success_for_task/`, queryParams),
            list: (queryParams?: ImportLogViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-logs/`, queryParams),
        },

        ImportMacLocalityView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-mac-localities`, queryParams),
        },

        ImportPresetViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-presets/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-presets/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-presets/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-presets/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-presets/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/import-presets/${pk}/`, queryParams),
        },

        ImportViewSet: {
            create: (queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/`, queryParams),
            destroy: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/`, queryParams),
            get_available_reports: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/available_reports/`, queryParams),
            list: (queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/`, queryParams),
            partial_update: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/`, queryParams),
            queue_emr_import: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/queue_emr_import/`, queryParams),
            queue_patient_import: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/queue_emr_patient_import/`, queryParams),
            retrieve: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/`, queryParams),
            update: (pk: any, queryParams?: ImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/import/${pk}/`, queryParams),
        },

        IndicationViewSet: {
            create: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/`, queryParams),
            destroy: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/${pk}/`, queryParams),
            list: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/`, queryParams),
            list_detail: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/${pk}/detail/`, queryParams),
            search: (queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/search/`, queryParams),
            update: (pk: any, queryParams?: IndicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/indications/${pk}/`, queryParams),
        },

        InfectionEntryViewSet: {
            list: (queryParams?: InfectionEntryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-entries/`, queryParams),
            retrieve: (pk: any, queryParams?: InfectionEntryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-entries/${pk}/`, queryParams),
        },

        InfectionInformationVerificationViewSet: {
            create: (queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information-verification/`, queryParams),
            destroy: (pk: any, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information-verification/${pk}/`, queryParams),
            list: (queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information-verification/`, queryParams),
            partial_update: (pk: any, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information-verification/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information-verification/${pk}/`, queryParams),
            update: (pk: any, queryParams?: InfectionInformationVerificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information-verification/${pk}/`, queryParams),
        },

        InfectionInformationViewSet: {
            create: (queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information/`, queryParams),
            destroy: (pk: any, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information/${pk}/`, queryParams),
            list: (queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information/`, queryParams),
            partial_update: (pk: any, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information/${pk}/`, queryParams),
            update: (pk: any, queryParams?: InfectionInformationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/infection-information/${pk}/`, queryParams),
        },

        InitialImportViewSet: {
            get_patient_list: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/get_patient_list/`, queryParams),
            get_sync_details_by_type: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/get_sync_details_by_type/`, queryParams),
            import_validations_history: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/import_validations_history/`, queryParams),
            initial_imports_by_facility: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/initial_imports_by_facility/`, queryParams),
            reset_all_initial_imports: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/reset_all_initial_imports/`, queryParams),
            start_download_census_statuses_from_emr: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/start_download_census_statuses_from_emr/`, queryParams),
            start_download_patient_list: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/start_download_patient_list/`, queryParams),
            start_import_data: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/start_import_data/`, queryParams),
            start_import_patients: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/start_import_patients/`, queryParams),
            user_verification: (queryParams?: InitialImportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/initial-import/user_verification/`, queryParams),
        },

        InsuranceCompanies: {
            get: (queryParams?: InsuranceCompaniesQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/insurance-companies`, queryParams),
        },

        LabAntibiogramViewSet: {
            antibiogram_download: (queryParams?: LabAntibiogramViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-antibiogram/antibiogram_download/`, queryParams),
            antimicrobic_sensitivity_download: (queryParams?: LabAntibiogramViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-antibiogram/antimicrobic_sensitivity_download/`, queryParams),
        },

        LabOrderGroupViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-order-groups/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-order-groups/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-order-groups/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-order-groups/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-order-groups/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-order-groups/${pk}/`, queryParams),
        },

        LabOrderViewSet: {
            barcode: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/${pk}/barcode/`, queryParams),
            create: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/`, queryParams),
            create_covid: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/create-covid/`, queryParams),
            destroy: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/${pk}/`, queryParams),
            download: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/download/`, queryParams),
            download_barcodes: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/download_barcodes/`, queryParams),
            list: (queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/`, queryParams),
            partial_update: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/${pk}/`, queryParams),
            update: (pk: any, queryParams?: LabOrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-orders/${pk}/`, queryParams),
        },

        LabReportViewSet: {
            batch_lab_test_review: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/batch_lab_test_review/`, queryParams),
            create: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/`, queryParams),
            destroy: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/`, queryParams),
            download: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/download/`, queryParams),
            list: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/`, queryParams),
            list_detail: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/`, queryParams),
            resend_fax: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/resend_fax/`, queryParams),
            retrieve: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/detail/`, queryParams),
            search: (queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/search/`, queryParams),
            update: (pk: any, queryParams?: LabReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-reports/${pk}/`, queryParams),
        },

        LabResultBillingReportViewSet: {
            download_lab_result_billing: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-result-billing-reports/download_lab_result_billing/`, queryParams),
            download_productivity: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-result-billing-reports/download_productivity/`, queryParams),
            list: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-result-billing-reports/`, queryParams),
            productivity: (queryParams?: LabResultBillingReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-result-billing-reports/productivity/`, queryParams),
        },

        LabResultViewSet: {
            create: (queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/`, queryParams),
            destroy: (pk: any, queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/${pk}/`, queryParams),
            list: (queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/`, queryParams),
            partial_update: (pk: any, queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/${pk}/`, queryParams),
            recent_summary: (queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/recent_summary/`, queryParams),
            retrieve: (pk: any, queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/${pk}/`, queryParams),
            update: (pk: any, queryParams?: LabResultViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-results/${pk}/`, queryParams),
        },

        LabShipmentBagViewSet: {
            create: (queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-bags/`, queryParams),
            destroy: (pk: any, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-bags/${pk}/`, queryParams),
            list: (queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-bags/`, queryParams),
            partial_update: (pk: any, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-bags/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-bags/${pk}/`, queryParams),
            update: (pk: any, queryParams?: LabShipmentBagViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-bags/${pk}/`, queryParams),
        },

        LabShipmentBoxViewSet: {
            create: (queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-boxes/`, queryParams),
            destroy: (pk: any, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-boxes/${pk}/`, queryParams),
            list: (queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-boxes/`, queryParams),
            partial_update: (pk: any, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-boxes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-boxes/${pk}/`, queryParams),
            update: (pk: any, queryParams?: LabShipmentBoxViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipment-boxes/${pk}/`, queryParams),
        },

        LabShipmentViewSet: {
            create: (queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/`, queryParams),
            destroy: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/${pk}/`, queryParams),
            download: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/${pk}/download/`, queryParams),
            list: (queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/`, queryParams),
            partial_update: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/${pk}/`, queryParams),
            update: (pk: any, queryParams?: LabShipmentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-shipments/${pk}/`, queryParams),
        },

        LabSponsoredInfectionControlViewSet: {
            culture_specimen_dashboard: (queryParams?: LabSponsoredInfectionControlViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-sponsored-infection-control/culture_specimen_dashboard/`, queryParams),
            list: (queryParams?: LabSponsoredInfectionControlViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-sponsored-infection-control/`, queryParams),
            list_download: (queryParams?: LabSponsoredInfectionControlViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-sponsored-infection-control/list_download/`, queryParams),
        },

        LabTestTypesViewSet: {
            create: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/`, queryParams),
            destroy: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/${pk}/`, queryParams),
            list: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/`, queryParams),
            list_detail: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/${pk}/detail/`, queryParams),
            search: (queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/search/`, queryParams),
            update: (pk: any, queryParams?: LabTestTypesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-test-types/${pk}/`, queryParams),
        },

        LabTestViewSet: {
            attachments: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/${pk}/attachments/`, queryParams),
            create: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/`, queryParams),
            destroy: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/${pk}/`, queryParams),
            list: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/`, queryParams),
            list_detail: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/list-detail/`, queryParams),
            list_download: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/list_download/`, queryParams),
            partial_update: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/${pk}/detail/`, queryParams),
            search: (queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/search/`, queryParams),
            update: (pk: any, queryParams?: LabTestViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/lab-tests/${pk}/`, queryParams),
        },

        LabelViewSet: {
            create: (queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/`, queryParams),
            destroy: (pk: any, queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/${pk}/`, queryParams),
            list: (queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/`, queryParams),
            list_detail: (queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/${pk}/`, queryParams),
            update: (pk: any, queryParams?: LabelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/labels/${pk}/`, queryParams),
        },

        LanguagesView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/language-valueset`, queryParams),
        },

        LastVisitTaskViewSet: {
            list: (queryParams?: LastVisitTaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/last-visit/`, queryParams),
            retrieve: (pk: any, queryParams?: LastVisitTaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/last-visit/${pk}/`, queryParams),
        },

        LevelOfCarePayerViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/level-of-care/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/level-of-care/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/level-of-care/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/level-of-care/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/level-of-care/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/level-of-care/${pk}/`, queryParams),
        },

        LocationViewSet: {
            create: (queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/`, queryParams),
            destroy: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/${pk}/`, queryParams),
            list: (queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/`, queryParams),
            list_detail: (queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/${pk}/detail/`, queryParams),
            search: (queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/search/`, queryParams),
            update: (pk: any, queryParams?: LocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/locations/${pk}/`, queryParams),
        },

        LogViewSet: {
            list: (queryParams?: LogViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/logs/`, queryParams),
        },

        MACLocalityBillingCodeViewSet: {
            billing_codes: (queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/billing-codes/`, queryParams),
            create: (queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/`, queryParams),
            destroy: (pk: any, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/${pk}/`, queryParams),
            list: (queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/`, queryParams),
            partial_update: (pk: any, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/${pk}/`, queryParams),
            update: (pk: any, queryParams?: MACLocalityBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/macs-billing-codes/${pk}/`, queryParams),
        },

        MACViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicare-administrative-contractors/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicare-administrative-contractors/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicare-administrative-contractors/${pk}/`, queryParams),
        },

        ManualImportView: {
            import_from_document: (queryParams?: ManualImportViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/manual-import/import_from_document/`, queryParams),
        },

        MedicaidPriceSettingViewSet: {
            batch_update: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/batch-update/`, queryParams),
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medicaid-price-settings/${pk}/`, queryParams),
        },

        MedicalProgramBillingFacilityViewSet: {
            summary: (queryParams?: MedicalProgramBillingFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-facilities/summary/`, queryParams),
        },

        MedicalProgramBillingPatientViewSet: {
            all_patients: (queryParams?: MedicalProgramBillingPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-patients/all_patients/`, queryParams),
            billable_patients: (queryParams?: MedicalProgramBillingPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-patients/billable_patients/`, queryParams),
            download_billable_patients: (queryParams?: MedicalProgramBillingPatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-patients/download_billable_patients/`, queryParams),
        },

        MedicalProgramBillingTaskViewSet: {
            download_all_tasks: (queryParams?: MedicalProgramBillingTaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-tasks/download_all_tasks/`, queryParams),
            invalid_tasks: (queryParams?: MedicalProgramBillingTaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-tasks/invalid_tasks/`, queryParams),
            patient_tasks: (queryParams?: MedicalProgramBillingTaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-tasks/patient_tasks/`, queryParams),
        },

        MedicalProgramBillingUserViewSet: {
            download_summary: (queryParams?: MedicalProgramBillingUserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-users/download_summary/`, queryParams),
            summary: (queryParams?: MedicalProgramBillingUserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-program-billing-users/summary/`, queryParams),
        },

        MedicalProgramViewSet: {
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-programs/`, queryParams),
        },

        MedicalResultTypeViewSet: {
            create: (queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-result-types/`, queryParams),
            destroy: (pk: any, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-result-types/${pk}/`, queryParams),
            list: (queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-result-types/`, queryParams),
            partial_update: (pk: any, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-result-types/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-result-types/${pk}/`, queryParams),
            update: (pk: any, queryParams?: MedicalResultTypeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-result-types/${pk}/`, queryParams),
        },

        MedicalSpecialtyViewSet: {
            list: (queryParams?: MedicalSpecialtyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medical-specialties/`, queryParams),
        },

        MedicationsViewSet: {
            create: (queryParams?: MedicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medications/`, queryParams),
            destroy: (pk: any, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medications/${pk}/`, queryParams),
            list: (queryParams?: MedicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medications/`, queryParams),
            partial_update: (pk: any, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medications/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medications/${pk}/`, queryParams),
            update: (pk: any, queryParams?: MedicationsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/medications/${pk}/`, queryParams),
        },

        NotificationViewSet: {
            create: (queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/`, queryParams),
            destroy: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/${pk}/`, queryParams),
            list: (queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/`, queryParams),
            mark_all_as_read: (queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/mark_all_as_read/`, queryParams),
            partial_update: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/${pk}/`, queryParams),
            read: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/${pk}/read/`, queryParams),
            retrieve: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/${pk}/`, queryParams),
            update: (pk: any, queryParams?: NotificationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/notifications/${pk}/`, queryParams),
        },

        NurseStationViewSet: {
            create: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/`, queryParams),
            destroy: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/${pk}/`, queryParams),
            history: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/${pk}/history/`, queryParams),
            list: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/`, queryParams),
            list_detail: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/${pk}/detail/`, queryParams),
            search: (queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/search/`, queryParams),
            update: (pk: any, queryParams?: NurseStationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/nurse-station/${pk}/`, queryParams),
        },

        OperationViewSet: {
            facility_details: (pk: any, queryParams?: OperationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/operation/${pk}/facility_details/`, queryParams),
            organization_list: (queryParams?: OperationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/operation/organization_list/`, queryParams),
        },

        OrderDownloadViewSet: {
            get: (queryParams?: OrderDownloadViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/orders_report`, queryParams),
        },

        OrderViewSet: {
            list: (queryParams?: OrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/orders/`, queryParams),
            retrieve: (pk: any, queryParams?: OrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/orders/${pk}/`, queryParams),
            set_active_status: (pk: any, queryParams?: OrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/orders/${pk}/set_active_status/`, queryParams),
            status_history: (pk: any, queryParams?: OrderViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/orders/${pk}/status_history/`, queryParams),
        },

        OrganizationGroupViewSet: {
            create: (queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organization-groups/`, queryParams),
            destroy: (pk: any, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organization-groups/${pk}/`, queryParams),
            list: (queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organization-groups/`, queryParams),
            partial_update: (pk: any, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organization-groups/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organization-groups/${pk}/`, queryParams),
            update: (pk: any, queryParams?: OrganizationGroupViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organization-groups/${pk}/`, queryParams),
        },

        OrganizationViewSet: {
            create: (queryParams?: OrganizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organizations/`, queryParams),
            destroy: (pk: any, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organizations/${pk}/`, queryParams),
            list: (queryParams?: OrganizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organizations/`, queryParams),
            partial_update: (pk: any, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organizations/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organizations/${pk}/`, queryParams),
            update: (pk: any, queryParams?: OrganizationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/organizations/${pk}/`, queryParams),
        },

        PIMeasuresViewSet: {
            download_report: (queryParams?: PIMeasuresViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/pi-measures/download_report/`, queryParams),
            get_data: (queryParams?: PIMeasuresViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/pi-measures/get_data/`, queryParams),
        },

        PatientAddressViewSet: {
            batch_create: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/batch_create/`, queryParams),
            batch_update: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/batch_update/`, queryParams),
            create: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/`, queryParams),
            destroy: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/${pk}/`, queryParams),
            list: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/`, queryParams),
            list_detail: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/${pk}/detail/`, queryParams),
            search: (queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/search/`, queryParams),
            update: (pk: any, queryParams?: PatientAddressViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-addresses/${pk}/`, queryParams),
        },

        PatientAdvancedDirectiveViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-advanced-directive/`, queryParams),
        },

        PatientAdvancedMonitoringListViewSet: {
            list: (queryParams?: PatientAdvancedMonitoringListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-advanced-monitoring-list/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientAdvancedMonitoringListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-advanced-monitoring-list/${pk}/`, queryParams),
        },

        PatientAllergyViewSet: {
            create: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/`, queryParams),
            destroy: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/${pk}/`, queryParams),
            list: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/`, queryParams),
            list_detail: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/${pk}/detail/`, queryParams),
            search: (queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/search/`, queryParams),
            update: (pk: any, queryParams?: PatientAllergyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-allergies/${pk}/`, queryParams),
        },

        PatientCensusView: {
            get: (queryParams?: PatientCensusViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-by-census`, queryParams),
        },

        PatientCensusViewSet: {
            batch_create_cmrns: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-census/batch_create_cmrns/`, queryParams),
            check_patient_cmrns: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-census/check_patient_cmrns/`, queryParams),
            list: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-census/`, queryParams),
            list_advanced_filtering: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-census/list_advanced_filtering/`, queryParams),
            patients_with_cmrn: (queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-census/patients_with_cmrn/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientCensusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-census/${pk}/`, queryParams),
        },

        PatientComplexSearchViewSet: {
            list: (queryParams?: PatientComplexSearchViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-complex-search/`, queryParams),
        },

        PatientContactPointViewSet: {
            batch_create: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/batch_create/`, queryParams),
            batch_update: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/batch_update/`, queryParams),
            create: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/`, queryParams),
            destroy: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/${pk}/`, queryParams),
            list: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/`, queryParams),
            list_detail: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/${pk}/detail/`, queryParams),
            search: (queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/search/`, queryParams),
            update: (pk: any, queryParams?: PatientContactPointViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-contact-points/${pk}/`, queryParams),
        },

        PatientCopyViewSet: {
            copy: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-copy/copy/`, queryParams),
        },

        PatientCreateViewSet: {
            batch_import_from_cda: (queryParams?: PatientCreateViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-create/batch-import-from-cda/`, queryParams),
            create: (queryParams?: PatientCreateViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-create/`, queryParams),
            import_from_cda: (queryParams?: PatientCreateViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-create/import-from-cda/`, queryParams),
        },

        PatientDetailViewSet: {
            partial_update: (pk: any, queryParams?: PatientDetailViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-details/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientDetailViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-details/${pk}/`, queryParams),
            update: (pk: any, queryParams?: PatientDetailViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-details/${pk}/`, queryParams),
        },

        PatientDocumentCDAViewSet: {
            assign_to_patient: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/assign-to-patient/`, queryParams),
            assign_user_to_direct_address: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/assign-user-to-direct-address/`, queryParams),
            create: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/`, queryParams),
            destroy: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/${pk}/`, queryParams),
            download: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/download/`, queryParams),
            get_document_validation_results: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/get-document-validation-results/`, queryParams),
            get_unassigned_ccd_documents: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/get-unassigned-ccd-documents/`, queryParams),
            list: (queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/${pk}/`, queryParams),
            reassign_to_patient: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/${pk}/reassign-to-patient/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/${pk}/`, queryParams),
            transmit_cda: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/${pk}/transmit-cda/`, queryParams),
            update: (pk: any, queryParams?: PatientDocumentCDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-cda/${pk}/`, queryParams),
        },

        PatientDocumentSubtypesView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-document-subtypes`, queryParams),
        },

        PatientDocumentViewSet: {
            create: (queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/`, queryParams),
            destroy: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/${pk}/`, queryParams),
            download: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/${pk}/download/`, queryParams),
            list: (queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/${pk}/`, queryParams),
            reconcile_ccd: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/${pk}/reconcile-ccd/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/${pk}/`, queryParams),
            update: (pk: any, queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/${pk}/`, queryParams),
            upload_remote_link: (queryParams?: PatientDocumentViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-documents/upload-remote-link/`, queryParams),
        },

        PatientERXViewSet: {
            create_patient_in_erx: (pk: any, queryParams?: PatientERXViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-erx/${pk}/create_patient_in_erx/`, queryParams),
            patient_status_in_erx: (pk: any, queryParams?: PatientERXViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-erx/${pk}/patient_status_in_erx/`, queryParams),
        },

        PatientInformationAuditView: {
            list: (queryParams?: PatientInformationAuditViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-information-audit/`, queryParams),
        },

        PatientInsuranceViewSet: {
            create: (queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-insurance/`, queryParams),
            destroy: (pk: any, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-insurance/${pk}/`, queryParams),
            list: (queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-insurance/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-insurance/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-insurance/${pk}/`, queryParams),
            update: (pk: any, queryParams?: PatientInsuranceViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-insurance/${pk}/`, queryParams),
        },

        PatientMedicationServiceDetailsViewSet: {
            retrieve: (pk: any, queryParams?: PatientMedicationServiceDetailsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-medication-service-details/${pk}/`, queryParams),
        },

        PatientMergeViewSet: {
            list: (queryParams?: PatientMergeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-merge/`, queryParams),
            merge: (pk: any, queryParams?: PatientMergeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-merge/${pk}/merge/`, queryParams),
            suggestions: (queryParams?: PatientMergeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-merge/suggestions/`, queryParams),
        },

        PatientNoteViewSet: {
            create: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/`, queryParams),
            destroy: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/${pk}/`, queryParams),
            list: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/`, queryParams),
            list_detail: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/${pk}/detail/`, queryParams),
            search: (queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/search/`, queryParams),
            update: (pk: any, queryParams?: PatientNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-notes/${pk}/`, queryParams),
        },

        PatientPayerCategoryChangeViewSet: {
            list: (queryParams?: PatientPayerCategoryChangeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-payer-category-change/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientPayerCategoryChangeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-payer-category-change/${pk}/`, queryParams),
        },

        PatientPersonViewSet: {
            partial_update: (pk: any, queryParams?: PatientPersonViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-person/${pk}/`, queryParams),
            update: (pk: any, queryParams?: PatientPersonViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-person/${pk}/`, queryParams),
        },

        PatientPhaseOfDeclineChangeViewSet: {
            list: (queryParams?: PatientPhaseOfDeclineChangeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-phase-of-decline-change/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientPhaseOfDeclineChangeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-phase-of-decline-change/${pk}/`, queryParams),
        },

        PatientPhysicianTeamAccessPermissionHistoryViewSet: {
            list: (queryParams?: PatientPhysicianTeamAccessPermissionHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permission-history/`, queryParams),
        },

        PatientPhysicianTeamAccessPermissionViewSet: {
            add_to_pending: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/add_to_pending/`, queryParams),
            authorize: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/authorize/`, queryParams),
            cancel: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/cancel/`, queryParams),
            create: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/`, queryParams),
            decline: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/decline/`, queryParams),
            discard: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/discard/`, queryParams),
            discontinue: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/discontinue/`, queryParams),
            list: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/`, queryParams),
            overwrite: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/overwrite/`, queryParams),
            print: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/print/`, queryParams),
            reject: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/reject/`, queryParams),
            revoke: (queryParams?: PatientPhysicianTeamAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions/revoke/`, queryParams),
        },

        PatientPhysicianTeamAccessPermissionWithConnectionsViewSet: {
            list: (queryParams?: PatientPhysicianTeamAccessPermissionWithConnectionsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/access-permissions-with-connections/`, queryParams),
        },

        PatientPhysicianTeamMedicalProgramViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-physician-team-medical-programs/`, queryParams),
            history: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-physician-team-medical-programs/${pk}/history/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-physician-team-medical-programs/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-physician-team-medical-programs/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-physician-team-medical-programs/${pk}/`, queryParams),
        },

        PatientPhysicianTeamViewSet: {
            create: (queryParams?: PatientPhysicianTeamViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-physician-team/`, queryParams),
        },

        PatientQrdaExportViewSet: {
            export: (pk: any, queryParams?: PatientQrdaExportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-qrda-exports/${pk}/export/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientQrdaExportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-qrda-exports/${pk}/`, queryParams),
        },

        PatientReportViewSet: {
            download_risk_summary: (queryParams?: PatientReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-reports/download-visit-summary/`, queryParams),
        },

        PatientRepresentativeViewSet: {
            create: (queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-representatives/`, queryParams),
            destroy: (pk: any, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-representatives/${pk}/`, queryParams),
            list: (queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-representatives/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-representatives/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-representatives/${pk}/`, queryParams),
            update: (pk: any, queryParams?: PatientRepresentativeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-representatives/${pk}/`, queryParams),
        },

        PatientSpecialtyMedicationViewSet: {
            retrieve: (pk: any, queryParams?: PatientSpecialtyMedicationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-specialty-medication/${pk}/`, queryParams),
        },

        PatientSyncViewSet: {
            cerner_sync_details: (pk: any, queryParams?: PatientSyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-syncs/${pk}/cerner_sync_details/`, queryParams),
            queue_ccd: (pk: any, queryParams?: PatientSyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-syncs/${pk}/queue_ccd/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientSyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patient-syncs/${pk}/`, queryParams),
        },

        PatientViewSet: {
            add_physician_team: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/add_physician_team/`, queryParams),
            addresses: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/addresses/`, queryParams),
            allergies: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/allergies/`, queryParams),
            assessment: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/assessment/`, queryParams),
            available_physician_teams: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/available_physician_teams/`, queryParams),
            cerner_community_view_url: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/cerner_community_view_url/`, queryParams),
            contact_points: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/contact_points/`, queryParams),
            create: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/`, queryParams),
            destroy: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/`, queryParams),
            details_for_copy: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/details_for_copy/`, queryParams),
            download_facesheet: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/download-facesheet/`, queryParams),
            download_patient_list: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/download_patient_list/`, queryParams),
            eligibility_check_history: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/eligibility-check-history/`, queryParams),
            export_ehi: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/export-ehi/`, queryParams),
            extras: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/extras/`, queryParams),
            follow: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/follow/`, queryParams),
            generate_ccd: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/generate-ccd/`, queryParams),
            generate_upload_ccd: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/generate-upload-ccd/`, queryParams),
            he_url: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/he_url/`, queryParams),
            immunizations: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/immunizations/`, queryParams),
            last_vital_examinations: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/last-vital-examinations/`, queryParams),
            last_vitals: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/last_vitals/`, queryParams),
            list: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/`, queryParams),
            list_detail: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/list-detail/`, queryParams),
            list_simple: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/list_simple/`, queryParams),
            mds: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/mds/`, queryParams),
            medical_programs: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/medical_programs/`, queryParams),
            partial_update: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/`, queryParams),
            patient_allergies: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/patient_allergies/`, queryParams),
            patient_census_list: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/census/`, queryParams),
            person_search: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/person_search/`, queryParams),
            physician_teams: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/physician_teams/`, queryParams),
            remove_physician_team: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/remove_physician_team/`, queryParams),
            retrieve: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/detail/`, queryParams),
            search: (queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/search/`, queryParams),
            set_primary_physician: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/set-primary-physician/`, queryParams),
            task_details: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/task_details/`, queryParams),
            unfollow: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/unfollow/`, queryParams),
            update: (pk: any, queryParams?: PatientViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients/${pk}/`, queryParams),
        },

        PatientsAtRiskView: {
            get: (queryParams?: PatientsAtRiskViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-at-risks`, queryParams),
        },

        PatientsAvailableToRequestAccessPermissionViewSet: {
            list: (queryParams?: PatientsAvailableToRequestAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-available-to-request-access-permissions/`, queryParams),
            print: (queryParams?: PatientsAvailableToRequestAccessPermissionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-available-to-request-access-permissions/print/`, queryParams),
        },

        PatientsAvailableToSearchViewSet: {
            list: (queryParams?: PatientsAvailableToSearchViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/patients-available-to-search/`, queryParams),
        },

        PayerAltNameViewSet: {
            list: (queryParams?: PayerAltNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/payments/payer-names/`, queryParams),
            retrieve: (pk: any, queryParams?: PayerAltNameViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/payments/payer-names/${pk}/`, queryParams),
        },

        PayerViewSet: {
            list: (queryParams?: PayerViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/payments/payers/`, queryParams),
            retrieve: (pk: any, queryParams?: PayerViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/payments/payers/${pk}/`, queryParams),
        },

        PersonIdViewSet: {
            create: (queryParams?: PersonIdViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-ids/`, queryParams),
            destroy: (pk: any, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-ids/${pk}/`, queryParams),
            list: (queryParams?: PersonIdViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-ids/`, queryParams),
            partial_update: (pk: any, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-ids/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-ids/${pk}/`, queryParams),
            update: (pk: any, queryParams?: PersonIdViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-ids/${pk}/`, queryParams),
        },

        PersonMergeEventViewSet: {
            confirm: (queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-events/confirm/`, queryParams),
            destroy: (pk: any, queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-events/${pk}/`, queryParams),
            proposals: (queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-events/proposals/`, queryParams),
            propose: (queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-events/propose/`, queryParams),
            reject: (queryParams?: PersonMergeEventViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-events/reject/`, queryParams),
        },

        PersonMergeProposalViewSet: {
            list: (queryParams?: PersonMergeProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-proposals/`, queryParams),
            proposal_details: (queryParams?: PersonMergeProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/person-merge-proposals/details/`, queryParams),
        },

        PersonViewSet: {
            list: (queryParams?: PersonViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/persons/`, queryParams),
            patients: (pk: any, queryParams?: PersonViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/persons/${pk}/patients/`, queryParams),
            retrieve: (pk: any, queryParams?: PersonViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/persons/${pk}/`, queryParams),
        },

        PhysicianTeamMemberShipUpdate: {
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities-members/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities-members/${pk}/`, queryParams),
        },

        PhysicianTeamQrda1ExportViewSet: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/physician-team-qrda-export/qrda1-export/`, queryParams),
        },

        PhysicianTeamQrda3ExportViewSet: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/physician-team-qrda-export/qrda3-export/`, queryParams),
        },

        PhysicianTeamUserView: {
            assign_user_by_email: (pk: any, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physician-teams/edit-relations/${pk}/assign-user-by-email/`, queryParams),
            create_membership_by_email: (pk: any, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physician-teams/edit-relations/${pk}/create-membership-by-email/`, queryParams),
            delete_membership: (pk: any, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physician-teams/edit-relations/${pk}/delete-membership/`, queryParams),
            destroy: (pk: any, queryParams?: PhysicianTeamUserViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physician-teams/edit-relations/${pk}/`, queryParams),
        },

        PhysicianViewSet: {
            create: (queryParams?: PhysicianViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physicians/`, queryParams),
            list: (queryParams?: PhysicianViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physicians/`, queryParams),
            retrieve: (pk: any, queryParams?: PhysicianViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/physicians/${pk}/`, queryParams),
        },

        Ping: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/ping`, queryParams),
        },

        PrimaryVisitBillingCodeViewSet: {
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-billing-primary-codes/`, queryParams),
        },

        ProcedureViewSet: {
            create: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/`, queryParams),
            destroy: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/${pk}/`, queryParams),
            list: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/`, queryParams),
            list_detail: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/${pk}/detail/`, queryParams),
            search: (queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/search/`, queryParams),
            update: (pk: any, queryParams?: ProcedureViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/procedures/${pk}/`, queryParams),
        },

        ProductivityReport: {
            list: (queryParams?: ProductivityReportQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/productivity-report/`, queryParams),
        },

        ProgressNoteViewSet: {
            create: (queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/`, queryParams),
            destroy: (pk: any, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/${pk}/`, queryParams),
            list: (queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/`, queryParams),
            partial_update: (pk: any, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/${pk}/`, queryParams),
            summary: (queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/summary/`, queryParams),
            update: (pk: any, queryParams?: ProgressNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/progress-notes/${pk}/`, queryParams),
        },

        PsychotropicGroupsViewSet: {
            create: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/`, queryParams),
            destroy: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/${pk}/`, queryParams),
            list: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/`, queryParams),
            list_detail: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/${pk}/detail/`, queryParams),
            search: (queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/search/`, queryParams),
            update: (pk: any, queryParams?: PsychotropicGroupsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropic-groups/${pk}/`, queryParams),
        },

        PsychotropicsViewSet: {
            detailed_patient_list: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/detailed-patient-list/`, queryParams),
            filtered_diagnosis_overview: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/filtered-diagnosis-overview/`, queryParams),
            filtered_population_overview: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/filtered-population-overview/`, queryParams),
            filtered_treatment_overview: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/filtered-treatment-overview/`, queryParams),
            global_population_overview: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/global-population-overview/`, queryParams),
            heatmap: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/heatmap/`, queryParams),
            patient_details: (pk: any, queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/${pk}/patient-details/`, queryParams),
            treatment_overview: (queryParams?: PsychotropicsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/psychotropics/treatment-overview/`, queryParams),
        },

        QRDAViewSet: {
            post: (queryParams?: QRDAViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/qrda-report-generate`, queryParams),
        },

        RefreshTokenViewSet: {
            create: (queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/refresh-tokens/`, queryParams),
            destroy: (pk: any, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/refresh-tokens/${pk}/`, queryParams),
            list: (queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/refresh-tokens/`, queryParams),
            partial_update: (pk: any, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/refresh-tokens/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/refresh-tokens/${pk}/`, queryParams),
            update: (pk: any, queryParams?: RefreshTokenViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/oauth/refresh-tokens/${pk}/`, queryParams),
        },

        RehospitalizationView: {
            get: (queryParams?: RehospitalizationViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rehospitalization_deprecated`, queryParams),
        },

        RelatedPersonModelViewSet: {
            create: (queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/related_person/`, queryParams),
            destroy: (pk: any, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/related_person/${pk}/`, queryParams),
            list: (queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/related_person/`, queryParams),
            partial_update: (pk: any, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/related_person/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/related_person/${pk}/`, queryParams),
            update: (pk: any, queryParams?: RelatedPersonModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/related_person/${pk}/`, queryParams),
        },

        RemitsViewSet: {
            list: (queryParams?: RemitsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/remits/`, queryParams),
            retrieve: (pk: any, queryParams?: RemitsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/remits/${pk}/`, queryParams),
        },

        ReportByUnitView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-by-units`, queryParams),
        },

        ReportViewSet: {
            cancel: (pk: any, queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/${pk}/cancel/`, queryParams),
            create: (queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/`, queryParams),
            list: (queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/`, queryParams),
            partial_update: (pk: any, queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/${pk}/`, queryParams),
            stats: (queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/stats/`, queryParams),
            update: (pk: any, queryParams?: ReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/report-queue/${pk}/`, queryParams),
        },

        ResidentDaysView: {
            get: (queryParams?: ResidentDaysViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-days`, queryParams),
        },

        ResidentNoteViewSet: {
            create: (queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-notes/`, queryParams),
            destroy: (pk: any, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-notes/${pk}/`, queryParams),
            list: (queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-notes/`, queryParams),
            partial_update: (pk: any, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-notes/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-notes/${pk}/`, queryParams),
            update: (pk: any, queryParams?: ResidentNoteViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/resident-notes/${pk}/`, queryParams),
        },

        RxNormViewSet: {
            list: (queryParams?: RxNormViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rx-norm/`, queryParams),
            retrieve: (pk: any, queryParams?: RxNormViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/rx-norm/${pk}/`, queryParams),
        },

        SMARTAuthorizationView: {
            get: (queryParams?: SMARTAuthorizationViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/o/smart_authorization/`, queryParams),
            post: (queryParams?: SMARTAuthorizationViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/o/smart_authorization/`, queryParams),
        },

        SSNModelViewSet: {
            create: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/`, queryParams),
            destroy: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/${pk}/`, queryParams),
            list: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/`, queryParams),
            list_detail: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/${pk}/detail/`, queryParams),
            search: (queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/search/`, queryParams),
            update: (pk: any, queryParams?: SSNModelViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/ssn/${pk}/`, queryParams),
        },

        ScopeDescriptionView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/oauth/scopes`, queryParams),
        },

        SearchBillingCodeViewSet: {
            list: (queryParams?: SearchBillingCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/neo-billing-codes/`, queryParams),
        },

        SearchBillingModifierViewSet: {
            list: (queryParams?: SearchBillingModifierViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/neo-billing-modifiers/`, queryParams),
        },

        SearchCptCodeViewSet: {
            list: (queryParams?: SearchCptCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/neo-cpt-codes/`, queryParams),
        },

        SearchView: {
            get: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/search`, queryParams),
        },

        SecondaryVisitBillingCodeViewSet: {
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-billing-secondary-codes/`, queryParams),
        },

        SendMailView: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/send-mail`, queryParams),
        },

        ServiceCodeViewSet: {
            list: (queryParams?: ServiceCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/payments/service-codes/`, queryParams),
            retrieve: (pk: any, queryParams?: ServiceCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/payments/service-codes/${pk}/`, queryParams),
        },

        SnomedConceptSearchViewSet: {
            retrieve: (pk: any, queryParams?: SnomedConceptSearchViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/snomed_concept_search/${pk}/`, queryParams),
            search: (queryParams?: SnomedConceptSearchViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/snomed_concept_search/search/`, queryParams),
        },

        StatusObservationViewSet: {
            create: (queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/status-observations/`, queryParams),
            destroy: (pk: any, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/status-observations/${pk}/`, queryParams),
            list: (queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/status-observations/`, queryParams),
            partial_update: (pk: any, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/status-observations/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/status-observations/${pk}/`, queryParams),
            update: (pk: any, queryParams?: StatusObservationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/status-observations/${pk}/`, queryParams),
        },

        SyncDetailPaginatedViewSet: {
            list: (queryParams?: SyncDetailPaginatedViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/sync_detail_paginated/`, queryParams),
        },

        SyncDetailViewSet: {
            list: (queryParams?: SyncDetailViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/sync_detail/`, queryParams),
        },

        SyncProcessViewSet: {
            list: (queryParams?: SyncProcessViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/sync_process/`, queryParams),
        },

        SyncViewSet: {
            create: (queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/`, queryParams),
            destroy: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/${pk}/`, queryParams),
            list: (queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/`, queryParams),
            list_detail: (queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/${pk}/detail/`, queryParams),
            schedule_table: (queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/schedule-table/`, queryParams),
            search: (queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/search/`, queryParams),
            update: (pk: any, queryParams?: SyncViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/syncs/${pk}/`, queryParams),
        },

        SystemAlertViewSet: {
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/`, queryParams),
            list_active: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/list_active/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/${pk}/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/system_alerts/${pk}/`, queryParams),
        },

        TaskAssignedUserViewSet: {
            assign_user: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/assign-user/`, queryParams),
            batch_update: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/batch-update/`, queryParams),
            create: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/`, queryParams),
            destroy: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, queryParams),
            list: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/`, queryParams),
            list_detail: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/${pk}/detail/`, queryParams),
            search: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/search/`, queryParams),
            update: (pk: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-assigned-users/${pk}/`, queryParams),
        },

        TaskBillingStatusViewSet: {
            batch_update: (queryParams?: TaskBillingStatusViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/billing-status/batch-update/`, queryParams),
        },

        TaskHistory: {
            download_patient: (queryParams?: TaskHistoryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-history/download_patient/`, queryParams),
            list: (queryParams?: TaskHistoryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-history/`, queryParams),
            patients: (queryParams?: TaskHistoryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-history/patients/`, queryParams),
        },

        TaskLastValuesView: {
            get: (queryParams?: TaskLastValuesViewQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/last-values`, queryParams),
        },

        TaskProposalViewSet: {
            create: (queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/`, queryParams),
            create_or_update: (queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/create_or_update/`, queryParams),
            destroy: (pk: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/${pk}/`, queryParams),
            list: (queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/`, queryParams),
            partial_update: (pk: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/${pk}/`, queryParams),
            sort: (queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/sort/`, queryParams),
            update: (pk: any, queryParams?: TaskProposalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/task-proposals/${pk}/`, queryParams),
        },

        TaskSchedulerViewSet: {
            list: (queryParams?: TaskSchedulerViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-list/`, queryParams),
            retrieve: (pk: any, queryParams?: TaskSchedulerViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-list/${pk}/`, queryParams),
        },

        TaskStatusHistoryViewSet: {
            list: (queryParams?: TaskStatusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/status-history/`, queryParams),
            retrieve: (pk: any, queryParams?: TaskStatusHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/status-history/${pk}/`, queryParams),
        },

        TaskSummary: {
            download: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/download/`, queryParams),
            download_summary: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/download_summary/`, queryParams),
            download_summary_by_member: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/download_summary_by_member/`, queryParams),
            invalid_tasks: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/invalid_tasks/`, queryParams),
            list: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/`, queryParams),
            overview: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/overview/`, queryParams),
            patient_tasks: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/patient_tasks/`, queryParams),
            patients: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/patients/`, queryParams),
            summary_by_code: (queryParams?: TaskSummaryQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/task-summary/summary_by_code/`, queryParams),
        },

        TaskViewSet: {
            approve_for_billing: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/approve-for-billing/`, queryParams),
            batch_pdf_download: (queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/batch_pdf_download/`, queryParams),
            batch_resolve: (queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/batch-resolve/`, queryParams),
            create: (queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/`, queryParams),
            destroy: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/`, queryParams),
            download_as_ccd: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/download-as-ccd/`, queryParams),
            download_history: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/download-history/`, queryParams),
            download_pdf: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/download-pdf/`, queryParams),
            generate_upload_ccd: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/generate-upload-ccd/`, queryParams),
            list: (queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/`, queryParams),
            partial_update: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/`, queryParams),
            remove_billing_approval: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/remove-billing-approval/`, queryParams),
            resolve_all_non_draft_notes: (queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/resolve-all-non-draft-notes/`, queryParams),
            retrieve: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/`, queryParams),
            review_for_scheduling: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/review_for_scheduling/`, queryParams),
            set_billing_status: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/set-billing-status/`, queryParams),
            update: (pk: any, queryParams?: TaskViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/tasks/tasks/${pk}/`, queryParams),
        },

        TokenObtainPairView: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/auth/login`, queryParams),
        },

        TokenRefreshView: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/auth/refresh`, queryParams),
        },

        TokenVerifyView: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/auth/verify`, queryParams),
        },

        TreatmentEntriesBySpecialtyViewSet: {
            list: (queryParams?: TreatmentEntriesBySpecialtyViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-by-specialty/`, queryParams),
        },

        TreatmentHistoryAntibioticsViewSet: {
            create: (queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-treatments/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-treatments/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-treatments/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-treatments/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-treatments/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryAntibioticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antibiotics-treatments/${pk}/`, queryParams),
        },

        TreatmentHistoryAntidepressantsViewSet: {
            create: (queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antidepressants-treatments/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antidepressants-treatments/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antidepressants-treatments/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antidepressants-treatments/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antidepressants-treatments/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryAntidepressantsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antidepressants-treatments/${pk}/`, queryParams),
        },

        TreatmentHistoryAntipsychoticsViewSet: {
            create: (queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antipsychotics-treatments/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antipsychotics-treatments/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antipsychotics-treatments/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antipsychotics-treatments/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antipsychotics-treatments/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryAntipsychoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/antipsychotics-treatments/${pk}/`, queryParams),
        },

        TreatmentHistoryAuditViewSet: {
            create: (queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-audit/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-audit/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-audit/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-audit/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-audit/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-audit/${pk}/`, queryParams),
        },

        TreatmentHistoryICDUpdateViewSet: {
            partial_update: (pk: any, queryParams?: TreatmentHistoryICDUpdateViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-icd-update/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryICDUpdateViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-icd-update/${pk}/`, queryParams),
        },

        TreatmentHistoryNarcoticsViewSet: {
            create: (queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/narcotics-treatments/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/narcotics-treatments/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/narcotics-treatments/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/narcotics-treatments/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/narcotics-treatments/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryNarcoticsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/narcotics-treatments/${pk}/`, queryParams),
        },

        TreatmentHistoryOriginsViewSet: {
            create: (queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-origins/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-origins/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-origins/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-origins/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-origins/${pk}/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryOriginsViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history-origins/${pk}/`, queryParams),
        },

        TreatmentHistoryViewSet: {
            batch_delete: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/batch-delete/`, queryParams),
            create: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/`, queryParams),
            destroy: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/${pk}/`, queryParams),
            list: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/`, queryParams),
            list_detail: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/${pk}/detail/`, queryParams),
            search: (queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/search/`, queryParams),
            update: (pk: any, queryParams?: TreatmentHistoryViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/treatment-history/${pk}/`, queryParams),
        },

        TrendViewSet: {
            infections: (queryParams?: TrendViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/trends/infections/`, queryParams),
        },

        UnitViewSet: {
            create: (queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/`, queryParams),
            destroy: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/${pk}/`, queryParams),
            list: (queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/`, queryParams),
            list_detail: (queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/${pk}/detail/`, queryParams),
            search: (queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/search/`, queryParams),
            update: (pk: any, queryParams?: UnitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/units/${pk}/`, queryParams),
        },

        UnmatchedPatientActionViewSet: {
            category_count: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/category-counts/`, queryParams),
            create: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/`, queryParams),
            destroy: (pk: any, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/${pk}/`, queryParams),
            list: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/`, queryParams),
            partial_update: (pk: any, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/${pk}/`, queryParams),
            source_counts: (queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/source-counts/`, queryParams),
            update: (pk: any, queryParams?: UnmatchedPatientActionViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/unmatched-patient-action/${pk}/`, queryParams),
        },

        UsedICDCodeViewSet: {
            get: (queryParams?: UsedICDCodeViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/used-icd-codes`, queryParams),
        },

        UserAccessViewSet: {
            list: (queryParams?: UserAccessViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-access-log/`, queryParams),
            retrieve: (pk: any, queryParams?: UserAccessViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-access-log/${pk}/`, queryParams),
        },

        UserFacilityViewSet: {
            create: (queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/`, queryParams),
            destroy: (pk: any, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/${pk}/`, queryParams),
            list: (queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/`, queryParams),
            list_filtered: (queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/list_filtered/`, queryParams),
            partial_update: (pk: any, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/${pk}/`, queryParams),
            update: (pk: any, queryParams?: UserFacilityViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users-facilities/${pk}/`, queryParams),
        },

        UserLogViewSet: {
            list: (queryParams?: UserLogViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-logs/`, queryParams),
            logs: (pk: any, queryParams?: UserLogViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-logs/${pk}/logs/`, queryParams),
            retrieve: (pk: any, queryParams?: UserLogViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-logs/${pk}/`, queryParams),
        },

        UserSettingsViewSet: {
            partial_update: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-settings`, queryParams),
            retrieve: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-settings`, queryParams),
        },

        UserStatusChangesViewSet: {
            list: (queryParams?: UserStatusChangesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-status-changes/`, queryParams),
            retrieve: (pk: any, queryParams?: UserStatusChangesViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-status-changes/${pk}/`, queryParams),
        },

        UserTimeSpentOnLocationViewSet: {
            create: (queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-time-spent-on-locations/`, queryParams),
            destroy: (pk: any, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, queryParams),
            list: (queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-time-spent-on-locations/`, queryParams),
            partial_update: (pk: any, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, queryParams),
            update: (pk: any, queryParams?: UserTimeSpentOnLocationViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/user-time-spent-on-locations/${pk}/`, queryParams),
        },

        UserViewSet: {
            create: (queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/`, queryParams),
            destroy: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/`, queryParams),
            get_password_change_url: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/get_password_change_url/`, queryParams),
            list: (queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/`, queryParams),
            list_detail: (queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/list-detail/`, queryParams),
            partial_update: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/`, queryParams),
            retrieve_detail: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/detail/`, queryParams),
            search: (queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/search/`, queryParams),
            send_password_change_mail: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/send_password_change_mail/`, queryParams),
            status_changes: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/status_changes/`, queryParams),
            update: (pk: any, queryParams?: UserViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/users/${pk}/`, queryParams),
        },

        ValueSetViewSet: {
            list: (queryParams?: ValueSetViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/valuesets/`, queryParams),
            retrieve: (pk: any, queryParams?: ValueSetViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/valuesets/${pk}/`, queryParams),
        },

        VerifyFax: {
            post: (token: any, pk: any, fax_type: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/ccm/verify-fax/${token}/${pk}/${fax_type}`, queryParams),
        },

        VerifyFaxNew: {
            post: (token: any, queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/ccm/verify-fax-new/${token}`, queryParams),
        },

        VerifyPharmacyOrder: {
            post: (queryParams: any = null, headers: Headers = null): string => addQueryString(AP + `/api/v4/verify-pharmacy-order/`, queryParams),
        },

        VisitReportAuditViewSet: {
            batch_create_cmrns: (queryParams?: VisitReportAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports-audit/batch_create_cmrns/`, queryParams),
            check_patient_cmrns: (queryParams?: VisitReportAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports-audit/check_patient_cmrns/`, queryParams),
            patients_with_cmrn: (queryParams?: VisitReportAuditViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports-audit/patients_with_cmrn/`, queryParams),
        },

        VisitReportViewSet: {
            download_visit_billing: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/download_visit_billing/`, queryParams),
            download_visit_billing_extended: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/download_visit_billing_extended/`, queryParams),
            download_visit_productivity: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/download_visit_productivity/`, queryParams),
            has_drafts: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/has_drafts/`, queryParams),
            summary: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/summary/`, queryParams),
            tasks: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/tasks/`, queryParams),
            tasks_for_scheduler_paginated: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/tasks_for_scheduler_paginated/`, queryParams),
            tasks_paginated: (queryParams?: VisitReportViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visit-reports/tasks_paginated/`, queryParams),
        },

        VisitViewSet: {
            create: (queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/`, queryParams),
            destroy: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/${pk}/`, queryParams),
            download_visit_summary_by_patient: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/${pk}/download-visit-summary/`, queryParams),
            list: (queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/`, queryParams),
            partial_update: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/${pk}/`, queryParams),
            update: (pk: any, queryParams?: VisitViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/visits/${pk}/`, queryParams),
        },

        VitalViewSet: {
            create: (queryParams?: VitalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/vitals/`, queryParams),
            destroy: (pk: any, queryParams?: VitalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/vitals/${pk}/`, queryParams),
            list: (queryParams?: VitalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/vitals/`, queryParams),
            partial_update: (pk: any, queryParams?: VitalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/vitals/${pk}/`, queryParams),
            retrieve: (pk: any, queryParams?: VitalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/vitals/${pk}/`, queryParams),
            update: (pk: any, queryParams?: VitalViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/vitals/${pk}/`, queryParams),
        },

        WorkListViewSet: {
            list: (queryParams?: WorkListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/work-list/`, queryParams),
            partial_update: (pk: any, queryParams?: WorkListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/work-list/${pk}/`, queryParams),
            reject: (pk: any, queryParams?: WorkListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/work-list/${pk}/reject/`, queryParams),
            retrieve: (pk: any, queryParams?: WorkListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/work-list/${pk}/`, queryParams),
            update: (pk: any, queryParams?: WorkListViewSetQueryParams, headers: Headers = null): string => addQueryString(AP + `/api/v4/work-list/${pk}/`, queryParams),
        },

    };

    urls=APIService.urls

}
