export interface AnyProperties {
    [prop: string]: any;
}

export type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;

export type Id = number | string;

export function isId(x: any) {
    return ['number', 'string'].includes(typeof x);
}

export function getId(x: any): number {
    if (!x) return x;
    return isId(x) ? x : x.id;
}

export function getIdT(x: number): number;
export function getIdT(x: string): string;
export function getIdT(x: {id?: number}): number;
export function getIdT(x: {id?: string}): string;
export function getIdT(x: any) {
    if (!x) return x;
    return isId(x) ? x : x.id;
}

export function setIfDefined<T, K extends keyof T>(target: T, targetProp: K, val: T[K]) {
    if (typeof val !== 'undefined') target[targetProp] = val;
}

export type Errors<T = any> = {[key in keyof T]?: string[]};

export type ServiceType<T> = Omit<T, 'merge' | '_binaryRead' | 'toBinary' | '_binaryWrite'>;

export type NumberKeys<T> = {[K in keyof T]: T[K] extends number ? K : never}[keyof T];
